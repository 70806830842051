import {useCallback, useState} from "react";

const useInput = (initValue) => {
    const [value, setValue] = useState(initValue);

    const onChangeValue = useCallback((callbackValue) => {
        setValue(callbackValue)
    }, [])

    return [value, onChangeValue];
}


export default useInput;