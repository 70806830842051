import React, {useLayoutEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {GET_ARAM_BALANCE_REQUEST} from "../../store/reducer/balance";

const useDispatchAramBalance = () => {
    const {
        getAramBalanceLoading,
        getAramBalanceDone,
        getAramBalanceError,
        aram,
    } = useSelector((state) => state.balance);
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        dispatch({
            type: GET_ARAM_BALANCE_REQUEST,
        })
    }, []);

    return {getAramBalanceLoading, getAramBalanceDone, getAramBalanceError, data: aram}
};

export default useDispatchAramBalance;
