import React, {useMemo} from 'react';
import styled, {useTheme} from "styled-components";
import {SummonerItem} from "../../common/ImageSrc";

const LensWrapper = styled.div`
  .imgItem {
    border-radius: 50%;
  }

  .imgItem + .imgItem {
    margin-left: 4px;
  }
`;

const EmptyDiv = styled.div`

`;
const ItemWithMythic = ({item, array, lens, margin, emptyColor, isWin}) => {
    const theme = useTheme();
    const itemMemo = useMemo(() => {
        try {
            if (Array.isArray(array)) {
                return array;
            }
            return [];
        } catch (e) {
            return [];
        }
    }, [array]);

    return (
        <>
            {itemMemo.map((data, index) => {
                return (
                    <div className={'img_wrapper'} key={index}>
                        <SummonerItem itemId={data} key={index}
                                      emptyColor={isWin ? theme.color.blue_100 : theme.color.red_500}/>
                    </div>
                )
            })}
            {lens &&
                <LensWrapper>
                    <SummonerItem itemId={lens}/>
                </LensWrapper>
            }
        </>
    );
};

export default ItemWithMythic;