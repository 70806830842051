import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {MultiSearchContainer} from '../../styled/MultiStyle';
import styled from "styled-components";

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 8px;
`;

const MultiSearch = ({getSummonersName, inpText}) => {
    const {t} = useTranslation();
    const [text, setText] = useState('');


    // 찾기버튼 클릭
    const handleSummonerSearch = () => {

        if (text !== "") {
            getSummonersName(text);
        }
    };

    // 소환사명 입력
    const handleSummonersName = (e) => {
        setText(e.target.value);
    };

    useEffect(() => {
        if (inpText !== "") {
            setText(inpText);
        }
    }, [inpText]);


    return (
        <MultiSearchContainer>
      <textarea
          onChange={handleSummonersName}
          placeholder={`Zeus #T1\nOner #T1\nFaker #T1\nGumayusi #T1\nKeria #T1`}
          spellCheck="false"
          value={text}
      ></textarea>
            <ButtonWrapper>
                <button onClick={handleSummonerSearch}>
                    <i>{t('multi.search')}</i>
                </button>
            </ButtonWrapper>
        </MultiSearchContainer>
    );
};

export default MultiSearch;
