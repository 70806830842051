import React, {useCallback} from 'react';
import {useTheme} from "styled-components";

const UseRuneFunc = () => {
    const theme = useTheme();


    const findCategoryRune = useCallback((runeData, findValue) => {
        return runeData.find((data) => data.id === findValue);
    }, []);


    const findChildRune = useCallback((categoryRune = [], findValue) => {
        return categoryRune.find((data) => {
            if (data.id === findValue) {
                return true;
            } else if (Array.isArray(data.runes)) {
                return findCategoryRune(data.runes, findValue);
            }
        });
    }, [findCategoryRune]);

    const findParentRune = useCallback((categoryRune = [], findValue) => {
        try {
            if (typeof findValue === 'number') {
                return categoryRune.find((data) => {
                    if (data.id === findValue) {
                        return true;
                    } else {
                        return findChildRune(data.slots, findValue);
                    }
                });
            }
            return null;
        } catch (e) {
            return [];
        }

    }, [findChildRune]);





    const getRuneBorderColor = (runeId) => {
        switch (Number(runeId)) {
            case 8000:
                return '#FFCC54'
            case 8100:
                return '#E85C5C';
            case 8200:
                return '#8C85EB'
            case 8300:
                return '#6CBABC'
            case 8400:
                return "#6DD174"
        }
    }
    return {
        getRuneBorderColor,
        findCategoryRune,
        findParentRune,
        findChildRune
    }
};

export default UseRuneFunc;