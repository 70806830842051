import {useSuspenseQuery} from "@tanstack/react-query";
import axios from "axios";

const setEventName = (name) => {
    switch (name) {
        case 'たぬき忍者':
            return "TanukiNinja"
        case 'しゃるる':
            return "Syaruru"
        case 'raizin':
            return "Raizin"
        case 'jasper':
            return "Jasper"
        case 'げまげま':
            return "ROOM"
        default:
            return ""
    }
}

const useEventAPI = () => {
    const query = useSuspenseQuery({
        queryKey: ['jpEvent'],
        queryFn: async () => {
            const [event,liveInfo,scrim] = await Promise.all([
                axios.get(`${process.env.REACT_APP_CACHE_API}summoner/strm_pro_event`),
                axios.get(`${process.env.REACT_APP_CACHE_API}summoner/strm_pro_event_broadcast`),
                axios.get(`${process.env.REACT_APP_CACHE_API}summoner/strm_pro_win_table`)
            ]);


            const eventArray = Object.values(event.data)
            const scrimArray = Object.values(scrim.data);

            const eventObjArray = eventArray.map((data) => {
                const findLeader = data.find((item) => item.hasOwnProperty('comment_jp'));
                const playerList = data.filter((item) => item.puu_id !== findLeader.puu_id);
                return {
                    leader: findLeader,
                    player: playerList
                }
            })


            return {
                eventArray: eventObjArray,
                liveInfo: liveInfo?.data?.broadcast_info || [],
                tier_off: {
                    challenger_1st: 1000,
                    challenger_cut_off: 1000,
                    grandmaster_cut_off: 1000,
                },
                scrim: scrimArray
            }
        },
        retry: 0,
        refetchOnWindowFocus: false,
    })
    return {
        eventQuery: query,
    }
};

export default useEventAPI;