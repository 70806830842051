import React, {useMemo} from 'react';
import styled, {useTheme} from "styled-components";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  & + & {
    margin-top: 10px;
  }
`;
const DailyTag = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
  align-items: center;
  width: fit-content;
  padding: 6px 7px;
  background: ${props => props.bgColor};
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
`;
const TempPatch = styled.div`
  position: absolute;
  width: 6px;
  height:102px;
  left: 0;
  top: ${props => {
    let top = 0;
    if (props.daily) top = '30px'
    return top
  }};
  z-index: 3;
  background: ${props => props.bgColor};
  border-top-left-radius: ${props => {
    let radius = '5px';
    if (props.daily) radius = 0;
    return radius
  }};
  border-bottom-left-radius: ${props => {
    let radius = '5px';
    if (props.open) radius = 0;
    return radius
  }};
`;
const TagHeader = styled.span`
  font-size: 12px;
  margin-left: 5px;
  color: #fff;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
const RankHeader = styled(TagHeader)`
  font-weight: bold;
  cursor: default;
  &:hover {
    text-decoration: none;
  }
`;
const SummonerDailyTag = ({children, isWin, isCarry, open, matchId}) => {
    const dailyAi = useSelector((state) => state.summoner.dailyAi);
    const theme = useTheme();
    const {t} = useTranslation()
    const summoner = useSelector((state) => state.summoner.summoner);
    const {region} = useParams()
    const tier = summoner?.season_tier_info_dict?.ranked_solo_5x5?.tier ? summoner.season_tier_info_dict.ranked_solo_5x5.tier.toLowerCase() : 'all'

    const isDaily = useMemo(() => {
        try {
            return dailyAi.find((data) => data.match_id === matchId)
        } catch (e) {
            return false
        }
    }, [dailyAi]);

    const getColor = () => {

        if (isWin) {
            return theme.color.blue_100
        } else {
            return theme.color.red_100
        }
    }

    // if (true) {
    //     return (
    //         <Wrapper>
    //             <TempPatch bgColor={getColor()} open={open} daily={true}/>
    //             <DailyTag bgColor={getColor()}>
    //                 <img src={'/images/icn_trophy_yellow.svg'} width={20} height={20} alt={""}/>
    //                 <TagHeader>{t("daily.summonerHeader")}<RankHeader>{1}{t("summoner.graph_grade", {
    //                     count: 1,
    //                     ordinal: true
    //                 })}</RankHeader> </TagHeader>
    //             </DailyTag>
    //             {children}
    //         </Wrapper>
    //     )
    // }

    if (isDaily) {
        return (
            <Wrapper>
                <TempPatch bgColor={getColor()} open={open} daily={true}/>
                <DailyTag bgColor={getColor()}>
                    <img src={'/images/icn_trophy_yellow.svg'} width={20} height={20} alt={""}/>
                    <TagHeader onClick={() => {
                        window.open(`/daily/ai/${region?.toLowerCase()}/${isDaily.game_type === 450 ? 'aram' : 'rank'}/${isDaily.game_type === 450? "" : tier}`)
                    }}>{t("daily.summonerHeader")}</TagHeader>
                    <RankHeader>{isDaily.ranking}{t("summoner.graph_grade", {
                        count: isDaily.ranking,
                        ordinal: true
                    })}</RankHeader>
                </DailyTag>
                {children}
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            <TempPatch bgColor={getColor()} open={open}/>
            {children}
        </Wrapper>
    );
};

export default SummonerDailyTag;