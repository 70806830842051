import React, {useCallback} from 'react';
import styled, {useTheme} from "styled-components";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  background-color: ${props => props.theme.color.white_201};
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;
  align-items: center;
  padding: 0 10px;
  flex:1;
`;

const InputBar = styled.input`
  height: 40px;
  border-radius: 6px;
  color: ${props => props.theme.color.dark_100};
  background-color: transparent;
  border: 0;
  margin-right: 30px;
  flex: 1;
  padding-left: 5px;
  font-size: 14px;

  ::placeholder {
    color: ${props => props.theme.color.dark_200};
  }
`;

const SearchButton = styled.button`
  right: 10px;
  top: 0;
  bottom: 2px;
  position: absolute;
`;

const ButtonImg = styled.img`
`;


const ChampionsInput = ({inputValue, setInputValue}) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const onChangeInputValue = useCallback((value) => {
        if (typeof value.target.value === 'string') {
            setInputValue(value.target.value);
        }
    }, []);


    return (
        <Wrapper>
            <InputBar
                autoComplete={'off'}
                value={inputValue}
                onChange={onChangeInputValue}
                placeholder={t('championAnalyze.searchPlaceholder')}
            />
            <SearchButton>
                <ButtonImg
                    src={`${theme.icon.path}/ico_search_w.svg`}
                    width={18}
                    height={18}
                />
            </SearchButton>
        </Wrapper>
    );
};


export default React.memo(ChampionsInput);
