import styled from "styled-components";
import useChampionFunc from "../../../../common/hooks/useChampionFunc";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import React, {useCallback} from "react";
import ChampionSkillImage from "../../../common/ChampionSkillImage";

const Wrapper = styled.div`
  display: flex;
`;
const PaddingBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & + & {
    margin-left: 5px;
  }
`;

const ListTagValue = styled.div`
  border-radius: 4px;
  background-color: ${props => {
    let color = props.theme.color.white_200;
    if (props.isFirstMastered) color = props.theme.color.green_200
    if (props.last) color = props.theme.color.green_200;
    return color;
  }};

  font-size: 14px;
  font-weight: ${props => {
    let weight = 500;
    if (props.isFirstMastered || props.last) weight = 'bold';
    return weight
  }};
  width: 24px;
  aspect-ratio: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${props => {
    let color = props.theme.color.dark_200;
    if (props.last || props.isFirstMastered) color = props => props.theme.color.green_100;

    return color;
  }};

`;

const ArrowBox = styled.div`
  margin: 0 5px;
`;

const SkillWrapper = styled.div`
  height: 32px;
  display: flex;
  flex-direction: row;
`;

const SequenceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 32px;
  margin-left: 15px;
`;
const BuildSkillSq = ({list, champion_id, skillList}) => {
    const {getChampion} = useChampionFunc()

    const version = useSelector((state) => state.gameVersion.version);
    const {t} = useTranslation();
    const champion = getChampion(champion_id);

    const getLastSkills = useCallback((array) => {
        try {
            if (Array.isArray(array)) {
                const sliceArray = array.slice(0, 14);
                const qIndex = sliceArray.lastIndexOf(1);
                const qCount = sliceArray.filter((data) => data === 1).length;
                const qMastered = qCount >= skillList?.spells[0].cost.length ? 1 : 0;
                const wIndex = sliceArray.lastIndexOf(2);
                const wCount = sliceArray.filter((data) => data === 2).length
                const wMastered = wCount >= skillList?.spells[1].cost.length ? 1 : 0;
                const eIndex = sliceArray.lastIndexOf(3);
                const eCount = sliceArray.filter((data) => data === 3).length
                const eMastered = eCount >= skillList?.spells[2].cost.length ? 1 : 0;
                const rIndex = sliceArray.lastIndexOf(4);
                const rCount = sliceArray.filter((data) => data === 4).length
                const rMastered = rCount >= skillList?.spells[3]?.cost.length ? 1 : 0;
                let indexArray = [{
                    index: qIndex,
                    count: qCount,
                    mastered: qMastered,
                    value: 1,
                    text: 'Q'
                }, {
                    index: wIndex,
                    count: wCount,
                    mastered: wMastered,
                    value: 2,
                    text: 'W'
                }, {
                    index: eIndex,
                    count: eCount,
                    mastered: eMastered,
                    value: 3,
                    text: 'E'
                }];

                //77 == 우디르
                if (Number(champion?.champion_id) === 77) {
                    indexArray = [
                        ...indexArray,
                        {
                            index: rIndex,
                            count: rCount,
                            mastered: rMastered,
                            value: 4,
                            text: 'R'
                        }];
                }


                indexArray.sort((a, b) => {
                    if (a.mastered < b.mastered) {
                        return 1;
                    } else if (a.mastered > b.mastered) {
                        return -1;
                    } else if (a.count < b.count) {
                        return 1;
                    } else if (a.count > b.count) {
                        return -1;
                    } else if (a.index > b.index) {
                        return 1;
                    } else if (a.index < b.index) {
                        return -1;
                    }
                    return 0;
                });
                return indexArray || [];
            }
            return [];
        } catch (e) {
            console.log(e);
            return [];
        }

    }, [skillList])
    const getSkillName = (index) => {
        switch (index) {
            case 1:
                return 'Q';
            case 2:
                return 'W';
            case 3:
                return 'E';
            case 4:
                return 'R';
            default:
                return ""
        }
    }

    const getSkillId = (skillName) => {
        switch (skillName) {
            case 'Q':
                return 0;
            case 'W':
                return 1;
            case 'E':
                return 2;
            case 'R':
                return 3;
            default:
                return 0
        }
    }

    const skillValue = getLastSkills(list)
    const masterSkill = skillValue[0];

    return (
        <Wrapper>
            <SkillWrapper>
                {skillValue.map((item, index) => {
                    return (
                        <ChampionSkillImage
                            width={32}
                            height={32}
                            key={index}
                            spell={skillList?.spells[getSkillId(item.text)]}
                            version={version}
                            img={skillList?.spells[getSkillId(item.text)]?.image?.full}
                            absoluteText={item.text}
                        >
                            {index + 1 !== skillValue.length && <ArrowBox>
                                <img src={'/images/icon_collapse_arrow.svg'} width={14} height={14}/>
                            </ArrowBox>}
                        </ChampionSkillImage>
                    )
                })}
            </SkillWrapper>
            <SequenceWrapper>

                {list?.map((item, index) => {
                    return (
                        <PaddingBox key={index}>
                            <ListTagValue isFirstMastered={masterSkill.value === item}
                                          last={masterSkill.index === index}>{getSkillName(item)}</ListTagValue>
                        </PaddingBox>
                    )
                })
                }

            </SequenceWrapper>
        </Wrapper>
    );
};

export default BuildSkillSq;