import React, {useMemo, useRef} from 'react';
import styled from "styled-components";
import dompurify from "dompurify";
import Tooltip from "react-tooltip-lite";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;


const TextBox = styled(Tooltip)`
  flex: 1;
  .react-tooltip-lite {
    background-color: #13172B;
    height: max-content;
    flex-wrap: wrap;
    box-shadow: 0 2px 4px #00000029;
    opacity: 1;
    padding: 6px 16px;
    border-radius: 6px;
    font-weight: 300;
    font-size: 12px;
    color: #ECECEC;
    line-height: 1.4;
    text-align: ${props => {
    let align = 'center';
    if (props.textAlign) align = props.textAlign;
    return align;
}};
  }
  .react-tooltip-lite-arrow {
    border-color: #13172B;
  }
`;


const BigTooltip = ({children, tooltipText, disabled,textColor,textAlign}) => {
    const sanitizer = dompurify.sanitize;
    const ref = useRef(null);



    const renderText = useMemo(() => {
        if (tooltipText) {

            return (
                <div style={{maxWidth: 400,whiteSpace:'pre-wrap'}} dangerouslySetInnerHTML={{__html: sanitizer(tooltipText)}}/>
            )
        }
        return "";
    }, [tooltipText]);



    //어떤 광고는 zIndex값이 99999임...
    return (
        <Wrapper disabled={disabled} ref={ref} className={'bigTooltip'}>
            {tooltipText ?
                <TextBox content={renderText} arrow={true} color={textColor} hoverDelay={50} distance={15} textAlign={textAlign} zIndex={999999}>
                    {children}
                </TextBox> : children}
        </Wrapper>
        // <Wrapper disabled={disabled}>
        //     {children}
        //     <AbsoluteBox className={'absoluteBox'} reverse={reverse}>
        //         {reverse && <ArrowUp/>}
        //         <TextBox>
        //             <TooltipText
        //                 dangerouslySetInnerHTML={{__html: sanitizer(tooltipText)}}
        //             >
        //             </TooltipText>
        //         </TextBox>
        //         {!reverse && <Arrow/>}
        //     </AbsoluteBox>
        // </Wrapper>
    );
};


export default BigTooltip;
