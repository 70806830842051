import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // 로컬스토리지를 불러옵니다
import theme from './modules/theme';
import summoner from './modules/summoner';
import gameVersion from './modules/gameVersion';
import imageStore from './modules/imageStore';
import modal from './modules/modal';
import searchList from './modules/searchList';
import bookmarkList from './modules/bookmarkList';
import region from './modules/region';
import translation from './modules/translation';
import notice from "./modules/notice";
import champions from "./modules/champions";
import ranking from "./modules/ranking";
import video from "./modules/video"
import balance from './modules/balace';
import header from './modules/header';
import spectate from './modules/spectate';

const persistConfig = {
  key: 'searchStore',
  // localStorage에 저장합니다.
  storage,
  // 여러개의 reducer 중에 searchList만 localstorage에 저장합니다.
  // blacklist -> 그것만 제외합니다
  whitelist: ['searchList', 'bookmarkList','theme'],
};

const rootReducer = combineReducers({
  spectate,
  balance,
  theme,
  notice,
  summoner,
  champions,
  gameVersion,
  imageStore,
  modal,
  searchList,
  bookmarkList,
  region,
  translation,
  ranking,
  video,
  header
});

export default persistReducer(persistConfig, rootReducer);
