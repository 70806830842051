import React from 'react';

import styled from "styled-components";
import {useTranslation} from "react-i18next";
import RankingTableHeader
    from "../../../../../Champions/ChampionsContent/ChampionsRank/ChampionsRankingTable/RankingTableHeader";

const TableHeaderWrapper = styled.tr`
  border-color: ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.white_100};
  border-style: solid;
  border-bottom-width: 1px;
`;

const AramBalanceTableHeader = ({onClickSort, sortData, currentSort}) => {
    const {t} = useTranslation()

    return (
        <TableHeaderWrapper>
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                title={"#"}
                disableBar={true}
            />
            <RankingTableHeader
                onClickSort={onClickSort}
                currentSort={currentSort}
                title={t('championsRanking.champion')}
                sort={sortData.champion_name}
                disableBar={true}
            />
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                sort={sortData.patch_number}
                title={t('gameAnalytics.patch')}/>
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                sort={sortData.win_rate}
                title={t('championsRanking.winRate')}/>
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                title={t('championsRanking.pickRate')}
                sort={sortData.pick_rate}
            />
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                sort={sortData.damage_dealt}
                title={t('championDetailBuild.balance.damageDealtNoPre')}/>
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                sort={sortData.damage_taken}
                title={t('championDetailBuild.balance.damageTakenNoPre')}/>
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                sort={sortData.healing}
                title={t('championDetailBuild.balance.healing')}/>
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                sort={sortData.shield_mod}
                title={t('championDetailBuild.balance.shieldMod')}/>
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                sort={sortData.tenacity}
                title={t('championDetailBuild.balance.tenacity')}/>
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                sort={sortData.energy_regeneration}
                title={t('championDetailBuild.balance.energyReg')}/>
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                sort={sortData.ability_haste}
                title={t('championDetailBuild.balance.abilityHaist')}/>
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                sort={sortData.attack_speed_scaling}
                title={t('championDetailBuild.balance.attackSpeed')}/>
        </TableHeaderWrapper>
    );
};

export default AramBalanceTableHeader;
