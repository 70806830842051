import React, {useCallback} from 'react';
import styled, {useTheme} from "styled-components";
import KDAText from "../../../../common/KDAText";


const FlatWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const KDAWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 3px;
  height: 17px;
`;


const ROWKDAWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 7px;
`;


const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ContentSpan = styled.span`
  line-height: 14px;
  font-size: 12px;
  margin-inline: 3px;
  color: ${props => {
    let color = props.theme.color.dark_200;
    if (props.color) color = props.color;
    return color;
  }};
`;

const SplashSpan = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.dark_200};
  line-height: 14px;
`;


const OverviewKDA = ({data,flat}) => {
    const theme = useTheme()
    const calValue = useCallback((value, deaths) => {
        return (value / deaths).toFixed(2);
    }, [])

    const calKDA = useCallback(() => {
        if (data.kills === 0 && data.assists === 0) {
            return "0.00";
        } else if (data.deaths === 0) {
            return calValue(data.kills + data.assists, 1);
        } else {
            return calValue(data.kills + data.assists, data.deaths);
        }
    }, [data]);

    if (typeof data !== 'object') return null;

    if(flat) {
        return (
            <FlatWrapper>
                <ROWKDAWrapper>
                    <KDAText fontSize={'14px'} fontWeight={'500'} lineHeight={'17px'}
                             value={calKDA()}>{calKDA()} KDA</KDAText>
                </ROWKDAWrapper>
                <ContentWrapper>
                    <ContentSpan>{data.kills.toFixed(1)}</ContentSpan>
                    <SplashSpan>/</SplashSpan>
                    <ContentSpan color={theme.color.red_300}>{data.deaths.toFixed(1)}</ContentSpan>
                    <SplashSpan>/</SplashSpan>
                    <ContentSpan>{data.assists.toFixed(1)}</ContentSpan>
                </ContentWrapper>
            </FlatWrapper>
        )
    }

    return (
        <Wrapper>
            <KDAWrapper>
                <KDAText fontSize={'14px'} fontWeight={'500'} lineHeight={'17px'}
                         value={calKDA()}>{calKDA()} KDA</KDAText>
            </KDAWrapper>
            <ContentWrapper>
                <ContentSpan>{data.kills.toFixed(1)}</ContentSpan>
                <SplashSpan>/</SplashSpan>
                <ContentSpan color={theme.color.red_300}>{data.deaths.toFixed(1)}</ContentSpan>
                <SplashSpan>/</SplashSpan>
                <ContentSpan>{data.assists.toFixed(1)}</ContentSpan>
            </ContentWrapper>
        </Wrapper>
    );
};

export default OverviewKDA;
