import React from 'react';
import styled from "styled-components";

const ErrorWrapper = styled.div`
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorText = styled.span`
  font-size: 22px;
  font-weight: bold;
`;


const InternalServerError = ({errorText = "Internal Server Error"}) => {
    return (
        <ErrorWrapper>
            <ErrorText>{errorText}</ErrorText>
        </ErrorWrapper>
    );
};

export default InternalServerError;