export const LANGUAGE_FOR_OS = {
    EN: 'en',
    "ko-KR": 'ko-KR',
    ko: 'ko',
    JA: 'ja',
    DA: 'da',
    VI: 'vi',
    DE: 'de',
    ES: 'es',
    FI: 'fi',
    FR: 'fr',
    HU: 'hu',
    IT: 'it',
    NL: 'nl',
    NORWAY: 'norway',
    PL: 'pl',
    PT: 'pt',
    RO: 'ro',
    RU: 'ru',
    SR: 'sr',
    SV: 'sv',
    TH: 'th',
    TR: 'tr',
    ZHCN: 'zh-CN',
    ZHTW: 'zh-TW',
};
