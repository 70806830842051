import React, {useMemo} from 'react';
import styled, {useTheme} from "styled-components";

const Wrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;


const ArrowWrapper = styled.div`
  & + & {
    margin-left: 4px;
  }
`;


const ListItem = styled.button`
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;


  width: 32px;
  height: 32px;
  aspect-ratio: 1;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  border: ${props => {
    let border = '1px';
    if (props.selected) border = '2px';
    return border;
  }} solid ${props => {
    let color = props.theme.color.default_border_color;
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};
  background-color: ${props => {
    let color = props.theme.color.site_main_color;
    if (props.selected) color = props.theme.color.green_200;
    return color;
  }};
  color: ${props => {
    let color = props.theme.color.dark_200;
    if (props.selected) color = props => props.theme.color.green_100;
    return color;
  }};

  &:hover {
    background-color: ${props => {
      let color = props.theme.color.white_200;
      if (props.selected) color = props.theme.color.green_200;
      return color;
    }};
  }

  & + & {
    margin-left: 4px;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;


const RankingTableNavigator = ({
                                   currentPage,
                                   lastPage,
                                   onClickNext,
                                   onClickPrev,
                                   onClickToEnd,
                                   onClickToFist,
                                   onClickGoToPage
                               }) => {
    const theme = useTheme();
    const visiblePageList = useMemo(() => {
        const BASE_LENGTH = 10;
        const startNumber = Math.floor((currentPage - 1) / 10) * 10;
        let listLength = BASE_LENGTH;
        if (BASE_LENGTH + startNumber > lastPage) {
            listLength = lastPage - startNumber;
        }
        if (listLength > 0) {
            return Array.from(new Array(listLength), (_, index) => 1 + index + startNumber);
        }
        return [];
    }, [currentPage, lastPage]);


    return (
        <Wrapper>
            <ListItem onClick={onClickToFist}>
                <img src={`${theme.icon.path}/icon_rank_navi_left_arrow.svg`} width={5} height={8} alt={''}/>
                <img src={`${theme.icon.path}/icon_rank_navi_left_arrow.svg`} width={5} height={8} alt={''}/>
            </ListItem>
            <ListItem onClick={onClickPrev}>
                <img src={`${theme.icon.path}/icon_rank_navi_left_arrow.svg`} width={12} height={8} alt={''}/>
            </ListItem>
            {visiblePageList.map((data) => {
                return (
                    <ListItem key={"list" + data} selected={data === currentPage}
                              onClick={() => onClickGoToPage(data)}>
                        {data}
                    </ListItem>
                )
            })}
            <ListItem onClick={onClickNext}>
                <img src={`${theme.icon.path}/icon_rank_navi_right_arrow.svg`} width={12} height={8} alt={''}/>
            </ListItem>
            <ListItem onClick={onClickToEnd}>
                <img src={`${theme.icon.path}/icon_rank_navi_right_arrow.svg`} width={5} height={8} alt={''}/>
                <img src={`${theme.icon.path}/icon_rank_navi_right_arrow.svg`} width={5} height={8} alt={''}/>
            </ListItem>
        </Wrapper>
    );
};

export default RankingTableNavigator;
