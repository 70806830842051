import {useSelector} from "react-redux";
import useSortArray from "../../../../../common/hooks/useSortArray";
import useChampionFunc from "../../../../../common/hooks/useChampionFunc";
import React, {useMemo} from "react";
import InternalServerError from "../../../../common/InternalError";
import {Loading} from "../../../../common";
import styled from "styled-components";
import ArmRankTableHeader from "./ArmRankTableHeader";
import AramRankTableModel from "../../../../../Model/AramRankTableModel";
import AramRankTableContent from "./AramRankTableContent";


const Wrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TBody = styled.tbody`

`;


const initSort = [{
    value: 'rank', sortType: false, reverseSort: true,
}];

const initSortList = {
    rank: {
        value: 'rank', sortType: false, reverseSort: true,
    }, tier: {
        value: 'tier', sortType: true, reverseSort: true,
    }, win_rate: {
        value: 'win_rate', sortType: true,
    }, pick_rate: {
        value: 'pick_rate', sortType: true,
    }, dpm: {
        value: 'dpm', sortType: true,
    }, pentakills: {
        value: 'pentakills', sortType: true,
    },
}


const LoadingWrapper = styled.div`
  width: 100%;
  height: 30vh;
`;

const AramRankTable = ({ranks = [], pageValue = 99}) => {
    const version = useSelector((state) => state.gameVersion.version);
    const {
        getChampionsRankLoading, getChampionsRankError
    } = useSelector((state) => state.champions);
    const {sort, sortList, onClickSort} = useSortArray({
        initSort: initSort, initSortList: initSortList,
    });
    const {getChampion} = useChampionFunc();


    const model = useMemo(() => new AramRankTableModel(ranks, sort), [sort, ranks]);


    if (getChampionsRankError) return (<InternalServerError/>);
    if (getChampionsRankLoading) return (<LoadingWrapper><Loading/></LoadingWrapper>);

    if (ranks.length === 0) return (<InternalServerError errorText={"There are no data"}/>);

    return (<Wrapper>
        <colgroup>
            <col width={'12%'}/>
            <col width={'20%'}/>
            <col width={'8%'}/>
            <col width={'10%'}/>
            <col width={'10%'}/>
            <col width={'20%'}/>
            <col width={'13%'}/>
        </colgroup>
        <thead>
        <ArmRankTableHeader
            currentSort={sort}
            onClickSort={onClickSort}
            sortData={sortList}
        />
        </thead>
        <TBody>
            {Array.isArray(model.sortedList) && model.sortedList.slice(0, pageValue).map((data, index) => {

                return (
                    <AramRankTableContent
                        tier={data.tier}
                        rankDelta={data.rank_delta}
                        champion={getChampion(data.champion_id)}
                        pickRate={data?.pick_rate}
                        winRate={data?.win_rate}
                        highestDPM={model.highestValue}
                        dpm={data?.dpm}
                        key={`${data.rank}aramTable${data.champion_id}`}
                        rank={data.rank}
                        pentaKills={data?.pentakills}
                        version={version}
                    />)
            })}
        </TBody>
    </Wrapper>);
};


export default AramRankTable;
