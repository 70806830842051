import React, {useCallback, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {isArena, isSwarm, sortChampData} from "../../function";

const useSummerMatch = () => {
    const summoner = useSelector((state) => state.summoner.summoner);
    const matchInfo = useSelector((state) => state.summoner.match);
    const [championFilter, setChampionFilter] = useState(0);
    const [queueFilter, setQueueFilter] = useState(100);


    const swarmFilterMatch = () => {
        try {
            const myPuuId = summoner.summoner_basic_info_dict?.puu_id;
            const queueFilteredList = matchInfo.filter((data) => {
                return isSwarm(data.match_basic_dict.queue_id)
            });
            const championFilterList = queueFilteredList.filter((data) => {
                if (championFilter === 0) {
                    return true;
                } else {
                    const me = data.participants_list.find((item) => item.puu_id === myPuuId);
                    return me.champion_id === championFilter
                }
            });
            const addTimeStamp = championFilterList.map((data) => {
                return {
                    ...data,
                    createAt: data.match_basic_dict.creation_timestamp
                }
            })
            return sortChampData(addTimeStamp, [{value: "createAt", sortType: false}]);


        } catch (e) {
            console.log(e)
            return [];
        }
    }

    const filterByFunction = (filterFunction) => {
        try {
            const myPuuId = summoner.summoner_basic_info_dict?.puu_id;
            const queueFilteredList = matchInfo.filter((data) => {
                return filterFunction(data.match_basic_dict.queue_id)
            });
            const championFilterList = queueFilteredList.filter((data) => {
                if (championFilter === 0) {
                    return true;
                } else {
                    const me = data.participants_list.find((item) => item.puu_id === myPuuId);
                    return me.champion_id === championFilter
                }
            });
            const addTimeStamp = championFilterList.map((data) => {
                return {
                    ...data,
                    createAt: data.match_basic_dict.creation_timestamp
                }
            })
            return sortChampData(addTimeStamp, [{value: "createAt", sortType: false}]);


        } catch (e) {
            console.log(e)
            return [];
        }
    }

    const filterMatch = useMemo(() => {
        try {
            if (isSwarm(queueFilter)) {
                return filterByFunction(isSwarm);
            }
            if(isArena(queueFilter)) {
                return filterByFunction(isArena)
            }
            return filterByFunction((queueId) => queueFilter === 100 ? true : queueId === queueFilter)
        } catch (e) {
            return [];
        }
    }, [queueFilter, championFilter, matchInfo]);


    const queueFilterMatch = useMemo(() => {
        try {
            if (isSwarm(queueFilter)) {
                return swarmFilterMatch();
            }
            const queueFilteredList = matchInfo.filter((data) => queueFilter === 100 ? true : data.match_basic_dict.queue_id === queueFilter);
            const addTimeStamp = queueFilteredList.map((data) => {
                return {
                    ...data,
                    createAt: data.match_basic_dict.creation_timestamp
                }
            })
            return sortChampData(addTimeStamp, [{value: "createAt", sortType: false}]);
        } catch (e) {
            return [];
        }
    }, [queueFilter, championFilter, matchInfo]);

    const onChangeQueueFilter = useCallback((value) => {
        setQueueFilter(prev => {
            if (prev === value) {
                return 100;
            } else {
                return value;
            }
        });
        setChampionFilter(0);
    }, []);

    const onChangeChampionId = useCallback((value) => {
        setChampionFilter(prev => {
            if (prev === value) {
                return 0;
            } else {
                return value;
            }
        });
    }, []);


    return {
        queueFilter,
        championFilter,
        filterMatch,
        onChangeChampionId,
        onChangeQueueFilter,
        queueFilterMatch,
    }
};

export default useSummerMatch;