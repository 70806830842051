import React, {useCallback} from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const TierImage = styled.img`
`;

const CRTTier = ({tier,width =32,height = 32}) => {

    const getTierImage = useCallback(() => {
        if(typeof tier === 'number') {
            return tier;
        } else {
            return 5;
        }
    },[tier])

    return (
        <Wrapper>
            <TierImage
                src={`/images/ico_tier_rank_${getTierImage()}.svg`}
                width={width}
                height={height}
            />
        </Wrapper>
    );
};

export default CRTTier;