const runeShards_en =  {
    "id": 5000,
    "key": "Rune Shards",
    "icon": "null",
    "name": "Rune Shards",
    "slots": [
        {
            "runes": [
                {
                    "id": 5008,
                    "key": "Offense",
                    "icon": "perk-images/StatMods/StatModsAdaptiveForceIcon.png",
                    "name": "Offense",
                    "shortDesc": "+9 Adaptive Force"
                },
                {
                    "id": 5005,
                    "key": "Offense",
                    "icon": "perk-images/StatMods/StatModsAttackSpeedIcon.png",
                    "name": "Offense",
                    "shortDesc": "+10% Attack Speed"
                },
                {
                    "id": 5007,
                    "key": "Offense",
                    "icon": "perk-images/StatMods/StatModsCDRScalingIcon.png",
                    "name": "Offense",
                    "shortDesc": "+8 Ability Haste"
                }
            ]
        },
        {
            "runes": [
                {
                    "id": 5008,
                    "key": "Offense",
                    "icon": "perk-images/StatMods/StatModsAdaptiveForceIcon.png",
                    "name": "Offense",
                    "shortDesc": "+9 Adaptive Force"
                },
                {
                    "id": 5010,
                    "key": "Flex",
                    "icon": "perk-images/StatMods/StatModsMovementSpeedIcon.png",
                    "name": "Flex",
                    "shortDesc": "+2% Movement Speed"
                },
                {
                    "id": 5001,
                    "key": "Defence",
                    "icon": "perk-images/StatMods/StatModsHealthPlusIcon.png",
                    "name": "Defence",
                    "shortDesc": "+15 ~ 140 Health (based on level)"
                }
            ]
        },
        {
            "runes": [
                {
                    "id": 5011,
                    "key": "Defence",
                    "icon": "perk-images/StatMods/StatModsHealthScalingIcon.png",
                    "name": "Defence",
                    "shortDesc": "+65 Health",
                },
                {
                    "id": 5013,
                    "key": "Defence",
                    "icon": "perk-images/StatMods/StatModsTenacityIcon.png",
                    "name": "Defence",
                    "shortDesc": "+10% Tenacity and Slow Resist"
                },
                {
                    "id": 5001,
                    "key": "Defence",
                    "icon": "perk-images/StatMods/StatModsHealthPlusIcon.png",
                    "name": "Defence",
                    "shortDesc": "+15 ~ 140 Health (based on level)"
                }
            ]
        }

    ]
}

export default runeShards_en;