import React, {useCallback, useLayoutEffect} from 'react';
import useQuery from "./useQuery";
import useInput from "./useInput";


const DEFAULT_TIER = 'Emerald+';
const useQueryTier = () => {
    const [currentTier, onChangeCurrentTier] = useInput(DEFAULT_TIER);
    const query = useQuery();
    const tier = query.get('tier');

    const encodeTier = useCallback(() => {
        return encodeURIComponent(tier);
    }, [tier]);

    const decodeTier = useCallback(() => {
        return `${decodeURIComponent(tier).trim()}+`;
    }, [tier]);

    const getTierByQuery = useCallback(() => {
        if (encodeTier() === 'null') {
            return DEFAULT_TIER;
        }
        if (!encodeTier()) {
            return DEFAULT_TIER
        }
        return decodeTier();
    }, [encodeTier, decodeTier]);


    useLayoutEffect(() => {
        onChangeCurrentTier(getTierByQuery());
    },[getTierByQuery]);

    return [currentTier, onChangeCurrentTier];
};

export default useQueryTier;
