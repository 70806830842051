import React, {useCallback, useState} from 'react';
import {allOffObject} from "../../function";

const useSortArray = ({
                          initSort,
                          initSortList,
                      }) => {
    const [sort, setSort] = useState(initSort);
    const [sortList, setStateList] = useState({...initSortList});

    const onClickSort = useCallback((data,extra) => {
        if (typeof data === 'object') {
            const copyData = {...data};
            const copySortData = {...sortList};
            if (copyData) {
                if (copySortData[copyData.value]) {
                    const allOff = allOffObject(copySortData);
                    copyData.sortType = !copyData.sortType;
                    allOff[copyData.value].sortType = copyData.sortType;
                    if(extra) {
                        setSort([{...copyData},...extra]);
                    } else {
                        setSort([{...copyData}]);
                    }
                    setStateList(copySortData);
                }
            }
        }
    }, [sortList]);

    useCallback((data) => {

    },[sortList]);


    return {sort, sortList,onClickSort}
};

export default useSortArray;
