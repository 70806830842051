import React from 'react';
import {getAIScoreColor, getAIScoreTextColor, hundredCheck, threedigits} from "../../../../../../../function";
import styled, {useTheme} from "styled-components";
import WinRateGraph from "../../../../../../common/WinRateGraph";
import ChampionImageWithLane from "../../../../../../common/ChampionImageWithLane";

const TR = styled.tr`
`;
const ContentTD = styled.td`
  padding: 8px;
`;

const TableContentWrapper = styled.div`
  display: flex;
  align-items: center;

  .imgChamp {
    width: 34px;
    aspect-ratio: 1;
  }

  .imgDiv {
    border-radius: 5px;
  }

`;


const GamesCountText = styled.span`
  font-size: 12px;
  flex: 1;
  font-weight: 500;
  text-align: center;
  color: ${props => props.theme.color.dark_100};
`;


const ChampSynergyTableItem = ({games, win_rate, champion_id}) => {
    const theme = useTheme();

    const dmg = hundredCheck(Number(win_rate).toFixed(1))

    return (
        <TR className={theme.color.theme}>
            <ContentTD>
                <TableContentWrapper>
                    <ChampionImageWithLane
                        champion_id={champion_id}
                    />
                    <GamesCountText>{threedigits(games)}</GamesCountText>
                </TableContentWrapper>
            </ContentTD>
            <ContentTD>
                <WinRateGraph
                    addPercent={true}
                    dmg={dmg}
                    total={100}
                    color={getAIScoreTextColor(dmg)}
                    barColor={getAIScoreColor(dmg)}
                    bgColor={theme.color.gray_100}
                />
            </ContentTD>
        </TR>
    );
};

export default React.memo(ChampSynergyTableItem);