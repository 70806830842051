import React, {useCallback, useEffect, useRef} from 'react';
import styled, {useTheme} from "styled-components";
import ChampCarouselItem from "./ChampCarouselItem";
import {sortChampData} from "../../../../../../function";


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: clip;
  max-width: 880px;

  * {
    pointer-events: ${props => {
      let evetns = 'all';
      if (props.isDragging) evetns = 'none';

      return evetns;
    }};
`;

const ArrowRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-left: 10px;
  border: 1px solid ${props => props.theme.color.default_border_color};
  &:hover {
    background-color: ${props => props.theme.color.white_200};
  }
`;

const EmptyArrow = styled.div`
  width: 34px;
  height: 24px;
`;
const ArrowLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border: 1px solid ${props => props.theme.color.default_border_color};
  &:hover {
    background-color: ${props => props.theme.color.white_200};
  }
`;


const ChampCarouselContent = ({
                                  list,
                                  handleChamp,
                                  selectedChamp,
                                  position,
                                  visibleCount,
                                  onChangeIndicate,
                                  indicate
                              }) => {
    const dragRef = useRef(null);
    const staticContentValue = 54 * visibleCount;
    const theme = useTheme();


    const onClickSelected = useCallback((e, value) => {
        handleChamp(value);
    }, []);

    const scrollToArrowLeftArrow = useCallback(() => {
        const value = staticContentValue * (indicate - 1);
        dragRef.current.scrollTo({left: value, behavior: 'smooth'});

        onChangeIndicate(indicate - 1);
    }, [indicate])

    const scrollToRightArrow = useCallback(() => {

        dragRef.current.scrollTo({left: staticContentValue * (indicate + 1), behavior: 'smooth'});
        onChangeIndicate(indicate + 1);
    }, [indicate]);

    useEffect(() => {
        dragRef.current.scrollTo({left: staticContentValue * (indicate), behavior: 'smooth'});
    }, [indicate])


    useEffect(() => {
        dragRef.current.scrollTo({left: 0});
    }, [list]);


    if (!Array.isArray(list) || list.length === 0) return null;

    return (
        <Wrapper>
            {list.length > 15 ?
                <ArrowLeft onClick={scrollToArrowLeftArrow}>
                    <img
                        width={14}
                        height={12}
                        src={`${theme.icon.path}/ico_arrow_synergy_prev.svg`}
                        alt={""}
                    />
                </ArrowLeft> : <EmptyArrow/>}
            <CarouselWrapper
                ref={dragRef}
            >
                {sortChampData(list, [{value: 'games', sortType: false,}, {
                    value: 'champion_id',
                    sortType: true,
                }]).map((data) => {
                    return (
                        <ChampCarouselItem
                            position={position}
                            onClickSelected={onClickSelected}
                            key={data.champion_id}
                            champion_id={data.champion_id}
                            win_rate={data.win_rate}
                            games={data.games}
                            selected={selectedChamp === data.champion_id}/>
                    )
                })}
            </CarouselWrapper>
            {list.length > 15 ?
                <ArrowRight onClick={scrollToRightArrow}>
                    <img
                        width={14}
                        height={12}
                        src={`${theme.icon.path}/ico_arrow_synergy_next.svg`}
                        alt={""}
                    />
                </ArrowRight>: <EmptyArrow/>}
        </Wrapper>
    );
};

export default ChampCarouselContent;

