import React, {useCallback} from 'react';
import styled from "styled-components";

const Wrapper = styled.th`
  background-color: ${props => props.theme.color.site_main_color};
  vertical-align: middle;
`;

const HeaderTextWrapper = styled.div`
  display: flex;
  justify-content: ${props => {
    let content = 'center';
    if (props.arrowVisible) content = 'space-between';
    return content;
  }};
  align-items: center;
`;

const HeaderText = styled.span`
  color: ${props => props.theme.color.dark_200};
  position: relative;
  display: flex;
  font-size: 12px;
  white-space: nowrap;
  ${props => {
    if (props.clickable) {
      return `&:hover {
          cursor: pointer;
        }`
    }
  }}

`;



const PrevArrowWrapper = styled.div`
  cursor: pointer;
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 3px;
  overflow: hidden;
`;

const SummaryTableHeader = ({
                                title, sort, onClickSort = () => {
    }, onClickPrev, arrowVisible,
                            }) => {
    const onClickHeader = useCallback(() => {
        if (sort) {
            onClickSort(sort)
        }
    }, []);


    return (
        <Wrapper onClick={!arrowVisible ? onClickHeader : () => {}} >
            <HeaderTextWrapper arrowVisible={arrowVisible}>
                {arrowVisible ?
                    <PrevArrowWrapper onClick={onClickPrev}>
                        <img src={'./images/ico_list_arrow_prev.svg'} alt={''} width={20} height={20}/>
                    </PrevArrowWrapper> : null}
                <HeaderText clickable={sort ? true : false} onClick={arrowVisible ? onClickHeader : () => {}}>
                    {title}
                </HeaderText>
            </HeaderTextWrapper>
        </Wrapper>);
};

export default React.memo(SummaryTableHeader);
