import React, {useMemo} from 'react';
import styled from "styled-components";
import {NavLink, useLocation} from "react-router-dom";


const NavBlock = styled.div`
  display: flex;
  flex-direction: row;
  padding: 7px;
  height: 100%;
`;

const TextBox = styled.div`
  font-weight: ${props => {
    let weight = 500;
    if (props.selected) weight = 'bold'
    return weight
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 38px;
  font-size: 18px;
  color: ${props => {
    let color = props.theme.color.dark_200;
    if (props.selected) color = props.theme.color.site_main_color;
    return color;
  }};
  height: 100%;
  background-color: ${props => {
    let color = 'transparent';
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};
  border-radius: 6px;

  &:hover {
    background-color: ${props => {
      let color = props.theme.color.white_200;
      if (props.selected) color = props.theme.color.green_100;
      return color;
    }};
  }

  & + & {
    margin-left: 6px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 45px;
  border-radius: 6px;
  padding-right: 20px;
  background-color: ${props => props.theme.color.site_main_color};
  border: 1px solid ${props => props.theme.color.default_border_color};

`;


const ColorHeader = ({list, children}) => {
        const pathName = useLocation().pathname;
        const selected = useMemo(() => {
            return list.find(d => {
                return pathName.indexOf(d.to) > -1;
            });
        }, [list, pathName]);


        if (!selected) {
            return null
        }

        return (
            <Wrapper backgroundColor={selected.backgroundColor}>
                <NavBlock>
                    {list.map((data, index) => {
                        return (
                            <TextBox selected={selected.to === data.to} key={index + "colorHead"}>
                                <NavLink
                                    to={data.to}>{data.title}
                                </NavLink>
                            </TextBox>
                        )
                    })}
                </NavBlock>
                {children}
            </Wrapper>
        );
    }
;

export default ColorHeader;
