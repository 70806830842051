import React from 'react';
import {useSelector} from "react-redux";

const UseItemFunc = () => {
    const dataSelector = useSelector((state) => state.imageStore.items);

    const findItemObj = (itemId) => {
        if (Object.keys(dataSelector).length !== 0) {
            let number = Object.entries(dataSelector).filter((item) => item[0] === `${itemId}`)[0];
            if (number !== undefined) {
                return (itemId === 0 ? 0 : number[1]);
            }
        }
        return {}
    };

    const isFinalShoes = (itemId) => {
        switch (itemId) {
            case 3006:
                return true
            default:
                return false
        }
    }


    const isFinalItem = (itemId) => {
        try {
            const isShoes = isFinalShoes(itemId)

            if (isShoes) {
                return true;
            }

            const imgObj = findItemObj(itemId)

            if (!imgObj) {
                return false;
            }

            if (!imgObj?.from) {
                return false;
            }

            if (imgObj?.into) {
                return false;
            }
            return true;

        } catch (e) {
            return false;
        }
    };


    return {
        findItemObj,
        isFinalItem

    }
};

export default UseItemFunc;