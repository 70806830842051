import React from 'react';
import RankingTableHeader from "../RankingTableHeader";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const TableHeaderWrapper = styled.tr`
  height: 40px;
  border-color: ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.white_100};
  border-style: solid;
  border-bottom-width: 1px;
`;

const ChampionRankingTableHeader = ({onClickSort,sortData,currentSort}) => {
    const {t} = useTranslation()
    return (
        <TableHeaderWrapper>
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                sort={sortData.rank}
                percent={5}
                title={t('championsRanking.ranking')}
            />
            <RankingTableHeader
                percent={10}
                onClickSort={onClickSort}
                title={t('championsRanking.champion')}
                disableBar={true}
            />
            <RankingTableHeader
                currentSort={currentSort}
                percent={8}
                onClickSort={onClickSort}
                sort={sortData.tier}
                title={t('championsRanking.tier')}/>
            <RankingTableHeader
                currentSort={currentSort}
                percent={10}
                onClickSort={onClickSort}
                sort={sortData.win_rate}
                title={t('championsRanking.winRate')}/>
            <RankingTableHeader
                currentSort={currentSort}
                percent={10}
                onClickSort={onClickSort}
                sort={sortData.pick_rate}
                title={t('championsRanking.pickRate')}/>
            <RankingTableHeader
                currentSort={currentSort}
                percent={10}
                onClickSort={onClickSort}
                sort={sortData.ban_rate}
                title={t('championsRanking.banRate')}/>
            <RankingTableHeader
                percent={10}
                title={t('championsRanking.counter')}
                disableBar={true}
            />
        </TableHeaderWrapper>
    );
};

export default ChampionRankingTableHeader;