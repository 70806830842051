import React, {useMemo} from 'react';
import styled from "styled-components";

const Box = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const EmblemImage = ({tier, unrankVisible = false, width = 35, height = 35}) => {
    const currentTier = useMemo(() => {
        try {
            return tier.split(" ")[0].toUpperCase()
        } catch (e) {
            return "";
        }
    }, [tier]);
    if ((!currentTier || currentTier === 'UNRANKED') && unrankVisible === false) {
        return <Box width={width} height={height}/>

    }
    return (
        <img src={`/images/Emblem_${currentTier}.png`} className="imgEmblem" alt={tier} width={width}
             height={height}/>);
};

export default EmblemImage;