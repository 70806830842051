import React, {useMemo} from 'react';
import {useLocation, useParams} from "react-router-dom";
import {useSuspenseQuery} from "@tanstack/react-query";
import {aiRankingAPI, aiRankingAPIYesterday} from "../../api/aiRanking";

const useAiRankingApi = () => {
    const params = useParams();
    const {search} = useLocation();
    const date = useMemo(() => {
        const queryString = new URLSearchParams(search);
        return queryString.get('date') === 'yesterday' ? 'yesterday' : 'today'
    }, [search]);
    const query = useSuspenseQuery({
        queryKey: ['aiRanking', params?.platform, date],
        queryFn: async () => {
            if (date === 'yesterday') {
                const result = await aiRankingAPIYesterday({
                    platformId: params?.platform,
                })
                return result
            } else {
                const result = await aiRankingAPI({
                    platformId: params?.platform,
                })
                return result
            }
        },
        retry: 0,
        refetchOnWindowFocus: false,
        staleTime: 3000,
    })
    return {
        aiRanking: query,
    }
};

export default useAiRankingApi;