import React from 'react';
import styled from "styled-components";

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 3px 5px;
`;

const Th = styled.th`
  background-color: ${props => props.theme.color.white_100};
  height: 44px;
  vertical-align: middle;
`;

const AbsoluteSpliter = styled.div`
  position: absolute;
  right: 0;
  width: 1px;
  height: 8px;
`;

const Title = styled.span`
  font-size: 12px;
  font-weight: 500;
  word-wrap: break-word;
  white-space: pre;
  margin-left: ${props => props.marginLeft || 0}px;
  color: ${props => props.theme.color.dark_200};
`;
const LeftCell = styled(Cell)`
  justify-content: flex-start;
`;

const RankingTableHeader = (props) => {
    const {children, last, left, marginLeft, empty} = props;

    if (empty) {
        return <Th/>
    }

    if (typeof children !== 'string') return null;

    if (left) {
        return (
            <Th>
                <LeftCell>
                    <Title marginLeft={marginLeft}>{children}</Title>
                </LeftCell>
            </Th>
        )
    }

    return (
        <Th>
            <Cell>
                <Title>{children}</Title>
            </Cell>
        </Th>
    );
};

export default RankingTableHeader;
