import React from 'react';
import {FlexBox} from "../../ui/styled";
import styled, {useTheme} from "styled-components";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";


const DarkModeText = styled.span`
  font-size: 12px;
  white-space: pre-wrap;
  line-height: 17px;
  font-weight: bold;
  color: ${props => props.theme.color.dark_100};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 5px;
`;

const ExtraText = styled.span`
  font-size: 12px;
  line-height: 17px;
  font-weight: bold;
  color: ${props => props.theme.color.red_600};
`;
const GreenText = styled.span`
  color: #0DBAAE;
`;

const Wrapper = styled(FlexBox)`
    cursor: pointer;
`;

const HomeNotice = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const history = useHistory();
    const region = useSelector((state) => state.region.result);
    const currentLang = useSelector((state) => state.translation.lang);


    if (currentLang === 'ja') {
        return (
            <Wrapper justifyContent={'flex-end'} height={'auto'} width={'736px'}
                     alignItems={'flex-start'} margin={'0 0 10px 0'} onClick={() => history.push('/event')}>
                    <ButtonWrapper>
                            <DarkModeText>しゃるる杯の全力を、
                            </DarkModeText>
                            <ExtraText>
                             今すぐチェック！
                            </ExtraText>
                    </ButtonWrapper>
            </Wrapper>
        )
    }

    return null
};

export default HomeNotice;