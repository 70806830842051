import React from 'react';
import BuildLaneFilter from "./BuildLaneFilter";
import BuildOverview from "./BuildOverview";
import BuildRunes from "./BuildRunes";
import BuildContent from "./BuildContent";

const ChampionsDetailBuildProvider = ({children}) => {
    return (
        <div>
            {children}
        </div>
    );
};


ChampionsDetailBuildProvider.LaneFilter = BuildLaneFilter;
ChampionsDetailBuildProvider.Overview = BuildOverview;
ChampionsDetailBuildProvider.Build = BuildContent
ChampionsDetailBuildProvider.Runes = BuildRunes;

export default ChampionsDetailBuildProvider;