import React from 'react';
import styled, {useTheme} from "styled-components";
import EventPlayerList from "./EventPlayerList";
import useEventAPI from "../../common/hooks/useEventAPI";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import EventTeamLeader from "./EventTeamLeader";
import EventChart from "./EventChart";
import EventScrimTable from "./EventScrimTable";

const Wrapper = styled.div`
`;
const BannerWrapper = styled.div`
  cursor: pointer;
`;
const EventBox = styled.div`
  & + & {
    margin-top: 20px;
  }
`;
const EventContent = () => {
    const theme = useTheme();
    const {eventQuery} = useEventAPI();
    const currentLang = useSelector((state) => state.translation.lang);

    const getCheer = (data) => {
        if(currentLang === 'ja') {
            return data.leader.comment_jp
        }
        if (currentLang === 'KOR' ) {
            return data.leader.comment_kr
        }
        return data.leader.comment_en
    }
    const getBannerName = () => {
        if(currentLang === 'ja') {
            return 'jp'
        }
        if (currentLang === 'KOR' ) {
            return 'kr'
        }
        return 'en'
    }
    return (
        <Wrapper>
            <BannerWrapper onClick={() => window.open('https://www.twitch.tv/syaruru3')}>
                <img src={`images/event/event_banner_${getBannerName()}.svg`} width={1080} height={52} alt={''}/>
            </BannerWrapper>
            <GreenBox/>
            <EventScrimTable teamArray={eventQuery.data.eventArray} scrim={eventQuery.data.scrim}/>
            {eventQuery.data.eventArray.map((data, index) => {
                return (
                    <EventBox key={index}>
                        <EventTeamLeader name={data.leader.name} teamIndex={index + 1} cheer={getCheer(data)} puuId={data.puu_id} riot_id={data.leader.riot_id_name} riot_tag={data.leader.riot_id_tag_line}/>
                        <EventPlayerList data={data.player} room={false} liveInfo={eventQuery.data.liveInfo}/>
                        <EventChart list={data.player} tierOff={eventQuery.data.tier_off}/>
                    </EventBox>
                )
            })}
            {/*<EventInfo list={eventQuery.data.playerArray} aiList={eventQuery.data.aiScoreArray}/>*/}
        </Wrapper>
    );
};


const GreenBoxWrapper = styled.div`
  margin: 10px 0 20px;
  border: 2px solid ${props => props.theme.color.green_100};
  background-color: ${props => props.theme.color.green_200};
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 96px;
  color: ${props => props.theme.color.green_100};
  font-weight: bold;
  font-size: 20px;
`;

const GreenBox = () => {
    const {t} = useTranslation()
    return (
        <GreenBoxWrapper>
            {t('gemagema.banner')}
        </GreenBoxWrapper>
    )
}
export default EventContent;