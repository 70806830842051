import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import {LANGUAGE_FOR_OS} from './types';
import translationEn from './locales/translation.en';
import translationKo from './locales/translation.ko';
import translationVN from './locales/translation.vi.json'
import translationDA from './locales/translation.da.json'
import translationDE from './locales/translation.de.json'
import translationEs from './locales/translation.es.json'
import translationFi from './locales/translation.fi.json'
import translationFr from './locales/translation.fr.json'
import translationHu from './locales/translation.hu.json'
import translationIt from './locales/translation.it.json'
import translationJa from './locales/translation.ja.json'
import translationNL from './locales/translation.nl.json'
import translationNorway from './locales/translation.norway.json'
import translationPL from './locales/translation.pl.json'
import translationPT from './locales/translation.pt.json'
import translationRO from './locales/translation.ro.json'
import translationRu from './locales/translation.ru.json'
import translationSr from './locales/translation.sr.json'
import translationSv from './locales/translation.sv.json'
import translationTh from './locales/translation.th.json'
import translationTr from './locales/translation.tr.json'
import translationZhCN from './locales/translation.zh-cn.json'
import translationZhTw from './locales/translation.zh-tw.json'


const resources = {
    [LANGUAGE_FOR_OS.ko]: {
        translation: translationKo,
    }, [LANGUAGE_FOR_OS["ko-KR"]]: {
        translation: translationKo,
    }, [LANGUAGE_FOR_OS.EN]: {
        translation: translationEn,
    }, [LANGUAGE_FOR_OS.VI]: {
        translation: translationVN,
    }, [LANGUAGE_FOR_OS.DA]: {
        translation: translationDA,
    }, [LANGUAGE_FOR_OS.DE]: {
        translation: translationDE
    }, [LANGUAGE_FOR_OS.ES]: {
        translation: translationEs
    }, [LANGUAGE_FOR_OS.FI]: {
        translation: translationFi
    }, [LANGUAGE_FOR_OS.FR]: {
        translation: translationFr
    }, [LANGUAGE_FOR_OS.HU]: {
        translation: translationHu
    }, [LANGUAGE_FOR_OS.IT]: {
        translation: translationIt
    }, [LANGUAGE_FOR_OS.JA]: {
        translation: translationJa
    }, [LANGUAGE_FOR_OS.NL]: {
        translation: translationNL
    }, [LANGUAGE_FOR_OS.NORWAY]: {
        translation: translationNorway
    }, [LANGUAGE_FOR_OS.PL]: {
        translation: translationPL
    }, [LANGUAGE_FOR_OS.PT]: {
        translation: translationPT
    }, [LANGUAGE_FOR_OS.RO]: {
        translation: translationRO
    }, [LANGUAGE_FOR_OS.RU]: {
        translation: translationRu
    }, [LANGUAGE_FOR_OS.SR]: {
        translation: translationSr
    }, [LANGUAGE_FOR_OS.SV]: {
        translation: translationSv
    }, [LANGUAGE_FOR_OS.TH]: {
        translation: translationTh
    }, [LANGUAGE_FOR_OS.TR]: {
        translation: translationTr
    }, [LANGUAGE_FOR_OS.ZHCN]: {
        translation: translationZhCN
    }, [LANGUAGE_FOR_OS.ZHTW]: {
        translation: translationZhTw
    }
};


const init = () => {

    i18n
        .use(initReactI18next)
        .use(I18nextBrowserLanguageDetector)
        .init({
            initImmediate: false,
            resources: resources,
            fallbackLng: LANGUAGE_FOR_OS.EN,
            debug: false,
            defaultNS: 'translation',
            ns: 'translation', // keySeparator: '.', // we do not use keys in form messages.welcome
            keySeparator: '.',
            react: {
                useSuspense: false,
            },
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
            detection: {
                order: ["querystring", "localStorage", "navigator"],
                lookupLocalStorage: process.env.REACT_APP_LANG_COOKIE,
                lookupQuerystring: "hl",
            },
        });
};

export default init;
