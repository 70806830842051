import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {GET_ARAM_BALANCE_FAILURE, GET_ARAM_BALANCE_REQUEST, GET_ARAM_BALANCE_SUCCESS} from "../reducer/balance";

const getAramBalanceData = () => {
    const url = `${process.env.REACT_APP_CACHE_API}champion/balance`;
    return axios.get(url).then((result) => result.data);
}


function* getAramBalance() {

    try {
        const getData = yield call(getAramBalanceData);

        yield put({
            type: GET_ARAM_BALANCE_SUCCESS,
            data: getData
        })
    } catch (e) {

        yield put({
            type: GET_ARAM_BALANCE_FAILURE,
            error: e,
        })
    }
}

function* watchGetRanking() {
    yield takeLatest(GET_ARAM_BALANCE_REQUEST, getAramBalance)
}


export default function* balanceSaga() {
    yield all([
        fork(watchGetRanking),
    ])
}
