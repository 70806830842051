import React, {useMemo} from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const RowWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  
`;


const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const GamesText = styled.span`
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  
`;

const KDAWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 17px;
  margin-bottom: 3px;
`;

const ROWKDAWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 5px;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const WinLoseSpan = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.dark_200};
  line-height: 14px;
`;
const OverviewGames = ({games,lose,wins,flat}) => {
    const {t} = useTranslation();
    const currentLang = useSelector((state) => state.translation.lang);
    const getGameText = useMemo(() => {
        if (currentLang === 'KOR') {
            return "게임"
        }
        if (games === 1) {
            return "Game"
        } else {
            return "Games"
        }

    }, [currentLang]);


    if(flat) {
        return (
            <RowWrapper>
                <ROWKDAWrapper>
                    <GamesText>{Number(games)} {getGameText}</GamesText>
                </ROWKDAWrapper>
                <ContentWrapper>
                    <WinLoseSpan>({wins}{t('gameAnalytics.win')} {lose}{t('gameAnalytics.lose')})</WinLoseSpan>
                </ContentWrapper>
            </RowWrapper>
        )
    }

    return (
        <Wrapper>
            <KDAWrapper>
                <GamesText>{Number(games)} {getGameText}</GamesText>
            </KDAWrapper>
            <ContentWrapper>
                <WinLoseSpan>{wins}{t('gameAnalytics.win')} {lose}{t('gameAnalytics.lose')}</WinLoseSpan>
            </ContentWrapper>
        </Wrapper>
    );
};

export default OverviewGames;
