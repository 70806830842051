import React, {Suspense, useMemo} from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import {Redirect, Route, Switch, useLocation, useRouteMatch} from "react-router-dom";
import ChampionsDetailCategory from "./Category";
import NotFound from "../../NotFound";
import {Loading} from "../../common";
import ChampionsHistory from "../../ChampionsHistory";
import useGetChampionSpells from "../../../common/hooks/useGetChampionSpells";
import ChampionDetailMastery from "../ChampionDetailMastery";
import ChampionsDetailHeader from "./ChampionsDetailHeader";
import ChampionsDetailBuild from "./ChampionsDetailBuild";
import SponsorAD from "../../../ad/Tapin";


const LoadingWrapper = styled.div`
  height: 50vh;
  width: 100%;
`;


const ChampionsDetailContent = ({champName}) => {
    const {path} = useRouteMatch();
    const skillList = useGetChampionSpells(champName);
    const championStore = useSelector((state) => state?.imageStore.champions);
    const version = useSelector((state) => state?.gameVersion.version);
    const location = useLocation();




    const champData = useMemo(() => {
        if (championStore.length > 0) {
            return championStore.find(
                (data) => data.champion_name_en.replace(/\s|&|willump|!|/gi, "").toLowerCase() === champName);
        }
    }, [championStore, champName]);

    if (championStore.length === 0 || skillList.spells.length === 0) return (
        <LoadingWrapper>
            <Loading/>
        </LoadingWrapper>
    );
    if (!champData) return (
        <NotFound/>
    )


    return (
        <div>
            <ChampionsDetailHeader
                skillList={skillList}
                champion={champData}
                version={version}
            />
            <SponsorAD/>
            <ChampionsDetailCategory/>
            <Switch>
                <Route path={`${path}/build`}>
                    <ChampionsDetailBuild
                        championName={champName}
                        skillList={skillList}
                        champion={champData}
                    />
                </Route>
                <Route path={`${path}/mastery/:position`}>
                    <ChampionDetailMastery champion={champData}/>
                </Route>
                {/*<Route path={`${path}/counter`}>*/}

                {/*</Route>*/}
                <Route path={`${path}/history`}>
                    <Suspense fallback={<Loading/>}>
                        <ChampionsHistory
                            skillList={skillList}
                            champion={champData}
                        />
                    </Suspense>
                </Route>
                <Route>
                    <Redirect
                        to={`/champions/${champName}/build${location.hash}`}
                    />
                </Route>
            </Switch>
        </div>
    );
};

export default ChampionsDetailContent;
