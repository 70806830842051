import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import useQuery from "./useQuery";

const useGetURLTierVersion = () => {
    const query = useQuery()
    const versions = useSelector((state) => state.champions.championVersions);

    const currentTier = useMemo(() => {
        return encodeURIComponent(query.get('tier')) !== 'null' && encodeURIComponent(query.get('tier')) ? decodeURIComponent(query.get('tier')).trim() + "+" : "Emerald+"
    }, [query.get('tier')])
    const currentVersion = useMemo(() => {
        return encodeURIComponent(query.get('version')) !== 'null' && encodeURIComponent(query.get('version')) ? encodeURIComponent(query.get('version')) : versions[0]
    }, [query.get('version'),versions])

    return {
        currentVersion,
        currentTier
    }
};


export default useGetURLTierVersion;