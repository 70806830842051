import React, {useMemo} from 'react';
import styled from "styled-components";
import {useLocation} from "react-router-dom";
import useQuery from "../../../../../common/hooks/useQuery";

const FlexOne = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;

  color: ${props => {
    let color = props.theme.color.dark_200;
    if (props.selected) color = props => props.theme.color.green_100;
    return color;
  }};

  font-weight: ${props => {
    let color = '';
    if (props.selected) color = 'bold';
    return color;
  }};;
  
  background-color: ${props => {
    let color = props.theme.color.site_main_color;
    if (props.selected) color = props.theme.color.green_200;
    return color;
  }};

  &:hover {
    background-color: ${props => {
      let color = props.theme.color.white_200;
      if (props.selected) color = props.theme.color.green_200;
      return color;
    }};
  }

  border: ${props => {
    let width = '1px';
    if (props.selected) width = '1.5px';
    return width;
  }} solid ${props => {
    let color = props.theme.color.default_border_color;
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};
  padding-left: ${props => {
    let padding = '1px';
    if (props.left) padding = 0;
    if (props.selected) padding = 0;
    return padding
  }};

  border-left: ${props => {
    let width = 0;
    if (props.left) width = '1px';
    if (props.selected) width = '1.5px';
    return width;
  }} solid ${props => {
    let color = props.theme.color.default_border_color;
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};
  border-right: ${props => {
    let width = 0;
    if (props.right) width = '1px';
    if (props.selected) width = '1.5px';
    return width;
  }} solid ${props => {
    let color = props.theme.color.default_border_color;
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  overflow: hidden;
  height: 36px;
  margin-bottom: 10px;

  ${FlexOne}:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  ${FlexOne}:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;
const MasteryRegion = ({regionList, onClick}) => {
    useLocation();
    const query = useQuery();
    const currentRegion = query.get("region");
    const selectedRegion = useMemo(() => {
        try {
            if (!currentRegion) {
                return "All"
            }
            const validateLocation = regionList.find((data) => data.toLowerCase() === currentRegion.toLowerCase());
            if (!validateLocation) {
                return "All"
            }
            return validateLocation;
        } catch (e) {
            return 'All'
        }
    }, [currentRegion]);

    const selectedIndex = useMemo(() => {
        return regionList.findIndex((data) => data === selectedRegion)
    }, [selectedRegion]);


    return (
        <Wrapper>
            {regionList.map((data, index) => {
                return (
                    <FlexOne
                        key={data}
                        selected={selectedRegion === data}
                        onClick={() => onClick(data.toLowerCase())}
                        left={(selectedIndex + 1) !== index}
                        last={regionList.length - 1 === index}
                        right={regionList.length - 1 === index}
                    >
                        {data}
                    </FlexOne>
                )
            })}
        </Wrapper>
    );
};

export default MasteryRegion;