import React, {Suspense, useCallback} from 'react';
import styled, {useTheme} from "styled-components";
import ChampionsWhiteWrapper from "../ChampionsWhiteWrapper";
import ChampionsRankLaneSelect from "./ChampionsRankLaneSelect";
import {useDispatch, useSelector} from "react-redux";
import ChampionsRankingTable from "./ChampionsRankingTable";
import {useTranslation} from "react-i18next";
import ChampionsHeader from "../ChampionsHeader";
import ChampionFilter from "../ChampionFilter";
import {PREV_RANKING_LANE} from "../../../../store/reducer/champions";
import {useHistory} from "react-router-dom";
import useScrollPagination from "../../../../common/hooks/useScrollPagination";
import {getReplaceTier} from "../../../../function";
import {Loading} from "../../../common";
import useGetURLTierVersion from "../../../../common/hooks/useGetURLTierVersion";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 590px;
  margin-left: 10px;
`;

const LaneWrapper = styled.div`
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 30vh;
`;
const ChampionsRank = () => {
    const {t} = useTranslation();
    const rankingLane = useSelector((state) => state?.champions?.prevRankingLane);
    const dispatch = useDispatch();
    const history = useHistory();
    const {currentTier,currentVersion} = useGetURLTierVersion();
    const versions = useSelector((state) => state.champions.championVersions);
    const [pageValue, initPage] = useScrollPagination();
    const theme = useTheme();



    const onClickLane = useCallback((index, lane) => {
        dispatch({
            type: PREV_RANKING_LANE,
            data: lane,
        })
        initPage(1);
    }, []);

    const onSelectVersion = useCallback((version) => {
        let replaceTier = getReplaceTier(version, currentTier)
        history.replace(`/champions?version=${version}&tier=${replaceTier}`);

    }, [currentTier]);

    const onSelectTier = useCallback((tier) => {
        history.replace(`/champions?version=${currentVersion}&tier=${tier}`);
    }, [currentVersion]);


    return (
        <Wrapper>
            <ChampionsHeader
                title={t('championAnalyze.championRanking')}
            >
                <ChampionFilter
                    borderColor={theme.color.default_border_color}
                    currentVersion={currentVersion}
                    selectVersion={onSelectVersion}
                    currentTier={currentTier}
                    selectTier={onSelectTier}
                />
            </ChampionsHeader>
            <LaneWrapper>
                <ChampionsRankLaneSelect
                    lane={rankingLane}
                    onClick={onClickLane}
                />
            </LaneWrapper>
            <ChampionsWhiteWrapper>
                {versions.length === 0 ?
               <LoadingWrapper>
                   <Loading/>
               </LoadingWrapper>
                    :
                    <Suspense fallback={<LoadingWrapper><Loading/></LoadingWrapper>}>
                        <ChampionsRankingTable
                            pageValue={pageValue}
                        />
                    </Suspense>
                }
            </ChampionsWhiteWrapper>
        </Wrapper>
    );
};

export default ChampionsRank;
