import styled from "styled-components";


const changeToNewValue = (basic, change) => {
    let basicValue = basic;
    if (change) basicValue = change;
    return basicValue;
}

export const PurpleBox = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 10px;
  font-weight: bold;
  color: ${props => props.theme.color.dark_200};
  font-size: ${props => changeToNewValue('14px', props.fontSize)};
  border: ${props => changeToNewValue(`1px solid ${props.theme.color.default_border_color}`, props.border)};
  background-color: ${props => changeToNewValue(props.theme.color.site_main_color,props.bgColor)};
  border-radius: ${props => changeToNewValue('6px',props.borderRadius)};
`;



export const SummonerMatchBox = styled.div`
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;

`;