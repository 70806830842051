import React, {useCallback} from 'react';
import styled from "styled-components";
import ChampionImageWithLane from "../../../../../../common/ChampionImageWithLane";
import WinRateText from "../../../../../../common/WinrateText";
import {hundredCheck} from "../../../../../../../function";

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 54px;
  cursor: pointer;
  padding: ${props => {
    let width = '6px;'
    if (props.selected) width = '5.5px'
    return width;
  }};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  border: ${props => {
    let border = props.theme.color.default_border_color
    if (props.selected) border = props.theme.color.green_100;
    let width = '1px'
    if (props.selected) width = '1.5px'
    return `${width} solid ${border}`;
  }};
  background: ${props => {
    let background = 'transparent';
    if (props.selected) background = props.theme.color.green_200;
    return background;
  }};
  
  
  &:hover {
    background: ${props => {
      let background = props.theme.color.white_200;
      if (props.selected) background = props.theme.color.green_200;
      return background;
    }};
  }

  & + & {
    margin-left: 5px;
  }
`;

const ChampImageWrapper = styled.div`
  width: 42px;
  height: 42px;


  .non_image > img {
    width: 42px;
    height: 42px;
    pointer-events: none;
    margin: 0;
  }

  .imgChamp {
    width: 48px;
    height: 48px;
    pointer-events: none;
  }

  .imgDiv {
    border-radius: 8px;
  }

`;

const GameCountText = styled.span`
  color: ${props => props.theme.color.dark_100};
  font-size: 12px;
  font-weight: 500;
  margin-top: 2px;
  line-height: 14px;
`;

const WinRateTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2px;
  height: 14px;
`;

const Percent = styled.span`
  margin-left: 2px;
  font-size: 12px;
  color: ${props => props.theme.color.dark_200};
`;


const ChampCarouselItem = ({selected, onClickSelected, champion_id, games, win_rate, position, index}) => {
    const onClick = useCallback((e) => {
        onClickSelected(e, champion_id);
    }, [onClickSelected]);

    const renderWinRatePercent = useCallback((value) => {
            return hundredCheck(value.toFixed(1))
    }, []);


    return (
        <Wrapper selected={selected} onClick={onClick}>
            <ChampImageWrapper>
                <ChampionImageWithLane
                    emptyHelmet={true}
                    disabled={true}
                    champion_id={champion_id}
                    position={position}
                />
            </ChampImageWrapper>
            <GameCountText selected={selected}>{games}</GameCountText>
            <WinRateTextWrapper>
                <WinRateText fontSize={"12px"}>{renderWinRatePercent(win_rate)}</WinRateText>
                <Percent>%</Percent>
            </WinRateTextWrapper>
        </Wrapper>
    );
};

export default ChampCarouselItem;
