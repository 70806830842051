import React, {useMemo} from 'react';
import swarm_temp from "../../../store/static_json/swarm_temp";
import styled from "styled-components";

const Wrapper = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background-color: #000;
  border-radius: 50%;

  & + & {
    margin-left: 4px;
  }
`;
const SwarmImage = ({id, width, height}) => {

    const imgObj = useMemo(() => {
        try {
            const find = swarm_temp.find((data) => Number(data.id) === Number(id));
            return find;
        } catch (e) {
            return 0;
        }
    }, []);
    const getSwarmImagePath = () => {
        const basePath = "https://raw.communitydragon.org/latest/game/assets/ux/strawberry/upgradeselection/icons"
        return basePath + imgObj.augmentSmallIconPath.toLowerCase()
    }


    if (!imgObj) return null

    return (
        <Wrapper>
            <img src={getSwarmImagePath()} alt={''} width={width} height={height}/>
        </Wrapper>
    );
};

export default SwarmImage;