import React from 'react';
import SpectateLiveSummonerItem from "./summonerItem";
import styled from "styled-components";


const Wrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${props => props.theme.color.default_border_color};

  & + & {
    border-left: 1px solid ${props => props.theme.color.default_border_color};
  }

`;
const SpectateLiveSummonerList = ({reverse,team,list,platformId}) => {
    return (
        <Wrapper team={team}>
            {list.map((data,index) => {
                return <SpectateLiveSummonerItem key={index} reverse={reverse} data={data} region={platformId}/>
            })}

        </Wrapper>
    );
};

export default SpectateLiveSummonerList;