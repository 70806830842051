const swarmPassive = [
    {
        "id": 33301,
        "nameTRA": "방어력 상승",
        "augmentSmallIconPath": "/Blessing_Augment/ArmorUp_Large.png",
        "rarity": "kGold"
    },
    {
        "id": 33301,
        "nameTRA": "한입 크기",
        "augmentSmallIconPath": "/Blessing_Augment/BiteSized_Large.png",
        "rarity": "kGold"
    },
    {
        "id": 33301,
        "nameTRA": "탄환 애호가",
        "augmentSmallIconPath": "/Blessing_Augment/BulletMania_Large.png",
        "rarity": "kGold"
    },
    {
        "id": 33301,
        "nameTRA": "카드 수집가",
        "augmentSmallIconPath": "/Blessing_Augment/CardCollector_Large.png",
        "rarity": "kGold"
    },
    {
        "id": 33301,
        "nameTRA": "축배!",
        "augmentSmallIconPath": "/Blessing_Augment/Celebration_Large.png",
        "rarity": "kGold"
    },
    {
        "id": 33301,
        "nameTRA": "능력치의 순환",
        "augmentSmallIconPath": "/Blessing_Augment/CircleofStats_Large.png",
        "rarity": "kGold"
    },
    {
        "id": 33301,
        "nameTRA": "치명적인 확장",
        "augmentSmallIconPath": "/Blessing_Augment/CriticalExpansion_Large.png",
        "rarity": "kGold"
    },
    {
        "id": -1,
        "nameTRA": "죽음과 세금",
        "augmentSmallIconPath": "/Blessing_Augment/Death_and_Taxes_Large.png",
        "rarity": "kGold"
    },
    {
        "id": -1,
        "nameTRA": "크기 교란기",
        "augmentSmallIconPath": "/Blessing_Augment/DimensionScrambler_Large.png",
        "rarity": "kGold"
    },
    {
        "id": 33302,
        "nameTRA": "성쇠",
        "augmentSmallIconPath": "/Blessing_Augment/EbbandFlow_Large.png",
        "rarity": "kGold"
    },
    {
        "id": 33301,
        "nameTRA": "정예 폭격수",
        "augmentSmallIconPath": "/Blessing_Augment/EliteBomber_Large.png",
        "rarity": "kGold"
    },
    {
        "id": -1,
        "nameTRA": "재생형 조직",
        "augmentSmallIconPath": "/Blessing_Augment/Fast_Healer_Large.png",
        "rarity": "kGold"
    },
    {
        "id": -1,
        "nameTRA": "속도 수집",
        "augmentSmallIconPath": "/Blessing_Augment/GatheringSpeed_Large.png",
        "rarity": "kGold"
    },
    {
        "id": 33301,
        "nameTRA": "유리 대포",
        "augmentSmallIconPath": "/Blessing_Augment/GlassCannon_Large.png",
        "rarity": "kGold"
    },
    {
        "id": -1,
        "nameTRA": "강타자",
        "augmentSmallIconPath": "/Blessing_Augment/Heavy_Hitter_Large.png",
        "rarity": "kGold"
    },
    {
        "id": -1,
        "nameTRA": "비축가",
        "augmentSmallIconPath": "/Blessing_Augment/Hoarder_Large.png",
        "rarity": "kGold"
    },
    {
        "id": 33301,
        "nameTRA": "전쟁기계",
        "augmentSmallIconPath": "/Blessing_Augment/Juggernaut_Large.png",
        "rarity": "kGold"
    },
    {
        "id": 33301,
        "nameTRA": "생명 그 이상",
        "augmentSmallIconPath": "/Blessing_Augment/LargerThanLife_Large.png",
        "rarity": "kGold"
    },
    {
        "id": -1,
        "nameTRA": "장거리",
        "augmentSmallIconPath": "/Blessing_Augment/LongRange_Large.png",
        "rarity": "kGold"
    },
    {
        "id": 33301,
        "nameTRA": "대장정",
        "augmentSmallIconPath": "/Blessing_Augment/LongRoad_Large.png",
        "rarity": "kGold"
    },
    {
        "id": -1,
        "nameTRA": "신진 대사 폭주",
        "augmentSmallIconPath": "/Blessing_Augment/MetabolicOverdrive_Large.png",
        "rarity": "kGold"
    },
    {
        "id": 33301,
        "nameTRA": "중요 임무",
        "augmentSmallIconPath": "/Blessing_Augment/NoCrit_Large.png",
        "rarity": "kGold"
    },
    {
        "id": 33301,
        "nameTRA": "피로 회복제",
        "augmentSmallIconPath": "/Blessing_Augment/PickmeUp_Large.png",
        "rarity": "kGold"
    },
    {
        "id": -1,
        "nameTRA": "영점 사격",
        "augmentSmallIconPath": "/Blessing_Augment/PointBlank_Large.png",
        "rarity": "kGold"
    },
    {
        "id": -1,
        "nameTRA": "부동의 제물",
        "augmentSmallIconPath": "/Blessing_Augment/RageEngine_Large.png",
        "rarity": "kGold"
    },
    {
        "id": -1,
        "nameTRA": "전속력 돌진",
        "augmentSmallIconPath": "/Blessing_Augment/RammingSpeed_Large.png",
        "rarity": "kGold"
    },
    {
        "id": 33301,
        "nameTRA": "난사 후 기도",
        "augmentSmallIconPath": "/Blessing_Augment/SprayandPray_Large.png",
        "rarity": "kGold"
    },
    {
        "id": -1,
        "nameTRA": "부분적 채식주의",
        "augmentSmallIconPath": "/Blessing_Augment/StealEssence_Large.png",
        "rarity": "kGold"
    },
    {
        "id": 33301,
        "nameTRA": "지속시간 업그레이드",
        "augmentSmallIconPath": "/Blessing_Augment/TimeNotDamage_Large.png",
        "rarity": "kGold"
    },
    {
        "id": 33301,
        "nameTRA": "궁극기 속도 상승",
        "augmentSmallIconPath": "/Blessing_Augment/UltimateHaste_Large.png",
        "rarity": "kGold"
    },
    {
        "id": -1,
        "nameTRA": "속행",
        "augmentSmallIconPath": "/Blessing_Augment/WithHaste_Large.png",
        "rarity": "kGold"
    },
    {
        "id": 33301,
        "nameTRA": "노련한 싸움꾼",
        "augmentSmallIconPath": "/Blessing_Augment/XPFanatic_Large.png",
        "rarity": "kGold"
    },
    {
        "id": -1,
        "nameTRA": "정서 보조 애완동물",
        "augmentSmallIconPath": "/Blessing_Augment/Zookeeper_Large.png",
        "rarity": "kGold"
    },
    {
        "id": 33101,
        "nameTRA": "스킬 가속",
        "augmentSmallIconPath": "/Cooldown_Small.png",
        "rarity": "kBronze"
    },
    {
        "id": 33102,
        "nameTRA": "범위 크기",
        "augmentSmallIconPath": "/AoESize_Small.png",
        "rarity": "kBronze"
    },
    {
        "id": 33103,
        "nameTRA": "방어력",
        "augmentSmallIconPath": "/Armor_Small.png",
        "rarity": "kBronze"
    },
    {
        "id": 33104,
        "nameTRA": "치명타 확률",
        "augmentSmallIconPath": "/CritChance_Small.png",
        "rarity": "kBronze"
    },
    {
        "id": 33105,
        "nameTRA": "피해량",
        "augmentSmallIconPath": "/Damage_Small.png",
        "rarity": "kBronze"
    },
    {
        "id": 33106,
        "nameTRA": "지속시간",
        "augmentSmallIconPath": "/Duration_Small.png",
        "rarity": "kBronze"
    },
    {
        "id": 33107,
        "nameTRA": "경험치",
        "augmentSmallIconPath": "/Exp_Small.png",
        "rarity": "kBronze"
    },
    {
        "id": 33108,
        "nameTRA": "체력 재생",
        "augmentSmallIconPath": "/HealthRegeneration_Small.png",
        "rarity": "kBronze"
    },
    {
        "id": 33109,
        "nameTRA": "최대 체력",
        "augmentSmallIconPath": "/MaxHealth_Small.png",
        "rarity": "kBronze"
    },
    {
        "id": 33110,
        "nameTRA": "이동 속도",
        "augmentSmallIconPath": "/MoveSpeed_Small.png",
        "rarity": "kBronze"
    },
    {
        "id": 33111,
        "nameTRA": "획득 반경",
        "augmentSmallIconPath": "/PickupRange_Small.png",
        "rarity": "kBronze"
    },
    {
        "id": 33112,
        "nameTRA": "투사체 개수",
        "augmentSmallIconPath": "/ProjectileCount_Small.png",
        "rarity": "kBronze"
    },
    {
        "id": 33201,
        "nameTRA": "인도의 저주",
        "augmentSmallIconPath": "/Weapon/9307_Aurora_Weapon.png",
        "rarity": "kSilver"
    },
    {
        "id": 33401,
        "nameTRA": "고양된 저주",
        "augmentSmallIconPath": "/Weapon/9307_Aurora_Weapon.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33202,
        "nameTRA": "족쇄 할퀴기",
        "augmentSmallIconPath": "/Weapon/9303_Briar_Weapon.png",
        "rarity": "kSilver"
    },
    {
        "id": 33402,
        "nameTRA": "포악한 베기",
        "augmentSmallIconPath": "/Weapon/9303_Briar_Weapon.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33203,
        "nameTRA": "촉수 후려치기",
        "augmentSmallIconPath": "/Weapon/9305_Illaoi_Weapon.png",
        "rarity": "kSilver"
    },
    {
        "id": 33403,
        "nameTRA": "곰의 강타",
        "augmentSmallIconPath": "/Weapon/9305_Illaoi_Weapon.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33204,
        "nameTRA": "야옹 야옹",
        "augmentSmallIconPath": "/Weapon/9300_Jinx_Weapon.png",
        "rarity": "kSilver"
    },
    {
        "id": 33404,
        "nameTRA": "전투 고양이 총알 세례",
        "augmentSmallIconPath": "/Weapon/9300_Jinx_Weapon.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33205,
        "nameTRA": "방패 타격",
        "augmentSmallIconPath": "/Weapon/9301_Leona_Weapon.png",
        "rarity": "kSilver"
    },
    {
        "id": 33405,
        "nameTRA": "사자의 광명",
        "augmentSmallIconPath": "/Weapon/9301_Leona_Weapon.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33206,
        "nameTRA": "토끼뜀",
        "augmentSmallIconPath": "/Weapon/9308_Riven_Weapon.png",
        "rarity": "kSilver"
    },
    {
        "id": 33406,
        "nameTRA": "당근 격돌",
        "augmentSmallIconPath": "/lol-game-data/assets/ASSETS/Items/Icons2D/AutoAtlas/SmallIcons/Strawberry/9308_Riven_Weapon.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33207,
        "nameTRA": "음향의 물결",
        "augmentSmallIconPath": "/Weapon/9302_Seraphine_Weapon.png",
        "rarity": "kSilver"
    },
    {
        "id": 33407,
        "nameTRA": "동물 메아리",
        "augmentSmallIconPath": "/Weapon/9302_Seraphine_Weapon.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33208,
        "nameTRA": "날개 달린 단검",
        "augmentSmallIconPath": "/Weapon/9306_Xayah_Weapon.png",
        "rarity": "kSilver"
    },
    {
        "id": 33408,
        "nameTRA": "연인의 도탄",
        "augmentSmallIconPath": "/Weapon/9306_Xayah_Weapon.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33209,
        "nameTRA": "강철 폭풍",
        "augmentSmallIconPath": "/Weapon/9304_Yasuo_Weapon.png",
        "rarity": "kSilver"
    },
    {
        "id": 33409,
        "nameTRA": "떠도는 폭풍",
        "augmentSmallIconPath": "/Weapon/9304_Yasuo_Weapon.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33210,
        "nameTRA": "칼날 부메랑",
        "augmentSmallIconPath": "/Weapon/9183_Boomerang.png",
        "rarity": "kSilver"
    },
    {
        "id": 33410,
        "nameTRA": "사중 부메랑",
        "augmentSmallIconPath": "/Weapon/9183_Boomerang.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33211,
        "nameTRA": "소용돌이 장갑",
        "augmentSmallIconPath": "/Weapon/9181_NeonLotus.png",
        "rarity": "kSilver"
    },
    {
        "id": 33411,
        "nameTRA": "폭풍의 건틀릿",
        "augmentSmallIconPath": "/Weapon/9181_NeonLotus.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33212,
        "nameTRA": "개틀링 토끼 건",
        "augmentSmallIconPath": "/Weapon/9176_GatlingGun.png",
        "rarity": "kSilver"
    },
    {
        "id": 33412,
        "nameTRA": "이중 깡충깡충 포화",
        "augmentSmallIconPath": "/Weapon/9176_GatlingGun.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33213,
        "nameTRA": "사자의 비가",
        "augmentSmallIconPath": "/Weapon/9175_CrescentStrike.png",
        "rarity": "kSilver"
    },
    {
        "id": 33413,
        "nameTRA": "휘감는 빛",
        "augmentSmallIconPath": "/Weapon/9175_CrescentStrike.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33214,
        "nameTRA": "상어잡이 해양 기뢰",
        "augmentSmallIconPath": "/Weapon/9185_FireBomb.png",
        "rarity": "kSilver"
    },
    {
        "id": 33414,
        "nameTRA": "무한의 괴물 퇴치기",
        "augmentSmallIconPath": "/Weapon/9185_FireBomb.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33215,
        "nameTRA": "타오르는 단궁",
        "augmentSmallIconPath": "/Weapon/9177_FireStrike.png",
        "rarity": "kSilver"
    },
    {
        "id": 33415,
        "nameTRA": "진화한 불꽃 사격",
        "augmentSmallIconPath": "/Weapon/9177_FireStrike.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33216,
        "nameTRA": "얼음작렬 갑옷",
        "augmentSmallIconPath": "/Weapon/9193_FrostNova.png",
        "rarity": "kSilver"
    },
    {
        "id": 33416,
        "nameTRA": "완전 빙결",
        "augmentSmallIconPath": "/Weapon/9193_FrostNova.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33217,
        "nameTRA": "광휘 역장",
        "augmentSmallIconPath": "/Weapon/9173_HollowRadiance.png",
        "rarity": "kSilver"
    },
    {
        "id": 33417,
        "nameTRA": "폭발의 포옹",
        "augmentSmallIconPath": "/Weapon/9173_HollowRadiance.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33218,
        "nameTRA": "회오리 칼날",
        "augmentSmallIconPath": "/Weapon/9171_HolyBauble.png",
        "rarity": "kSilver"
    },
    {
        "id": 33418,
        "nameTRA": "그치지 않는 폭풍",
        "augmentSmallIconPath": "/Weapon/9171_HolyBauble.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33219,
        "nameTRA": "동물 지뢰",
        "augmentSmallIconPath": "/Weapon/9188_Mine.png",
        "rarity": "kSilver"
    },
    {
        "id": 33419,
        "nameTRA": "징크스의 삼중 다이너마이트",
        "augmentSmallIconPath": "/Weapon/9188_Mine.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33220,
        "nameTRA": "절멸자",
        "augmentSmallIconPath": "/Weapon/9178_DropTheHammer.png",
        "rarity": "kSilver"
    },
    {
        "id": 33420,
        "nameTRA": "동물의 종말",
        "augmentSmallIconPath": "/Weapon/9178_DropTheHammer.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33221,
        "nameTRA": "전투 토끼 석궁",
        "augmentSmallIconPath": "/Weapon/9179_FanOfKnives.png",
        "rarity": "kSilver"
    },
    {
        "id": 33421,
        "nameTRA": "토끼 프라임 거대 석궁",
        "augmentSmallIconPath": "/Weapon/9179_FanOfKnives.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33222,
        "nameTRA": "발자국 중독 장치",
        "augmentSmallIconPath": "/Weapon/9192_PoisonTrail.png",
        "rarity": "kSilver"
    },
    {
        "id": 33422,
        "nameTRA": "맨발 화학 물질 분사기",
        "augmentSmallIconPath": "/Weapon/9192_PoisonTrail.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33223,
        "nameTRA": "귀여운 발사기",
        "augmentSmallIconPath": "/Weapon/9180_UwUBlaster.png",
        "rarity": "kSilver"
    },
    {
        "id": -1,
        "nameTRA": "왕 귀여운 발사기",
        "augmentSmallIconPath": "/Weapon/9180_UwUBlaster.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33224,
        "nameTRA": "메아리치는 박쥐칼날",
        "augmentSmallIconPath": "/Weapon/9190_ScatterArrows.png",
        "rarity": "kSilver"
    },
    {
        "id": 33423,
        "nameTRA": "베인의 크로마칼날",
        "augmentSmallIconPath": "/Weapon/9190_ScatterArrows.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33225,
        "nameTRA": "토끼 초강력 폭발",
        "augmentSmallIconPath": "/Weapon/9184_StarStrike.png",
        "rarity": "kSilver"
    },
    {
        "id": 33424,
        "nameTRA": "고속 토끼 속사포",
        "augmentSmallIconPath": "/Weapon/9184_StarStrike.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33226,
        "nameTRA": "스태틱의 검",
        "augmentSmallIconPath": "/Weapon/9174_StatikkShiv.png",
        "rarity": "kSilver"
    },
    {
        "id": 33425,
        "nameTRA": "프룸비스의 전기도축칼",
        "augmentSmallIconPath": "/Weapon/9174_StatikkShiv.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33227,
        "nameTRA": "티.버",
        "augmentSmallIconPath": "/Weapon/9187_Tibbers.png",
        "rarity": "kSilver"
    },
    {
        "id": 33426,
        "nameTRA": "티.버 (특.대.형 에디션)",
        "augmentSmallIconPath": "/Weapon/9187_Tibbers.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33228,
        "nameTRA": "최후의 도시 대중교통",
        "augmentSmallIconPath": "/Weapon/9189_Train.png",
        "rarity": "kSilver"
    },
    {
        "id": 33427,
        "nameTRA": "FC 급행열차",
        "augmentSmallIconPath": "/Weapon/9189_Train.png",
        "rarity": "kPrismatic"
    },
    {
        "id": 33229,
        "nameTRA": "유미봇",
        "augmentSmallIconPath": "/Weapon/9172_TwinShadows.png",
        "rarity": "kSilver"
    },
    {
        "id": 33428,
        "nameTRA": "유미봇_최종_최종 ",
        "augmentSmallIconPath": "/Weapon/9172_TwinShadows.png",
        "rarity": "kPrismatic"
    }
];

export default swarmPassive