import React, {useCallback, useState} from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import ChampionsList from "../../../Champions/ChampionsContent/ChampionsSearch/ChampionsList";
import ChampionsHeader from "../../../Champions/ChampionsContent/ChampionsHeader";
import ChampionsCname from "../../../Champions/ChampionsContent/ChampionsSearch/ChampionsCName";
import ChampionsWhiteWrapper from "../../../Champions/ChampionsContent/ChampionsWhiteWrapper";
import ChampionsInput from "../../../Champions/ChampionsContent/ChampionsInput";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 480px;
  margin-right: 5px;


`;
const SearchInputWrapper = styled.div`
  margin-bottom: 10px;
`;

const MasterySearch = () => {
    const history = useHistory();
    const location = useLocation();
    const {t} = useTranslation();
    const [choVisible, setChoVisible] = useState(false);
    const [indivisualVisible, setindivisualVisible] = useState('');
    const [laneVisible, setLaneVisible] = useState('all');
    const championData = useSelector((state) => state?.imageStore.champions);

    const onClickCname = useCallback((value) => {
        if (value !== undefined && (typeof value === 'string' || typeof value === 'boolean')) {
            setChoVisible(value);
        }
    }, []);

    const onChangeINidivisualVisible = useCallback((value) => {
        setindivisualVisible(value)
    }, []);

    useCallback((index, value) => {
        if (typeof value === 'string') {
            const result = value.toLowerCase()
            setLaneVisible(result);
        }

    }, []);

    const onClickChamp = useCallback((champName) => {
        if (laneVisible === 'all' || laneVisible === 'rotation') {
            history.push({
                pathname: `/champions/${champName}/mastery/all`,
                search: location.search
            });
        } else {

            history.push({
                pathname: `/champions/${champName}/mastery/${laneVisible}`,
                search: location.search
            });
        }
    }, [location.search, laneVisible]);

    return (
        <Wrapper>
            <ChampionsHeader
                flexD={'column'}
                alignItems={'stretch'}
            >
                <SearchInputWrapper>
                    <ChampionsInput
                        inputValue={indivisualVisible}
                        setInputValue={onChangeINidivisualVisible}
                    />

                </SearchInputWrapper>
                <ChampionsCname
                    onClick={onClickCname}
                />
            </ChampionsHeader>
            <ChampionsWhiteWrapper>
                <ChampionsList
                    laneVisible={laneVisible}
                    championData={championData}
                    inidivisualVisible={indivisualVisible}
                    choVisible={choVisible}
                    onClickChamp={onClickChamp}
                />
            </ChampionsWhiteWrapper>
        </Wrapper>
    );
};

export default MasterySearch;
