import React, {useMemo} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;
  background-color: ${props => props.theme.color.site_main_color};
  margin-bottom: 10px;
`;
const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.color.default_border_color};
  padding: 5px 10px;
`;
const TitleSpan = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px;
`;
const ChampionDetailBalance = () => {
    const {t} = useTranslation()
    const balance = useSelector((state) => state?.champions?.balance);
    return (
        <Wrapper>
            <Title>
                <img width="16" height="16" src={'images/icon_s_position_aram.svg'} alt={''}/>
                <TitleSpan>
                    {t('balance.title')}
                </TitleSpan>
            </Title>
            <Content>
                <BalanceInfo title={t('balance.damage')} isPercent={true} value={balance.damage_dealt}/>
                <BalanceInfo title={t('balance.damageTaken')} isPercent={true} value={balance.damage_taken}
                             reverse={true}/>
                <BalanceInfo title={t('balance.atk')} value={balance.attack_speed_scaling} isPercent={true}/>
                <BalanceInfo title={t('balance.skill')} value={balance.ability_haste}/>
                <BalanceInfo title={t('balance.hp')} value={balance.healing} isPercent={true}/>
                <BalanceInfo title={t('balance.tenacity')} value={balance.tenacity} isPercent={true}/>
                <BalanceInfo title={t('balance.shield')} value={balance.shield_mod} isPercent={true}/>
                <BalanceInfo title={t('balance.ap')} value={balance.energy_regeneration} isPercent={true}/>
            </Content>
        </Wrapper>
    );
};

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  flex: 1;
`;
const InfoTitle = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_200};
`;
const PlusMinusWrapper = styled.span`
  font-size: 14px;
  font-weight: ${props => {
    let weight = 'bold';
    if (props.value === 0) weight = 500;
    return weight
  }};
  color: ${props => {
    if (props.value === 0) {
      return props.theme.color.dark_200
    }
    if (props.colorReverse) {
      let color = '#1CA484';
      if (props.value > 0) return '#E96767';
      return color;
    } else {
      let color = '#E96767';
      if (props.value > 0) return '#1CA484';
      return color;
    }

  }}
`;
const BalanceInfo = ({title, value, reverse, isPercent}) => {
    const calValue = useMemo(() => {
        if (value > 0) {
            return `+${value} ${isPercent ? "%" : ""}`
        } else if (value === 0) {
            return "-";
        } else {
            return `${value} ${isPercent ? "%" : ""}`;
        }
    }, [value]);
    return (
        <InfoWrapper>
            <InfoTitle>
                {title}
            </InfoTitle>
            <PlusMinusWrapper colorReverse={reverse} value={value}>
                {calValue}
            </PlusMinusWrapper>
        </InfoWrapper>
    )
}

export default ChampionDetailBalance;