import React, {useMemo} from 'react';
import styled from "styled-components";
import BestContentHeader from "./BestContentHeader";
import BestSection from "./BestSection";
import {Link} from "react-router-dom";

const Wrapper = styled.div`
  padding: 8px;
  ${props => !props.top && `border-top: 1px solid ${props.theme.color.default_border_color};`};
`;

const WrapperHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${props => {
    let color = props.theme.color.background_lose;
    if (props.win) color = props.theme.color.background_win;
    if(props.bgColor) color = props.bgColor;
    return color;
  }};
  padding: 4px;
  border-radius: 3px;
`;

const ContentWrapper = styled.div`
  display: flex;
  margin-top: 10px;
`;


const SummoerNameWrapper = styled.div`
  margin-top: 9px;
  display: flex;
  flex: 1;
  justify-content: center;
`;

const SummonerNameText = styled.span`
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
`;

const BestContent = ({
                         region = 'KR',
                         icon,
                         title,
                         lane = 'Top',
                         top = false,
                         extra,
                         aiText,
                         data,
                         score,
                         extraValue = 0,
                         staticColor,
                         onClickAi,
                         fontColor,
                         scoreFontSize,
                         paddingHorizontal,
                         paddingVertical,
                         noIcon,
                         isUrf,
    isAram,
    hide,
    headerBgColor,
    whiteLane,
                     }) => {


    const getName = useMemo(() => {
        const name = data?.riot_id_name;

        if(typeof name === "string" && name.length > 0) {
            if(hide) {
                return new Array(data?.riot_id_name.length).fill('').map(() => "*");
            } else {
                return data?.riot_id_name
            }
        }

        return "";
    },[hide,data])


    return (
        <Wrapper top={top}>
            <WrapperHeader win={data?.is_win} bgColor={headerBgColor}>
                <BestContentHeader
                    whiteLane={whiteLane}
                    noIcon={noIcon}
                    fontColor={fontColor}
                    icon={icon}
                    title={title}
                    lane={lane}
                />
            </WrapperHeader>
            <ContentWrapper>
                <BestSection
                    isAram={isAram}
                    isUrf={isUrf}
                    paddingVertical={paddingVertical}
                    paddingHorizontal={paddingHorizontal}
                    scoreFontSize={scoreFontSize}
                    onClick={onClickAi}
                    staticColor={staticColor}
                    champId={data.champion_id}
                    extraValue={extraValue}
                    score={score}
                    data={data}
                    aiText={aiText}
                    extra={extra}
                />
            </ContentWrapper>
            <SummoerNameWrapper>
                <Link
                    replace
                    to={`/summoner/${region}/${data.riot_id_name}`}
                    target="_blank"
                    className="common_search"
                >
                    <SummonerNameText>{getName}</SummonerNameText>
                </Link>
            </SummoerNameWrapper>
        </Wrapper>
    );
};

export default React.memo(BestContent);
