import React from 'react';
import HistoryContentPatchInfoContent from "./HistoryContentPatchInfoContent";
import styled, {useTheme} from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;

`;

const HistoryContentPatchInfo = ({list, champion, version, aram, type,skillList}) => {
    const theme = useTheme();

    return (
        <Wrapper>
            {Array.isArray(list) ? list.map((data, index) => {
                return (
                    <HistoryContentPatchInfoContent
                        skillList={skillList}
                        background={ theme.color.white_201}
                        champion={champion}
                        version={version}
                        spellKey={type === 'ARAM' ? 'ARAM' : data.skill_key}
                        spell={champion}
                        key={index}
                        title={data.title}
                        skillDetailList={data.skill_detail_history}
                    />
                )
            }) : null}
            {Array.isArray(aram) ? aram.map((data, index) => {
                return (
                    <HistoryContentPatchInfoContent
                        skillList={skillList}
                        background={theme.color.white_201}
                        champion={champion}
                        version={version}
                        spellKey={'ARAM'}
                        spell={champion}
                        key={index}
                        title={data.title}
                        skillDetailList={data.skill_detail_history}
                    />
                )
            }) : null}
        </Wrapper>
    );
};

export default HistoryContentPatchInfo;
