import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";


const AiRenderWrapper = styled.div`
  cursor: pointer;
`;

const AiWrapper = styled.div`
  padding-top: 9px;
`;
// &.verygood {
//     color: ${props => props.theme.color.yellow_100};
// }
//
// &.good {
//     color: #259A7E;
// }
//
// &.bad {
//     color: ${props => props.theme.color.blue_400};
// }
//
// &.verybad {
//     color: ${props => props.theme.color.gray_400};
// }
const AiText = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${props => {
    let color = props.theme.color.gray_400;
    if (props.value >= 75) {
      color = props.theme.color.yellow_100;
    } else if (props.value >= 50) {
      color = '#259A7E';
    } else if (props.value >= 25) {
      color = '#DE5353';
    }
    return color;
  }}
`;

const RatingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px 0;
  justify-content: center;
  align-items: center;
`;

const CrownImg = styled.img`
  width: 14px;
  height: 12px;
  margin-right: 3px;
`;

const KDAWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 6px 0 3px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const KDATitle = styled.span`
  padding: 1px 5px;
  color: ${props => props.theme.color.dark_100};
  font-size: 12px;
  text-align: center;
`

const KDAValue = styled.span`
  padding: 1px 3px 1px 0;
  color: ${props => props.theme.color.dark_100};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
`;

const DeathValue = styled.span`
  padding: 1px 3px 1px 0;
  font-style: normal;
  font-weight: 700;
  color:  #C24A4A;
  font-size: 12px;
`;

const MultiItemScore = ({item, t, target = 0, typeButton, setTypeButton}) => {

    const [changeRender, onChangeRender] = useState(target);
    useEffect(() => {
        onChangeRender(target)
        setTypeButton(false);
    }, [target, typeButton])

    const onClickRender = useCallback((value) => {
        if (value === 0) {
            onChangeRender(1)
        } else if (value === 1) {
            onChangeRender(0)
        }
    }, [])

    const renderKda = () => {
        return (
            <KDAWrapper onClick={() => onClickRender(1)}>
                <FlexWrapper>
                    <KDATitle>
                        K
                    </KDATitle>
                    <KDATitle>
                        D
                    </KDATitle>
                    <KDATitle>
                        A
                    </KDATitle>
                </FlexWrapper>
                <FlexWrapper>
                    <KDAValue>
                        {item.kills}
                    </KDAValue>
                    <DeathValue>
                        {item.deaths}
                    </DeathValue>
                    <KDAValue>
                        {item.assists}
                    </KDAValue>
                </FlexWrapper>
            </KDAWrapper>)
    }


    const renderAi = () => {
        const aiValue = item.is_runaway ? 0 : item.ai_score.toFixed(0)
        return (
            <AiRenderWrapper onClick={() => onClickRender(0)}>
                <AiWrapper>
                    <AiText value={aiValue}>{aiValue}</AiText>
                </AiWrapper>
                {/*<div className="kda">*/}
                {/*    {item.is_runaway ? (*/}
                {/*        '-'*/}
                {/*    ) : item.death === 0 ? (*/}
                {/*        <span className="kda_color perfect">Perfect</span>*/}
                {/*    ) : (*/}
                {/*        kdaColor(item.kda.toFixed(2))*/}
                {/*    )}*/}
                {/*</div>*/}
                <RatingWrapper>

                    {item.mvp && <CrownImg src="/images/ico_best_player01.svg" alt="mvp"/>}
                    {item.ace && <CrownImg src="/images/ico_best_player04.svg" alt="mvp"/>}
                    {item.is_runaway ? (<span
                        className="away">{t('tags.escape')}</span>) : (item.ai_score_rank + t('summoner.grade', {
                        count: item.ai_score_rank,
                        ordinal: true,
                    }))}
                </RatingWrapper>
            </AiRenderWrapper>)
    }

    if (!item) return null
    return (<div>
        {changeRender === 0 && renderAi()}
        {changeRender === 1 && renderKda()}

    </div>);
};

export default MultiItemScore;
