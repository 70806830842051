import React, {useLayoutEffect, useState} from 'react';
import axios from "axios";
import {championCapitalize} from "../../function";
import {useSelector} from "react-redux";
import {getDataDragonRegion} from "../../i18n/languages";

const UseGetChampionSpells = (championName) => {
    const version = useSelector((state) => state?.gameVersion.version);
    const currentLang = useSelector((state) => state.translation.lang);
    const [skillList, setSkillList] = useState({
        spells: [],
        passive: {},
    });

    useLayoutEffect(() => {
        if (championName) {
            try {
                axios.get(`${process.env.REACT_APP_IMG}${version}/data/${getDataDragonRegion(currentLang)}/champion/${championCapitalize(championName)}.json`).then((res) => {
                    if (typeof res?.data?.data === 'object') {
                        const data = Object.values(res.data.data)[0];
                        setSkillList({
                            spells: data.spells,
                            passive: data.passive,
                        });
                    }
                });
            } catch (e) {
                console.log(e);
            }
        }
    }, [currentLang,championName]);


    return skillList

};

export default UseGetChampionSpells;
