import React from 'react';
import styled from "styled-components";
import ChampionDetailMasteryFilter from "./ChampionDetailMasteryFilter";
import ChampionDetailMasteryTable from "./ChampionDetailMasteryTable";
import useDispatchMastery from "../../../common/hooks/useDispatchMastery";
import {GET_CHAMPIONS_MASTERY_REQUEST} from "../../../store/reducer/champions";
import {useParams} from "react-router-dom";
import {capitalizeLetter} from "../../../function";
import {useSelector} from "react-redux";
import {ErrorBoundary} from "react-error-boundary";
import UseSetCanonical from "../../../common/hooks/useSetCanonical";
import useTitleAndDescription from "../../../common/hooks/useTitleAndDescription";
import {useTranslation} from "react-i18next";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80vh;
`;


const FilterWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 12px;
`;

const LoadingWrapper = styled.div`
  height: 100px;
`;
const TableWrapper = styled.div`
  min-height: 300px;
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;
  overflow: hidden;
`;

const ChampionDetailMastery = ({champion}) => {
    UseSetCanonical();
    const {t} = useTranslation();
    const search = useParams();
    const lane = search?.position ? capitalizeLetter(search?.position) : 'All';
    const champion_id = champion?.champion_id;
    const ranking = useSelector((state) => state.champions.masteryChampion);
    const {
        getChampionsMasteryLoading,


    } = useSelector((state) => state.champions);
    useDispatchMastery(GET_CHAMPIONS_MASTERY_REQUEST, {lane, champion_id,})


    useTitleAndDescription({
        title: t('header.titleChampionOtp', {
            champion: champion?.champion_name
        }),
        description: t('header.titleChampionOtpDescription', {
            champion: champion?.champion_name
        })
    })


    return (
        <Wrapper>
            <FilterWrapper>
                <ChampionDetailMasteryFilter/>
            </FilterWrapper>
            <TableWrapper>
                {getChampionsMasteryLoading ? <LoadingWrapper/> :
                    <ErrorBoundary fallback={<div>something went wrong...</div>}>
                        <ChampionDetailMasteryTable pageValue={100} ranks={ranking}/>
                    </ErrorBoundary>
                }
            </TableWrapper>
        </Wrapper>
    );
};

export default ChampionDetailMastery;