import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
  align-items: center;
`;
const ProIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: ${props => {
    let color = '#8775FB';
    if (props.status === 'streamer') {
      color = '#EE6787'
    }
    return color;
  }};
  border-radius: 6px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  padding-bottom: 2px;
`;
const InfluIcon = ({status, width = 34, height = 14, icon}) => {
    const iconTextChange = () => {
        if(status === 'streamer') {
            return 'STRM'
        }
        return status.toUpperCase()
    }
    if (icon) {
        return (
            <div>
                <ProIcon width={width} height={height} status={status}>{iconTextChange()}</ProIcon>
            </div>
        )
    }
    return (
        <Wrapper>
            {status?.toLowerCase() === "pro" && <img src={'/images/tag_pro.svg'} width={width} height={height}/>}
            {status?.toLowerCase() === "streamer" && <img src={'/images/tag_strm.svg'} width={width} height={height}/>}
        </Wrapper>
    );
};

export default InfluIcon;