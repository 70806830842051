import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import useInput from "../../../../common/hooks/useInput";
import ChampionsDetailAgainstTable from "../ChampionsDetailAgainstTable";

const ChampionsDetailSynergy = ({currentBuild, version}) => {
    const {t} = useTranslation();
    const [currentSort, setCurrentSort] = useInput([{value: "synergy", sortType: false}]);
    const [sortList, setSortList] = useState({
        games: {
            value: "games",
            sortType: true,
        },
        win_rate: {
            value: "win_rate",
            sortType: true,
        },
        synergy: {
            value: "synergy",
            sortType: false,
        }
    });


    const onClickHeader = useCallback((sort) => {
        if (typeof sort === 'object') {
            const copyData = {...sort};
            const copySortData = {...sortList};
            if (copyData) {
                if (copySortData[copyData.value]) {
                    const allOff = allOffObject(copySortData);
                    copyData.sortType = !copyData.sortType;
                    allOff[copyData.value].sortType = copyData.sortType;
                    setCurrentSort([{...copyData}]);
                    setSortList(copySortData);
                }
            }
        }
    }, []);


    const allOffObject = useCallback((copyData) => {
        if (typeof copyData === 'object') {
            const copy = {...copyData};
            Object.keys(copy).forEach((key) => {
                copy[key].sortType = true;
            })
            return copy;
        }
        return copyData;
    }, [])

    const normalizeList = useMemo(() => {

        try {
            return currentBuild.synergy_champion.map((data) => {
                return {
                    ...data,
                    enemy_champion_id: data.teams
                }
            })
        } catch (e) {
           return [];
        }
    }, [currentBuild?.synergy_champion]);
    return (
        <ChampionsDetailAgainstTable
            synergy={true}
            width={'400px'}
            currentSort={currentSort}
            onClickHeader={onClickHeader}
            sortList={sortList}
            champions={normalizeList}
            title={t('championDetailBuild.synergyWith')}
            barColor={'#F3A634'}
            version={version}
            CHAMPION_TABLE_FLEX={.51}
            GAMES_TABLE_FLEX={.17}
            WINRATE_TABLE_FLEX={.17}
            SYNERGY_TABLE_FLEX={.17}
        />
    );
};

export default ChampionsDetailSynergy;
