import React from 'react';
import styled from "styled-components";


const UpArrowDiv = styled.div`
  width: 0;
  height: 0;
  border-top: 0  solid transparent;
  border-left: ${props => props.size + "px"} solid transparent;
  border-right: ${props => props.size + "px"} solid transparent;
  border-bottom: ${props => props.size + 2 + "px"} solid ${props => {
      let color = '#1CA484';
      if(props.color) color = props.color;
      return color;
  }};
`;

const UpArrow = ({color = '#1CA484',size = 3.5}) => {
    return (
        <UpArrowDiv color={color} size={size}/>
    );
};

export default UpArrow;
