const setText = (encrypt_key, match_id, platform_id) => {
    if (!encrypt_key || !match_id || !platform_id) {
        return null;
    }
    return `rem Deeplol.gg Spectate
SETLOCAL enableextensions enabledelayedexpansion
@echo off

echo -----------------------
echo Spectate by Deeplol.gg
echo -----------------------

for %%d in (C D E F) do (
  for %%p in ("\\Riot Games\\League of Legends KR" "\\Riot Games\\League of Legends" "\\Games\\League of Legends KR" "\\Games\\League of Legends" "\\Games\\League of Legends\\Riot Games\\League of Legends") do (
    if exist "%%d:%%~p" (
      SET LOL_PATH= %%d:%%~p
      @cd /d "!lol_path!"
      goto :Locale
    )
  )
)

echo Not found League of Legends folder!
pause
exit



:Locale
cd /d "!lol_path!\\Config"
for /F "delims=" %%d in ('find " locale: " LeagueClientSettings.yaml') do set "locale=%%d"
for /F "tokens=2 delims=: " %%d in ("!locale!") do set "locale=%%d"
cd /d "!lol_path!\\Game"
if exist "League of Legends.exe" (
\t@start "" "League of Legends.exe" "spectator spectator-consumer.${platform_id.toLowerCase()}.lol.pvp.net:80 ${encrypt_key} ${match_id} ${platform_id}" "-UseRads" "-Locale=!locale!" "-GameBaseDir=.."
\tgoto :eof
)

pause
exit`;
}


export const createClientLiveURL = (encrypt_key, match_id, platform_id) => {
    const baseText = setText(encrypt_key, match_id, platform_id);
    if (!baseText) {
        throw "check your parameter"
    }
    const blob = new Blob([baseText]);
    return window.URL.createObjectURL(blob);
}