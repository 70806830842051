import {GET_RANKING_FAILURE, GET_RANKING_REQUEST, GET_RANKING_SUCCESS} from "../reducer/ranking";

const initialState = {
    ranking : {},
    getRankingLoading: false,
    getRankingDone : false,
    getRankingError : null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_RANKING_REQUEST: {
            return {
                ...state,
                ranking : {...state.ranking,
                    summoner_rank_list: [],
                    total_page:'',
                },
                getRankingLoading: true,
                getRankingDone : false,
                getRankingError : null,
            }
        }
        case GET_RANKING_SUCCESS : {
            return {
                ...state,
                ranking : {...action.data.ranking},
                getRankingLoading: false,
                getRankingDone : true,
            }
        }
        case GET_RANKING_FAILURE: {
            return {
                ...state,
                getRankingLoading: false,
                getRankingError : action.error,
            }
        }
        default:
            return state;
    }
};
export default reducer;
