import React, {useCallback, useMemo} from 'react';
import BigTooltip from "../../Tooltip/BigTooltipBox";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const ReletiveDiv = styled.div`
  position: relative;
  margin: ${props => props.margin};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ChampionImage = styled.img`
    border-radius: 6px;
`;

const AbsoluteSpan = styled.span`
  text-align: center;
  width: 2.5ch;
  position: absolute;
  padding: 2px;
  font-size: 12px;
  background-color: rgba(0,0,0,0.8);
  color: #fff;
  bottom: 0;
  right: 0;
`;

const ChampionSkillImage = ({version, img, absoluteText, width = 36, height = 36, margin, reverse, spell,children}) => {
    const {i18n,t} = useTranslation();
    const getAllEqualsArray = useCallback((array) => {
        if (Array.isArray(array) && array.length > 0) {
            const every = array.every((data) => data === array[0]);
            if (every) {
                return array[0].toString()
            } else {
                return array.toString().replaceAll(',', " / ");
            }
        }
        return [];
    }, []);

    const tooltipText = useMemo(() => {
        if (spell) {
            const name = spell.name;
            const cooldown = getAllEqualsArray(spell.cooldown);
            const cost = getAllEqualsArray(spell.cost);
            const range = getAllEqualsArray(spell.range);
            const removeSkillInfo = spell?.tooltip?.replaceAll(/{{.*?}}(?=.)/g,"?");
            const tooltip = removeSkillInfo?.replaceAll(/{{ spellmodifierdescriptionappend }}/g,"").replaceAll(/\?}}/g,"");

            return `<span style="color: #46B9AA; font-weight: bold">${name}</span>\n${t("skills.cooldown")}: ${cooldown}\n${t("skills.cost")}: ${cost}\n${t("skills.range")}: ${range}\n\n${tooltip}\n\n${spell.description}`
        }

    }, [spell,i18n.language])

    return (
        <ReletiveDiv margin={margin} className={'imgSkillDiv'}>
            <BigTooltip
                reverse={reverse}
                tooltipText={tooltipText}
                textAlign={'left'}
            >
                <ChampionImage
                    className={'imgSkill'}
                    width={width}
                    height={height}
                    src={`${process.env.REACT_APP_IMG}${version}/img/spell/${img}?w=72&h=72&f=webp`}
                    onError={(e) => e.target.src = "/images/icon_non_item.svg"}
                />
                {absoluteText && <AbsoluteSpan>{absoluteText}</AbsoluteSpan>}
            </BigTooltip>
            {children && React.cloneElement(children,{spell})}
        </ReletiveDiv>
    );
};

export default ChampionSkillImage;
