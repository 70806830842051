import {all, fork} from 'redux-saga/effects';
import summonerSaga from "./summoner";
import championsSaga from "./champions";
import rankingSaga from "./ranking";
import videoSaga from "./video";
import balanceSaga from "./balance";
import spectateSaga from "./spectate";


export function* rootSaga() {
    // noinspection JSCheckFunctionSignatures
    yield all([
        fork(summonerSaga),
        fork(championsSaga),
        fork(rankingSaga),
        fork(videoSaga),
        fork(balanceSaga),
        fork(spectateSaga),
    ])
}
