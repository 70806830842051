import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import {useHistory, useLocation} from "react-router-dom";
import ListButton from "../../../../common/ListButton";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 390px;
`;


const MasteryFilterRegion = ({selected}) => {
    const history = useHistory()
    const location = useLocation();
    const regionList = ['All', 'KR', 'NA', 'EUW', 'BR', 'VN'];
    const onClickRegion = useCallback((region) => {
        history.replace(`${location.pathname}?region=${region.toLowerCase()}`)
    }, [location]);
    const selectedIndex = useMemo(() => {
        return regionList.findIndex((data) => data.toLowerCase() === selected.toLowerCase());
    }, [selected])
    return (
        <Wrapper>
            {regionList.map((data, index) => {
                return (
                    <ListButton selected={selected.toLowerCase() === data.toLowerCase()} key={data}
                                onClick={() => onClickRegion(data)} selectedIndex={selectedIndex} index={index}
                                itemLength={regionList.length}>{data}</ListButton>
                )
            })}
        </Wrapper>
    );
};

export default MasteryFilterRegion;