import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 123px;
  text-align: center;
`;

const NoGameBox = ({children}) => {
    if (typeof children !== 'string') return null;
    return (
        <Wrapper>
            {children}
        </Wrapper>
    );
};

export default NoGameBox;