export const krAdStart = () => {
    try {
        const hasScript = document.querySelector('script[src="//js.rev.iq"]');

        if (hasScript) {
            return
        }


        const script = document.createElement('script');
        script.type = 'module';
        script.src = '//js.rev.iq';
        script.dataset.domain = 'deeplol.gg';
        document.head.appendChild(script);
    } catch (e) {
        console.log(e);
    }
}


export const playwire_start = (list) => {
    try {
        window.ramp = window.ramp || {};
        window.ramp.que = window.ramp.que || [];
        window.ramp.passiveMode = true;
        const playwire_init = function () {
            console.log('init called');
            window.ramp.addUnits(list).then(() => {
                window.ramp.displayUnits()
                console.log("display units called");
            }).catch((e) => {
                window.ramp.displayUnits()
                console.log('display error');
                console.log(e)
            });
        }
        if (list) {
            console.log("call playwire");
            console.log(window.ramp);
            window.ramp.que.push(playwire_init);
        }
    } catch (e) {
        console.log(e);
    }
}

export const playwire_destory = (list) => {
    try {
        console.log("destroyUnits called")
        if (window?.ramp?.settings?.slot) {
            console.log("destroyUnits", window.ramp.getUnits());
            window.ramp.destroyUnits(window.ramp.getUnits());
        } else {
            window.ramp.destroyUnits('all');
        }

    } catch (e) {
        console.log("destory fail", e);
    }
}


export const champion_center_content = [
    {
        type: 'corner_ad_video'
    },
    {
        type: 'flex_leaderboard'
    },
    {
        type: 'site_skin'
    },
    {
        selectorId: 'champion_content_top_ad_box',
        type: 'leaderboard_atf'
    },
    {
        selectorId: 'champion_content_bottom_ad_box',
        type: 'leaderboard_btf'
    },
    {
        type: 'bottom_rail'
    },
    {
        type: 'left_rail'
    },
    {
        type: 'right_rail'
    },
]


export const detail_summoner_ad = [
    {
        type: 'flex_leaderboard'
    },
    {
        selectorId: "detail_summoner_id",
        type: 'leaderboard_atf',
    }
]
export const summoner_ad_content = [
    {
        type: 'flex_leaderboard'
    },
    {
        type: 'corner_ad_video'
    },
    {
        type: 'site_skin'
    },
    {
        selectorId: 'summoner_rank_ad',
        type: 'med_rect_atf'
    },

    {
        selectorId: 'summoner_sidebar_ad',
        type: 'med_rect_btf'
    },
    {
        selectorId: 'summoner_content_top_ad_box',
        type: 'leaderboard_atf'
    },
    {
        selectorId: 'summoner_content_bottom_ad_box',
        type: "leaderboard_btf"
    },
    {
        type: 'bottom_rail'
    },
    {
        type: 'left_rail'
    },
    {
        type: 'right_rail'
    },
]
export const center_content = [
    {
        type: 'flex_leaderboard'
    },
    {
        type: 'corner_ad_video'
    },
    {
        type: 'site_skin'
    },
    {
        selectorId: 'center_content_top_ad_box',
        type: 'leaderboard_atf'
    },
    {
        selectorId: 'center_content_bottom_ad_box',
        type: 'leaderboard_btf'
    },
    {
        type: 'bottom_rail'
    },
    {
        type: 'left_rail'
    },
    {
        type: 'right_rail'
    },
]


export const champion_detail_content = [
    {
        type: 'flex_leaderboard'
    },
    {
        type: 'corner_ad_video'
    },
    {
        type: 'site_skin'
    },
    {
        selectorId: 'center_content_top_ad_box',
        type: 'leaderboard_atf'
    },
    {
        selectorId: 'center_content_bottom_ad_box',
        type: 'leaderboard_atf'
    },
    {
        type: 'bottom_rail'
    },
    {
        type: 'left_rail'
    },
    {
        type: 'right_rail'
    },
]
export const ranking_ad_content = [
    {
        type: 'flex_leaderboard'
    },
    {
        type: 'corner_ad_video'
    },
    {
        type: 'site_skin'
    },
    {
        selectorId: 'ranking_content_top_ad_box',
        type: 'leaderboard_atf'
    },
    {
        selectorId: "ranking_content_bottom_ad_box",
        type: 'leaderboard_btf'
    },
    {
        type: 'bottom_rail'
    },
    {
        type: 'left_rail'
    },
    {
        type: 'right_rail'
    },
]

export const home_ad_conetne = [
    {
        type: 'flex_leaderboard'
    },
    {
        type: 'corner_ad_video'
    },
    {
        selectorId: 'home_content_bottom_ad_box',
        type: 'leaderboard_btf'
    },]


export const multi_ad_content = [
    {
        type: 'flex_leaderboard'
    },
    {
        type: 'corner_ad_video'
    },
    {
        type: 'site_skin'
    },
    {
        selectorId: 'multi_content_top_ad_box',
        type: 'leaderboard_atf'
    },
    {
        type: 'bottom_rail'
    },
    {
        type: 'left_rail'
    },
    {
        type: 'right_rail'
    },
]