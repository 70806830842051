import styled from 'styled-components';

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  padding: 0 0 3rem 0;
  .home {
    width: 1080px;
    min-height: 74vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 130px;
    .react-tabs {
      position: relative;
      background-color: ${props => props.theme.color.site_main_color};
      border: 1px solid ${props => props.theme.color.purple_100};
      border-radius: 6px;
      overflow: hidden;
      &__tab-list {
        display: flex;
        border-radius: 6px;
      }
      &__tab {
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 37px;
        font-size: 14px;
        font-weight: 500;
        color: ${props => props.theme.color.dark_200};
        background-color: ${props => props.theme.color.white_201};
        cursor: pointer;


        
        
        
        
        &--selected {
          background-color: ${props => props.theme.color.site_main_color};
          color: ${props => props.theme.color.dark_100};
          border-bottom: 0;
          
          &:first-of-type {
            border-top-left-radius: 6px;
            border-left: 0;
            border-right: 0;
          }
          &:last-of-type {
            border-top-right-radius: 6px;
            border-right: 0;
            border-left: 0;
          }
        }
      }
      &__tab-panel {
        display: none;
        border-top: 0;
        ::-webkit-scrollbar {
          width: 5px;
        }
        ::-webkit-scrollbar-thumb {
          background-color: #b8cddc;
        }
        ::-webkit-scrollbar-track {
          background-color: #dbe8ff;
        }

        &--selected {
          display: block;
        }
      }
    }
  }
`;
