import React, {useCallback} from 'react';
import {threedigits} from "../../../../../function";
import styled, {useTheme} from "styled-components";
import ChampionImageWithLane from "../../../../common/ChampionImageWithLane";
import {dark, light} from "../../../../../common/theme";

const ItemBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  padding-left: 12px;

  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.color.white_200};
  }

`;
const ItemWrapper = styled.div`
  padding-right: 3px;
  background-color: ${props => props.theme.color.site_main_color};
  
  & + & {
    .light {
      border-top: 1px solid ${props => light.gray_200};
    }
    
    .dark {
      border-top: 1px solid ${props => dark.gray_200};
    }
  }

  &:last-of-type {
    .light {
      border-bottom: ${props => {
        let border = 0;
        if (props.length < 10) {
          border = ` 1px solid ${light.gray_200}`;
        }
        return border
      }};
    }
    .dark {
      border-bottom: ${props => {
        let border = 0;
        if (props.length < 10) {
          border = ` 1px solid ${dark.gray_200}`;
        }
        return border
      }}; 
    }
  }
`;


const ChampImgWrapper = styled.div`
  flex: ${props => props.flex || 1};
  display: flex;
  align-items: center;
`;


const GamesWrapper = styled.div`
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  flex: ${props => props.flex || 1};
`;

const SynergySpan = styled.span`
  color: ${props => {
    let color = props.theme.color.red_100;
    if (props.value > 0) color = props.theme.color.blue_100;
    return color;
  }};
`;

const WinrateColor = styled.span`
  font-size: 12px;
  color: ${props => {
    let color = props.theme.color.red_100;
    if (props.children >= 50) color = props.theme.color.blue_100;
    return color;
  }};
`;

const Percent = styled.span`
  color: ${props => props.theme.color.dark_200};
  font-size: 12px;
  font-weight: 500;
  margin-left: 1px;
`;


const ChampionImgBox = styled.div`
  position: relative;
  border-radius: 4px;

  .champName {
    font-weight: 500;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 12px;
    margin-right: 3px;
    height: 16px;
  }

  .imgChamp {
    width: 38px;
    height: 38px;
  }

  .imgDiv {
    border-radius: 5px;
  }

`;

const LaneBox = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;


const AgainstTableContent = ({
                                 length,
                                 games,
                                 champion_id,
                                 lane,
                                 winRate,
                                 synergy,
                                 CHAMPION_TABLE_FLEX,
                                 GAMES_TABLE_FLEX,
                                 WINRATE_TABLE_FLEX,
                                 SYNERGY_TABLE_FLEX
                             }) => {
    const theme = useTheme();
    const synergyCalc = useCallback((value) => {
        if (value > 0) {
            return `+${(value * 100).toFixed(1)}`
        } else {
            return `-${(Math.abs(value) * 100).toFixed(1)}`
        }
    }, [])


    return (
        <ItemWrapper length={length}>
            <ItemBox  className={theme.color.theme}>
                <ChampImgWrapper flex={CHAMPION_TABLE_FLEX}>
                    <ChampionImgBox>
                        <ChampionImageWithLane
                            enableChampName={true}
                            position={lane}
                            champion_id={champion_id}
                        />
                    </ChampionImgBox>
                </ChampImgWrapper>
                <GamesWrapper flex={GAMES_TABLE_FLEX}>
                    <span>{threedigits(games)}</span>
                </GamesWrapper>
                <GamesWrapper flex={WINRATE_TABLE_FLEX}>
                    <WinrateColor>{winRate === 100 ? 100 : winRate}</WinrateColor>
                    <Percent>%</Percent>
                </GamesWrapper>
                {typeof SYNERGY_TABLE_FLEX === 'number' &&
                    <GamesWrapper flex={SYNERGY_TABLE_FLEX}>
                        <SynergySpan value={Number(synergy)}>{synergyCalc(synergy)}</SynergySpan>
                        <Percent>%</Percent>
                    </GamesWrapper>
                }
            </ItemBox>
        </ItemWrapper>
    );
};

export default AgainstTableContent;
