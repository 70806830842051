export const GET_IMAGE_DATA_REQUEST = 'imageStore/GET_IMAGE_DATA_REQUEST';
export const GET_IMAGE_DATA_SUCCESS = 'imageStore/GET_IMAGE_DATA_SUCCESS';
export const GET_IMAGE_DATA_FAILURE = 'imageStore/GET_IMAGE_DATA_FAILURE';


export const SET_AI_DATA = 'imageStore/SET_AI_DATA';
export const SET_SEASON = 'imageStore/SET_SEASON';
export const SET_OLD_SEASON = 'imageStore/SET_OLD_SEASON';

export const SET_ITEM_INFO = 'imageStore/SET_ITEM_INFO'

export const SET_ARGUMENT_INFO = 'imageStore/SET_ARGUMENT_INFO'
export const SET_RUNE = 'imageStore/SET_RUNE'
export const SET_SPELL = 'imageStore/SET_SPELL'
export const SET_ITEM = 'imageStore/SET_ITEM'

export const SET_NOTICE = 'imageStore/SET_NOTICE';




