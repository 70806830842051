import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const ColorBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${props => {
    let color = '#8997AB';
    if (props.rank === 1) color = '#F4A634';
    if (props.rank > 1 && props.rank < 5) color = '#54B59E';
    if (props.rank > 4) color = '#E57474';
    return color;
}};
  width: ${props => props.width || '43px'};
  height: ${props => props.height || '28px'};
  border-radius: ${props => props.borderRadius || '3px'};
  color: #fff;
`;

const GradeText = styled.span`
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
`;

const RankSpan = styled.span`
  font-size: ${props => props.fontSize || '19px'};
  font-weight: 500;
`;

const ColorRankBox = ({rank = 4, width, height, borderRadius, fontSize,}) => {
    const {t} = useTranslation()
    return (
        <ColorBox rank={rank} width={width} height={height} borderRadius={borderRadius}>
            <RankSpan fontSize={fontSize}>{rank}{t('summoner.grade', {count: rank, ordinal: true})}</RankSpan>
        </ColorBox>
    );
};

export default ColorRankBox;