import React, {useState} from 'react';
import {useMutation} from "@tanstack/react-query";
import axios from "axios";

const Test = () => {
    const [input, setInput] = useState('');
    const [result, setResult] = useState('');
    const mutation = useMutation({
        mutationFn: async (puu_id) => {
            setResult("")
            const url = new URL('https://clf-b2c-api-cdn.dlol.gg/common/cdn-test');
            url.searchParams.set('puu_id', puu_id)
            const result = await axios.get(url.toString())
            return JSON.stringify(result)
        },
        onError: (e) => {
           setResult(JSON.stringify(e.message))
        },
        onSuccess:(res) => {
            setResult(res)
        }
    })


    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <input style={{width: 800}}
                       onChange={(e) => {
                           setInput(e.target.value);
                       }}
                />
                <button style={{background: 'lightgray', padding: '0 20px', marginLeft: 10}} onClick={async () => {
                    await mutation.mutate(input)
                }}>버튼
                </button>
            </div>
            <div style={{marginTop:20}}>
                {result}
            </div>
        </div>
    );
};

export default Test;