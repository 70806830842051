import React from 'react';

const useArenaFunc = ({match}) => {

    const getTeamList = () => {
        return match.match_basic_dict.arena_team;
    }
    const getArenaTeam = (myMatch) => {
        try {
            const puu_id = myMatch.puu_id;
            const teams = getTeamList(match);

            const findCurrentTeam = teams.find((teamList) => {
                return teamList.participant_puu_ids.find((user_puuid) => user_puuid === puu_id);
            })

            if (!findCurrentTeam) throw "puu_id not match"

            return findCurrentTeam;

        } catch (e) {
            return {
                assists: 0,
                deaths: 0,
                id: "KRUG",
                kda: 0,
                kills: 0,
                lose: 0,
                participant_puu_ids: [],
                placement: 0,
                round_seq: {
                    value: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                },
                win: 0,
            }
        }
    }

    const getGetLastWinRound = (team) => {
        try {
           const list =  team.round_seq.value;
           const findIndex = list.findLastIndex((data) => data !== 0);

           return {
               index :findIndex + 1,
               value : list[findIndex],
           };
        } catch (e) {
           return 0;
        }
    }

    return {
        getArenaTeam,
        getGetLastWinRound,
    }
};

export default useArenaFunc;