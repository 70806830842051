import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import MatchItem from './MatchItem';
import {MatchListContainer} from '../../styled/SummonerStyle';
import {GET_MORE_MATCHES_REQUEST} from "../../store/reducer/summoner";
import Loading from "../common/Loading";
import NewOverview from "./NewOverview";
import RestDayInfo from "./MatchList/RestDayInfo";
import {getTierScore} from "../../function";
import {FlexBox} from "../ui/styled";
import styled from "styled-components";


const Wrapper = styled.div`
  width: 100%;
`;
const MatchList = ({
                       region,
                       queueNum,
                       queueFilter,
                       championFilter,
                       filterMatch,
                       onChangeChampionId,
                       onChangeQueueFilter,
                       queueFilterMatch,
                   }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const summoner = useSelector((state) => state.summoner.summoner);
    const {
        getMoreMatchesDone,
        getMatchByQueueDone
    } = useSelector((state) => state.summoner);
    const [openId, setOpenId] = useState(0);
    const matchInfo = useSelector((state) => state.summoner.match);
    const currentLang = useSelector((state) => state.translation.lang);
    const [disabled, setDisabled] = useState(false);
    // const dailyAi = useSelector((state) => state.summoner.dailyAi);

    useEffect(() => {
        if (getMoreMatchesDone) {
            setDisabled(false);
        }
    }, [getMoreMatchesDone]);


    const onPressHandleMoreGame = () => {
        setDisabled(true);
        dispatch({
            type: GET_MORE_MATCHES_REQUEST,
            data: {
                champId: 0,
                queueNum: 'ALL',
                region: region,
                offset: matchInfo.length,
                match_creation_time: 1,
            }
        })
    }

    // 챔피언 선택


    // 정보 더 보기
    const handleMoreInfo = (gameId) => {
        const currentId = openId;
        if (currentId === gameId) {
            setOpenId(0);
        } else {
            setOpenId(gameId);
        }
    };


    const addCompanionMatchInfo = useMemo(() => {
        try {
            const result = filterMatch.map((data, index) => {
                const myPuuId = summoner.summoner_basic_info_dict?.puu_id;
                const myMatch = data.participants_list.find((item) => item.puu_id === myPuuId);
                const prevMyMatch = index === 0 ? {} : filterMatch[index - 1].participants_list.find((item) => item.puu_id === myPuuId);
                const nextMyMatch = filterMatch.length - 1 === index ? {} : filterMatch[index + 1].participants_list.find((item) => item.puu_id === myPuuId);

                const enemySide = data.participants_list.filter((item) => item.side !== myMatch.side);
                const current = data.participants_list.filter((item) => item.side === myMatch.side);

                const prev = index === 0 ? [] : filterMatch[index - 1].participants_list.filter((item) => item.side === prevMyMatch.side);
                const next = filterMatch.length - 1 === index ? [] : filterMatch[index + 1].participants_list.filter((item) => item.side === nextMyMatch.side);


                const prevWithTogether = current.map((item) => {
                    const hasItem = prev.find((prevItem) => prevItem.puu_id === item.puu_id);
                    const withTogether = !!(hasItem || item?.withTogether);
                    return {
                        ...item,
                        withTogether,
                    }
                });


                const result = prevWithTogether.map((item) => {
                    const hasItem = next.find((nextItem) => nextItem.puu_id === item.puu_id);
                    const withTogether = Boolean(hasItem || item?.withTogether);
                    return {
                        ...item,
                        withTogether,
                    }
                });

                const togetherCount = result.filter((item) => item?.withTogether);


                return {
                    ...data,
                    participants_list: [...result, ...enemySide],
                    togetherCount: togetherCount.length,
                }
            }, []);
            return result;
        } catch (e) {
            console.log(e);
            return filterMatch;
        }
    }, [filterMatch]);


    const separatingMatchByTakeARest = useMemo(() => {
        if (Array.isArray(addCompanionMatchInfo) && addCompanionMatchInfo.length > 0) {
            //4 hours == 3600 * 4
            return addCompanionMatchInfo.reduce((sum, cur, index) => {
                if (cur && index > 0) {
                    const restTime = 3600 * 4;
                    const currentMatchTime = Number(Number(cur?.match_basic_dict.creation_timestamp) + Number(cur?.match_basic_dict.game_duration));
                    const duration = Number(cur?.match_basic_dict.game_duration);

                    if (sum[sum.length - 1].lastMatchStamp > currentMatchTime) {
                        const timeCalc = Number(sum[sum.length - 1].lastMatchStamp) - Number(currentMatchTime);
                        if (timeCalc - restTime > 0) {
                            sum.push({
                                timestamp: currentMatchTime,
                                lastMatchStamp: currentMatchTime,
                                duration: duration,
                                match: [{...cur},],
                            })
                        } else {
                            sum[sum.length - 1].lastMatchStamp = currentMatchTime;
                            sum[sum.length - 1].duration += duration;
                            sum[sum.length - 1].match.push({...cur});
                        }
                    }
                }
                return sum;
            }, [{
                timestamp: addCompanionMatchInfo[0].match_basic_dict.creation_timestamp + addCompanionMatchInfo[0].match_basic_dict.game_duration,
                lastMatchStamp: Number(addCompanionMatchInfo[0].match_basic_dict.creation_timestamp) + Number(addCompanionMatchInfo[0].match_basic_dict.game_duration),
                match: [{...addCompanionMatchInfo[0]}],
                duration: addCompanionMatchInfo[0].match_basic_dict.game_duration,
            }]);

        }
        return [];
    }, [addCompanionMatchInfo]);

    const getAvgTier = useCallback((match,) => {
        try {
            const playerList = match.participants_list;
            const filterUnrank = playerList.filter((data) => data.tier !== "unranked");
            const combinedPlayerTier = filterUnrank.reduce((sum, cur) => {
                sum += getTierScore(`${cur.tier} ${cur.division}`).num;
                return sum;
            }, 0);
            const length = filterUnrank.length === 0 ? 1 : filterUnrank.length;
            return getTierScore(Math.trunc(combinedPlayerTier / length)).num;
        } catch (e) {
            console.log("avgTier", e);
            return 0;
        }
    }, []);


    if (!getMatchByQueueDone) return (
        <FlexBox height={'300px'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
            <Loading/>
        </FlexBox>
    );


    return (
        <Wrapper>
            {/*<Overview*/}
            {/*    region={region}*/}
            {/*    matchInfo={matchInfo}*/}
            {/*    handleQueueFilter={handleQueueFilter}*/}
            {/*    handleChampFilter={handleChampFilter}*/}
            {/*    queueNum={queueNum}*/}
            {/*/>*/}
            {Array.isArray(matchInfo) && matchInfo.length !== 0 ? (
                <div>
                    <NewOverview
                        matchList={filterMatch}
                        onChangeSelectedChampionId={onChangeChampionId}
                        selectedChampionId={championFilter}
                        onChangeSelectedQueue={onChangeQueueFilter}
                        selectedQueue={queueFilter}
                        queueFilterMatch={queueFilterMatch}
                        region={region}
                    />

                    <MatchListContainer>
                        {separatingMatchByTakeARest.map((separate, index) => {
                            const time = separate.timestamp;
                            const myMatches = separate.match.reduce((sum, data) => {
                                const thisMatch = data.participants_list.find((player) => player.puu_id === summoner.summoner_basic_info_dict?.puu_id)
                                if (thisMatch) {
                                    sum.push(thisMatch);
                                }
                                return sum;
                            }, []);
                            const tierNumberMap = separate.match.map((item) => getAvgTier(item));
                            return (
                                <React.Fragment key={separate.timestamp}>
                                    <RestDayInfo
                                        timestamp={time}
                                        currentLang={currentLang}
                                        myMatch={myMatches}
                                        tierArray={tierNumberMap}
                                        duration={separate.duration}
                                    />
                                    {separate.match.map((match, index) => {
                                        if (match?.participants_list?.length === 0) {
                                            return null
                                        }
                                        return (
                                            <MatchItem
                                                key={match.match_basic_dict.match_id + "match"}
                                                summoner={summoner}
                                                currentLang={currentLang}
                                                region={region}
                                                match={match}
                                                openId={openId === match.match_basic_dict.match_id}
                                                handleMoreInfo={handleMoreInfo}
                                            />
                                        );
                                    })}
                                </React.Fragment>
                            )
                        })}
                        <button
                            className="more_game"
                            disabled={disabled}
                            onClick={() => onPressHandleMoreGame(summoner)}
                        >
                            <span>{t('summoner.more')}</span>
                        </button>
                    </MatchListContainer>
                </div>
            ) : (
                <NewOverview
                    onChangeQueue={onChangeQueueFilter}
                    selectedQueue={queueFilter}
                    region={region}
                    empty={true}
                />
            )}
        </Wrapper>
    );
};

export default MatchList;
