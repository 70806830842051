import React, {useMemo} from 'react';
import styled from "styled-components";
import WinrateBar from "../../../../../common/WinrateBar";
import {scoreColor} from "../../../../../../function";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const WinRateSpan = styled.span`
  margin-left: 8px;
  width: 40px;
  font-weight: bold;
`;

const BarWrapper = styled.div`
  width: 100px;
`;
const Percent = styled.span`
  margin-left: 3px;
  font-weight: normal;
`;

const RankingWinRate = ({win, lose}) => {
    const percent = useMemo(() => {
        if (win === 0 && lose === 0) {
            return scoreColor(0.0)
        }
        return scoreColor((win / (win + lose) * 100).toFixed(1))
    }, [win, lose])


    return (
        <Wrapper>
            <BarWrapper>
                <WinrateBar
                    win={win}
                    lose={lose}
                />
            </BarWrapper>
            <WinRateSpan>{percent}<Percent>%</Percent></WinRateSpan>
        </Wrapper>
    );
};

export default RankingWinRate;
