import React from 'react';
import styled from "styled-components";
import ChampionsDetailBuildProvider from "../../../compound/ChampionsDetailBuildProvider";
import BuildCategory from "../ChampionsDetailBuild/BuildCategory";
import {Route, Switch} from "react-router-dom";
import {useSelector} from "react-redux";
import BuildContent from "../../../compound/ChampionsDetailBuildProvider/BuildContent";
import UseChampionBuildHooks from "../../../../common/hooks/useChampionBuildHooks";
import ChampionDetailAgainstHard from "../ChampionDetailAginstHard";
import ChampionsDetailAgainstEasy from "../ChampionsDetailAginstEasy";
import ChampionsDetailSynergy from "../ChampionsDetailSynergy";
import ChampionDetailBalance from "../../ChampionDetailBalance";

const HeaderText = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 7px;
`;


const AgainstWrapper = styled.div`
  margin-top: 10px;
  display: flex;

  .againstTable:not(:last-of-type) {
    margin-right: 10px;
  }
`;

const ChampionsDetailMasteryLayout = ({
                                          skillList,
                                          championName,
                                          currentBuild,
                                          championId,
                                          champion,
                                          currentTier,
                                          onChangeTier,
                                          onChangeCurrentVersion,
                                          currentVersion,
                                          urls,
                                          currentLane
                                      }) => {
    const {
        selectedLane,
        coreList,
        lanePick,
        onChangeCoreSelected,
        coreSelected,
        resetCoreSelected
    } = UseChampionBuildHooks(currentBuild.build, urls[currentLane]);

    const version = useSelector((state) => state?.gameVersion.version);

    return (
        <ChampionsDetailBuildProvider>
            <BuildCategory
                resetCoreSelected={resetCoreSelected}
                championName={championName}
                selectedLane={selectedLane}
                lanePick={lanePick}
                currentTier={currentTier}
                onChangeTier={onChangeTier}
                changeVersion={onChangeCurrentVersion}
                filterCurrentVersion={currentVersion}
                champion_id={Number(champion.champion_id)}
                buildInfo={currentBuild}
                urls={urls ? urls : []}
            />
            {/*<ColumnBox alignItems={'flex-start'} border={`1px solid ${theme.color.default_border_color}`} padding={'13px 15px'}*/}
            {/*           margin={'0 0 8px 0'} background={theme.color.site_main_color}>*/}
            {/*    <ColumnBox margin={'15px 0 0 0'} width={'100%'}>*/}
            {/*        <HeaderText>{selectedLane === 'aram' ? t('championDetailBuild.summaryAramHeader') : t('championDetailBuild.summaryHeader')}</HeaderText>*/}
            {/*        <ChampionsDetailBuildProvider.Overview*/}
            {/*            version={currentVersion}*/}
            {/*            champion={champion}*/}
            {/*            overview={overview}*/}
            {/*            iconId={3}*/}
            {/*            patchId={0}*/}
            {/*            lane={selectedLane}*/}
            {/*        />*/}

            {/*    </ColumnBox>*/}
            {/*</ColumnBox>*/}

            <Switch>
                <Route exact path={`/champions/:champName/build/aram`}>
                    <ChampionDetailBalance/>
                </Route>
            </Switch>
            <BuildContent list={coreList?.build_lst} coreSelected={coreSelected}
                          onChangeCoreSelected={onChangeCoreSelected}>
                <ChampionsDetailBuildProvider.Runes
                    coreList={coreList}
                    skillList={skillList}
                    coreSelected={coreSelected}
                    champId={championId}
                />
            </BuildContent>
            <Switch>
                <Route exact path={`/champions/:champName/build/aram`}>{null}</Route>
                <Route exact path={`/champions/:champName/build/urf`}>{null}</Route>
                <Route>
                    <AgainstWrapper>
                        <ChampionDetailAgainstHard
                            currentBuild={coreList?.match_up}
                            version={version}
                        />
                        <ChampionsDetailAgainstEasy
                            currentBuild={coreList?.match_up}
                            version={version}
                        />
                        <ChampionsDetailSynergy
                            currentBuild={coreList?.match_up}
                            version={version}
                        />
                    </AgainstWrapper>
                </Route>
            </Switch>
        </ChampionsDetailBuildProvider>
    );
};

export default ChampionsDetailMasteryLayout;