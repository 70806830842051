import React, {useMemo} from 'react';
import styled from "styled-components";
import useChampionFunc from "../../../../common/hooks/useChampionFunc";
import {ColumnBox, FlexBox} from "../../../ui/styled";
import ChampionImageWithLane from "../../../common/ChampionImageWithLane";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: ${props => {
    let color = props.theme.color.site_main_color
    if (props.selected) color = props.theme.color.green_200;
    return color;
  }};

  padding: 10px;
  border: ${props => {
    let border = `1px solid ${props.theme.color.default_border_color}`;
    if (props.selected) border = `1.5px solid ${props.theme.color.green_100}`;
    return border;
  }};

  &:hover {
    background-color: ${props => {
      let color = props.theme.color.white_200
      if (props.selected) color = props.theme.color.green_200;
      return color;
    }};
  }

  cursor: pointer;
`;

const ChampionBox = styled(FlexBox)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 5px;

  .imgChamp {
    width: 42px;
  }

  .imgDiv {
    border-radius: 9px;
  }

`;

const ChampText = styled.span`
  line-height: 17px;
  font-size: 14px;
  font-weight: bold;
  color: ${props => {
    let color = props.theme.color.dark_100;
    if (props.selected) color = props.theme.color.green_100
    return color;
  }};
`;

const RankText = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-right: 4px;
  color: ${props => {
    let color = props.theme.color.dark_200;
    if (props.selected) color = props.theme.color.dark_100
    return color
  }};

`;

const GamesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;


const SummonerMasteryChampion = ({selected, champion_id, onClick, games, winRate}) => {
    const {t} = useTranslation();
    const {getChampion} = useChampionFunc()
    const champion = getChampion(champion_id);
    return (
        <Wrapper selected={selected} onClick={onClick}>
            <ChampionBox>
                <ChampionImageWithLane champion_id={champion_id} disabled={true}/>
            </ChampionBox>
            <ColumnBox>
                <ChampText selected={selected}>{champion.champion_name}</ChampText>
                <GamesWrapper>
                    <RankText selected={selected}>{games} {t('summoner.mastery.game')}</RankText>
                    <WinRateBox selected={selected} winRate={winRate}/>
                </GamesWrapper>
            </ColumnBox>
        </Wrapper>
    );
};


const WinRateBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  padding: 2px 3px;
  background-color: ${props => {
    if (props.selected) {
      let selectedColor = props.theme.color.red_200;
      if (props.winRate >= 50) selectedColor = props.theme.color.blue_200
      return selectedColor
    }
    let winRate = props.theme.color.red_400;
    if (props.winRate >= 50) winRate = props.theme.color.blue_300
    return winRate
  }};
`;


const WinRateText = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: ${props => {
    let selectedColor = props.theme.color.red_100;
    if (props.winRate >= 50) selectedColor = props.theme.color.blue_100
    return selectedColor
  }};
`;


const WinRateBox = ({winRate, selected}) => {
    const winValue = useMemo(() => {
        return (winRate * 100).toFixed(1)
    }, [winRate]);

    return (
        <WinRateBoxWrapper selected={selected} winRate={winValue}>
            <WinRateText selected={selected} winRate={winValue}>{winValue}%</WinRateText>
        </WinRateBoxWrapper>
    )
}

export default SummonerMasteryChampion;