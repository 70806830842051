import React, {useCallback} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const ButtonWrapper = styled.button`
  border: 1px solid ${props => props.theme.color.default_border_color};
  background: ${props => props.theme.color.site_main_color};
  color: ${props => props.theme.color.dark_100};
  border-radius: 3px;
  height: 28px;
  min-width: 106px;
  padding:0 15px;
  &:hover {
    background-color: ${props => props.theme.color.white_200};
  }
`;

const ButtonText = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
`;

const DetailHideSummonerName = ({onChangeHide,hide}) => {
    const {t} = useTranslation();

    const onClick = useCallback(() => {
        onChangeHide();
    },[]);

    return (
        <ButtonWrapper onClick={onClick}>
            <ButtonText>{hide ? t('detailTab.show') :t('detailTab.hide')  }</ButtonText>
        </ButtonWrapper>
    );
};

export default DetailHideSummonerName;
