import React, {useMemo} from 'react';
import styled from "styled-components";
import {Link, useLocation, useRouteMatch} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  background-color: ${props => props.theme.color.site_main_color};
  border: 1px solid ${props => props.theme.color.default_border_color};
  padding: 7px;
  border-radius: 6px;

  span:nth-child(n+1) {
    margin-left: 5px;
  }
`;

const CategorySpan = styled(Link)`
  color: ${props => {
    let color = props.theme.color.dark_200;
    if (props.selected) color = props => props.theme.color.white_300;
    return color;
  }};
  text-align: center;
  font-size: 18px;
  min-width: 12ch;
  padding-inline: 12px;
  padding-block: 6px;
  background: ${props => {
    let background = 'transparent';
    if (props.selected) background = props.theme.color.green_100;
    return background
  }};
  
  font-weight: ${props => {
      let weight = 500;
      if(props.selected) weight = 'bold';
      return weight
  }};

  &:hover {
    background-color: ${props => {
      let color = props.theme.color.white_200
      if (props.selected) color = props.theme.color.green_100;
      return color
    }}
  }

  border-radius: 6px;

  & + & {
    margin-left: 5px;
  }
`;

const ChampionsDetailCategory = () => {
    const {t} = useTranslation();
    const {url} = useRouteMatch();
    const location = useLocation();
    const currentSelected = useMemo(() => location.pathname.split("/")[3], [location]);


    return (
        <Wrapper>
            <CategorySpan
                to={`${url}/build`}
                selected={currentSelected === 'build'}>
                {t("championDetail.build")}
            </CategorySpan>
            {/*<CategorySpan*/}
            {/*    to={`${url}/counter`}*/}
            {/*    selected={currentSelected === 'counter'}>*/}
            {/*    {t("championDetail.counter")}*/}
            {/*</CategorySpan>*/}
            <CategorySpan
                to={`${url}/mastery/all`}
                selected={currentSelected === 'mastery'}
            >
                {t("championDetail.mastery")}
            </CategorySpan>
            <CategorySpan
                to={`${url}/history`}
                selected={currentSelected === 'history'}
            >
                {t("championDetail.history")}
            </CategorySpan>
        </Wrapper>
    );
};

export default React.memo(ChampionsDetailCategory);
