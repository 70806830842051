import React from 'react';
import {useParams} from "react-router-dom";
import {useSuspenseQuery} from "@tanstack/react-query";
import {proAndStreamerAPI} from "../../api/proAndStreamerAPI";

const useProAndStreamerApi = (status) => {
    const {proName} = useParams();
    const query = useSuspenseQuery({
        queryKey: ['proSummoner', proName],
        queryFn: async () => {
            const result =  await proAndStreamerAPI({
                name: proName,
                status: status,
            })
            return {
                ...result,
                url_name: proName,
                url_tag: status === 'pro' ? 'pro' : 'strm'
            }
        },
        retry: 0,
        refetchOnWindowFocus: false,
    })
    return {
        proAndStreamerQuery: query,
    }
};

export default useProAndStreamerApi;