import React from 'react';
import styled from "styled-components";

const DefaultWrapper = styled.div`
  width: 58px;
  cursor: pointer;
  height: 38px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => {
    let color = props.theme.color.site_main_color;
    if (props.selected) color = props.theme.color.green_200;
    return color;
  }};
  color: ${props => {
    let color = props.theme.color.dark_200;
    if (props.selected) color = props.theme.color.green_100;
    return color
  }};
  font-weight: ${props => {
    let weight = 500;
    if (props.selected) weight = 'bold';
    return weight
  }};
  font-size: 12px;
  &:hover {
    background-color: ${props => {
      let color = props.theme.color.white_200;
      if (props.selected) color = props.theme.color.green_200;
      return color;
    }};

  }
`;

const ImageWrapper = styled.div`
  margin-right: 2px;
`;

const ButtonListLane = ({selected, onClick, data}) => {
    return (
        <DefaultWrapper onClick={() => onClick(data)} selected={selected === data.value}>
            {data.title !== 'All' && <ImageWrapper><img
                alt={""}
                width={16} height={16}
                src={`./images/icon_s_position_${data.title.toLowerCase()}.svg`}
                onError={(e) => e.target.style.display = 'none'}
            /></ImageWrapper>}
            {data.title === 'All' ?
                "All" : `${data.rate}%`
            }
        </DefaultWrapper>
    );
};

export default ButtonListLane;