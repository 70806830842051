import React from 'react';
import {onStorePuuId} from "../../function";
import styled from "styled-components";

const Wrapper = styled.div`
    cursor: pointer;
  background-color: #fff;
  border-radius: 100px;
`;
const EventPlayer = ({name, width, height, riot_id, riot_tag, puuId, isRoom}) => {
    return (
        <Wrapper onClick={() => {
            if (isRoom) {
                window.open('https://www.twitch.tv/gemaxgema','_blank')
            } else {
                onStorePuuId(`${riot_id}-${riot_tag}`, 'jp', puuId);
            }
        }}>
            <img src={`images/event/image_${name}.svg`} width={width} height={height} alt={''} className={'playerImage'}/>
        </Wrapper>
    );
};

export default EventPlayer;