import React from 'react';
import SummonerMatchesRank from "./SummonerMatchesRank/SummonerMatchesRank";
import SummonerMatchesLPChart from "./SummonerMatchesLPChart";
import SummonerMatchesChampion from "./SummonerMatchesChampion";
import SummonerMatchesRecentPlayWith from "./SummonerMatchesRecentPlayWith";
import MatchList from "../../summoner/MatchList";


const SummonerMatchesProvider = ({children}) => {

    return (
        <div>
            {children}
        </div>
    )
}


SummonerMatchesProvider.Rank = SummonerMatchesRank;
SummonerMatchesProvider.LPChart = SummonerMatchesLPChart;
SummonerMatchesProvider.Champion = SummonerMatchesChampion;
SummonerMatchesProvider.RecentPlayWith = SummonerMatchesRecentPlayWith
SummonerMatchesProvider.MatchList = MatchList;




export default SummonerMatchesProvider;