import React, {useMemo} from 'react';
import styled, {useTheme} from "styled-components";
import {ImageLane} from "../common";
import {getTierColor, onStorePuuId, romanNumberConverter, romanNumberReverseConverter} from "../../function";
import EventIcon from "./EventIcon";
import {isNaN} from "lodash";

const Wrapper = styled.div`
  margin-top: 29px;
`;
const ChartBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.site_main_color};
  border-radius: 6px;
`;
const TierBox = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 171px;
`;
const Tier = styled.div`
  position: absolute;
  font-size: 14px;
  top: -19px;
  font-weight: 500;
`;

const ExtraTier = styled(Tier)`
  font-weight: 900;
`;

const Chart = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 180px;
  height: 100%;
`;
const Gauge = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  position: absolute;
  width: ${props => props.width}px;
  left: 180px;
`;
const PlayerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2;

  &.light + &.light {
    border-top: 1px solid ${props => props.theme.color.default_border_color};
  }

  &.dark + &.dark {
    border-top: 1px solid ${props => props.theme.color.default_border_color};
  }
`;

const LastTier = styled(ExtraTier)`
  right: 0;
`;


const DEFAULT_WIDTH = 600;
const EventChart = ({list, tierOff}) => {
    const tierArray = ['IRON', 'BRONZE', 'SILVER', 'GOLD', 'PLATINUM', 'EMERALD'];
    const MAX_VALUE = 900;
    const WRAPPER_VALUE = Math.trunc(MAX_VALUE / tierArray.length);
    const WIDTH_VALUE = WRAPPER_VALUE / 4;
    const BOUNDARY_VALUE = 860
    const tierGaugeCalc = ({tier, division, lp}) => {
        const currentDivision = romanNumberReverseConverter(division);
        if (tier === 'GRANDMASTER' || tier === 'CHALLENGER' || tier === 'MASTER' || tier === 'DIAMOND') {
            return BOUNDARY_VALUE
        }

        // if(tier === 'MASTER') {
        //     const calcLp = lp * (WRAPPER_VALUE / tierOff.grandmaster_cut_off);
        //     return DEFAULT_WIDTH + calcLp
        // }

        const tierIndex = tierArray.findIndex((data) => data === tier);
        const tierMultiple = WRAPPER_VALUE * tierIndex;
        const divisionMultiple = WIDTH_VALUE * currentDivision
        const lpMultiple = lp * (WIDTH_VALUE / 100)

        const result =  tierMultiple + divisionMultiple + lpMultiple

        if(isNaN(result)){
            return 44
        }

        if(result > BOUNDARY_VALUE) {
            return BOUNDARY_VALUE
        }

        return result
    }
    const getTier = ({tier}) => {
        try {
            const splitTier = tier.split(' ');
            const division = splitTier[1];
            const tierValue = splitTier[0];
            return {tier: tierValue, division}
        } catch (e) {
            return {
                tier: 'unranked',
                division: ""
            }
        }
    }

    const getLaneByIndex = (index) => {
       switch (index) {
           case 0: return "Top"
           case 1: return "Jungle"
           case 2: return "Middle"
           case 3: return "bot"
           case 4: return "supporter"
           default: return "Top"
       }
    }
    return (
        <Wrapper>
            <ChartBox>
                {list.map((data, index) => {
                    const currentTier = getTier({tier: data.tier})
                    return (
                        <PlayerBox
                            division={romanNumberConverter(currentTier.division)}
                            key={data.puu_id}
                            name={data.name}
                            gauge={tierGaugeCalc({
                                tier: currentTier.tier,
                                division: currentTier.division,
                                lp: data.lp
                            })}
                            tier={currentTier.tier}
                            lane={getLaneByIndex(index)}
                            riot_id={data.riot_id_name}
                            riot_tag={data.riot_id_tag_line}
                            puuId={data.puu_id}
                            lp={data.lp}
                        />
                    )
                })}
                <Chart>
                    {tierArray.map((data,index) => {
                        return (
                            <DashedLine tierArray={[`${data[0]}`, '', '', '']} tier={data}
                                        width={`${WRAPPER_VALUE}px`} isFirst={index === 0} key={index}/>
                        )

                    })}
                    <LastTier className={'tier_color diamond'}>D</LastTier>
                </Chart>
            </ChartBox>
        </Wrapper>
    )
        ;
};

const DashedLineWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: ${props => props.width};
  height: 100%;
  background-color: ${props => {
    let color = 'rgba(167, 160, 151, 0.1)';
    if (props.tier === 'BRONZE') {
      color = 'rgba(192, 170, 155, 0.1)'
    } else if (props.tier === 'SILVER') {
      color = 'rgba(183, 187, 203, 0.1)';
    } else if (props.tier === 'GOLD') {
      color = 'rgba(219, 199, 152, 0.1)'
    } else if (props.tier === 'PLATINUM') {
      color = 'rgba(151, 203, 196, 0.1)'
    } else if (props.tier === 'EMERALD') {
      color = 'rgba(110, 207, 148, 0.1)'
    }
    return color
  }};
`;
const Dash = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 1px;
  margin-right: 74px;
  border-right: 1px dashed ${props => {
    let color = 'rgba(167, 160, 151, 0.5)';
    if (props.tier === 'BRONZE') {
      color = 'rgba(135, 107, 38, 0.5)'
    } else if (props.tier === 'SILVER') {
      color = 'rgba(106, 121, 171, 0.5)';
    } else if (props.tier === 'GOLD') {
      color = 'rgba(186, 141, 38, 0.5)'
    } else if (props.tier === 'PLATINUM') {
      color = 'rgba(29, 167, 148, 0.5)'
    } else if (props.tier === 'EMERALD') {
      color = 'rgba(18, 195, 201, 0.5)'
    }
    return color
  }};
`;
const EmptyBox = styled(Dash)`
  border-right: 1px solid ${props => props.theme.color.default_border_color};
`;
const DashedLine = ({tierArray, tier, width,isFirst}) => {
    return (
        <DashedLineWrapper width={width} tier={tier}>
            {tierArray.map((data, index) => {

                if(isFirst && index === 0) {
                   return (
                       <EmptyBox key={index} tier={tier}>
                           <ExtraTier className={`tier_color ${tier?.toLowerCase()}`}>
                               {data}
                           </ExtraTier>
                       </EmptyBox>
                   )
                } else if (index === 0) {
                    return (
                        <Dash key={index} tier={tier}>
                            <ExtraTier className={`tier_color ${tier?.toLowerCase()}`}>
                                {data}
                            </ExtraTier>
                        </Dash>
                    )
                }
                return null
            })}
        </DashedLineWrapper>
    )
}
const PlayerBoxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  width: 180px;
  height: 60px;

  .imgLane {
    width: 20px;
    height: 20px;
  }


`;
const PlayerName = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.color.dark_100};
  margin-left: 10px;
  max-width: 10ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
const IconBox = styled.div`
  border: 4px solid ${props => getTierColor(props.tier)};
  border-radius: 100%;
`;

const AbsoluteTierBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
`;
const GaugeBar = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  flex: 1;
  height: 28px;
  background-color: ${props => getTierColor(props.tier)}
`;

const GaugeTierBox = styled.div`
  position: absolute;
  left: calc(100% + 3px);
  display: flex;
  flex-direction: column;
`;
const GaugeTier = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => getTierColor(props.tier)};
  border-radius: 100px;
  width: 38px;
  height: 20px;
  margin-bottom: 2px;
`;
const GaugeTierSpan = styled.span`
  color: #fff;
  font-size: 14px;
  font-weight: bold;
`;
const LPSpan = styled.span`
  line-height: 17px;
  font-size: 14px;
  font-weight: bold;
  color: ${props => getTierColor(props.tier)};
  white-space: nowrap;
`;
const LPTier = styled.div`
    display: flex;
  flex-direction: row;
  justify-content: center;
`;
const PlayerBox = ({name, gauge, tier, division,lane, riot_id, riot_tag, puuId,lp}) => {
    const theme = useTheme();

    const tierMemo = useMemo(() => {
        if (tier.toLowerCase() === 'challenger') {
            return 'C';
        } else if (tier.toLowerCase() === 'grandmaster') {
            return 'GM';
        } else if (tier.toLowerCase() === 'master') {
            return "M";
        } else if (tier.toLowerCase() === 'unranked') {
            return 'U';
        } else {
            if (tier.length > 0) {
                return tier.toUpperCase()[0] + division;
            }
        }
    },[tier,division])
    return (
        <PlayerWrapper className={theme.color.theme}>
            <PlayerBoxWrapper>
                <ImageLane lane={lane} color={'dark'}/>
                <PlayerName onClick={() =>  onStorePuuId(`${riot_id}-${riot_tag}`, 'jp', puuId)}>{name}</PlayerName>
                {tier !== 'unranked' &&
                <Gauge tier={tier} width={gauge}>
                    <GaugeBar tier={tier}>
                        <IconBox tier={tier}>
                            <EventIcon name={name} height={36} width={36} riot_tag={riot_tag} riot_id={riot_id}
                                       puuId={puuId}/>
                        </IconBox>
                        <GaugeTierBox>
                            <GaugeTier tier={tier}>
                                <GaugeTierSpan>
                                    {tierMemo}
                                </GaugeTierSpan>
                            </GaugeTier>
                            <LPTier>
                                <LPSpan tier={tier}>
                                    {lp} LP
                                </LPSpan>
                            </LPTier>
                        </GaugeTierBox>
                    </GaugeBar>
                </Gauge>}
            </PlayerBoxWrapper>
        </PlayerWrapper>
    )

}

export default EventChart;