import React, {useMemo} from 'react';
import styled, {useTheme} from "styled-components";
import SummonerMasteryTable from "./SummonerMasteryTable";
import {ColumnBox, FlexBox} from "../../ui/styled";
import {useTranslation} from "react-i18next";
import DamageGraph from "../../summoner/DetailTab/GameAnalytics/GameSummary/Table/TableContent/DamageGraph";
import {hundredCheck, threedigits} from "../../../function";

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.site_main_color};
  min-height: ${props => props.minHeight || 'auto'};
  border-radius: 6px;
  overflow: hidden;

  tr + tr {
    border-top: 1px solid ${props => props.theme.color.gray_200};
  }
`;

const ContentTD = styled.td`
  vertical-align: middle;
`;

const DPMWrapper = styled.div`
  width: 200px;
`;


const ValueWrapper = styled.div`
  display: inline;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const BlackSpan = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_100};
`;

const GamesSpan = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_200};

`;


const ValueText = styled.span`
  font-size: 12px;
  color: ${props => {
    let color = props.theme.color.red_100;
    if (props.value >= 50) {
      color = props.theme.color.blue_100;
    }
    return color;
  }};
  font-weight: 500;
`;


const PercentText = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.dark_200};
  font-weight: 500;
  margin-left: 1px;
`;

const BarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 72px;
`;
const SummonerMasteryItem = ({title, children, list, minHeight, hasDpm, highestDPM}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const allGames = useMemo(() => {
        try {
            const games = list.reduce((sum, cur) => {
                sum = sum + cur.games;
                return sum;
            }, 0)
            return games
        } catch (e) {
            return 0;
        }
    }, [list]);

    const getPickRate = (games) => {
        return (games / allGames * 100)?.toFixed(1)
    }
    return (
        <Wrapper className={'masteryItem'}>
            <SummonerMasteryTable title={title} headers={
                hasDpm ?
                    [{title: t('summoner.mastery.dpm'), col: '300px'},
                        {
                            title: t('summoner.mastery.games'),
                            col: '50px'
                        }, {title: t('summoner.mastery.winRate'), col: "100px"}]
                    :
                    [{
                        title: t('summoner.mastery.games'),
                        col: '50px'
                    }, {title: t('summoner.mastery.winRate'), col: "100px"}]
            }
                                  list={list}>
                {(data) => {

                    const pickRate = getPickRate(data.games)
                    return (
                        <>
                            <ContentTD>
                                <FlexBox>
                                    {children(data)}
                                </FlexBox>
                            </ContentTD>
                            {hasDpm && <ContentTD>
                                <CenterDiv>
                                    <DPMWrapper>
                                        <DamageGraph
                                            bgColor={theme.color.gray_100}
                                            color={theme.color.font_58}
                                            barColor={'#EF4D8A'}
                                            dmg={data.dpm}
                                            total={highestDPM}
                                        />
                                    </DPMWrapper>
                                </CenterDiv>
                            </ContentTD>}
                            <ContentTD>
                                <CenterDiv>
                                    <ColumnBox alignItems={'center'}>
                                        <GamesSpan>{threedigits(data.games)}</GamesSpan>
                                    </ColumnBox>
                                </CenterDiv>
                            </ContentTD>
                            <ContentTD>
                                <CenterDiv>
                                    <ValueWrapper>
                                        <ValueText value={(data.win_rate * 100).toFixed(1)}>
                                            {hundredCheck((data.win_rate * 100).toFixed(1))}
                                        </ValueText>
                                        <PercentText>
                                            %
                                        </PercentText>
                                    </ValueWrapper>
                                </CenterDiv>
                            </ContentTD>
                        </>
                    )

                }}
            </SummonerMasteryTable>
        </Wrapper>
    );
};

export default SummonerMasteryItem;