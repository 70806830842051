import React, {useCallback, useMemo} from 'react';
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import RankingDropBox from "../../../common/RankingDropBox";
import {useDispatch, useSelector} from "react-redux";
import {
    GET_CHAMPIONS_BY_SEASON_REQUEST,
    GET_SUMMONER_CHAMPION_ARAM_REQUEST,
    GET_SUMMONER_CHAMPION_URF_REQUEST
} from "../../../../store/reducer/summoner";
import {useParams} from "react-router-dom";
import ButtonList from "../../../common/ButtonList";
import ButtonListLane from "../../../common/ButtonList/ButtonListLane";


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;


const RankTag = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 76px;
  height: 28px;
  font-size: 12px;
  background: ${props => {
    let background = props.theme.color.background_FOE;
    if (props.selectedRank) background = props.theme.color.background_5F;
    return background;
  }};
  color: ${props => {
    let color = props.theme.color.font_rgba49;
    if (props.selectedRank) color = props => props.theme.color.font_fff;
    ;
    return color;
  }};


  outline: ${props => {
    let px = '0px';
    if (props.selectedRank) px = '1px';
    return px;
  }} solid #353063;
  outline-offset: -1px;
  border: 1px solid ${props => props.theme.color.default_border_color};
  margin-right: -1px;
  z-index: ${props => {
    let zIndex = '0';
    if (props.selectedRank) zIndex = '1';
    return zIndex;
  }};
`;

const RankWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;


const LaneWrapper = styled.div`
  margin-left: 10px;
`;


const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ChampSearchInput = styled.input`
  width: 200px;
  height: 40px;
  padding-right: 22px;
  border: 1px solid ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.white_201};
  border-radius: 6px;
  padding-left: 6px;
  color: ${props => props.theme.color.dark_100};
  background-image: ${props => `url('${props.theme.icon.path}/ico_search_w.svg')`};
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: center;
  background-size: 18px 18px;

  &&::placeholder {
    color: ${props => props.theme.color.dark_200};
  }
`;

const ResetButton = styled.button`
  margin-left: 10px;
  width: 40px;
  height: 40px;
  background: ${props => props.theme.color.site_main_color} url(${props => props.theme.icon.path + '/ico_reset.svg'}) no-repeat center;
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;
  font-size: 0;
  text-indent: -9999px;

  &:hover {
    background-color: ${props => props.theme.color.white_200};
  }
`;

const SeasonWrapper = styled.div`
`;


//ChampContainer 종속관계
// 12: 2022년도 프리시즌
// 13: 2022년도 시즌
// 14: 2023년도 프리시즌
// 15: 2023년도 시즌

const ChampFilter = ({
                         currentCategory,
                         seasonList,
                         season,
                         selectedSeason,
                         onChangeSelectedSeason,
                         summonerStautsLength,
                         handleGameType,
                         selectedRank,
                         selectedLane,
                         onChangePosition,
                         laneInfo,
                         champName,
                         handleInput,
                         handleReset
                     }) => {
    const {region} = useParams();
    const dispatch = useDispatch();
    const summoner = useSelector((state) => state.summoner.summoner);
    const {t} = useTranslation();


    const laneList = useMemo(() => {
        // 'All', 'Top', 'Jungle', 'Middle', 'Bot', 'Supporter'
        return [
            {
                title: 'All',
                value: 'All',
                rate: 999,
            },
            {
                title: 'Top',
                value: 'Top',
                rate: laneInfo?.Top?.rate?.toFixed(0) || 0,
            },
            {
                title: 'Jungle',
                value: 'Jungle',
                rate: laneInfo?.Jungle?.rate?.toFixed(0) || 0,
            },
            {
                title: 'Middle',
                value: 'Middle',
                rate: laneInfo?.Middle?.rate?.toFixed(0) || 0,
            },
            {
                title: 'Bot',
                value: 'Bot',
                rate: laneInfo?.Bot?.rate?.toFixed(0) || 0,
            },
            {
                title: 'Supporter',
                value: 'Supporter',
                rate: laneInfo?.Supporter?.rate?.toFixed(0) || 0,
            },
        ]
    }, [laneInfo]);


    const rankList = [{
        title: t('champions.filter.total'),
        value: 'total'
    }, {
        title: t('champions.filter.r_solo'),
        value: "ranked_solo_5x5"
    }, {
        title: t('champions.filter.r_flex'),
        value: "ranked_flex_sr"
    }];


    const onChangeSeason = useCallback((e) => {
        const value = e.target.value;
        if (value && summoner) {
            onChangeSelectedSeason(value);
            if (currentCategory === 0 || currentCategory === 1 || currentCategory === 2) {
                dispatch({
                    type: GET_CHAMPIONS_BY_SEASON_REQUEST,
                    data: {
                        platform_id: region,
                        puu_id: summoner.summoner_basic_info_dict.puu_id,
                        season: Number(value),
                    }
                });
            } else if (currentCategory === 3) {
                dispatch({
                    type: GET_SUMMONER_CHAMPION_ARAM_REQUEST,
                    data: {

                        platform_id: region,
                        season: Number(value),
                    }
                })
            } else if (currentCategory === 4) {
                dispatch({
                    type: GET_SUMMONER_CHAMPION_URF_REQUEST,
                    data: {

                        platform_id: region,
                        season: Number(value),
                    }
                })
            }
        }

    }, [summoner, currentCategory]);

    if (summonerStautsLength === 0) return (
        <Wrapper>
            <FilterWrapper>
                <SeasonWrapper>
                    <RankingDropBox
                        onChange={onChangeSeason}
                        selected={(data) => Number(data) === Number(selectedSeason)}
                        value={(data) => seasonList.find((item) => data === item.value)?.value}
                        title={(data) => seasonList.find((item) => data === item.value)?.title}
                        list={[23, 21]}
                    />
                </SeasonWrapper>
            </FilterWrapper>
        </Wrapper>
    )


    return (
        <Wrapper>
            <FilterWrapper>
                <SeasonWrapper>
                    <RankingDropBox
                        onChange={onChangeSeason}
                        selected={(data) => Number(data) === Number(selectedSeason)}
                        value={(data) => seasonList.find((item) => data === item.value)?.value}
                        title={(data) => seasonList.find((item) => data === item.value)?.title}
                        list={[23, 21,19]}
                    />
                </SeasonWrapper>
                <RankWrapper>
                    <ButtonList list={rankList} onClick={(data) => handleGameType(data.value)} selected={selectedRank}/>
                </RankWrapper>
                <LaneWrapper>
                    <ButtonList list={laneList} onClick={(data) => {
                        onChangePosition(data.title)
                    }} selected={selectedLane} Component={ButtonListLane}/>
                </LaneWrapper>
            </FilterWrapper>
            <SearchWrapper>
                <ChampSearchInput
                    autoComplete={'off'}
                    type="text"
                    value={champName}
                    onChange={handleInput}
                    placeholder={t('champions.searchChampion')}
                />
            </SearchWrapper>
        </Wrapper>
    );
};

export default ChampFilter;
