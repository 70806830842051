import styled, {useTheme} from "styled-components";
import {useTranslation} from "react-i18next";
import React, {useCallback, useMemo} from "react";
import RankingTableHeader from "../../Ranking/RankingContent/RankingTable/RankingTableContent/RankingTableHeader";
import {FlexBox} from "../../ui/styled";
import InfluInfo from "../../summoner/Profile/InfluInfo";
import SnsImage from "../../common/SnsImage";
import {dark, light} from "../../../common/theme";

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;
  overflow: hidden;
`;

const Table = styled.table`
  width: 100%;
`;

const ContentTR = styled.tr`
  cursor: pointer;
  overflow: hidden;
  background-color: ${props => props.theme.color.site_main_color};

  &:hover {
    background-color: ${props => props.theme.color.white_200};
  }

  .light + .light {
    border-top: ${props => {
      let color = light.default_border_color
      let result = `1px solid ${color}`;
      return result;
    }};
  }

  .dark + .dark {
    border-top: ${props => {
      let color = dark.default_border_color
      let result = `1px solid ${color}`;
      return result;
    }};
  }
 


`;

const Content = styled.td`
  height: 50px;
  vertical-align: middle;

  .imgIcon {
    border-radius: 100%;
  }

  .imgBox + .imgBox {
    margin-left: 10px;
  }
  .influ-name {
    font-size: 16px;
    color: ${props => props.theme.color.dark_200};
  }
`;


const Thead = styled.thead`
  border-bottom: 1px solid ${props => props.theme.color.default_border_color};
`;

const ProfileIconWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TBody = styled.tbody`
`;
const ProfileImage = styled.img`
  border-radius: 50%;
  background-color: ${props => props.theme.color.background_fff_000};
`;
const ProAndStreamerSearchTable = ({searchList, region, version}) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const sortedRankingList = useMemo(() => {
        if (Array.isArray(searchList)) {
            // const sort = rankingList.sort((a, b) => a.rank - b.rank);
            return searchList;
        } else {
            return [];
        }
    }, [searchList]);

    const onClickBox = useCallback((e, data) => {
        e.stopPropagation();
        if (data?.status?.toLowerCase() === 'pro' && data.url_name) {
            window.open(`/pro/${data.url_name}`)
        } else if (data?.status?.toLowerCase() === 'streamer') {
            window.open(`/strm/${data.player_name}`)
        } else {
            console.log('error', data);
        }
    }, [])


    if (sortedRankingList.length === 0) {
        return null
    }


    return (
        <Wrapper>
            <Table>
                <colgroup>
                    <col/>
                    <col width={100}/>
                    <col width={250}/>
                    <col width={278}/>
                </colgroup>
                <Thead>
                    <tr>
                        <RankingTableHeader>{t('ranking.proStreamer')}</RankingTableHeader>
                        <RankingTableHeader empty={true}/>
                        <RankingTableHeader> </RankingTableHeader>
                        <RankingTableHeader last={true}>{t('search.links')}</RankingTableHeader>
                    </tr>
                </Thead>
                <TBody>
                    {sortedRankingList.map((data, index) => {

                        return (
                            <ContentTR key={index}
                                       onClick={(e) => onClickBox(e, data)} className={theme.color.theme}>
                                <Content>
                                    <FlexBox margin={'0 0 0 20px'}>
                                        <ProfileIconWrapper>
                                            <ProfileImage
                                                src={`${process.env.REACT_APP_ROOT_IMG}${data.status.toLowerCase()}/${data.status === 'pro' ? data.championship_name : data.player_name}.png`}
                                                width={35}
                                                height={35}
                                                onError={(e) => e.target.src = '/images/img_unknown.svg'}
                                            />
                                        </ProfileIconWrapper>
                                        <FlexBox margin={'0 0 0 10px'}>
                                            <InfluInfo
                                                influData={{
                                                url_name: data.url_name,
                                                team: data.pro_team_al,
                                                status: data.status,
                                                name: data.player_name
                                            }} disableHover={true}/>
                                        </FlexBox>
                                    </FlexBox>
                                </Content>
                                <Content>
                                </Content>
                                <Content>
                                </Content>
                                <Content>
                                    <FlexBox justifyContent={'center'}>
                                        <SnsImage influData={data} width={30} height={30}/>
                                    </FlexBox>
                                </Content>
                            </ContentTR>
                        )
                    })}

                </TBody>
            </Table>
        </Wrapper>
    );
};

export default ProAndStreamerSearchTable;