import {GET_ARAM_BALANCE_FAILURE, GET_ARAM_BALANCE_REQUEST, GET_ARAM_BALANCE_SUCCESS} from "../reducer/balance";

const initialState = {
    aram: {},

    getAramBalanceLoading: false,
    getAramBalanceDone: false,
    getAramBalanceError: null,
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ARAM_BALANCE_REQUEST: {
            return {
                ...state,
                getAramBalanceLoading: true,
                getAramBalanceDone: false,
                getAramBalanceError: null,
            }
        }
        case GET_ARAM_BALANCE_SUCCESS: {
            return {
                ...state,
                aram: action.data,
                getAramBalanceLoading: false,
                getAramBalanceDone: true,
                getAramBalanceError: null,
            }
        }
        case GET_ARAM_BALANCE_FAILURE: {
            return {
                ...state,
                getAramBalanceLoading: false,
                getAramBalanceDone: false,
                getAramBalanceError: action.error,
            }
        }
        default:
            return state;
    }
};
export default reducer;
