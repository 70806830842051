import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import FilterBox from "../../../../common/FilterBox";
import {useTheme} from "styled-components";


const SearchListByLane = ({onClick}) => {
    const {t} = useTranslation();
    const list = useMemo(() => [t('championAnalyze.all'), t('championAnalyze.top'), t('championAnalyze.jungle'), t('championAnalyze.mid'), t('championAnalyze.bot'), t('championAnalyze.support'), t('championAnalyze.rotation')], [t]);
    const laneName = useMemo(() => ['all', 'top', 'jungle', 'middle', 'bot', 'supporter', 'rotation'], [])
    const theme = useTheme();

    return (
        <FilterBox
            imageSrc={(data, index) => `images/icon_s_position_${laneName[index]}.svg`}
            list={list}
            mapTitle={(data, index) => laneName[index]}
            onClick={onClick}
        />
    )
};

export default React.memo(SearchListByLane);
