import {persistStore} from "redux-persist";
import {applyMiddleware, createStore} from "redux";
import createSagaMiddleware from 'redux-saga'
import rootReducer from "../index";
import {rootSaga} from "../saga/root";

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(rootReducer,applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default { store, persistor };