import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import {calcKda, scoreColor, sortChampData} from "../../../../../function";
import {useTranslation} from "react-i18next";
import ChampionImageWithLane from "../../../../common/ChampionImageWithLane";
import {ColumnBox} from "../../../../ui/styled";


const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .non_image {
    width: 28px;
    height: 28px;
    pointer-events: none;
    margin: 0;
  }

  .imgChamp {
    width: 34px;
    aspect-ratio: 1;
    pointer-events: none;
  }

  .imgDiv {
    border-radius: 5px;
  }
`;

const Wrapper = styled.div`
  width: 163px;
  min-height: 92px;

  ${ContentWrapper}:nth-child(2) {
    margin-block: 4px;
  }
`;

const WinLoseSpan = styled.div`
  text-align: center;
  font-size: 12px;
  color: ${props => props.theme.color.dark_200};
`;

const PercentSpan = styled(WinLoseSpan)`
  font-weight: bold;
  margin-right: 2px;
`;


const SubText = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.dark_200};
`;

const WinLoseWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const reduceObj = {
    champion_id: 0,
    games: 0,
    win: 0,
    lose: 0,
    ai_score: 0,
    kills: 0,
    deaths: 0,
    assists: 0,
}


const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const KDASpan = styled.span`
  color: ${props => props.theme.color.dark_200};
  font-weight: 500;
  font-size: 11px;
`;


const RecentPlayedChampions = ({data}) => {
    const {t} = useTranslation();


    function sumPush(sum, cur) {
        sum.push({
            ...reduceObj,
            champion_id: cur.champion_id,
            games: 1,
            win: cur.is_win ? 1 : 0,
            lose: !cur.is_win ? 1 : 0,
            ai_score: cur.ai_score,
            kills: cur.kills,
            deaths: cur.deaths,
            assists: cur.assists,
        })
    }

    function setSum(findIndex, sum, cur) {
        if (findIndex > -1) {
            const IndexedSumData = sum[findIndex];
            sum[findIndex] = {
                ...IndexedSumData,
                games: ++IndexedSumData.games,
                win: cur.is_win ? ++IndexedSumData.win : IndexedSumData.win,
                lose: cur.is_win ? IndexedSumData.lose : ++IndexedSumData.lose,
                ai_score: IndexedSumData.ai_score + cur.ai_score,
                kills: cur.kills + IndexedSumData.kills,
                deaths: cur.deaths + IndexedSumData.deaths,
                assists: cur.assists + IndexedSumData.assists,
            }
        } else {
            sumPush(sum, cur);
        }
    }

    const reduceData = useCallback(() => {
        const result = data.reduce((sum, cur) => {
            if (sum.length > 0) {
                const findIndex = sum.findIndex((sumData) => sumData.champion_id === cur.champion_id);
                setSum(findIndex, sum, cur);
            } else {
                sumPush(sum, cur)
            }

            return sum;
        }, []);
        return sortChampData(result, [{sortType: false, value: "games"}, {
            sortType: false,
            value: "ai_score"
        }]).slice(0, 3);
    }, [data]);

    const recentData = useMemo(() => {
        try {
            return reduceData();
        } catch (e) {
            return []
        }

    }, [data]);


    if (!Array.isArray(data) || data.length === 0) {
        return <Wrapper>There are no data</Wrapper>
    }
    return (
        <Wrapper>
            {recentData.map((data, index) => {
                return (
                    <ContentWrapper key={data.cid}>
                        <WinLoseWrapper>
                            <ChampionImageWithLane
                                champion_id={Number(data.champion_id)}
                            />
                            <ColumnBox margin={'0 0 0 5px'} justifyContent={'space-between'} height={'28px'}>
                                <Row>
                                    <PercentSpan>
                                        {scoreColor(Math.floor(data.win / data.games * 100), '%')}
                                    </PercentSpan>
                                    {/*<WinLoseWrapper>*/}
                                    {/*    <WinLoseSpan width={"2ch"}>{data.games}</WinLoseSpan>*/}
                                    {/*    <SubText>games</SubText>*/}
                                    {/*</WinLoseWrapper>*/}
                                    <WinLoseSpan>({data.win}</WinLoseSpan>
                                    <SubText>{t('gameAnalytics.win')}</SubText>
                                    <WinLoseSpan>{data.lose}</WinLoseSpan>
                                    <SubText>{t('gameAnalytics.lose')}</SubText>
                                    <WinLoseSpan>)</WinLoseSpan>
                                </Row>
                                <Row>
                                    <KDASpan>{calcKda(data)} KDA</KDASpan>
                                </Row>
                            </ColumnBox>
                        </WinLoseWrapper>
                    </ContentWrapper>
                )
            })}
        </Wrapper>
    );
};

export default RecentPlayedChampions;
