import React, {useCallback} from 'react';
import Dropdown from "../../compound/Dropdown";
import RegionTag from "../../common/NewSearch/RegionTag";
import regionList from "../../../store/static_json/regionList";
import {changeRegion} from "../../../store/modules/region";
import {useDispatch} from "react-redux";
import styled, {useTheme} from "styled-components";
import DownArrow from "../../common/TagWithArrow/DownArrow";

const RowAbosulute = styled.div`
  padding: 15px;
  z-index: 1;
  position: absolute;
  top: ${props => {
    let top = '130%';
    if (props.top) top = props.top;
    return top;
  }};
  width: 197px;
  left: 0;
  box-shadow: 0 0px 6px #004CFF4D;
  border: 1px solid ${props => props.theme.color.purple_100};
  background-color: ${props => props.theme.color.site_main_color};
  border-radius: 6px;
`;


const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ListTitle = styled.div`
  color: ${props => props.theme.color.dark_200};
  font-size: 11px;
  margin-left: 7px;
  margin-bottom: 13px;
  margin-top: 7px;
`;

const ListContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;


const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const ArrowWrapper = styled.div`
  margin-left: ${props => props.arrowMargin};
`;

const SearchRegion = ({
                          region,
                          buttonWidth = "48px",
                          buttonHeight = "28px",
                          buttonFontSize,
                          buttonRadius,
                          arrowMargin = '12px',
                          top = '130%',
                          arrowColor,
                      }) => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const onClickRegionClick = useCallback((title) => {
        dispatch(changeRegion(title));
    }, []);


    return (
        <Dropdown>
            <Dropdown.Button>
                <ButtonWrapper>
                    <RegionTag
                        disableHover={true}
                        borderRadius={buttonRadius}
                        fontSize={buttonFontSize}
                        height={buttonHeight}
                        width={buttonWidth}
                        selected={region}
                        isDisabled={false}
                        title={region}
                    />
                    <ArrowWrapper arrowMargin={arrowMargin}>
                        <DownArrow color={arrowColor} size={4}/>
                    </ArrowWrapper>
                </ButtonWrapper>
            </Dropdown.Button>
            <Dropdown.List>
                <RowAbosulute top={top}>
                    <ListWrapper>
                        <ListContent>
                            {regionList.map((data) => {
                                if(data.disabled) return null;
                                return (
                                    <Dropdown.Item key={data.title}>
                                        <RegionTag
                                            enableShadow={true}
                                            height={'28px'}
                                            width={'48px'}
                                            onClick={onClickRegionClick}
                                            selected={region === data.title}
                                            isDisabled={data.disabled}
                                            title={data.title}
                                        /></Dropdown.Item>)
                            })}
                        </ListContent>
                    </ListWrapper>
                </RowAbosulute>
            </Dropdown.List>
        </Dropdown>
    );
};

export default SearchRegion;
