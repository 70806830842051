import React from "react";

const KoreanAd = () => {
    // const location = useLocation();
    //
    // const setAd = (value) => {
    //     localStorage.setItem('summonerBanner', String(value))
    //     setAdState(String(value));
    // }
    //
    // const [adState, setAdState] = useState(() => {
    //     const random = _.random(0, 100);
    //     // setAd(random)
    //     return random
    // });


    // useEffect(() => {
    //     if (adState === '1') {
    //         setAd(2)
    //     } else if (adState === '2') {
    //         setAd(1)
    //     }
    // }, [location]);
    //
    // if (adState <= 75) {
    //     return (
    //         <a href={'https://bit.ly/4fHuWzk'} target={'_blank'}>
    //             <img src={'./images/ad_geng_kr.png'} width={'100%'} height={52} alt={''}
    //                  className={'add_to_Gen'}/>
    //         </a>
    //     )
    // }



    // return (
    //
    //     <a href={'https://chzzk.naver.com/3dadd937e0e47035b3c8c377815ce88a/community/detail/16202072'}
    //        target={'_blank'}>
    //         <img src={'./images/tan_ck_banner.png'} width={'100%'} height={52} alt={''}
    //              className={'add_to_tan'}/>
    //     </a>
    // )

    // return (
    //     <a href={'https://ch.sooplive.co.kr/podone1104/post/138536371'} target={'_blank'}>
    //         <img src={'./images/podo_kr_title_1.png'} width={'100%'} height={52} alt={''}
    //              className={'add_to_podo'}/>
    //     </a>
    // )
        return (
            <a href={'https://bit.ly/3ZFluY1'} target={'_blank'}>
                <img src={'./images/ad_geng_kr.png'} width={'100%'} height={52} alt={''}
                     className={'add_to_Gen'}/>
            </a>
        )
}
export default KoreanAd