const runeShards =  {
    "id": 5000,
    "key": "Rune Shards",
    "icon": "null",
    "name": "룬 파편",
    "slots": [
        {
            "runes": [
                {
                    "id": 5008,
                    "key": "Offense",
                    "icon": "perk-images/StatMods/StatModsAdaptiveForceIcon.png",
                    "name": "공격",
                    "shortDesc": "적응형 능력치 +9"
                },
                {
                    "id": 5005,
                    "key": "Offense",
                    "icon": "perk-images/StatMods/StatModsAttackSpeedIcon.png",
                    "name": "공격",
                    "shortDesc": "공격 속도 +10%"
                },
                {
                    "id": 5007,
                    "key": "Offense",
                    "icon": "perk-images/StatMods/StatModsCDRScalingIcon.png",
                    "name": "공격",
                    "shortDesc": "스킬 가속 +8"
                }
            ]
        },
        {
            "runes": [
                {
                    "id": 5008,
                    "key": "Offense",
                    "icon": "perk-images/StatMods/StatModsAdaptiveForceIcon.png",
                    "name": "공격",
                    "shortDesc": "적응형 능력치 +9"
                },
                {
                    "id": 5010,
                    "key": "Flex",
                    "icon": "perk-images/StatMods/StatModsMovementSpeedIcon.png",
                    "name": "유연",
                    "shortDesc": "이동속도 +2%"
                },
                {
                    "id": 5001,
                    "key": "Defence",
                    "icon": "perk-images/StatMods/StatModsHealthPlusIcon.png",
                    "name": "방어",
                    "shortDesc": "체력 +15 ~ 140(레벨에 비례)"
                }
            ]
        },
        {
            "runes": [
                {
                    "id": 5011,
                    "key": "Defence",
                    "icon": "perk-images/StatMods/StatModsHealthScalingIcon.png",
                    "name": "방어",
                    "shortDesc": "체력 +65",
                },
                {
                    "id": 5013,
                    "key": "Defence",
                    "icon": "perk-images/StatMods/StatModsTenacityIcon.png",
                    "name": "방어",
                    "shortDesc": "강인함 및 둔화 저항 +10%"
                },
                {
                    "id": 5001,
                    "key": "Defence",
                    "icon": "perk-images/StatMods/StatModsHealthPlusIcon.png",
                    "name": "방어",
                    "shortDesc": "체력 +15 ~ 140(레벨에 비례)"
                }
            ]
        }

    ]
}

export default runeShards;