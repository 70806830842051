import React, {useLayoutEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {GET_SPECTATE_LIVE_REQUEST} from "../../store/reducer/spectate";

const useSpectateLive = () => {
    const dispatch = useDispatch();
    const {
        getSpectateLiveLoading,
        getSpectateLiveDone,
        getSpectateLiveError,
        live,
    } = useSelector((state) => state.spectate);

    useLayoutEffect(() => {
        dispatch({
            type: GET_SPECTATE_LIVE_REQUEST,
        })
    }, []);

    return {live, getSpectateLiveLoading, getSpectateLiveDone, getSpectateLiveError};
};

export default useSpectateLive;
