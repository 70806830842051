import React from 'react';
import styled from "styled-components";
import ScoreWrapper from "./ScoreWrapper";
import ChampionImageWithLane from "../../../../../common/ChampionImageWithLane";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  width: 48px;
  border-radius: 50%;
  aspect-ratio: 1;
  border: 1px solid black;
  background-color: black;

  .imgChamp {
    margin: 0;
    z-index: 999;
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
  .imgDiv {
    border-radius: 8px;
  }

`;

const AiscoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const AiText = styled.span`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 3px;
  width: 7ch;
  text-align: center;
`;

const CenterDiv = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: ${props => {
    let items = 'flex-start';
    if (props.isUrf) items = 'center';
    return items;
  }};
`;


const BestSection = ({
                         extra,
                         aiText,
                         score,
                         extraValue,
                         champId,
                         staticColor,
                         data,
                         onClick,
                         scoreFontSize,
                         paddingHorizontal,
                         paddingVertical,
                         isUrf,
                         isAram
                     }) => {


    return (
        <>
            <Wrapper>
                <FlexWrapper>
                    <ImageWrapper>
                        <ChampionImageWithLane champion_id={champId}/>
                    </ImageWrapper>
                </FlexWrapper>
                <FlexWrapper>
                    <AiscoreWrapper>
                        <CenterDiv isUrf={isUrf}>
                            <AiText>{aiText}</AiText>
                            <ScoreWrapper
                                hoverScale={!isAram}
                                noneClickable={isAram}
                                paddingVertical={paddingVertical}
                                paddingHorizontal={paddingHorizontal}
                                fontSize={scoreFontSize}
                                data={data}
                                onClick={onClick}
                                staticColor={staticColor}
                                extraValue={extraValue}
                                score={score}
                                extra={extra}
                            />
                        </CenterDiv>
                    </AiscoreWrapper>
                </FlexWrapper>
            </Wrapper>
        </>);
};

export default BestSection;
