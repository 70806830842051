import React, {useLayoutEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {GET_INGAME_CHECK_REQUEST} from "../../store/reducer/summoner";

const UseIngameDispatch = (region) => {
    const dispatch = useDispatch();
    const summoner = useSelector((state) => state.summoner.summoner);
    const {
        ingameStatus,
        getIngameInfoLoading,
        getIngameInfoSummonerLoading,
        getIngameCheckLoading,
        ingame
    } = useSelector((state) => state.summoner);

    useLayoutEffect(() => {
        dispatch({
            type: GET_INGAME_CHECK_REQUEST,
            data: {
                puu_id: summoner.summoner_basic_info_dict.puu_id,
                region: region,
                fetchAll: true
            }
        })
    }, []);

    return {getIngameInfoLoading, ingameStatus, getIngameCheckLoading, getIngameInfoSummonerLoading, ingame};
};

export default UseIngameDispatch;
