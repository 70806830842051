const swarm_champ = [
    {
        swarmChampionId: 3159,
//     오로라
        championId: 893,
    },
    {
        swarmChampionId: 3147,
//    리븐
        championId: 92,
    },
    {
        swarmChampionId: 3151,
//    징크스
        championId: 222,
    },
    {
        swarmChampionId: 3152,
//    레오나
        championId: 89,
    },
    {
        swarmChampionId: 3153,
//    세라핀
        championId: 147,
    },
    {
        swarmChampionId: 3156,
//    브라이어
        championId: 233,
    },
    {
        swarmChampionId: 3157,
//    야스오
        championId: 157,
    },
    {
        swarmChampionId: 3947,
//     자야
        championId: 498,
    },
    {
        swarmChampionId: 3678,
//    일라오이
        championId: 420,
    },

]

export default swarm_champ