import React, {useCallback, useState} from 'react';
import styled from "styled-components";
import FilterArrow from "../../../../../common/FilterArrow";
import BigTooltip from "../../../../../Tooltip/BigTooltipBox";

const Wrapper = styled.th`
  vertical-align: middle;
  height: ${props => {
    let height = '40px';
    if (props.height) height = props.height;
    return height
  }};
  //background-color: #F1F3F8;
`;

const HeaderTextWrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const HeaderText = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  white-space: pre-wrap;
  text-align: center;
  padding: 2px 0;
  line-height: 14px;
  min-height: 27px;
  color: ${props => {
    let color = props.theme.color.dark_200;
    if (props.selected) color = props.theme.color.dark_100;
    return color;
  }};
  ${props => {
    if (props.clickable) {
      return `&:hover {
          cursor: pointer;
        }`
    }
  }}

`;
const HeaderSelectedBar = styled.div`
  position: absolute;
  bottom: 0;
  width: ${props => {
    if (props.selected) {
      return '100%';
    } else {
      return 0;
    }
  }};
  display: block;
  height: 3px;
  background: ${props => props.theme.color.green_100};
  transition: ${props => {
    if (props.selected) {
      return 'width 200ms linear;';
    }
    return '';
  }};
  overflow: hidden;
`;


const RankingTableHeader = ({
                                percent, title, sort, onClickSort = () => {
    }, currentSort, disableBar, height, tooltip
                            }) => {
    const [onHover, setOnHover] = useState(false);


    const onClickHeader = useCallback(() => {
        if (sort) {
            onClickSort(sort)
        }
    }, []);


    const handleMouseOut = () => {
        setOnHover(false);
    }

    const handleMouseOver = () => {
        setOnHover(true)
    }

    const selected = disableBar ? false : currentSort?.[0]?.value === sort?.value


    const RenderBar = () => {
        if (disableBar) {
            return null
        }
        if (selected) {
            return <HeaderSelectedBar selected={selected}/>
        } else {
            return <HeaderSelectedBar selected={onHover}/>
        }
    }

    return (
        <Wrapper onClick={onClickHeader} percent={percent} height={height}>
            <HeaderTextWrapper onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>

                {tooltip ? <BigTooltip tooltipText={tooltip}>
                        <HeaderText clickable={sort ? true : false} selected={selected}>
                            {title}
                        </HeaderText>
                    </BigTooltip> :

                    <HeaderText clickable={sort ? true : false} selected={selected}>
                        {title}
                    </HeaderText>}
                {sort !== undefined &&
                    <FilterArrow
                        selected={selected}
                        arrow={sort.sortType}
                    />
                }
                <RenderBar/>
            </HeaderTextWrapper>
        </Wrapper>);
};
export default RankingTableHeader;
