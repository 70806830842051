import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import ChampionSkillImage from "../../../../common/ChampionSkillImage";
import {useTranslation} from "react-i18next";
import ChampionPassiveImage from "../../../../common/ChampionPassiveImage";
import {useSelector} from "react-redux";


const Wrapper = styled.div`
  background: ${props => {
    let background = props.theme.color.white_201;
    if (props.background) background = props.background;
    return background;
  }};
  width: 95%;
  border-radius: 6px;
  padding: 14px;

  & + & {
    margin-top: 10px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SkillNameText = styled.span`
  font-size: 18px;
  font-weight: 500;
  margin-left: 6px;
`;

const BasicText = styled.span`
  font-size: 18px;
  font-weight: 500;
`;

const PatchWrapper = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: column;
`;

const PatchText = styled.span`
  margin: 6px 0;
  font-size: 14px;
  opacity: 0.8;
  text-decoration: line-through;
`;

const AfterText = styled.span`
  font-size: 14px;
  opacity: 0.8;
  line-height: 20px;
  text-decoration: none;
`;

const ArrowSpan = styled.span`
  margin: 0 5px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const DashSpan = styled.span`
  line-height: 20px;
    margin-right: 4px;
`;

const SKILL_IMAGE_WIDTH = 36;
const SKILL_IMAGE_HEIGHT = 36;
const HistoryContentPatchInfoContent = ({skillDetailList, version, spellKey, champion, background}) => {
    const {t} = useTranslation();
    const gameVersionList = useSelector((state) => state.gameVersion.versionList);
    const {
        version_spell
    } = useSelector((state) => state.champions.patchHistory);


    const currentSpellImg = useMemo(() => {
        const spell = version_spell.find((data) => data?.version === version);
        if(spell) {
            return spell;
        } else {
           return version_spell.at(-2);
        }
    },[version_spell]);

    const imgVersion = useMemo(() => {
        const version = gameVersionList.find((data) => data?.indexOf(currentSpellImg?.version + '.1') > -1)
        if(version) {
            return version
        } else {
            return gameVersionList[0];
        }
    },[version_spell]);

    const RenderBySpellKey = useCallback(() => {
        switch (spellKey) {
            case 'Q'  : {
                return (
                    <div>
                        <ChampionSkillImage
                            margin={'0 5px 0 0'}
                            width={SKILL_IMAGE_WIDTH}
                            height={SKILL_IMAGE_HEIGHT}
                            spell={currentSpellImg?.spells[0]}
                            version={imgVersion}
                            img={currentSpellImg?.spells[0]?.image?.full}
                        />
                        <SkillNameText>{`Q - ${currentSpellImg?.spells[0].name}`}</SkillNameText>
                    </div>
                )
            }
            case 'W' : {
                return (
                    <div>
                        <ChampionSkillImage
                            margin={'0 5px 0 0'}
                            width={SKILL_IMAGE_WIDTH}
                            height={SKILL_IMAGE_HEIGHT}
                            spell={currentSpellImg?.spells[1]}
                            version={imgVersion}
                            img={currentSpellImg?.spells[1]?.image?.full}
                        />
                        <SkillNameText>{`W - ${currentSpellImg?.spells[1].name}`}</SkillNameText>
                    </div>
                )
            }
            case 'E' : {
                return (
                    <div>
                        <ChampionSkillImage
                            margin={'0 5px 0 0'}
                            width={SKILL_IMAGE_WIDTH}
                            height={SKILL_IMAGE_HEIGHT}
                            spell={currentSpellImg?.spells[2]}
                            version={imgVersion}
                            img={currentSpellImg?.spells[2]?.image?.full}
                        />
                        <SkillNameText>{`E - ${currentSpellImg?.spells[2].name}`}</SkillNameText>
                    </div>
                )
            }
            case 'R' : {
                return (
                    <div>
                        <ChampionSkillImage
                            margin={'0 5px 0 0'}
                            width={SKILL_IMAGE_WIDTH}
                            height={SKILL_IMAGE_HEIGHT}
                            spell={currentSpellImg?.spells[3]}
                            version={imgVersion}
                            img={currentSpellImg?.spells[3]?.image?.full}
                        />
                        <SkillNameText>{`R - ${currentSpellImg?.spells[3].name}`}</SkillNameText>
                    </div>
                )
            }
            case 'P' : {
                return (
                    <div>
                        <ChampionPassiveImage
                            margin={'0 5px 0 0'}
                            width={SKILL_IMAGE_WIDTH}
                            height={SKILL_IMAGE_HEIGHT}
                            version={imgVersion}
                            img={currentSpellImg?.passive?.image?.full}
                            tooltip={`<span style="font-weight: bold; color: #46B9AA;">${currentSpellImg?.passive.name}</span>\n${currentSpellImg?.passive?.description}`}
                        />
                        <SkillNameText>{`P - ${currentSpellImg?.passive.name}`}</SkillNameText>
                    </div>
                )
            }
            case 'B' : {
                return <BasicText>{t('championsHistory.title.basic')}</BasicText>
            }
            case 'N' : {
                return <BasicText>{t('championsHistory.title.newChamp')}</BasicText>
            }
            case 'ARAM' : {
                return (
                    <div>
                        <img
                            src={'/images/icon_s_position_aram.svg'}
                            width={SKILL_IMAGE_WIDTH}
                            height={SKILL_IMAGE_HEIGHT}
                            alt={""}
                        />
                        <SkillNameText>{t('championsHistory.title.aram')}</SkillNameText>
                    </div>)
            }
        }
    }, [spellKey, champion]);

    return (
        <Wrapper background={background} className={'infoContent'}>
            <ImageWrapper>
                {RenderBySpellKey()}
            </ImageWrapper>
            <PatchWrapper>
                {Array.isArray(skillDetailList) ? skillDetailList.map((data, index) => {
                    return (
                        <TextWrapper key={index}>
                           <DashSpan>-</DashSpan>
                            <AfterText>
                                <PatchText>{`${data.before ? data.text + " " + data.before : ''} `}</PatchText>
                                {data.before ? <ArrowSpan>⇒</ArrowSpan> : null}
                                <AfterText>{data.text + " " + data.after}</AfterText>
                            </AfterText>
                        </TextWrapper>
                    )
                }) : null}
            </PatchWrapper>
        </Wrapper>
    );
};

export default HistoryContentPatchInfoContent;
