import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import BestContent from "./BestContent";
import GameSummary from "./GameSummary";
import {isAram, setLaneIndex, sortChampData, threedigits} from "../../../../function";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;



const GameSummaryWrapper = styled.div`
    flex: 1;
`;





const GameAnalytics = ({match, myMatch, region, isUrf, hide,onChangeBuildIndex}) => {
    const {gameSummaryPatch} = useSelector((state) => state.summoner);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const mostAssist = [{
        value: 'final_stat_dict.assists',
        sortType: false,
    }]
    const Aisort = [{
        value: 'is_win',
        sortType: false,
    }, {
        value: 'ai_score',
        sortType: false,
    }];

    const mostDealt = [{
        value: 'total_damage_dealt',
        sortType: false,
    }]

    const onClickAi = useCallback((data) => {
    }, [])
    const arrayGap = (array) => {
        if (array.length === 0) {
            return array[0].ai_score
        } else {
            return Math.abs(array[0].ai_score - array[1].ai_score);
        }
    }
    const getKda = useCallback((kill, death, assist, runaway) => {
        if (death === 0) {
            //KDA 1000은 현실에서 불가능
            if (!runaway) {
                return (1000 + (kill + assist)).toFixed(2);
            } else {
                return 0;
            }
        } else {
            return ((kill + assist) / death).toFixed(2);
        }
    }, []);




    const setSideIndex = useCallback((side) => {
        switch (side) {
            case 'BLUE' :
                return 1
            case 'RED' :
                return 0
        }
    }, [])


    const InitMap = useMemo(() => {
        if (match) {
            const array = [...match.participants_list];
            const result = array.map((data) => ({
                ...data,
                KDA: Number(getKda(data.final_stat_dict.kills, data.final_stat_dict.deaths, data.final_stat_dict.assists, data.is_runaway)),
                laneIndex: setLaneIndex(data.position),
                sideIndex: setSideIndex(data.side)
            }))

            return result;
        }
        return [];
    }, [match]);
    const AiMemo = useMemo(() => match && sortChampData(match.participants_list, Aisort), [match]);

    const HardCarryGap = useMemo(() => {
        if (match) {
            const reducedList = match.participants_list.reduce((sum, data, index) => {

                if (data.is_win) {
                    sum.list.push(data);
                    if (sum.list.length > 0) {
                        sum.aiScoreAvg = Math.trunc(sum.list.reduce((sum, cur) => sum + cur.ai_score, 0) / sum.list.length)
                    } else {
                        sum.aiScoreAvg = data.ai_score;
                    }
                }
                return sum;
            }, {list: [], aiScoreAvg: 0,});


            const aiScoreList = reducedList.list.reduce((sum, cur) => {
                sum.push(cur.ai_score);
                return sum;
            }, []);
            const getHighestAiScore = Math.max(...aiScoreList);
            const findIndex = aiScoreList.findIndex((data) => data === getHighestAiScore);

            const getAvg = aiScoreList.reduce((sum, cur, index) => {
                if (index === findIndex) {
                    return sum;
                }

                sum.list.push(cur);
                if (sum.list.length > 0) {
                    sum.aiScoreAvg = Math.trunc(sum.list.reduce((sum, cur) => sum + cur, 0) / sum.list.length)
                } else {
                    sum.aiScoreAvg = cur;
                }

                return sum;
            }, {
                list: [],
                aiScoreAvg: 0,
            })


            return {
                ...reducedList.list[findIndex],
                gap: getHighestAiScore - getAvg.aiScoreAvg,
            }
        }
        return {
            riot_id_name: "",
            gap: 0,
        }
    }, []);
    const MostDealt = useMemo(() => sortChampData(match.participants_list, mostDealt), [match]);
    const Difference = useMemo(() => {
        if (match) {


            const top = [];
            const support = [];
            const mid = [];
            const jungle = [];
            const bot = [];
            if (match) {
                match.participants_list.reduce((sum, data, index) => {

                    switch (data.position) {
                        case 'Top':
                            top.push(data)
                            break;
                        case 'Jungle':
                            jungle.push(data)
                            break;
                        case 'Middle':
                            mid.push(data)
                            break;
                        case 'Bot':
                            bot.push(data)
                            break;
                        case 'Supporter':
                            support.push(data)
                            break;
                        default:
                            break;
                    }
                }, []);
                const topGap = arrayGap(top);
                const bottomGap = arrayGap(bot);
                const supportGap = arrayGap(support);
                const midGap = arrayGap(mid);
                const jungleGap = arrayGap(jungle);
                const combinedArray = [topGap, bottomGap, supportGap, midGap, jungleGap];
                const indexingArray = [top, bot, support, mid, jungle];
                const maxValue = Math.max(...combinedArray);
                const findIndex = combinedArray.findIndex((data, index) => data === maxValue);
                if (indexingArray[findIndex].length > 1) {
                    if (indexingArray[findIndex][0].ai_score > indexingArray[findIndex][1].ai_score) {
                        return {...indexingArray[findIndex][0], gap: combinedArray[findIndex]};
                    } else {
                        return {...indexingArray[findIndex][1], gap: combinedArray[findIndex]};
                    }
                } else {
                    return {...indexingArray[findIndex][0], gap: combinedArray[findIndex]};
                }
            }
        }
    }, []);


    const getLaneName = useCallback((lane) => {
        switch (lane) {
            case 'Top':
                return t('gameAnalytics.top')
            case 'Jungle':
                return t('gameAnalytics.jungle')
            case 'Middle':
                return t('gameAnalytics.mid')
            case 'Bot':
                return t('gameAnalytics.bot')
            case 'Supporter':
                return t('gameAnalytics.support')
            default:
                break;
        }
    }, []);

    useCallback(() => {
        if (AiMemo[0].riot_id_name === HardCarryGap.riot_id_name) {
            return AiMemo[0].ai_score >= 75 && AiMemo[0].is_win && HardCarryGap.gap >= 25;
        }
        return false;
    }, []);


    useCallback(() => {
        if (isUrf) {

            return (
                <BestContent
                    hide={hide}
                    isAram={isAram(match?.match_basic_dict?.queue_id)}
                    paddingVertical={6}
                    paddingHorizontal={4}
                    scoreFontSize={16}
                    noIcon={isUrf}
                    isUrf={isUrf}
                    icon={'images/ico_best_player05.svg'}
                    onClickAi={onClickAi}
                    region={region}
                    score={threedigits(MostDealt[0]?.total_damage_dealt)}
                    data={MostDealt[0]}
                    staticColor={'#EC5F95'}
                    aiText={t('gameAnalytics.damage')}
                    extra={false}
                    title={t('gameAnalytics.damage')}
                    lane={MostDealt[0]?.lane}
                />
            )
        } else {
            return (
                <BestContent
                    hide={hide}
                    isAram={isAram(match?.match_basic_dict?.queue_id)}
                    noIcon={isUrf}
                    icon={'images/ico_best_player02.svg'}
                    onClickAi={onClickAi}
                    region={region}
                    extraValue={Difference?.gap}
                    score={Difference?.ai_score}
                    data={Difference}
                    aiText={'AI-Score'}
                    extra={true}
                    title={getLaneName(Difference.position) + " " + t('gameAnalytics.gap')}
                    lane={Difference?.position}
                />
            )
        }
    }, [isUrf, match, myMatch, hide]);

    //
    // const BestMemo = useMemo(() => (
    //     <BestWrapper>
    //         <BestHeader
    //             title={t('gameAnalytics.bestPlayer')}
    //         />
    //         <BestContent
    //             whiteLane={isTriple()}
    //             headerBgColor={isTriple() ? "#FE4C89" : ""}
    //             hide={hide}
    //             isAram={isAram(match?.match_basic_dict?.queue_id)}
    //             noIcon={isUrf}
    //             fontColor={isTriple() ? '#fff' : "#000"}
    //             icon={isTriple() ? 'images/ico_best_player01_w.svg' : 'images/ico_best_player01.svg'}
    //             onClickAi={onClickAi}
    //             region={region}
    //             score={AiMemo[0].ai_score}
    //             data={AiMemo[0]}
    //             aiText={'AI-Score'}
    //             top={true}
    //             title={isTriple() ? t('gameAnalytics.hardCarry') : t('gameAnalytics.carry')}
    //             lane={AiMemo[0].position}
    //         />
    //         {urfContent()}
    //         <BestContent
    //             hide={hide}
    //             isAram={isAram(match?.match_basic_dict?.queue_id)}
    //             noIcon={isUrf}
    //             icon={'images/ico_best_player03.svg'}
    //             onClickAi={onClickAi}
    //             region={region}
    //             staticColor={'#7473AB'}
    //             score={MostAssist[0].final_stat_dict.assists}
    //             data={MostAssist[0]}
    //             aiText={t('gameAnalytics.assist')}
    //             title={t('gameAnalytics.mostAssist')}
    //             lane={MostAssist[0].position}
    //         />
    //         <BestContent
    //             hide={hide}
    //             isAram={isAram(match?.match_basic_dict?.queue_id)}
    //             noIcon={isUrf}
    //             icon={'images/ico_best_player04.svg'}
    //             onClickAi={onClickAi}
    //             region={region}
    //             score={AiMemo[5].ai_score}
    //             data={AiMemo[5]}
    //             aiText={'AI-Score'}
    //             title={t('gameAnalytics.ace')}
    //             lane={AiMemo[5].position}
    //         />
    //     </BestWrapper>
    // ), [match, myMatch, isUrf, hide]);
    //

    const SummaryMemo = useMemo(() =>
        (
            <GameSummaryWrapper >
                <GameSummary
                    gameType={match.match_basic_dict.queue_id}
                    gameVersion={match.match_basic_dict.game_version}
                    onClickAi={onClickAi}
                    region={region}
                    AiMemo={AiMemo}
                    duration={match.match_basic_dict.game_duration}
                    match={InitMap}
                    myMatch={myMatch}
                    hide={hide}
                    onChangeBuildIndex={onChangeBuildIndex}
                />
            </GameSummaryWrapper>
        ), [match, myMatch, hide,onChangeBuildIndex]);

    return (
        <>
            <Wrapper>
                {SummaryMemo}
            </Wrapper>
        </>
    );
};

export default GameAnalytics;
