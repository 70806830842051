import React, {useCallback, useMemo} from 'react';
import ChampionImageWithLane from "../../common/ChampionImageWithLane";
import SmallInfluInfo from "../../common/SmallInfluInfo";
import {isArena, isSwarm, onStorePuuId, setLaneIndex, sortChampData} from "../../../function";
import BigTooltip from "../../Tooltip/BigTooltipBox";

import styled from "styled-components";
import {useTranslation} from "react-i18next";
import SmallSummonerArenaList from "./SmallSummonerArenaList";
import {ErrorBoundary} from "react-error-boundary";
import MatchSwarmSummoner from "../MatchSwarmSummoner";

const SmallChampImage = styled.div`
  z-index: 2;

  .imgDiv {
    border-radius: ${props => props.borderRadius};
  }
`;
const PlayerMarginBox = styled.div`
  margin-right: 4px;
`;

const SmallSummonerList = ({match, myMatch, hide, region, queueId}) => {

    const sortLane = [{
        value: 'laneIndex',
        sortType: false,
    }];


    const {t} = useTranslation();
    const getName = useCallback((player) => {
        const name = player?.riot_id_name;
        if (typeof name === 'string' && name.length > 0) {
            if (hide) {
                return new Array(name.length).fill('').map(() => "*");
            } else {
                return name;
            }
        }
        if (player?.summoner_name) {
            return player.summoner_name;
        }
        return "";
    }, [hide]);
    const getPClassName = (puu_id) => {
        if (hide) {
            return "";
        } else if (puu_id === myMatch.puu_id) {
            return "my";
        }
    }


    const redTeam = useMemo(() => {
        try {
            const filter = match.participants_list.filter((data) => data.side !== 'BLUE');
            const hasLaneIndexMap = filter.map((data) => {
                return {...data, laneIndex: setLaneIndex(data.position)}
            })
            return sortChampData(hasLaneIndexMap, sortLane);
        } catch (e) {
            return [];
        }
    }, [match]);


    const blueTeam = useMemo(() => {
        try {
            const filter = match.participants_list.filter((data) => data.side === 'BLUE');
            const hasLaneIndexMap = filter.map((data) => {
                return {...data, laneIndex: setLaneIndex(data.position)}
            })
            return sortChampData(hasLaneIndexMap, sortLane);
        } catch (e) {
            return [];
        }
    }, [match]);


    if (isSwarm(queueId)) {
        return (
            <MatchSwarmSummoner myMatch={myMatch} match={match} getName={getName} getPClassName={getPClassName} region={region} hide={hide} list={match.participants_list}/>
        )
    }

    if (isArena(queueId)) {
        return (
            <ErrorBoundary fallback={null}>
                <SmallSummonerArenaList match={match} getName={getName} getPClassName={getPClassName} region={region}
                                        hide={hide} myMatch={myMatch}/>
            </ErrorBoundary>)

    }

    return (
        <>
            <div className="team">
                {blueTeam.map((player, index) => {
                    const name = getName(player);
                    return (<SmallChampImage
                        borderRadius={hide ? '3px' : player.puu_id === myMatch.puu_id ? '50%' : '3px'}
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                        key={index}
                    >
                        <PlayerMarginBox>
                            <ChampionImageWithLane
                                disabled={true}
                                champion_id={player.champion_id}
                            />
                        </PlayerMarginBox>
                        {player.pro_streamer_status &&
                            <PlayerMarginBox>
                                <SmallInfluInfo status={player.pro_streamer_status}/>
                            </PlayerMarginBox>}
                        <p className={getPClassName(player.puu_id)}>
                                                    <span
                                                        onClick={() => onStorePuuId(player.riot_id_tag_line ? `${player.riot_id_name}-${player.riot_id_tag_line}` : name, region, player.puu_id)}
                                                        className="common_search"
                                                    >
                                                       {name}
                                                    </span>
                        </p>
                        {(player?.withTogether && match?.togetherCount > 1) &&
                            <BigTooltip tooltipText={t('summoner.withTogether')}>
                                <img src={'/images/ico_team.svg'} width={12} height={12} alt={""}/>
                            </BigTooltip>
                        }
                    </SmallChampImage>);
                })}
            </div>
            <div className="team">
                {redTeam.map((player, index) => {
                    const name = getName(player);
                    return (
                        <SmallChampImage
                            borderRadius={hide ? '3px' : player.puu_id === myMatch.puu_id ? '50%' : '3px'}
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                            key={index}
                        >

                            <PlayerMarginBox>
                                <ChampionImageWithLane
                                    disabled={true}
                                    champion_id={player.champion_id}
                                />
                            </PlayerMarginBox>
                            {player.pro_streamer_status &&
                                <PlayerMarginBox>
                                    <SmallInfluInfo status={player.pro_streamer_status}/>
                                </PlayerMarginBox>}
                            <p className={getPClassName(player.puu_id)}>
                                                    <span
                                                        onClick={() => onStorePuuId(player.riot_id_tag_line ? `${player.riot_id_name}-${player.riot_id_tag_line}` : name, region, player.puu_id)}
                                                        className="common_search"
                                                    >

                                                        {name}
                                                    </span>
                            </p>
                            {(player?.withTogether && match?.togetherCount > 1) &&
                                <BigTooltip tooltipText={t('summoner.withTogether')}>
                                    <img src={'/images/ico_team.svg'} width={12} height={12} alt={""}/>
                                </BigTooltip>
                            }
                        </SmallChampImage>);
                })}
            </div>
        </>
    );
};

export default SmallSummonerList;