import React from 'react';
import {useTranslation} from "react-i18next";

const UseTransBuildTitle = () => {
    const {t} = useTranslation();
    const translateTitle = (title) => {
        switch (title) {
            case 0:
                return t('championDetailBuild.tab.recomm')
            case 1:
                return t('championDetailBuild.tab.ad')
            case 2:
                return t('championDetailBuild.tab.ap')
            case 3:
                return t('championDetailBuild.tab.tank')
            case 4:
                return t('championDetailBuild.tab.utility')
            case 5:
                return t('championDetailBuild.tab.lethal')
            case 6:
                return t('championDetailBuild.tab.crit')
            case 7:
                return t('championDetailBuild.tab.hybrid')
            case 8:
                return t('championDetailBuild.tab.heal')
            case 9:
                return t('championDetailBuild.tab.bruiser')
            default:
                return "UNKNOWN"
        }

    };
    return {
        translateTitle
    }
}

export default UseTransBuildTitle;