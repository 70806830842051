import React, {useCallback, useState} from 'react';
import SearchListLI from "../../Champions/ChampionsContent/ChampionsSearch/SearchListByLane/ListLI";
import styled from "styled-components";


const Wrapper = styled.div`
  height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ListUL = styled.ul`
  overflow: hidden;
  height: 40px;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-around;

  li:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  li:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const FilterBox = ({
                       list,
                       mapTitle = (data, index) => data,
                       imageSrc,
                       onClick,
                       disableSetSelected = false,
                       customSelected,
                       visibleText
                   }) => {
    const [selected, setSelected] = useState(0);
    const onClickLi = useCallback((value, lane) => {
        if (value !== undefined && lane !== undefined) {
            if (!disableSetSelected) {
                setSelected(value);
            }
            onClick(value, lane)
        }
    }, [disableSetSelected]);

    return (
        <Wrapper>
            <ListUL>
                {list.map((data, index) => {
                    return (
                        <SearchListLI
                            lastIndex={list.length - 1}
                            selectedIndex={selected}
                            visibleText={visibleText}
                            src={imageSrc(data, index)}
                            lane={mapTitle(data, index)}
                            key={data + "laneData"}
                            data={data}
                            index={index}
                            onClick={onClickLi}
                            selected={customSelected ? customSelected(data, index) : selected === index}
                        />
                    )
                })}
            </ListUL>
        </Wrapper>
    );
};

export default FilterBox;
