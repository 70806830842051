import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {ColumnBox, FlexBox} from "../../../ui/styled";
import {ImageRune} from "../../../common";
import {hundredCheck, threedigits} from "../../../../function";

const Table = styled.table`
  .imgRune {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #000;
  }
`
const HeaderTr = styled.tr`
  border-bottom: 1px solid ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.background_F5};
  position: relative;
`;

const TableTD = styled.td`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_100};
  padding-left: 15px;
  vertical-align: middle;
`;

const CenterTD = styled.td`
  font-size: 12px;
  height: 26px;
  text-align: center;
  font-weight: 500;
  color: ${props => props.theme.color.dark_200};
  vertical-align: middle;
`;

const MainRuneText = styled.span`
  text-align: center;
  width: 149px;
  margin-right: 16px;
  color: ${props => props.theme.color.dark_100};
  font-weight: bold;
`;

const SubRuneText = styled.span`
  margin-left: 17px;
  width: 69px;
  text-align: center;
  color: ${props => props.theme.color.dark_100};
  font-weight: bold;
`;
const WhiteTBody = styled.tbody`
  background-color: ${props => props.theme.color.site_main_color};
  
  tr {
    height: 48px;
    border-bottom: 1px solid ${props => props.theme.color.gray_200};
  }

  tr:first-child {
    padding-top: 12px;
  }

  tr {
    td:first-child {
      padding-left: 15px;
    }
  }
`;
const ContentTD = styled.td`
  vertical-align: middle;
`;

const getRuneBorderColor = (runeId) => {
    switch (Number(runeId)) {
        case 8000:
            return '#FFCC54'
        case 8100:
            return '#E85C5C';
        case 8200:
            return '#8C85EB'
        case 8300:
            return '#6CBABC'
        case 8400:
            return "#6DD174"
    }
}
const RuneWrapper = styled.div`
  border: ${props => {
    let border = 0;
    if (props.border) border = `2px solid ${getRuneBorderColor(props.borderColor)}`;
    return border
  }};
  border-radius: 50%;

  & + & {
    margin-left: 5px;
  }
  
`;
const MainRuneWrapper = styled(RuneWrapper)`
  &:nth-of-type(2) {
    margin-left: 3px !important;
  }
`;
const Bar = styled.div`
  height: 17px;
  border-right: 1px solid ${props => props.theme.color.default_border_color};
  margin: 0 16px;
`;

const ValueText = styled.span`
  font-size: 12px;
  color: ${props => {
    let color = props.theme.color.red_100;
    if (props.value > 50) {
      color = props.theme.color.blue_100;
    }
    return color;
  }};
  font-weight: 500;
`;

const PurpleText = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.font_58};
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const PercentText = styled.span`
  font-size: 10px;
  color: ${props => props.theme.color.dark_100};
  opacity: 0.6;
  margin-left: 2px;
`;


const SummonerMasteryRuneList = ({list}) => {
    const {t} = useTranslation();
    return (
        <Table>
            <colgroup>
                <col/>
                <col width={'80px'}/>
                <col width={'80px'}/>
            </colgroup>
            <HeaderTr>
                <TableTD>
                    <FlexBox>
                        <MainRuneText>{t('championDetailBuild.mainRune')}</MainRuneText>
                        <SubRuneText>{t('championDetailBuild.subRune')}</SubRuneText>
                    </FlexBox>
                </TableTD>
                <CenterTD>{t('summoner.mastery.games')}</CenterTD>
                <CenterTD>{t('summoner.mastery.winRate')}</CenterTD>
            </HeaderTr>
            <WhiteTBody>
                {(!Array.isArray(list) || list.length === 0) ? null : list.map((data, index) => {
                    const winValue = (data.win_rate * 100)?.toFixed(1);
                    return (
                        <tr key={index}>
                            <ContentTD>
                                <FlexBox margin={index === 0 ? '4px 0 0 0' : 0}>
                                    <FlexBox width={'auto'}>
                                        {data.rune_main.map((mainRune, index) => {
                                            if (index === 0) return null;
                                            return (<MainRuneWrapper border={index === 1} borderColor={data.rune_main[0]}
                                                                 key={index + 'mainRune'}>
                                                <ImageRune runeId={mainRune}/>
                                            </MainRuneWrapper>)
                                        })}
                                    </FlexBox>
                                    <Bar/>
                                    <FlexBox width={'auto'}>
                                        {data.rune_sub.map((mainRune, index) => {
                                            if (index === 0) return null;
                                            return (<RuneWrapper key={index + 'mainRune'}>
                                                <ImageRune runeId={mainRune}/>
                                            </RuneWrapper>)
                                        })}
                                    </FlexBox>
                                </FlexBox>
                            </ContentTD>
                            <ContentTD>
                                <CenterDiv>
                                    <ColumnBox alignItems={'center'}>
                                        <FlexBox>
                                            <PurpleText>{threedigits(data.games)}</PurpleText>
                                        </FlexBox>
                                    </ColumnBox>
                                </CenterDiv>
                            </ContentTD>
                            <ContentTD>
                                <CenterDiv>
                                    <ValueText value={winValue}>{hundredCheck(winValue)}</ValueText>
                                    <PercentText>
                                        %
                                    </PercentText>

                                </CenterDiv>
                            </ContentTD>
                        </tr>
                    )
                })}
            </WhiteTBody>
        </Table>
    );
};

export default SummonerMasteryRuneList;