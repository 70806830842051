import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import ChampCarouselContent from "./ChampCarouselContent";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  padding: 6px 15px;
`;

const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const CarouselInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 93px;
  padding: 6px 0;
`;

const ChampSelectDiv = styled.div`
  height: 43px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 93px;
`;

const InfoText = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.dark_200};
  height: 14px;
`;

const GameCountText = styled(InfoText)`
  margin-top: 2px;

`;

const WinRateText = styled(InfoText)`
  margin-top: 2px;
`;


const ChampSelectSpan = styled.span`
  font-weight: bold;
  color: ${props => props.theme.color.dark_100};
`;

const IndicateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 6px;
  min-height: 6px;
`;

const CarouselContentWrapper = styled.div`
  overflow: hidden;
`;

const IndicateCircle = styled.div`
  cursor: pointer;
  border-radius: 50%;
  width: 6px;
  aspect-ratio: 1;
  background: ${props => props.theme.color.green_100};
  margin-right: 10px;
  opacity: ${props => {
    let opacity = '0.5';
    if (props.selected) opacity = '1';
    return opacity;
  }};
`;

const ChampCarousel = ({list, handleChamp, selectedChamp, position}) => {
    const {t, i18n} = useTranslation();
    const [visibleCount, setVisibleCount] = useState(15);
    const [indicate, setIndicate] = useState(0);


    const onChangeIndicate = useCallback((value) => {
        const calcCount = Math.floor(880 / 59);
        if (value !== -1 && value <= Math.floor(list.length / calcCount)) {
            setIndicate(value);
            setVisibleCount(calcCount);
        }
    }, []);


    useEffect(() => {
        onChangeIndicate(0);
    }, [list])


    return (
        <Wrapper>
            <CarouselWrapper>
                <CarouselInfoWrapper>
                    <ChampSelectDiv>
                        <ChampSelectSpan>{t('tabs.selectChampion')}</ChampSelectSpan>
                    </ChampSelectDiv>
                    <GameCountText>{t('tabs.games')}</GameCountText>
                    <WinRateText>{t('championDetailBuild.wr')}(%)</WinRateText>
                </CarouselInfoWrapper>
                <CarouselContentWrapper>
                    {(Array.isArray(list) && list.length !== 0) &&
                        <ChampCarouselContent
                            indicate={indicate}
                            onChangeIndicate={onChangeIndicate}
                            visibleCount={visibleCount}
                            position={position}
                            list={list}
                            selectedChamp={selectedChamp}
                            handleChamp={handleChamp}
                        />}
                </CarouselContentWrapper>
            </CarouselWrapper>
            <IndicateWrapper>
                {(Array.isArray(list) && list.length !== 0) &&
                    new Array(Math.ceil(list.length / visibleCount)).fill("").map((data, index) => {
                        return (
                            <IndicateCircle
                                onClick={() => onChangeIndicate(index)}
                                selected={index === Math.trunc(indicate)}
                                key={index}
                            />
                        )
                    })}
            </IndicateWrapper>
        </Wrapper>
    );
};

export default ChampCarousel;
