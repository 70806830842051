// 초기 상태 값
import {GET_MAIN_VIDEO_FAILURE, GET_MAIN_VIDEO_REQUEST, GET_MAIN_VIDEO_SUCCESS} from "../reducer/video";

const initialState = {
    mainVideo: {},

    getMainVideoLoading: false,
    getMainVideoDone: false,
    getMainVideoError: null,
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MAIN_VIDEO_REQUEST:
            return {
                getMainVideoLoading: true,
                getMainVideoDone: false,
                getMainVideoError: null,
            };
        case GET_MAIN_VIDEO_SUCCESS: {

            return {
                mainVideo: {...action.data},
                getMainVideoLoading: false,
                getMainVideoDone: true,
                getMainVideoError: null,
            };
        }
        case GET_MAIN_VIDEO_FAILURE: {
            return {
                mainVideo: {title : ""},
                getMainVideoLoading: false,
                getMainVideoError: action.error,
            }
        }
        default:
            return state;
    }
};
export default reducer;
