import React, {useMemo} from 'react';
import ChampLane from "../../../summoner/Champ/ChampLane";
import styled from "styled-components";
import {hundredCheck} from "../../../../function";
import {FlexBox} from "../../../ui/styled";
import {useTranslation} from "react-i18next";


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;

  .laneTag {
    width: 80px;
  }`;

const SummonerMasteryLane = ({laneList, selectedLane, pickList, setSelectedLane}) => {
    const {t} = useTranslation();
    const filterLane = useMemo(() => {
        try {
            return pickList.filter((data) => data.lane.toLowerCase() !== 'aram')
        } catch (e) {
            return [];
        }
    }, [pickList]);

    const aram = useMemo(() => {
        try {
            return pickList.filter((data) => data.lane.toLowerCase() === 'aram')
        } catch (e) {
            return [];
        }
    }, [pickList]);
    return (
        <FlexBox>
            <Wrapper>
                <ChampLane
                    selectedLane={selectedLane}
                    onChangePosition={(lane) => {
                        setSelectedLane(lane);
                    }}
                    customList={filterLane.map((data) => data.lane)}
                    mapValue={(lane) => {
                        const result = filterLane.find((data) => data.lane === lane)?.pick_rate
                        return hundredCheck(result) || 0;
                    }}

                    valueWidth={'4ch'}
                />
            </Wrapper>
            {aram.length > 0 &&
            <Wrapper>
                <ChampLane
                    noPercent={true}
                    selectedLane={selectedLane}
                    onChangePosition={(lane) => {
                        setSelectedLane(lane);
                    }}
                    customList={aram.map((data) => data.lane)}
                    mapValue={() => t('tabs.aram')}
                    valueWidth={'4ch'}
                />
            </Wrapper>}
        </FlexBox>
    );
};

export default SummonerMasteryLane;