import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import TableWinLose from "../../../../TableWinLose";

const Wrapper = styled.th`
  height: 33px;
  background-color: ${props => props.theme.color.site_main_color};
  width: ${props => props.percent}%;
  vertical-align: middle;
`;

const HeaderTextWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const WinLoseBox = styled.div`
  font-size: 13px;
  color: ${props => {
    let color = props.theme.color.font_fff_f1
    if (props.isWin) color = props.theme.color.font_fff_33
    return color;
  }};
  font-weight: 500;
  padding: 4px 5px;
  background-color: ${props => {
    let color = props.theme.color.red_100
    if (props.isWin) color = props.theme.color.blue_100;
    return color;
  }};
  border-radius: 5px;
`;

const TeamBox = styled.span`
  margin-left: 5px;
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_200};
`;


const WinTableHeader = ({isWin, teamIndex,}) => {
    const {t} = useTranslation();


    return (
        <Wrapper>
            <HeaderTextWrapper>
                <TableWinLose isWin={isWin}/>
                <TeamBox>({teamIndex === 0 ? t('summoner.side.redTeam') : t('summoner.side.blueTeam')})</TeamBox>
            </HeaderTextWrapper>
        </Wrapper>);
};

export default React.memo(WinTableHeader);
