import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
`;


const EmptyImage = styled.img`
`;

const EmptyText = styled.span`
  font-size: 20px;
  color: ${props => props.theme.color.dark_200};
`;

const ChampionsEmpty = ({translateText = 'championAnalyze.searchEmpty'}) => {
    const {t} = useTranslation();
    return (
        <Wrapper>
            <EmptyText>{t(translateText)}</EmptyText>
        </Wrapper>
    );
};

export default ChampionsEmpty;