import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  ${props => {
    if (props.width) return `width: ${props.width};`
    return `flex: 1;`;
  }};
  height: 100%;
  align-items: center;
  cursor: pointer;
  position: relative;
  background-color: ${props => {
    let color = props.theme.color.site_main_color;
    if (props.selected) color = props.theme.color.green_200;
    return color;
  }};

  &:hover {
    background-color: ${props => {
      let color = props.theme.color.white_200;
      if (props.selected) color = props.theme.color.green_200;
      return color;
    }};
  }

  border: 1px solid ${props => {
    let color = props.theme.color.default_border_color;
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};

  border-left: ${props => {
    let width = 0;
    if (props.left) width = '1px';
    if (props.selected) width = '1.5px';
    return width;
  }} solid ${props => {
    let color = props.theme.color.default_border_color;
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};
  border-right: ${props => {
    let width = 0;
    if (props.right) width = '1px';
    if (props.selected) width = '1.5px';
    return width;
  }} solid ${props => {
    let color = props.theme.color.default_border_color;
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};

  &:first-of-type {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
;

  &:last-of-type {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
;
`;


const ListText = styled.span`
  text-align: center;
  font-size: ${props => {
    let font = '14px';
    if (props.fontSize) font = props.fontSize;
    return font;
  }};;
  color: ${props => {
    let color = props.theme.color.dark_200;
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};
  font-weight: ${props => {
    let color = '';
    if (props.selected) color = 'bold';
    return color;
  }};;
`;
const ListButton = ({children, itemLength, selected, selectedIndex, index, onClick, width, fontSize, className}) => {
    const last = itemLength - 1 === index
    return (
        <Wrapper selected={selected} last={last} left={selectedIndex > -1 ? selectedIndex + 1 !== index : true}
                 right={last} onClick={onClick}
                 width={width} className={className ? className : ""}>
            <ListText selected={selected} fontSize={fontSize}>
                {children}
            </ListText>
        </Wrapper>
    );
};

export default ListButton;