import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import CRTableRanking from "./CRTableRanking";
import CRTChampion from "./CRTChampion";
import CRTTier from "./CRTTier";
import {useTranslation} from "react-i18next";
import BigTooltip from "../../../../../Tooltip/BigTooltipBox";
import {useHistory, useLocation} from "react-router-dom";
import useChampionFunc from "../../../../../../common/hooks/useChampionFunc";

const Wrapper = styled.tr`
  overflow: hidden;
  border-bottom: 1px solid ${props => props.theme.color.gray_200};
  
  
  &:hover {
    background-color: ${props => props.theme.color.white_200};
  }

  td:not(:last-of-type) {
    cursor: pointer;
  }
`;

const Content = styled.td`
  vertical-align: middle;
`;

const TempWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const WinPickBanText = styled.span`
  text-align: center;
  font-size: 12px;
  color: ${props => props.theme.color.dark_100};
  font-weight: 500;
`;

const PercentText = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.dark_200};
  font-weight: 500;
`;


const MarginDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TripleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-inline: 8px;

  ${MarginDiv}:not(:last-of-type) {
    margin-inline: 2px;
  }
`;

const EmptyDiv = styled.div`
  flex: 1;
  width: 100%;
  min-width: 110px;
  height: 100%;
`;

const ChampWrapper = styled.div`
  width: 160px;
`;

const ChampionsRankingTableContent = ({
                                          version,
                                          rank,
                                          winRate,
                                          pickRate,
                                          banRate,
                                          counter,
                                          tier,
                                          champion_id,
                                          lane,
                                          rankDelta
                                      }) => {
    const {i18n} = useTranslation();
    const {getChampion, getUrlNameByChampion, getChampionName} = useChampionFunc();
    const champion = getChampion(champion_id)
    const renderName = useMemo(() => getChampionName(champion), [champion]);
    const counterChampions = useMemo(() => {
        if (Array.isArray(counter) && counter.length > 0) {
            return counter.map((data) => getChampion(data));
        }
    }, [counter, i18n.language]);
    const history = useHistory();
    const location = useLocation();

    const navigateToBuild = useCallback(() => {
        const champName = getUrlNameByChampion(champion);
        history.push(`/champions/${champName}/build/${lane.toLowerCase()}${location.search}`);
    }, [champion, lane, location.search]);


    return (
        <Wrapper>
            <Content onClick={navigateToBuild}>
                <CRTableRanking
                    rank={rank}
                    value={rankDelta}
                />
            </Content>
            <Content onClick={navigateToBuild}>
                <ChampWrapper>
                    <CRTChampion
                        rounded={false}
                        disableOnClick={true}
                        lane={lane}
                        imageName={champion?.image_name}
                        paddingLeft={'16px'}
                        championName={renderName}
                        enChampName={champion?.champion_name_en}
                        version={version}
                    />
                </ChampWrapper>
            </Content>
            <Content onClick={navigateToBuild}>
                <CRTTier
                    tier={tier}
                />
            </Content>
            <Content onClick={navigateToBuild}>
                <TempWrapper>
                    <WinPickBanText>{(winRate * 100).toFixed(1)}<PercentText> %</PercentText></WinPickBanText>
                </TempWrapper>
            </Content>
            <Content onClick={navigateToBuild}>
                <TempWrapper>
                    <WinPickBanText>{(pickRate * 100).toFixed(1)}<PercentText> %</PercentText></WinPickBanText>
                </TempWrapper>
            </Content>
            <Content onClick={navigateToBuild}>
                <TempWrapper>
                    <WinPickBanText>{(banRate * 100).toFixed(1)}<PercentText> %</PercentText></WinPickBanText>
                </TempWrapper>
            </Content>
            <Content>
                {Array.isArray(counterChampions) && counterChampions.length > 0 ?
                    <TripleWrapper>
                        {counterChampions.map((data, index) => {
                            if (typeof data === 'object') {
                                return (
                                    <BigTooltip
                                        key={champion?.champion_id + "counter" + data.champion_id}
                                        tooltipText={`<span style="color: #46B9AA; font-weight: 500;">${data?.champion_name}</span>`}>
                                        <MarginDiv>
                                            <CRTChampion
                                                rounded={false}
                                                lane={lane}
                                                imageName={data.image_name}
                                                enChampName={data.champion_name_en}
                                                width={30}
                                                height={30}
                                                version={version}
                                            />
                                        </MarginDiv>
                                    </BigTooltip>
                                )
                            } else {
                                return <React.Fragment key={"empty" + index}/>
                            }
                        })}
                    </TripleWrapper>
                    :
                    <EmptyDiv/>
                }
            </Content>
        </Wrapper>
    );
};

const isEquals = (prev, next) => {
    return prev.champion?.champion_id === next.champion?.champion_id && prev.rank === next.rank && prev.lane === next.lane && prev.champion?.champion_name === next.champion?.champion_name;
}

export default React.memo(ChampionsRankingTableContent, isEquals);
