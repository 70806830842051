import React, {useMemo} from 'react';
import styled from "styled-components";
import IngamePlayerTableTr from "./IngamePlayerTableTr";
import {useTranslation} from "react-i18next";
import {FlexBox} from "../../../ui/styled";
import BigTooltip from "../../../Tooltip/BigTooltipBox";
import {capitalizeLetter, getTierScore, sortNestedObject} from "../../../../function";

const Table = styled.table`
  width: 100%;

`;

const HeaderTr = styled.tr`
  height: 32px;
  border-bottom: 1px solid ${props => props.theme.color.default_border_color};
`;

const HeaderTh = styled.th`
  font-size: 12px;
  position: relative;
  vertical-align: middle;
  background-color: ${props => props.theme.color.white_100};
  font-weight: 500;
  color: ${props => props.theme.color.dark_200};
`;

const TeamTd = styled.td`
  font-size: 14px;
  position: relative;
  vertical-align: middle;
  padding-left: 14px;
  font-weight: bold;
`;

const TeamSpan = styled.span`
  background-color: ${props => props.theme.color.white_100};
  color: ${props => props.color};
`;


const RightBar = styled.div`
  position: absolute;
  right: 0;
  top: 11px;
  height: 9px;
  background-color: ${props => props.theme.color.default_border_color};
  width: 1px;
`;

const QuestionWrapper = styled.div`
  margin-left: 3px;
`;

const AvgTierSpan = styled.span`
  margin-left: 10px;
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_200};
`;

const IngamePlayerTable = ({teamColor, teamName, players, enemyPlayers, open, onClickMore, region}) => {
    const {t} = useTranslation();
    const rankMemo = useMemo(() => {
        try {
            if (Array.isArray(players) && players.length > 0) {
                return sortNestedObject(players, [{sortType: 'summoner_info_dict.ai_score_avg', value: false}])
            }
            return players;
        } catch (e) {
            return players;
        }
    }, [players]);


    const getAvgTier = useMemo(() => {
        try {
            const playerList = players.map((data) => ({
                tier: data.summoner_realtime_data.season_tier_info_dict.ranked_solo_5x5.tier,
                division: data.summoner_realtime_data.season_tier_info_dict.ranked_solo_5x5.division
            }));
            const filterUnrank = playerList.filter((data) => data.tier !== "unranked");
            const combinedPlayerTier = filterUnrank.reduce((sum, cur) => {
                sum += getTierScore(`${cur.tier} ${cur.division}`).num;
                return sum;
            }, 0);
            const length = filterUnrank.length === 0 ? 1 : filterUnrank.length;
            const result = getTierScore(Math.trunc(combinedPlayerTier / length)).obj;
            return `${capitalizeLetter(result.tier)} ${result.division}`
        } catch (e) {
            console.log("avgTier", e);
            return 0;
        }
    }, []);


    return (
        <Table>
            <colgroup>
                <col width={'26%'}/>
                <col width={'9%'}/>
                <col width={'12%'}/>
                <col width={'8%'}/>
                <col width={'20%'}/>
                <col width={'7%'}/>
                <col width={'15%'}/>
                <col width={'3%'}/>
            </colgroup>
            <thead>
            <HeaderTr>
                <TeamTd>
                    <TeamSpan color={teamColor}>{teamName}</TeamSpan>
                    <AvgTierSpan>{t('ingame.avgTier')} : {getAvgTier}</AvgTierSpan>
                    <RightBar/>
                </TeamTd>
                <HeaderTh>S2024-3<RightBar/></HeaderTh>
                <HeaderTh>{t("ingame.rankWinRate")}<RightBar/></HeaderTh>
                <HeaderTh>
                    <FlexBox width={'100%'} justifyContent={'center'}>
                        AI-Score
                        <QuestionWrapper>
                            <BigTooltip tooltipText={t('ingame.ingameAiExplain')}>
                                <img src={'/images/list_btn_tip_s_w.svg'} alt={''}/>
                            </BigTooltip>
                        </QuestionWrapper>
                    </FlexBox>
                    <RightBar/></HeaderTh>
                <HeaderTh>{t("ingame.champAnalytics")}<RightBar/></HeaderTh>
                <HeaderTh>S2024-2<RightBar/></HeaderTh>
                <HeaderTh>{t("ingame.tags")}<RightBar/></HeaderTh>
                <HeaderTh></HeaderTh>
            </HeaderTr>
            </thead>
            <tbody>
            {players.map((data, index) => {
                    if (data) {
                        const year2022 = data.summoner_data.summoner_basic_info_dict.previous_season_tier_list.find((season) => season.season === 21);
                        const enemyChampionId = enemyPlayers[index]?.champion_id;
                        const championInfo = {...data.participant_info.season_champion_info_dict, mastery: data.mastery || 0}
                        const rank = rankMemo.findIndex((item) => item.summoner_id === data.summoner_id) + 1;
                        return (
                            <IngamePlayerTableTr
                                rank={rank}
                                puuId={data.summoner_data.summoner_basic_info_dict.puu_id}
                                region={region}
                                teamColor={teamColor}
                                open={open === data.summoner_id}
                                onClickMore={onClickMore}
                                key={data.summoner_data.summoner_basic_info_dict.puu_id + "playerTable"}
                                champion_id={data.champion_id}
                                spellIds={data.spell_id_dict}
                                runeObj={data.rune_detail_dict}
                                summonerName={data.riot_id_name}
                                tag={data.riot_id_tag_line}
                                influData={data.summoner_data.summoner_basic_info_dict.pro_streamer_info_dict}
                                tier2022={year2022}
                                tier2023={data?.summoner_realtime_data?.season_tier_info_dict?.ranked_solo_5x5}
                                leaguePoints={data?.summoner_realtime_data?.season_tier_info_dict?.ranked_solo_5x5?.league_points}
                                championInfo={championInfo}
                                aiScore={data.participant_info.summoner_info_dict.ai_score_avg}
                                summonerInfo={data?.participant_info}
                                summonerId={data.summoner_id}
                                data={data}
                                enemyChampionId={enemyChampionId}
                            />
                        )
                    } else {
                        return null;
                    }
                }
            )}
            </tbody>
        </Table>
    );
};

export default IngamePlayerTable;

