import React, {useMemo} from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import BigTooltip from "../Tooltip/BigTooltipBox";
import {getArgmentImagePath} from "../../function";

const Wrapper = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  border-radius: 50%;
  background-color: #000;
  margin: ${props => props.margin || 0};
`;
const ArgumentImage = ({width, height, imgId, margin}) => {
    const argumentsInfo = useSelector((state) => state?.imageStore.argumentsInfo);

    const imgObj = useMemo(() => {
        try {
            const findAug = argumentsInfo.find((data) => data.id === imgId);
            return findAug;
        } catch (e) {
            return null;
        }
    }, [imgId, argumentsInfo]);


    const calcValue = (string) => {
        const value = string || "";
        if (value.includes('*')) {
            const splited = value.split("*");
            return (Number(splited[0]) * Number(splited[1])).toFixed(0);
        }
        return string;
    }


    const tooltipText = useMemo(() => {
        try {
            if (!imgObj?.tooltip) {
                return "";
            }
            const displayName = imgObj?.name;
            let description = imgObj.desc;
            const match = description.match(/@.*?@(?=.)/g) || [];
            const spellValue = Object.entries(imgObj.dataValues);

            spellValue.forEach((item) => {
                if (match.length > 0) {
                    const currentMatch = match.find((data) => {
                       return  data.includes(String(item[0]))
                    });
                    if (currentMatch) {
                        const removeGol = currentMatch.replaceAll('@', '');
                        const replaceValue = calcValue(removeGol.replace(item[0], item[1]));
                        description = description.replaceAll(removeGol, replaceValue).replaceAll("@", "");

                    }
                }
            });
            const removedAllGol = description.replaceAll(/@.*?@/g,"?");
            return `<span style="color: #46B9AA; font-weight: bold">${displayName}</span>\n<span>${removedAllGol}</span>`

        } catch (e) {
            console.log(e);
            return "Error";
        }
    }, [imgObj]);


    if (!imgObj) return null;

    return (
        <BigTooltip tooltipText={tooltipText} textAlign={'left'}>
            <Wrapper width={width} height={height} margin={margin}>
                <img
                    src={getArgmentImagePath(imgObj)}
                    width={width} height={height} alt={''}/>
            </Wrapper>
        </BigTooltip>
    );
};

export default ArgumentImage;