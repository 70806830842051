import styled, {useTheme} from "styled-components";
import useChampionFunc from "../../../../../../common/hooks/useChampionFunc";
import {useHistory, useLocation} from "react-router-dom";
import React, {useCallback} from "react";
import CRTableRanking
    from "../../../../../Champions/ChampionsContent/ChampionsRank/ChampionsRankingTable/ChampionsRankingTableContent/CRTableRanking";
import CRTChampion
    from "../../../../../Champions/ChampionsContent/ChampionsRank/ChampionsRankingTable/ChampionsRankingTableContent/CRTChampion";
import CRTTier
    from "../../../../../Champions/ChampionsContent/ChampionsRank/ChampionsRankingTable/ChampionsRankingTableContent/CRTTier";
import DamageGraph from "../../../../../summoner/DetailTab/GameAnalytics/GameSummary/Table/TableContent/DamageGraph";
import {threedigits} from "../../../../../../function";

const Wrapper = styled.tr`
  overflow: hidden;
  border-bottom: 1px solid ${props => props.theme.color.default_border_color};

  td:not(:last-of-type) {
    cursor: pointer;
  }
`;

const Content = styled.td`
  vertical-align: middle;
`;

const TempWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const WinPickBanText = styled.span`
  text-align: center;
  font-size: 12px;
  color: ${props => props.theme.color.dark_100};
`;

const PercentText = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.dark_100};
  opacity: 0.6;
`;

const DamageWrapper = styled.div`
  width: 100px;
`;

const ChampWrapper = styled.div`
  width: 171px;
`;

const  URFRankTableContent= ({
                                  version,
                                  rank,
                                  winRate,
                                  pickRate,
                                  tier,
                                  champion,
                                  lane,
                                  rankDelta,
                                  highestDPM,
                                  dpm,
                                  pentaKills
                              }) => {

    const {getUrlNameByChampion, getChampionName} = useChampionFunc();
    const renderName = getChampionName(champion);
    const history = useHistory();
    const location = useLocation();
    const theme = useTheme();

    const navigateToBuild = useCallback(() => {
        const champName = getUrlNameByChampion(champion);
        history.push(`/champions/${champName}/build/urf`)
    }, [champion, lane, location.search]);


    return (
        <Wrapper>
            <Content onClick={navigateToBuild}>
                <CRTableRanking
                    rank={rank}
                    value={rankDelta}
                />
            </Content>
            <Content onClick={navigateToBuild}>
                <ChampWrapper>
                    <CRTChampion
                        disableOnClick={true}
                        lane={lane}
                        imageName={champion?.image_name}
                        paddingLeft={'16px'}
                        championName={renderName}
                        enChampName={champion?.champion_name_en}
                        version={version}
                    />
                </ChampWrapper>
            </Content>
            <Content onClick={navigateToBuild}>
                <CRTTier
                    tier={tier}
                />
            </Content>
            <Content onClick={navigateToBuild}>
                <TempWrapper>
                    <WinPickBanText>{(winRate * 100).toFixed(1)}<PercentText> %</PercentText></WinPickBanText>
                </TempWrapper>
            </Content>
            <Content onClick={navigateToBuild}>
                <TempWrapper>
                    <WinPickBanText>{(pickRate * 100).toFixed(1)}<PercentText> %</PercentText></WinPickBanText>
                </TempWrapper>
            </Content>
            <Content onClick={navigateToBuild}>
                <TempWrapper>
                    <DamageWrapper>
                        <DamageGraph
                            bgColor={theme.color.gray_100}
                            color={theme.color.font_74_EA}
                            barColor={'EF4D8A'}
                            dmg={dpm}
                            total={highestDPM}
                        />
                    </DamageWrapper>
                </TempWrapper>
            </Content>
            <Content onClick={navigateToBuild}>
                <TempWrapper>
                    <WinPickBanText>{threedigits(pentaKills)}</WinPickBanText>
                </TempWrapper>
            </Content>
        </Wrapper>
    );
};


const isEquals = (prev, next) => {
    return prev.champion?.champion_id === next.champion?.champion_id;
}
export default React.memo(URFRankTableContent, isEquals);
