import React, {useCallback, useEffect, useState} from 'react';
import {PurpleBox} from "../../ui/Box";
import styled, {useTheme} from "styled-components";
import {useTranslation} from "react-i18next";

const ImageWrapper = styled.div`
  position: relative;
  margin-right: 4px;
`;


const CopyText = styled.span`
  position: absolute;
  top: -15px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  color: #65cbff;
  transform: translateX(-50%);
  z-index: 23;
`;
const SummonerMasteryShare = () => {
    const [copy, setCopy] = useState(false);
    const theme = useTheme();
    const {t} = useTranslation();
    const onClickShare = useCallback(() => {
        setCopy(true);
        const copyText = `${window.location.href}`;
        navigator.clipboard.writeText(copyText);
    }, []);

    useEffect(() => {
        if (copy) {
            let timer = setTimeout(() => {
                setCopy(false)
            }, 2000);
            return () => {
                clearTimeout(timer)
            }
        }
    }, [copy]);

    return (
        <PurpleBox onClick={onClickShare} padding={0}>
            <ImageWrapper>
                <img
                    width={15}
                    height={15}
                    alt={""}
                    src={`${theme.icon.path}/ico_list_copy_link.svg`}
                />
                {copy && <CopyText className="share_tooltip">{t('multi.clipboard')}</CopyText>}
            </ImageWrapper>
            {t("summoner.mastery.share")}
        </PurpleBox>
    );
};

export default SummonerMasteryShare;