import styled, {useTheme} from "styled-components";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import useQueryVersion from "../../../../common/hooks/useQueryVersion";
import useQueryTier from "../../../../common/hooks/useQueryTier";
import useScrollPagination from "../../../../common/hooks/useScrollPagination";
import React, {useCallback, useMemo} from "react";
import ChampionsHeader from "../../../Champions/ChampionsContent/ChampionsHeader";
import ChampionFilter from "../../../Champions/ChampionsContent/ChampionFilter";
import ChampionsWhiteWrapper from "../../../Champions/ChampionsContent/ChampionsWhiteWrapper";
import URFRankTable from "./urfRankTable";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 5px;
`;


const URFRank = () => {
    const {t} = useTranslation();
    const championRanks = useSelector((state) => state?.champions?.ranks);
    const history = useHistory();
    const [currentVersion, onChangeCurrentVersion] = useQueryVersion();
    const [currentTier, onChangeCurrentTier] = useQueryTier();
    const [pageValue] = useScrollPagination();
    const theme = useTheme();

    const getSelectedRank = useMemo(() => {
        try {
            return championRanks.all;
        } catch (e) {
            return [];
        }
    }, [championRanks])


    const onSelectVersion = useCallback((version) => {
        history.replace(`/urf?version=${version}&tier=${currentTier}`);
        onChangeCurrentVersion(version);
    }, [currentTier]);

    const onSelectTier = useCallback((tier) => {
        history.replace(`/urf?version=${currentVersion}&tier=${tier}`);
        onChangeCurrentTier(tier)
    }, [currentVersion]);


    return (
        <Wrapper>
            <ChampionsHeader
                title={t('championAnalyze.championRanking')}
            >
                <ChampionFilter
                    disableTier={true}
                    borderColor={theme.color.default_border_color}
                    currentVersion={currentVersion}
                    selectVersion={onSelectVersion}
                    currentTier={currentTier}
                    selectTier={onSelectTier}
                />
            </ChampionsHeader>
            <ChampionsWhiteWrapper>
                <URFRankTable
                    pageValue={pageValue}
                    ranks={getSelectedRank}
                />
            </ChampionsWhiteWrapper>
        </Wrapper>
    );
};

export default URFRank;