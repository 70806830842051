import styled, {useTheme} from "styled-components";
import React, {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import RankingTableHeader from "../../../Ranking/RankingContent/RankingTable/RankingTableContent/RankingTableHeader";
import ProfileIcon from "../../../common/ProfileIcon";
import {FlexBox} from "../../../ui/styled";
import InfluInfo from "../../../summoner/Profile/InfluInfo";
import EmblemImage from "../../../EmblemImage";
import {capitalizeLetter, getRegionServer} from "../../../../function";
import {dark, light} from "../../../../common/theme";

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;
  overflow: hidden;
`;

const Table = styled.table`
  width: 100%;

  .light + .light {
    border-top: ${props => {
      let color = light.default_border_color
      let result = `1px solid ${color}`;
      return result;
    }};
  }

  .dark + .dark {
    border-top: ${props => {
      let color = dark.default_border_color
      let result = `1px solid ${color}`;
      return result;
    }};
  }
`;

const ContentTR = styled.tr`
  cursor: pointer;
  overflow: hidden;
  background-color: ${props => props.theme.color.site_main_color};

`;

const Content = styled.td`
  height: 50px;
  vertical-align: middle;

  .imgIcon {
    border-radius: 100%;
  }
`;


const Thead = styled.thead`
  border-bottom: 1px solid ${props => props.theme.color.default_border_color};
`;

const ProfileIconWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SummonerLevelTag = styled.div`
  position: absolute;
  bottom: -8px;
  min-width: 36px;
  min-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #fff;
  background-color: #313131;
  border-radius: 10px;
`;
const TBody = styled.tbody`

  tr {
    &:hover {
      background-color: ${props => props.theme.color.white_200};
    }
  }
`;
const SummonerNameText = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_100};
  line-height: 24px;
`;


const TagText = styled.span`
  font-size: 14px;
  color: ${props => props.theme.color.dark_200};
  font-weight: 500;
  line-height: 20px;
  margin-left: 5px;
`;

const TierText = styled.span`
  line-height: 17px;
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_100};
`;

const LPText = styled.span`
  line-height: 17px;
  font-size: 12px;
  color: ${props => props.theme.color.dark_200};
  font-weight: 500;
  margin-left: 10px;
`;

const EmptyBox = styled.div`
  width: 100%;
  height: 200px;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.site_main_color};
`;
const EmptyContentBox = styled.div`
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const EmptySpan = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: ${props => props.theme.color.dark_200};
`;

const LevelSpan = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.dark_100};
`;
const SummonerNameTitle = styled.span`
  margin-left: 84px;
`;
const SummonerSearchTableBox = ({searchList, region, version}) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const sortedRankingList = useMemo(() => {
        if (Array.isArray(searchList)) {
            // const sort = rankingList.sort((a, b) => a.rank - b.rank);
            return searchList;
        } else {
            return [];
        }
    }, [searchList]);

    const onClickBox = useCallback((platform_id, summonerName, tag) => {
        window.open(`/summoner/${getRegionServer(platform_id)}/${summonerName}-${tag}`, '_blank');
    }, [])


    if (sortedRankingList.length === 0) {
        return (
            <EmptyBox>
                <EmptyContentBox height={'200px'}>
                    <EmptySpan>{t('championAnalyze.searchEmpty')}</EmptySpan>
                </EmptyContentBox>
            </EmptyBox>
        )
    }


    return (
        <Wrapper>
            <Table>
                <colgroup>
                    <col/>
                    <col width={100}/>
                    <col width={250}/>
                    <col width={278}/>
                </colgroup>
                <Thead>
                    <tr>
                        <RankingTableHeader marginLeft={84}>{t('ranking.summoner')}</RankingTableHeader>
                        <RankingTableHeader>{t('ranking.level')}</RankingTableHeader>
                        <RankingTableHeader>{t('ranking.proStreamer')}</RankingTableHeader>
                        <RankingTableHeader last={true}>{t('ranking.tier')}</RankingTableHeader>
                    </tr>
                </Thead>
                <TBody>
                    {sortedRankingList.map((data, index) => {
                        return (
                            <ContentTR key={index}
                                       onClick={() => onClickBox(data.platform_id, data.riot_id_name, data.riot_id_tag_line)} className={theme.color.theme}>
                                <Content>
                                    <FlexBox margin={'0 0 0 20px'}>
                                        <ProfileIconWrapper>
                                            <ProfileIcon width={35} height={35} iconId={data?.profile_id}/>
                                        </ProfileIconWrapper>
                                        <FlexBox margin={'0 0 0 10px'}>
                                            <SummonerNameText>{data?.riot_id_name}</SummonerNameText>
                                            <TagText>#{data?.riot_id_tag_line}</TagText>
                                        </FlexBox>
                                    </FlexBox>
                                </Content>
                                <Content>
                                    <FlexBox justifyContent={'center'}>
                                        <LevelSpan>{data.summoner_level}</LevelSpan>
                                    </FlexBox>
                                </Content>
                                <Content>
                                    <FlexBox margin={'0 0 0 33px'} width={'100%'}>
                                        <InfluInfo
                                            influData={{
                                                url_name: data.url_name,
                                                team: data.pro_team_al,
                                                status: data.status,
                                                name: data.name,
                                            }}
                                        />
                                    </FlexBox>
                                </Content>
                                <Content>
                                    <FlexBox margin={'0 0 0 40px'} width={'100%'}>
                                        <EmblemImage tier={data.tier}/>
                                        <FlexBox margin={'0 0 0 10px'}>
                                            <TierText>{capitalizeLetter(data.tier)}</TierText>
                                            {data.tier !== 'unranked' && <LPText>{data.lp} LP</LPText>}
                                        </FlexBox>
                                    </FlexBox>
                                </Content>
                            </ContentTR>
                        )
                    })}

                </TBody>
            </Table>
        </Wrapper>
    );
};

export default SummonerSearchTableBox;



