import styled from "styled-components";
import {Header} from "../common";
import CenterContentBox from "../Champions/ContentWrapper";
import {ErrorBoundary} from "react-error-boundary";
import React from "react";
import DailyAramContent from "./aramcontent/DailyAramContent";
import {useTranslation} from "react-i18next";
import UseSetCanonical from "../../common/hooks/useSetCanonical";
import useTitleAndDescription from "../../common/hooks/useTitleAndDescription";
import {useParams} from "react-router-dom";

const Wrapper = styled.div`
`;

function Fallback({error, resetErrorBoundary}) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.

    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    );
}

const AramDaily = () => {
    UseSetCanonical();

    const {t} = useTranslation()
    const {platform, queue, tier} = useParams();
    useTitleAndDescription({
        title: t('header.titleAiRanking', {
            server: platform === 'all' ? "" : platform?.toUpperCase()
        }),
        description: t('header.titleAiRankingDescription', {
            server: platform === 'all' ? "" : platform?.toUpperCase()
        })
    })
    return (
        <Wrapper>
            <Header/>
            <CenterContentBox disableSponsor={true} disableAramHeader={true}>
                <ErrorBoundary FallbackComponent={Fallback}>
                    <DailyAramContent/>
                </ErrorBoundary>
            </CenterContentBox>
        </Wrapper>
    );
};

export default AramDaily;