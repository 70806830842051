import React, {useCallback} from 'react';
import styled, {useTheme} from "styled-components";

const ListLI = styled.li`
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
  cursor: pointer;
  position: relative;
  background-color: ${props => {
    let color = props.theme.color.site_main_color;
    if (props.selected) color = props.theme.color.green_200;
    return color;
  }};

  &:hover {
    background-color: ${props => {
      let color = props.theme.color.white_200;
      if (props.selected) color = props.theme.color.green_200;
      return color;
    }};
  }

  border: ${props => {
    let width = '1px';
    if (props.selected) width = '1.5px';
    return width;
  }} solid ${props => {
    let color = props.theme.color.default_border_color;
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};
  padding-left: ${props => {
    let padding = '1px';
    if (props.left) padding = 0;
    if (props.selected) padding = 0;
    return padding
  }};

  border-left: ${props => {
    let width = 0;
    if (props.left) width = '1px';
    if (props.selected) width = '1.5px';
    return width;
  }} solid ${props => {
    let color = props.theme.color.default_border_color;
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};
  border-right: ${props => {
    let width = 0;
    if (props.right) width = '1px';
    if (props.selected) width = '1.5px';
    return width;
  }} solid ${props => {
    let color = props.theme.color.default_border_color;
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};
`;

const ListText = styled.span`
  margin-left: 4px;
  text-align: center;
  font-size: 14px;
  color: ${props => {
    let color = props.theme.color.dark_200;
    if (props.selected) color = props => props.theme.color.green_100;
    return color;
  }};
  font-weight: ${props => {
    let color = 500;
    if (props.selected) color = 'bold';
    return color;
  }};
  line-height: 22px;
`;

const LaneImage = styled.img`
`;

const RankListLI = ({data, index, selected, onClick, last, lane = "Top",selectedIndex,lastIndex}) => {
    const theme = useTheme();
    const onClickList = useCallback(() => {
        if (onClick !== undefined && index !== undefined) {
            onClick(index, lane);
        }
    }, []);


    return (
        <ListLI
            left={(selectedIndex + 1) !== index}
            last={last}
            right={last}
            onClick={onClickList}
            selected={selected}
        >
            <LaneImage
                selected={selected}
                width={16} height={16} src={`images/icon_s_position_${lane.toLowerCase()}.svg`}/>
            <ListText
                selected={selected}>{data}</ListText>
        </ListLI>
    );
};


export default React.memo(RankListLI);