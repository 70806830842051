import React from 'react';
import ChampionImageWithLane from "../../../common/ChampionImageWithLane";
import InfluIcon from "../../../summoner/Profile/InfluInfo/InfluIcon";
import {ColumnBox} from "../../../ui/styled";
import InfluSns from "../../../summoner/Profile/InfluInfo/influSns";
import styled from "styled-components";
import LeagueTeam from "./leagueTeam";

const ChampionInfoBox = styled.div`
  display: flex;
  flex-direction: ${props => {
    let items = 'row-reverse';
    if (props.reverse) items = 'row';
    return items;
  }};
  align-items: center;
  justify-content: flex-start;
  flex: 1;


  .influ-sns-icon {
    margin: ${props => {
      let items = '0 0 0 4px';
      if (props.reverse) items = '0 4px 0 0';
      return items;
    }};
  }
`;
const SummonerText = styled.span`
  font-size: 16px;
  font-weight: bold;
`;






const ChampionBox = styled.div`

  .imgChamp {
    width: 58px;
    height: 58px;
  }

  .imgDiv {
    border-radius: 12px;
  }
`;
const ReverseBox = styled.div`
  display: flex;
  flex-direction: ${props => {
    let items = 'row-reverse';
    if (props.reverse) items = 'row';
    return items;
  }};
  align-items: center;

  &:nth-of-type(2) {
    margin-top: 4px;
    
  }
  .league-box {
    margin: ${props => {
      let items = '0 6px 0 0';
      if (props.reverse) items = '0 0 0 6px';
      return items;
    }};
    flex-direction: ${props => {
      let items = 'row';
      if (props.reverse) items = 'row-reverse';
      return items;
    }}; 
  }

  .influ-sns-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

  }

  .influ-sns-icon {
    img {
      width: 14px;
      height: 14px;
    }
  }
  
`;
const SpectateSummarySummoner = ({reverse, summoner}) => {
    return (
        <ChampionInfoBox reverse={reverse}>
            <ChampionBox>
                <ChampionImageWithLane champion_id={summoner.champion_id} position={summoner.position}
                                       positionLeft={reverse} emptyHelmet={true}/>
            </ChampionBox>
            <ColumnBox alignItems={!reverse ? 'flex-end' : "flex-start"} height={'41px'} margin={'0 12px 0 12px'}>
                <ReverseBox reverse={reverse}>
                    <InfluSns influData={summoner}/>
                </ReverseBox>
                <ReverseBox reverse={reverse}>
                    <InfluIcon status={summoner.status}/>
                    <LeagueTeam team={summoner.team} league={summoner.league} status={summoner.status}
                                otp={summoner.otp_champion_id}/>
                </ReverseBox>
                <ReverseBox reverse={!reverse}>
                    <SummonerText reverse={!reverse}>{summoner.name}</SummonerText>
                </ReverseBox>
            </ColumnBox>
        </ChampionInfoBox>
    );
};

export default SpectateSummarySummoner;