import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 10px;
  border: 1px solid ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.site_main_color};
  border-radius: 6px;
`;


const FlexWrapper = styled(Wrapper)`
  flex: 1;
`;

const HeaderSpan = styled.span`
  text-align: center;
  font-size: 12px;
  color: ${props => props.theme.color.dark_100};
  font-weight: 500;
`;

const ChildContent = styled.div`
  display: flex;
  align-items: ${props => {
    let items = 'flex-start';
    if (props.alignItems) items = props.alignItems;
    return items;
  }};
  justify-content: center;
  height: 100%;
`;

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`;

const OverviewHeader = (props) => {
    const {title, alignItems, flex} = props;


    if (flex) {
        return (
            <FlexWrapper>
                <HeaderDiv>
                    <HeaderSpan>
                        {title}
                    </HeaderSpan>
                </HeaderDiv>
                <ChildContent alignItems={alignItems}>
                    {props.children}
                </ChildContent>
            </FlexWrapper>
        )
    }


    return (
        <Wrapper>
            <HeaderDiv>
                <HeaderSpan>
                    {title}
                </HeaderSpan>
            </HeaderDiv>
            <ChildContent alignItems={alignItems}>
                {props.children}
            </ChildContent>
        </Wrapper>
    );
};

export default OverviewHeader;
