import React, {useCallback} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import ErrorBox from "../index";
import styled from "styled-components";
import {_NOTICE} from "../../../../store/modules/notice";

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  top: 120px;
  z-index: 9999;
`;

const ErrorWrapper = styled.div`
  flex: 0.5;
  display: flex;

  .errorStyle {
    width: 100%;
    position: relative;
    padding-block: 12px;
    border-radius: 22px;
    align-items: center;
  }
`;

const ImageButton = styled.div`
  position: absolute;
  cursor: pointer;
  right: 30px;
  margin: 0;
  line-height: 0;
  z-index: 99;
`;

const CloseImage = styled.img`
`;

const ServerStatusErrorbox = () => {
    const {t} = useTranslation();
    const notice = useSelector((state) => state.notice.notice);
    const dispatch = useDispatch();


    const onClick = useCallback(() => {
        dispatch({
            type: _NOTICE,
            data: 'normal',
        })
    }, [])


    if (notice === 'alertError') {
        return (
            <Wrapper>
                <ErrorWrapper>
                    <ErrorBox
                        infoText={t('notice.alertError')}
                    >
                        <ImageButton onClick={onClick}>
                            <CloseImage
                                src={'/images/icon_input_del_r.svg'}
                                width={16}
                                height={16}
                                alt={'X'}
                            />
                        </ImageButton>
                    </ErrorBox>
                </ErrorWrapper>
            </Wrapper>
        );
    } else {
        return null;
    }
};

export default ServerStatusErrorbox;
