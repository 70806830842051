import React, {useMemo} from 'react';
import {cho_convertor} from "../../function";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const doubleExp = new RegExp(`^([ㄱㄷㅅㅂㅈ])+`);

const useChoNameVisible = ({choName, search, list}) => {
    const {i18n} = useTranslation();
    const isKor = useMemo(() => ['ko-KR', 'ko'].includes(i18n.language), [i18n.language]);
    const isJp = useMemo(() => ['ja'].includes(i18n.language),[i18n.language]);
    const championData = useSelector((state) => state?.imageStore.champions);


    const championDataFilter = (logic) => {
        return championData.filter((filterData) => {
            return logic(filterData);
        });
    }

    const korExp = (regexp, value) => {
        if (doubleExp.test(value)) {
            const replacedRegexp = replaceExp(value);
            return championDataFilter((data) => regexp.test(data.cho_name) || replacedRegexp.test(data.cho_name));
        } else {
            return championDataFilter((data) => regexp.test(data.cho_name));
        }
    }


    const jpExp = (value) => {
        if (value === 'ワヲン行') {
            const jpNameExr = new RegExp(`^[ア|カ|サ|タ|ナ|ハ|マ|ヤ|ラ]+`)
            return championData.filter((filterData) => {
                return !jpNameExr.test(filterData.champion_name);
            });
        }
        const jpRegexp = new RegExp(`^${value[0]}`);
        return championData.filter((filterData) => {
            return jpRegexp.test(filterData.champion_name);
        });
    }

    const replaceExp = (value) => {
        let replacedValue = value.replaceAll('ㄱ', "ㄲ");
        replacedValue = replacedValue.replaceAll("ㄷ", "ㄸ");
        replacedValue = replacedValue.replaceAll("ㅅ", "ㅆ");
        replacedValue = replacedValue.replaceAll("ㅂ", "ㅃ");
        replacedValue = replacedValue.replaceAll("ㅈ", "ㅉ");
        return RegExp(`^${replacedValue}+`)
    };


    const searchReg = (data) => {

        const regexp = new RegExp(`^${cho_convertor(search.toLowerCase())}+`);
        if (regexp.test(data.champion_name_en.toLowerCase())) {
            return true;
        } else if (regexp.test(data.champion_name_kr)) {
            return true;
        } else if (regexp.test(data.cho_name.replace(" ", ""))) {
            return true;
        } else if (regexp.test(data.cho_name)) {
            return true;
        } else {
            const replacedRegexp = replaceExp(search);
            return replacedRegexp.test(data.cho_name);
        }
    }


    const choNameList = useMemo(() => {
        if (typeof search === 'string' && search.length > 0) {
            return championDataFilter((data) => searchReg(data));
        }
        if (choName === undefined) {
            return [];
        }
        if (Boolean(choName) === false) {
            return championData
        }

        if(isJp) {
            return jpExp(choName)
        }


        const regexp = new RegExp(`^${choName}+`);
        if (isKor) {
            return korExp(regexp, choName)
        } else {
            return championDataFilter((data) => regexp.test(data.champion_name_en));
        }
    }, [championData, choName, isKor, search]);


    const findChampion = (list, champion_id) => {
        return list.find((item) => Number(champion_id) === Number(item.champion_id));
    }


    const combineListAndChoList = useMemo(() => {
        if (Array.isArray(list) && list.length > 0) {
            return list.reduce((sum, cur) => {
                const currentItem = findChampion(choNameList, cur.champion_id);
                if (currentItem) {
                    sum.push({
                        ...cur,
                        ...currentItem
                    })
                }
                return sum;
            }, []);
        }
        return [];

    }, [choNameList, list]);


    return combineListAndChoList;
};

export default useChoNameVisible;
