import React from 'react';
import CenterBox from "../../common/CenterBox";
import {useSelector} from "react-redux";
import MasterySearch from "./MasterySearch";
import MasteryRank from "./MasteryRank";
import styled from "styled-components";
import {ColumnBox, FlexBox} from "../../ui/styled";
import {useTranslation} from "react-i18next";
import useDispatchMastery from "../../../common/hooks/useDispatchMastery";
import {GET_MASTERY_RANKING_REQUEST} from "../../../store/reducer/champions";
import SponsorAD from "../../../ad/Tapin";

const HeaderText = styled.span`
  font-size: 26px;
  font-weight: bold;
  margin: 12px 0;
`;
const HeaderInfoText = styled.span`
  font-size: 14px;
  color: ${props => props.theme.color.dark_200};
`;
const MasteryContent = () => {
    useDispatchMastery(GET_MASTERY_RANKING_REQUEST)

    const {t} = useTranslation()
    const currentLang = useSelector((state) => state.translation.lang);
    return (
        <CenterBox >
            <ColumnBox width={'100%'}>
                <HeaderText>
                    {t('header.navMastery')}
                </HeaderText>
                <HeaderInfoText>
                    {t('mastery.headerInfo')}
                </HeaderInfoText>
                <SponsorAD/>
                <FlexBox alignItems={'flex-start'} width={'100%'}>
                    <MasterySearch key={currentLang + 'mastery'}/>
                    <MasteryRank />
                </FlexBox>
            </ColumnBox>
        </CenterBox>
    );
};

export default MasteryContent;