import React, {useCallback} from 'react';
import styled from "styled-components";
import UpArrow from "../TagWithArrow/UpArrow";
import DownArrow from "../TagWithArrow/DownArrow";
import {useTranslation} from "react-i18next";


const PatchWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
`;


const BuffNerfText = styled.span`
  margin-left: 4px;
  font-size: 12px;
`;

const PatchBlock = ({status,hotfix,onClick}) => {
    const {t} = useTranslation();

    const BuffOrNerf = useCallback(({status, hotfix}) => {
        if (hotfix) {
            return (
                <>
                    <img src={'./images/icon_hotfix.svg'} width={12} height={12} alt={''}/>
                    <BuffNerfText>{t('championsHistory.hotfix')}</BuffNerfText>
                </>
            )
        }
        switch (status) {
            case 'buff' : {
                return (
                    <>
                        <UpArrow size={4}/>
                        <BuffNerfText>{t('championsHistory.buff')}</BuffNerfText>
                    </>
                )
            }
            case 'nerf' : {
                return (
                    <>
                        <DownArrow size={4}/>
                        <BuffNerfText>{t('championsHistory.nerf')}</BuffNerfText>
                    </>
                )
            }
            case 'fix': {
                return (
                    <>
                        <img src={'./images/icon_adj.svg'} width={12} height={12} alt={''}/>
                        <BuffNerfText>{t('championsHistory.adj')}</BuffNerfText>
                    </>
                )
            }
            case "hot_fix" : {
                return (
                    <>
                        <img src={'./images/icon_hotfix.svg'} width={12} height={12} alt={''}/>
                        <BuffNerfText>{t('championsHistory.hotfix')}</BuffNerfText>
                    </>
                )
            }
            default:
                return null;
        }

    }, []);



    return (
                <PatchWrapper onClick={onClick}>
                    <BuffOrNerf
                        status={status}
                        hotfix={hotfix}
                    />
                </PatchWrapper>
    );
};

export default PatchBlock;
