import styled, {useTheme} from "styled-components";
import {useTranslation} from "react-i18next";
import React, {useMemo} from "react";
import {changeToIntlRegion} from "../../../../function";
import {ColumnBox} from "../../../ui/styled";
import DownArrow from "../../../common/TagWithArrow/DownArrow";

const progressTierColor = (tier, color) => {

    switch (tier) {
        case "iron":
            return color.tier_cut_iron
        case "bronze":
            return color.tier_cut_bronze
        case "silver":
            return color.tier_cut_silver
        case "gold":
            return color.tier_cut_gold
        case "platinum":
            return color.tier_cut_platinum
        case "emerald":
            return color.tier_cut_emerald
        case "diamond":
            return color.tier_cut_diamond
        case "master":
            return color.tier_cut_master
        case "grandmaster":
            return color.tier_cut_grandmaster
        case "challenger":
            return color.tier_cut_challenger
        case "1st":
            return color.tier_cut_1st
        default:
            return color.tier_cut_iron
    }
}

const progressTierTextColor = (tier, color) => {
    switch (tier) {
        case "iron":
            return color.tier_cut_text_iron
        case "bronze":
            return color.tier_cut_text_bronze
        case "silver":
            return color.tier_cut_text_silver
        case "gold":
            return color.tier_cut_text_gold
        case "platinum":
            return color.tier_cut_text_platinum
        case "emerald":
            return color.tier_cut_text_emerald
        case "diamond":
            return color.tier_cut_text_diamond
        case "master":
            return color.tier_cut_text_master
        case "grandmaster":
            return color.tier_cut_text_grandmaster
        case "challenger":
            return color.tier_cut_text_challenger
        case "1st":
            return color.tier_cut_text_1st
        default:
            return color.tier_cut_text_iron
    }
}
const progressFillbarColor = (tier, color) => {
    switch (tier) {
        case "iron":
            return color.tier_cut_iron
        case "bronze":
            return color.tier_cut_bronze
        case "silver":
            return color.tier_cut_silver
        case "gold":
            return color.tier_cut_gold
        case "platinum":
            return color.tier_cut_platinum
        case "emerald":
            return color.tier_cut_emerald
        case "diamond":
            return color.tier_cut_diamond
        case "master":
            return "linear-gradient(90deg, #8D41A9 0%, #C23449 100%)"
        case "grandmaster":
            return "linear-gradient(90deg, #C23449 0%, #FD8A12 100%) "
        case "challenger":
            return "linear-gradient(90deg, #EE7A00 0%, #FFD941 100%)"
        default:
            return color.tier_cut_iron
    }
}

const progressEndBar = (tier, color) => {
    switch (tier) {
        case "master":
            return color.tier_cut_bar_master
        case "grandmaster":
            return color.tier_cut_bar_grandmaster
        case "challenger":
            return color.tier_cut_bar_challenger
    }
}
const ProgressWrapper = styled.div`
  width: 100%;
  padding: 0 13px;
  margin-bottom: 14px;
`;
const ProgressRelative = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
const ProgressBar = styled.div`
  padding: 0 13px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  width: 100%;
  height: 8px;
  border-radius: 10px;
  background-color: ${props => props.theme.color.gray_100};
`;

const ProgressCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: ${props => {
    if (props.selected) {
      return progressTierColor(props.tier, props.theme.color);
    }
    return "#fff"
  }};
  border: ${props => {
    if (!props.selected) {
      if (props.tier === '1st') {
        return `2px solid transparent`;
      }
      return `2px solid`
    }
    return 0;
  }};
  ${props => {

    if (props.tier === '1st' && !props.selected) {
      return `
  background-image: linear-gradient(#fff, #fff),${progressTierColor(props.tier, props.theme.color)};
  background-origin: border-box;
  background-clip: content-box, border-box;
      `
    } else {
      return `border-color: ${progressTierColor(props.tier, props.theme.color)};`;
    }
  }}
  z-index: 4;
`;
const ProgressText = styled.div`
  min-width: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: ${props => {
    const tier = props.tier;
    return progressTierTextColor(tier, props.theme.color);
  }};
  font-weight: ${props => {
    if (props.bold) {
      return "bold";
    }
    return 'normal';
  }};
`;
const EmptyBox = styled.div`
  height: 16px;
  width: 15px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 3px;
`;

const BottomBox = styled.div`
  position: absolute;
  height: 16px;
  width: 15px;
  bottom: -14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AbsoluteBox = styled.div`
  position: relative;
`;

const AbsoluteArrowBox = styled.div`
  top: -13px;
  right: -4px;
  position: absolute;
`;

const AbsoluteLPBox = styled.div`
  width: 50px;
  text-align: center;
  position: absolute;
  top: -20px;
  right: ${props => {
    return '-25px';
  }};
`;
const AbsoluteLPText = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: ${props => {
    const tier = props.tier;
    return progressTierTextColor(tier, props.theme.color);
  }};
`;

const TierFillBar = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 5px;
  background-color: ${props => {
    return progressTierColor(props.tier, props.theme.color);
  }};
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  width: ${props => {
    if (typeof props.tierIndex !== "undefined" && typeof props.tierIndex === "number") {
      let tierIndexPercent = 0;
      const extra = props.extra || 0;
      if (props.tierIndex === 1 && extra === 0) {
        return '23.7%';
      }
      if (props.tierIndex === 2 && extra === 0) {
        return '50%';
      }
      if (props.tierIndex === 3 && extra === 0) {
        return '76.2%';
      }

      if (props.tierIndex === 0) {
        tierIndexPercent = 0;
      }
      if (props.tierIndex === 1) {
        tierIndexPercent = 27;
      }
      if (props.tierIndex === 2) {
        tierIndexPercent = 53;
      }
      if (props.tierIndex === 3) {
        tierIndexPercent = 79;
      }
      if (props.tierIndex === 4) {
        return '100%';
      }
      tierIndexPercent = tierIndexPercent + props.extra
      return tierIndexPercent + '%';
    }
    return '100%'
  }};
`;
const MasterFillBar = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 5px;
  background: ${props => {
    if (props.isFirst) {
      return 'linear-gradient(90deg, #EE7A00 0%, #FFC6F3 13%, #E599FF 33%, #97CEFF 47%, #7FDFFD 61%, #9DFFDC 75%, #CAFFAA 89%, #E8FF8A 100%)'
    } else {
      return progressFillbarColor(props.tier, props.theme.color);
    }
  }};
  width: ${props => props.width}%;
`;

const MasterFillBarEnd = styled.div`
  position: absolute;
  right: 0;
  width: 2px;
  height: 8px;
  background-color: ${props => progressEndBar(props.tier, props.theme.color)};
`;
const BackLight = styled.div`
  position: absolute;
  width: 17px;
  height: 17px;
  filter: blur(2px);
  border-radius: 50%;
  background: ${props => progressTierColor(props.tier, props.theme.color)};
  z-index: 3;
`;
const ReletiveBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const ReletiveEnd = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const TierBar = styled.div`
  height: 10px;
  width: 1px;
  opacity: 0.3;
  background-color: ${props => progressTierTextColor(props.tier, props.theme.color)};
  margin: 0 4px;
`;
const ProgressLPText = styled.span`
  font-weight: 400;
`;
const SummonerMatchesProgress = ({division, currentLp, tier, tierCut}) => {
    const {i18n} = useTranslation();
    const theme = useTheme();
    const topLp = tierCut.challenger_1st;
    const challengerCut = tierCut.challenger_cut_off;
    const grandCut = tierCut.grandmaster_cut_off;

    const tierList = {
        iron: ['I4', 'I3', 'I2', 'I1', 'B4'],
        bronze: ['B4', 'B3', 'B2', 'B1', 'S4'],
        silver: ['S4', 'S3', 'S2', 'S1', 'G1'],
        gold: ['G4', 'G3', 'G2', 'G1', 'P4'],
        platinum: ['P4', 'P3', 'P2', 'P1', 'E4'],
        emerald: ['E4', 'E3', 'E2', 'E1', 'D4'],
        diamond: ['D4', 'D3', 'D2', 'D1', 'M'],
        master: ['M', 'GM'],
        grandmaster: ['GM', 'C'],
        challenger: ['C', '1st'],
        error: [],
    }

    const currentTier = useMemo(() => {
        try {
            if (Object.keys(tierList).includes(tier.toLowerCase())) {
                return tier.toLowerCase();
            }
            return 'error'
        } catch (e) {
            return 'error'
        }
    }, [tier]);
    const nextTier = useMemo(() => {
        try {
            switch (currentTier) {
                case 'iron':
                    return 'bronze'
                case 'bronze':
                    return 'silver'
                case 'silver':
                    return 'gold'
                case 'gold':
                    return 'platinum'
                case 'platinum':
                    return 'emerald'
                case 'emerald':
                    return 'diamond'
                case 'diamond':
                    return 'master'
                case 'master':
                    return 'grandmaster'
                case 'grandmaster':
                    return 'challenger'
                case 'challenger':
                    return '1st'
                default:
                    return 'error';
            }
        } catch (e) {
            return 'error'
        }
    }, [currentTier]);

    const shortTier = useMemo(() => {
        if (currentTier === 'master') {
            return 'M'
        } else if (currentTier === 'grandmaster') {
            return 'GM'
        } else if (currentTier === 'challenger') {
            return 'C'
        }
        return `${currentTier[0].toUpperCase()}${division}`;
    }, [currentTier, division])

    const tierIndex = tierList[currentTier].findIndex((data) => data === shortTier);
    const isMaster = (tier) => {
        if (tier === 'master') {
            return true
        }
        if (tier === 'grandmaster') {
            return true
        }
        if (tier === 'challenger') {
            return true
        }
        return false;
    }

    const getCut = (tier) => {
        if (tier === 'master') {
            return 0
        }
        if (tier === 'grandmaster') {
            return grandCut
        }
        if (tier === 'challenger') {
            return challengerCut
        }
        if (tier === '1st') {
            return topLp
        }
        return 500;
    }

    const addRegularTierSpace = (lp) => {
        const divided = lp / 100;
        return (lp - Math.trunc(divided) * 100) / 5;
    }


    const fillValue = ((currentLp - getCut(currentTier)) / (getCut(nextTier) - getCut(currentTier))) * 100;
    const tierFillValue = addRegularTierSpace(currentLp);

    const getFirstName = (data) => {
        if (data === '1st') {
            return changeToIntlRegion(i18n.language) === 'ko' ? '1위' : "1st";
        }
        return data;
    }

    if (tierList[currentTier].length === 0) return null;
    return (
        <ProgressWrapper>
            <ProgressRelative>
                {tierList[currentTier].map((data, index) => {
                    if (isMaster(currentTier)) {
                        if (tierList[currentTier].length - 1 === index) {
                            return (
                                <ColumnBox justifyContent={'center'} alignItems={'flex-end'} key={index}>
                                    <EmptyBox/>
                                    <ReletiveEnd>
                                        <ProgressCircle selected={getCut(nextTier) <= currentLp} tier={nextTier}>
                                            {getCut(nextTier) <= currentLp &&
                                                <img src={'./images/ico_check.svg'} alt={""} width={9} height={7}/>}
                                        </ProgressCircle>
                                        <BackLight tier={nextTier}/>
                                    </ReletiveEnd>
                                    <ProgressText bold={getCut(nextTier) <= currentLp}
                                                  tier={nextTier}>{getFirstName(data)}<TierBar
                                        tier={nextTier}/>{getCut(nextTier)} LP</ProgressText>
                                </ColumnBox>
                            )
                        }
                        return (
                            <ColumnBox justifyContent={'center'} key={index}>
                                <EmptyBox/>
                                <ProgressCircle selected={index <= tierIndex} tier={currentTier}>
                                    <img src={'./images/ico_check.svg'} alt={""} width={9} height={7}/>
                                </ProgressCircle>
                                <ProgressText bold={tierIndex <= index}
                                              tier={currentTier}>{data} <TierBar
                                    tier={currentTier}/>
                                    <ProgressLPText>{getCut(currentTier)} LP</ProgressLPText></ProgressText>
                            </ColumnBox>
                        )

                    }
                    if (tierList[currentTier].length - 1 === index) {
                        return (
                            <ColumnBox justifyContent={'center'} key={index}>
                                <EmptyBox/>
                                <ReletiveBox>
                                    <ProgressCircle selected={index === tierIndex} tier={nextTier}/>
                                    <BackLight tier={nextTier}/>
                                </ReletiveBox>
                                <ProgressText bold={tierIndex === index} tier={nextTier}>{data}</ProgressText>
                            </ColumnBox>
                        )
                    }
                    return (
                        <ColumnBox justifyContent={'center'} key={index}>
                            <EmptyBox/>
                            <ProgressCircle key={data} selected={index <= tierIndex} tier={currentTier}>
                                {index <= tierIndex &&
                                    <img src={'./images/ico_check.svg'} alt={""} width={9} height={7}/>}
                            </ProgressCircle>
                            <ProgressText bold={tierIndex === index} tier={currentTier}>{data}</ProgressText>
                        </ColumnBox>
                    )
                })}
                <ProgressBar>
                    {isMaster(currentTier) ?
                        <MasterFillBar width={fillValue} tier={currentTier}
                                       isFirst={currentTier === 'challenger' && currentLp === topLp}>
                            {fillValue < 100 && <MasterFillBarEnd tier={currentTier}/>}
                            <AbsoluteLPBox fill={fillValue}>
                                {fillValue < 100 && <AbsoluteLPText tier={currentTier}>{currentLp} LP</AbsoluteLPText>}
                            </AbsoluteLPBox>
                        </MasterFillBar>
                        : <TierFillBar tierIndex={tierIndex} tier={currentTier} extra={tierFillValue}>
                            <AbsoluteArrowBox>
                                <DownArrow size={4} tick={2.5}
                                           color={progressTierColor(currentTier, theme.color)}/>
                            </AbsoluteArrowBox>
                        </TierFillBar>}
                </ProgressBar>
            </ProgressRelative>
        </ProgressWrapper>
    )
}

export default SummonerMatchesProgress