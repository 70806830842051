import React from 'react';
import styled from "styled-components";

const Arrow = styled.span`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 6px;
  height: 12px;
  margin-left: 7px;

  &::before {
    content: '';
    display: inline-block;
    flex: 1;
    opacity: ${props => {
    let opacity = "0.4";
    if(props.selected) {
        if (props.arrow) opacity = "1";
    }
    return opacity
}};
    background: url('/images/ico_graph_arrow_up.svg') no-repeat center top;
  }

  &::after {
    content: '';
    height: 8px;
    display: inline-block;
    flex: 1;
    opacity: ${props => {
    let opacity = "0.4";
    if(props.selected) {
        if (!props.arrow) opacity = "1";
    }
    return opacity
}};
    background: url('/images/ico_graph_arrow_down.svg') no-repeat center bottom;
  }`;
const FilterArrow = ({arrow, selected}) => {
    return (
        <Arrow arrow={arrow} selected={selected}/>
    );
};

export default FilterArrow;