import {useSelector} from "react-redux";
import useSortArray from "../../../../../common/hooks/useSortArray";
import React, {useMemo} from "react";
import InternalServerError from "../../../../common/InternalError";
import styled from "styled-components";
import MasteryRankTableContent from "./MasteryRankTableContent";
import MasteryRankTableHeader from "./MasteryRankTableHeader";
import MasteryRankTableModel from "../../../../../Model/MasteryRankTableModel";
import {capitalizeLetter} from "../../../../../function";
import useQuery from "../../../../../common/hooks/useQuery";


const Wrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TBody = styled.tbody`

`;


const MasteryRankTable = ({ranks = [], pageValue = 99, regionList}) => {
    const initSort = [{
        value: 'rank', sortType: false, reverseSort: true,
    }];

    const initSortList = {
        rank: {
            value: 'rank', sortType: false, reverseSort: true,
        },
        tier: {
            value: 'tier', sortType: true, reverseSort: true,
        }, win_rate: {
            value: 'win_rate', sortType: true,
        }, play_rate: {
            value: 'play_rate', sortType: true,
        }, games: {
            value: 'games', sortType: true,
        }, riot_id_name: {
            value: 'riot_id_name', sortType: true, reverseSort: true,
        }, lp: {
            value: "lp", sortType: false,
        }
    }

    useSelector((state) => state.gameVersion.version);
    const query = useQuery();
    const queryRegion = query.get('region')
    const {sort, sortList, onClickSort} = useSortArray({
        initSort: initSort, initSortList: initSortList,
    });


    const filterRegion = useMemo(() => {
        try {
            const validateLocation = regionList.find((data) => data.toLowerCase() === queryRegion.toLowerCase());
            if (!validateLocation) {
                return 'all'
            }
            return validateLocation?.toLowerCase();

        } catch (e) {
            return 'all'
        }
    }, [queryRegion]);


    const model = useMemo(() => new MasteryRankTableModel(ranks, sort), [sort, ranks]);


    if (ranks.length === 0) return (<InternalServerError errorText={"There are no data"}/>);

    return (
        <Wrapper>
            <colgroup>
                <col width={'6%'}/>
                <col width={'7%'}/>
                <col/>
                <col width={'25%'}/>
                <col width={'15%'}/>
                <col width={'17%'}/>
                <col width={'15%'}/>
            </colgroup>
            <thead>
            <MasteryRankTableHeader
                currentSort={sort}
                onClickSort={onClickSort}
                sortData={sortList}
            />
            </thead>
            <TBody>
                {Array.isArray(model.sortedList) && model.sortedList.slice(0, pageValue).map((data, index) => {
                    return (
                        <MasteryRankTableContent
                            tag={data.riot_id_tag_line}
                            key={data.rank + data?.riot_id_name + data?.riot_id_tag_line + data.platform_id}
                            rank={data.rank}
                            champion_id={data.champion_id}
                            region={data.platform_id}
                            division={data.division}
                            games={data.games}
                            lp={data.lp}
                            play_rate={data.play_rate}
                            riot_id_name={data.riot_id_name}
                            tier={data.tier}
                            win_rate={data.win_rate}
                            filterRegion={filterRegion}
                            summoner_profile_id={data.summoner_profile_id}
                            influData={{
                                status: capitalizeLetter(data?.pro_streamer),
                                name: data.name,
                                url_name: data.url_name,
                            }}
                        />)
                })}
            </TBody>
        </Wrapper>);
};


export default MasteryRankTable;

