import React, {useCallback, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {ImageItem} from "../../../common";
import ChampionSkillImage from "../../../common/ChampionSkillImage";
import {getMaxCount, scoreColor, setLaneIndex, sortChampData} from "../../../../function";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import useGetChampionSpells from "../../../../common/hooks/useGetChampionSpells";
import useChampionFunc from "../../../../common/hooks/useChampionFunc";
import styled from "styled-components";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import ChampionImageWithLane from "../../../common/ChampionImageWithLane";
import SummonerBuildRune from "../../SummonerBuildRune";
import useItemFunc from "../../../../common/hooks/useItemFunc";


const ChampImageWrapper = styled.div`
  height: 57px;
  width: 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .imgChamp {
    width: 42px !important;
    height: 42px !important;

  }
`;

const Bar = styled.div`
  position: absolute;
  top: 15px;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 18px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 400;
  color: #f6f6f6;
  border-radius: 0 100px 100px 0;
  background-color: ${(props) => {
    let color = props.theme.color.red_100;
    if (props.isWin) color = props.theme.color.blue_100;
    return color;
  }};
`;

const BarReverse = styled(Bar)`
  border-radius: 100px 0 0 100px;
  justify-content: flex-end;
`;

const CustomTabList = styled(TabList)`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 4px;
`;

const ScoreSpan = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  font-weight: 700;
  font-size: 14px;
`;

const CustomTabs = styled(Tabs)`
  background-color: ${props => props.theme.color.site_main_color};

  .react-tabs__tab-panel {
    background-color: ${props => props.theme.color.site_main_color}
  }
`;

const TeamBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  flex: 1;
  height: 57px;

  & + & {
    margin-left: 54px;
    justify-content: flex-start;
  }

  .react-tabs__tab {
    z-index: 9;
    margin: 0 9px;

    &--selected {
      .imgDiv {
        border: 3px solid #F3A634;
      }
    }
  }

  .imgChamp {
    width: 42px;
    height: 42px;
  }

  .imgDiv {
    border-radius: 4px;
  }

  list-style-type: none;
`;
const BuildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.site_main_color};
  overflow: hidden;

  & + & {
    margin-top: 10px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background-color: ${props => props.theme.color.white_201};
  border-top: 1px solid ${props => props.theme.color.default_border_color};

  .builds {
    width: 100%;
    padding: 20px;

    h4 {
      padding: 6px 10px;
      font-size: 12px;
      font-weight: bold;
      border-bottom: 1px solid ${props => props.theme.color.default_border_color};
    }

    .build {
      display: flex;
      background-color: ${props => props.theme.color.site_main_color};
      padding: 10px 0;

      

      &.skills {
        padding: 8px 30px;
        justify-content: space-between;
        align-items: center;

        .skills_m_seq {
          width: 180px;
          position: relative;
          display: flex;

          &::after {
            content: '';
            position: absolute;
            top: 11px;
            left: 0;
            display: block;
            width: 100%;
            height: 16px;
            background: ${props => props.theme.color.gray_200};
          }

          .skills {
            position: relative;
            z-index: 10;

            &::after {
              content: '';
              position: absolute;
              top: 15px;
              right: -24px;
              width: 9px;
              height: 8px;
              background: url('/images/icn_expand_arrow_m.svg') no-repeat center;
              background-size: cover;
            }

            &:last-of-type::after {
              display: none;
            }
          }

          .skills + .skills {
            margin-left: 36px;
          }

          .texts {
            display: block;
            margin-top: 2px;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            color: ${props => props.theme.color.dark_100};
          }
        }

        .skills_seq {
          display: flex;


          .qwer {
            display: flex;
            flex-direction: column;

            span:first-of-type {
              border-top-left-radius: 6px;
            }

            span:last-of-type {
              border-bottom-left-radius: 6px;
            }

            span {
              font-weight: bold;
              background-color: ${props => props.theme.color.default_border_color};
              color: ${props => props.theme.color.dark_200};

              & + span {
                margin-top: 1px;
              }

              &.plus {
                font-size: 18px;
              }
            }
          }

          .skills {
            display: flex;
            color: #fff;

            & + .skills {
              margin-top: 1px;
            }

            span {
              margin-left: 1px;
            }

            &.q {
              .selected {
                background: transparent linear-gradient(180deg, #f26884 0%, #ac3e79 100%);
              }

              span:last-of-type {
                border-top-right-radius: 6px;
              }

            }

            &.w {
              .selected {
                background: transparent linear-gradient(180deg, #6a70cc 0%, #8630a4 100%);
              }
            }

            &.e {
              .selected {
                background: transparent linear-gradient(180deg, #5eb0db 0%, #415ca4 100%);
              }
            }

            &.r {
              .selected {
                background: transparent linear-gradient(180deg, #f8cb38 0%, #b95c2c 100%);
              }
            }

            &.plus {
              .selected {
                background: transparent linear-gradient(180deg, #56c58e 0%, #376a74 100%);
              }

              span:last-of-type {
                border-bottom-right-radius: 6px;
              }
            }
          }

          span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 22px;
            height: 24px;
            text-align: center;
            background-color: ${props => props.theme.color.white_201};
            font-size: 14px;
            font-weight: 500;
            color: ${props => props.theme.color.dark_100_white};
          }
        }
      }

      &.runes {
        justify-content: center;

        > div {
          margin: 5px 8px;
          padding: 7px;
          background-color: ${props => props.theme.color.site_main_color};
          text-align: center;
        }

        .runes_title {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 12px 0 24px;

          span {
            display: inline-block;
            margin-left: 15px;
            font-size: 12px;
          }
        }


      }
    }

    .imgItem {
      width: 28px;
      border-radius: 5px;
    }

    .imgSkill {
      width: 36px;
    }

    .imgRune {
      width: 32px;
    }
  }
}`;


const ItemWrapper = styled.div`
  display: flex;
  &.items {
    align-items: center;
    flex-wrap: wrap;

    .final {
      .imgItem {
        border: 2px solid ${props => props.theme.color.green_100};
      }
    };

    .items_per_min {
      flex: 0 1 auto;
      position: relative;
      padding: 10px 15px;

      &::after {
        content: '';
        position: absolute;
        top: 18px;
        left: 0;
        display: block;
        width: 200%;
        height: 16px;
        background: ${props => props.theme.color.gray_200};
      };

      &:last-of-type {
        .items::after {
          display: none;
        }

        &::after {
          width: 100%;
        }
      }

      .items {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;

        &::after {
          content: '';
          position: absolute;
          top: 11px;
          right: -20px;
          width: 8px;
          height: 8px;
          background: url('/images/icn_expand_arrow_m.svg') no-repeat center;
          background-size: cover;
        }

        > span {
          position: relative;
          display: block;

          &.ITEM_SOLD {
            &::after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              display: block;
              width: 100%;
              height: 100%;
              background: rgba(255, 255, 255, 0.3) url('/images/icon_s_close.svg') no-repeat center;
            }
          }
        }
      }

      .times {
        margin-top: 5px;
        display: block;
        font-size: 10px;
        font-weight: 500;
        text-align: center;
      }
    }
  }
`;

const DetailBuild = ({match, customIndex, resetIndex, onChangeBuildIndex}) => {
    const {t} = useTranslation();
    const sortLane = [{
        value: 'laneIndex',
        sortType: false,
    }];

    const redTeam = useMemo(() => {
        try {
            const filter = match.filter((data) => data.side !== 'BLUE');
            const hasLaneIndexMap = filter.map((data) => {
                return {...data, laneIndex: setLaneIndex(data.position)}
            })
            return sortChampData(hasLaneIndexMap, sortLane);
        } catch (e) {
            return [];
        }
    }, [match]);

    const blueTeam = useMemo(() => {
        try {
            const filter = match.filter((data) => data.side === 'BLUE');
            const hasLaneIndexMap = filter.map((data) => {
                return {...data, laneIndex: setLaneIndex(data.position)}
            })
            return sortChampData(hasLaneIndexMap, sortLane);
        } catch (e) {
            return [];
        }
    }, [match]);



    useEffect(() => {
        return () => {
            resetIndex();
        }
    }, [])

    return (<CustomTabs selectedIndex={customIndex}>
        <CustomTabList>
            <TeamBox>
                <Bar
                    isWin={blueTeam[0]?.is_win}>{blueTeam[0]?.is_win ? t('summoner.side.winTeam') : t('summoner.side.loseTeam')}</Bar>
                {/*<img src={'images/ico_trophy.svg'} width={18} height={19}*/}
                {/*     alt={""}/>*/}
                {blueTeam.map((data, index) => {
                    return (<Tab>
                        <ChampImageWrapper onClick={() => onChangeBuildIndex(index)}>
                            <ChampionImageWithLane champion_id={data.champion_id} disabled/>
                            <ScoreSpan>{scoreColor(Math.trunc(data?.final_stat_dict?.ai_score))}</ScoreSpan>

                        </ChampImageWrapper>
                    </Tab>)
                })}

            </TeamBox>


            <TeamBox>
                {/*<img src={'images/ico_trophy.svg'} width={18} height={19}*/}
                {/*     alt={""}/>*/}
                <BarReverse
                    isWin={redTeam[0]?.is_win}>{redTeam[0]?.is_win ? t('summoner.side.winTeam') : t('summoner.side.loseTeam')}</BarReverse>
                {redTeam.map((data, index) => {
                    return (<Tab>
                        <ChampImageWrapper onClick={() => onChangeBuildIndex(index + 5)}>
                            <ChampionImageWithLane champion_id={data.champion_id} disabled/>
                            <ScoreSpan>{scoreColor(Math.round(data?.final_stat_dict?.ai_score))}</ScoreSpan>
                        </ChampImageWrapper>
                    </Tab>)
                })}

            </TeamBox>

        </CustomTabList>

        {blueTeam.map((data, index) => {
            return (<TabPanel key={index + 'blueTeam'}>
                <DetailBuildContent modalData={data}/>
            </TabPanel>)
        })}
        {redTeam.map((data, index) => {
            return (<TabPanel key={index + 'redTeam'}>
                <DetailBuildContent modalData={data}/>
            </TabPanel>)
        })}
    </CustomTabs>)
};

export const DetailBuildContent = ({modalData}) => {
    const {getChampion, calcUrlNameByName} = useChampionFunc();
    const skillList = useGetChampionSpells(calcUrlNameByName(getChampion(modalData?.champion_id)?.champion_name_en));
    const version = useSelector((state) => state.gameVersion.version);
    const {t} = useTranslation();
    const [minute, setMinute] = useState([]);
    const [skills, setSkills] = useState({});
    const [master, setMaster] = useState([]);
    const [runes, setRunes] = useState({});
    const runeArr = useSelector((state) => state.imageStore.runes);

    const {isFinalItem} = useItemFunc();
    const initialFunc = useCallback(() => {
        /*** ITEM ***/
        let minArr = [];
        modalData.item_seq_list.forEach((item) => {
            minArr.push(item.minute);
        });
        setMinute([...new Set(minArr)]); // min(분) 배열 구해서 중복 제거

        /*** SKILL ***/
        let skillQ = Array.from({length: 18}).map((_,) => 0);
        let skillW = Array.from({length: 18}).map((_,) => 0);
        let skillE = Array.from({length: 18}).map((_,) => 0);
        let skillR = Array.from({length: 18}).map((_,) => 0);
        let skillPlus = Array.from({length: 18}).map((_,) => 0)
        let qCount = 0;
        let wCount = 0;
        let eCount = 0;
        let rCount = 0;
        let qAllCount = getMaxCount('Q', modalData.champion_id);
        let wAllCount = getMaxCount('W', modalData.champion_id);
        let eAllCount = getMaxCount('E', modalData.champion_id);
        let rAllCount = getMaxCount('R', modalData.champion_id);
        let successArr = [];

        const getId = (id) => {
            switch (id) {
                case 1 :
                    return "Q";
                case 2 :
                    return "W";
                case 3 :
                    return "E";
                case 4 :
                    return "R";
                default :
                    return "Q";
            }
        }


        modalData.skill_seq_list.forEach((item, index) => {
            let level = index + 1;
            if (item.type === 'EVOLVE') {
                skillPlus[index] = getId(item.skill_id);
            }
            if (item.type === "SKILL_LEVEL_UP" || item.type === "NORMAL") {
                if (item.skill_id === 1) {
                    skillQ[index] = level;
                    skillW[index] = 0;
                    skillE[index] = 0;
                    skillR[index] = 0;
                    skillPlus[index] = 0;

                    qCount++;
                    if (qCount === qAllCount) {
                        successArr.push({
                            skill: 'Q', icon: 1,
                        });
                    }
                } else if (item.skill_id === 2) {
                    skillQ[index] = 0;
                    skillW[index] = level;
                    skillE[index] = 0;
                    skillR[index] = 0;
                    skillPlus[index] = 0;
                    wCount++;
                    if (wCount === wAllCount) {
                        successArr.push({
                            skill: 'W', icon: 2,
                        });
                    }
                } else if (item.skill_id === 3) {
                    skillQ[index] = 0;
                    skillW[index] = 0;
                    skillE[index] = level;
                    skillR[index] = 0;
                    skillPlus[index] = 0;
                    eCount++;
                    if (eCount === eAllCount) {
                        successArr.push({
                            skill: 'E', icon: 3,
                        });
                    }
                } else if (item.skill_id === 4) {
                    skillQ[index] = 0;
                    skillW[index] = 0;
                    skillE[index] = 0;
                    skillR[index] = level;
                    skillPlus[index] = 0;
                    rCount++;
                    if (rCount === rAllCount) {
                        successArr.push({
                            skill: 'R', icon: 4,
                        });
                    }
                }
            }
        });
        setSkills({skillQ, skillW, skillE, skillR, skillPlus});

        let countArr = [{
            skill: 'Q', count: qCount, icon: 1,
        }, {
            skill: 'W', count: wCount, icon: 2,
        }, {
            skill: 'E', count: eCount, icon: 3,
        },];
        countArr.sort(function (a, b) {
            return b.count - a.count;
        });

        if (successArr.length === 0) {
            // 모든 스킬 마스터 X
            setMaster(countArr);
        } else if (successArr.length === 3) {
            // 모든 스킬 마스터 O
            setMaster(successArr);
        } else {
            // 한개 or 두개 스킬 마스터 O
            let successSkill = '';
            let skillArr = [];
            successArr.forEach((item) => {
                successSkill += item.skill;
            });
            countArr.forEach((item) => {
                if (!successSkill.includes(item.skill)) {
                    skillArr.push(item);
                }
            });
            let newArr = successArr.concat(skillArr);
            setMaster(newArr);
        }

        /*** RUNE ***/
        const runeData = modalData?.rune_detail_dict
        let perkPrimary = [runeData?.perk_primary_style, runeData?.perk_0, runeData?.perk_1, runeData?.perk_2, runeData?.perk_3];
        let perkSub = [runeData?.perk_sub_style, runeData?.perk_4, runeData?.perk_5];
        let rune_stat = [runeData?.stat_perk_0, runeData?.stat_perk_1, runeData?.stat_perk_2]
        setRunes({rune_main: perkPrimary, rune_sub: perkSub, rune_stat});
    }, [modalData, runeArr])

    useLayoutEffect(() => {
        initialFunc();
    }, [initialFunc,]);

    return (
        <Wrapper className={'buildWrapper'}>
        <div className="builds">
            <BuildWrapper>
                <h4>{t('modal.build.itemBuild')}</h4>
                <ItemWrapper className="items">
                    {minute.map((m, index) => {
                        return (<div className="items_per_min" key={index}>
                            <div className="items">
                                {modalData.item_seq_list.map((item, index) => {
                                    const isFinal = isFinalItem(item.item_id)
                                    if (m !== item.minute) {
                                        return null
                                    }
                                    return (
                                        <span key={index} className={`${item.type} ${isFinal ? 'final' : ""}`}>
                            <ImageItem itemId={item.item_id}/>
                          </span>)
                                })}
                            </div>
                            <span className="times">{m}{t('summoner.minute')}</span>
                        </div>);
                    })}
                </ItemWrapper>

            </BuildWrapper>
            <BuildWrapper>
                <h4>{t('modal.build.skillBuild')}</h4>
                <div className="build skills">
                    {/* num : Q = 1, W = 2, E = 3, R = 4 */}
                    {master.length !== 0 && (<div className="skills_m_seq">
                        {master.slice(0, 3).map((skill, index) => (<div className="skills" key={index}>
                            <ChampionSkillImage
                                reverse={true}
                                margin={'0 5px 0 0'}
                                width={36}
                                height={36}
                                spell={skillList?.spells[skill.icon - 1]}
                                version={version}
                                img={skillList?.spells[skill.icon - 1]?.image?.full}
                            />
                            <span className="texts">{skill.skill}</span>
                        </div>))}
                    </div>)}
                    {Object.values(skills).length !== 0 && (<div className="skills_seq">
                        <div className="qwer">
                            <span className="q">Q</span>
                            <span className="w">W</span>
                            <span className="e">E</span>
                            <span className="r">R</span>
                            <span className="plus">+</span>
                        </div>
                        <div className="qwer_per_level">
                            <div className="skills q">
                                {skills.skillQ.map((skill, index) => skill !== 0 ? (
                                    <span className="selected" key={index}>
                          {skill}
                        </span>) : (<span key={index}></span>))}
                            </div>
                            <div className="skills w">
                                {skills.skillW.map((skill, index) => skill !== 0 ? (
                                    <span className="selected" key={index}>
                          {skill}
                        </span>) : (<span key={index}></span>))}
                            </div>
                            <div className="skills e">
                                {skills.skillE.map((skill, index) => skill !== 0 ? (
                                    <span className="selected" key={index}>
                          {skill}
                        </span>) : (<span key={index}></span>))}
                            </div>
                            <div className="skills r">
                                {skills.skillR.map((skill, index) => skill !== 0 ? (
                                    <span className="selected" key={index}>
                          {skill}
                        </span>) : (<span key={index}></span>))}
                            </div>
                            <div className="skills plus">
                                {skills.skillPlus.map((skill, index) => skill !== 0 ? (
                                    <span className="selected" key={index}>
                          {skill}
                        </span>) : (<span key={index}></span>))}
                            </div>
                        </div>
                    </div>)}
                </div>
            </BuildWrapper>
            <BuildWrapper>

                <h4>{t('modal.build.runeBuild')}</h4>
                <div className="build runes">
                    <SummonerBuildRune runeList={runes}/>
                    {/*{Object.values(runes).length !== 0 && (<>*/}
                    {/*    <div className="runes_pri_seq">*/}
                    {/*        <div className="runes_title">*/}
                    {/*            <ImageRune runeId={runes.perkPrimary.id}/>*/}
                    {/*            <span>*/}
                    {/*  {currentLang === 'KOR' ? runes.perkPrimary.name : runes.perkPrimary.key}*/}
                    {/*</span>*/}
                    {/*        </div>*/}
                    {/*        <div className="runes_row">*/}
                    {/*            {runes.perkPrimary.slots.map((slot, index) => (<div className="runes" key={index}>*/}
                    {/*                {slot.runes.map((rune) => modalData.rune_detail_dict.perk_0 === rune.id || modalData.rune_detail_dict.perk_1 === rune.id || modalData.rune_detail_dict.perk_2 === rune.id || modalData.rune_detail_dict.perk_3 === rune.id ? (*/}
                    {/*                    <span className="selected" key={rune.key}>*/}
                    {/*          <ImageRune runeId={rune.id}/>*/}
                    {/*        </span>) : (<span key={rune.key}>*/}
                    {/*          <ImageRune runeId={rune.id}/>*/}
                    {/*        </span>))}*/}
                    {/*            </div>))}*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="runes_sec_seq">*/}
                    {/*        <div className="runes_title">*/}
                    {/*            <ImageRune runeId={runes.perkSub.id}/>*/}
                    {/*            <span>{currentLang === 'KOR' ? runes.perkSub.name : runes.perkSub.key}</span>*/}
                    {/*        </div>*/}
                    {/*        <div className="runes_row">*/}
                    {/*            {runes.perkSub.slots.map((slot, index) => (<div className="runes" key={index}>*/}
                    {/*                {slot.runes.map((rune) => modalData.rune_detail_dict.perk_4 === rune.id || modalData.rune_detail_dict.perk_5 === rune.id ? (*/}
                    {/*                    <span className="selected" key={rune.key}>*/}
                    {/*          <ImageRune runeId={rune.id}/>*/}
                    {/*        </span>) : (<span key={rune.key}>*/}
                    {/*          <ImageRune runeId={rune.id}/>*/}
                    {/*        </span>))}*/}
                    {/*            </div>))}*/}
                    {/*        </div>*/}
                    {/*        <div className="runes_row stats">*/}
                    {/*            <div className="runes">*/}
                    {/*                {currentRuneShards.slots[0].runes.map((data) => {*/}
                    {/*                    return (*/}
                    {/*                        <RuneShardLayout data={data}*/}
                    {/*                                         selectedRune={modalData.rune_detail_dict.stat_perk_2}*/}
                    {/*                                         key={data.id}/>*/}
                    {/*                    )*/}
                    {/*                })}*/}
                    {/*            </div>*/}
                    {/*            <div className="runes">*/}
                    {/*                {currentRuneShards.slots[1].runes.map((data) => {*/}
                    {/*                    return (*/}
                    {/*                        <RuneShardLayout data={data}*/}
                    {/*                                         selectedRune={modalData.rune_detail_dict.stat_perk_1}*/}
                    {/*                                         key={data.id}/>*/}
                    {/*                    )*/}
                    {/*                })}*/}
                    {/*            </div>*/}
                    {/*            <div className="runes">*/}
                    {/*                {currentRuneShards.slots[2].runes.map((data) => {*/}
                    {/*                    return (*/}
                    {/*                        <RuneShardLayout data={data}*/}
                    {/*                                         selectedRune={modalData.rune_detail_dict.stat_perk_0}*/}
                    {/*                                         key={data.id}/>*/}
                    {/*                    )*/}
                    {/*                })}*/}
                    {/*            </div>*/}

                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</>)}*/}
                </div>
            </BuildWrapper>
        </div>
    </Wrapper>)
}


export default DetailBuild;