import React, {useCallback} from 'react';
import styled from "styled-components";

const NameLI = styled.li`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 30px;
  border-radius: 6px;
  background-color: ${props => {
    let color = props.theme.color.site_main_color;
    if (props.selected) color = props.theme.color.green_200;
    return color;
  }};

  color: ${props => {
    let color = props.theme.color.dark_200
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};
  
  font-weight: ${props => {
      let weight = 500;
      if(props.selected) weight = 'bold';
      return weight
  }};


  &:hover {
    background-color: ${props => {
      let color = props.theme.color.white_200;
      if (props.selected) color = props.theme.color.green_200;
      return color;
    }};
  }
`;

const NameText = styled.span`
  width: auto;
  font-size: 12px;
  border-radius: 4px;
`;

const CNameLI = ({data, index, onClick, selected}) => {
    const onClickLi = useCallback(() => {
        if (index !== undefined && onClick !== undefined) {
            onClick(index, data);
        }
    }, [])

    return (
        <NameLI selected={selected} onClick={onClickLi}>
            <NameText selected={selected}>{data}</NameText>
        </NameLI>
    );
};

export default React.memo(CNameLI);