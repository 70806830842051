import React from 'react';
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {getProAndStreamerSeachAPI, getSearchSummonerAPI} from "../../store/saga/summoner";
import {useQueryClient} from "@tanstack/react-query";

const useSummonerSearch = () => {
    const history = useHistory();
    const queryClient = useQueryClient();
    const region = useSelector((state) => state.region.result);

    const navigateToSummonerSearch = async (summonerName) => {
        const getSummonerObj = {
            username: summonerName,
            region: region,
        }


        try {
            const summonerOneTag = await getSearchSummonerAPI(getSummonerObj);

            const influList = await queryClient.fetchQuery({
                queryKey: ['proStreamerSearch',summonerName],
                queryFn: async () => {
                    const result = await getProAndStreamerSeachAPI({
                        username: summonerName
                    })
                    return result
                },
            });


            if (summonerOneTag.length === 1 && influList.pro.length === 0 && influList.streamer.length === 0) {
                getSummonerObj.username = `${summonerOneTag[0].riot_id_name}-${summonerOneTag[0].riot_id_tag_line}`
                history.push({
                    pathname: `/summoner/${region}/${getSummonerObj.username}`,
                    state: {name: getSummonerObj.username},
                });
            } else {
                history.push(`/summoner/search/${region}/${summonerName}`);
            }
        } catch (e) {
            console.log(e);
        }
    }
    return {
        navigateToSummonerSearch,
    }
};

export default useSummonerSearch;