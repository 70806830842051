export const AI_SCORE_PERFECT = '#F3A634'
export const AI_SCORE_GOOD = '#54B59E'
export const AI_SCORE_BAD = '#E57474';

export const AI_SCORE_VERY_BAD = '#8997AB'



export const AI_SCORE_PERFECT_TEXT = '#F19000';
export const AI_SCORE_GOOD_TEXT = '#259A7E';
export const AI_SCORE_BAD_TEXT = '#DE5353';
export const AI_SCORE_VERY_BAD_TEXT = '#69788D';
