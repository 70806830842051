import React from 'react';
import styled from "styled-components";
import {onStorePuuId} from "../../function";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 140px;
  width: 100%;
  padding: 20px;
  background-color: ${props => props.theme.color.site_main_color};
  border: 2px solid ${props => props.theme.color.green_100};
  border-radius: 6px;
  margin-bottom: 10px;
`;
const LeaderImageBox = styled.div`
    cursor: pointer;
`;
const LeaderImage = styled.img`
  border-radius: 6px;
`;
const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  margin-left: 20px;
  flex: 1;
`;
const RowBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & + & {
    margin-top: 18px;
  }
`;
const TeamBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  padding: 0 10px;
  background-color: ${props => props.theme.color.green_200};
  border-radius: 6px;
  margin-right: 10px;
`;
const TeamSpan = styled.span`
  color: ${props => props.theme.color.green_100};
  font-size: 18px;
  font-weight: bold;
`;
const LeaderSpan = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: ${props => props.theme.color.green_100};
  cursor: pointer;
  text-transform: capitalize;
  &:hover {
    text-decoration: underline;
  }
`;
const CheerBox = styled.div`
  background-color: ${props => props.theme.color.white_200};
  width: 100%;
  height: 36px;
  padding: 0 10px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CheerSpan = styled.span`
  color: ${props => props.theme.color.dark_200};
  font-size: 14px;
  font-weight: 500;
`;
const EventTeamLeader = ({name, teamIndex, cheer, riot_id, riot_tag, puuId}) => {
    return (
        <Wrapper>
            <LeaderImageBox onClick={() => onStorePuuId(`${riot_id}-${riot_tag}`, 'jp', puuId)}>
                <LeaderImage src={`images/event/leader_${name}.svg`} width={100} height={100} alt={''}/>
            </LeaderImageBox>
            <InfoBox>
                <RowBox>
                    <TeamBox>
                        <TeamSpan>
                            TEAM {teamIndex}
                        </TeamSpan>
                    </TeamBox>
                    <LeaderSpan onClick={() => onStorePuuId(`${riot_id}-${riot_tag}`, 'jp', puuId) }>{name}</LeaderSpan>
                </RowBox>
                <RowBox>
                    <CheerBox>
                        <CheerSpan>
                            {cheer}
                        </CheerSpan>
                    </CheerBox>
                </RowBox>
            </InfoBox>
        </Wrapper>
    );
};

export default EventTeamLeader;