import React from 'react';
import {intlMinuetsTimeFormatter} from "../../function";
import {useTranslation} from "react-i18next";

const useTimeHook = ({langList} = {langList: []}) => {
    const {i18n} = useTranslation();
    const lessMinWithIntl = (time ) => {
        const typeNumber = Number(time);
        let language = i18n.language;
        if(Array.isArray(langList) && langList.length > 0) {
            language = langList.includes(i18n.language) ? i18n.language : 'en';
        }
        return intlMinuetsTimeFormatter(typeNumber, language);
    };


    return {lessMinWithIntl}

};

export default useTimeHook;