import React, {useCallback} from 'react';
import styled from "styled-components";

const ListLI = styled.li`
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
  cursor: pointer;
  position: relative;
  background-color: ${props => {
    let color = props.theme.color.site_main_color;
    if (props.selected) color = props.theme.color.green_200;
    return color;
  }};
  
  
  &:hover {
    background-color: ${props => {
      let color = props.theme.color.white_200;
      if (props.selected) color = props.theme.color.green_200;
      return color;
    }};
  }

  border: ${props => {
    let width = '1px';
    if (props.selected) width = '1.5px';
    return width;
  }} solid ${props => {
    let color = props.theme.color.default_border_color;
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};

  border-left: ${props => {
    let width = 0;
    if (props.left) width = '1px';
    if (props.selected) width = '1.5px';
    return width;
  }} solid ${props => {
    let color = props.theme.color.default_border_color;
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};
  border-right: ${props => {
    let width = 0;
    if (props.right) width = '1px';
    if (props.selected) width = '1.5px';
    return width;
  }} solid ${props => {
    let color = props.theme.color.default_border_color;
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};

`;

const ListText = styled.span`
  flex: 1;
  margin-left: 4px;
  font-size: 14px;
  color: ${props => {
    let color = props.theme.color.dark_200 
    if (props.selected) color = props => props.theme.color.green_100;
    return color;
  }};
  font-weight: ${props => {
    let color = '';
    if (props.selected) color = 'bold';
    return color;
  }};;
  line-height: 22px;
`;

const LaneImage = styled.img`
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const SearchListLI = ({data, index, selected, onClick, lane, src, visibleText, selectedIndex, lastIndex}) => {
    const onClickList = useCallback(() => {
        if (onClick !== undefined && index !== undefined) {
            onClick(index, lane);
        }
    }, []);

    return (
        <ListLI
            left={(selectedIndex + 1) !== index}
            right={lastIndex === index}
            onClick={onClickList}
            selected={selected}
        >
            <DataWrapper>
                {src && <LaneImage
                    selected={selected}
                    width={16} height={16} src={src}/>}
                {visibleText && <ListText selected={selected}>{data}</ListText>}
            </DataWrapper>
        </ListLI>
    );
};


export default React.memo(SearchListLI);
