import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import dompurify from 'dompurify';

import {ImageRune, ImageSpell} from './ImageSrc';
import {csPerMinute, getKda, getMaxCount, getTimeHMS, kdaColor, scoreColor,} from '../../function';
import {openModal} from '../../store/modules/modal';
import {ModalContainer} from '../../styled/CommonStyle';
import ChampionImageWithLane from "./ChampionImageWithLane";
import TierTag from "./TierTag";
import useGetChampionSpells from "../../common/hooks/useGetChampionSpells";
import useChampionFunc from "../../common/hooks/useChampionFunc";
import runeShards from "../../store/static_json/runeShards";
import runeShards_en from "../../store/static_json/runeShards_en";
import {DetailBuildContent} from "../summoner/DetailTab/DetailBuild";

const Modal = ({modalData, open, match}) => {
    const {t} = useTranslation();
    const sanitizer = dompurify.sanitize;
    const runeArr = useSelector((state) => state.imageStore.runes);
    const currentLang = useSelector((state) => state.translation.lang);
    const {getChampion, calcUrlNameByName} = useChampionFunc();
    const skillList = useGetChampionSpells(calcUrlNameByName(getChampion(modalData?.champion_id)?.champion_name_en));
    const version = useSelector((state) => state.gameVersion.version);
    const dispatch = useDispatch();
    const [minute, setMinute] = useState([]);
    const [skills, setSkills] = useState({});
    const [master, setMaster] = useState([]);
    const [runes, setRunes] = useState({});
    const {lane_ai_score_detail_dict, final_ai_score_detail_dict} = modalData;
    const {i18n} = useTranslation();
    const currentRuneShards = useMemo(() => ['ko', 'ko-KR'].includes(i18n.language) ? runeShards : runeShards_en, [i18n.language]);


    const setHms = useCallback((time) => {
        const hour = time > 3600 ? time / 3600 : 0;
        const min = time / 60;
        const sec = time % 60;
        return `${hour}:${min}:${sec}`;
    }, []);

    const durationCheck = () => {
        // let tmp = match?.duration?.split(':') | [];
        // let minute = parseInt(tmp[1]);
        // return minute < 15 ? false : true;
        const min = match.match_basic_dict.game_duration / 60;
        return min > 15;
    };

    const findIcons = (name, side) => {
        // Dragon, Elder_Dragon, Baron, Riftherald
        // Turret, Inhibitor, Execution
        let icon = '';
        let title = '';
        switch (name) {
            case 'Dragon':
                icon = '/images/icon_popup_dragon_square_normal.png';
                title = t('modal.icon.dragon');
                break;
            case 'Elder_Dragon':
                icon = '/images/icon_objectives_elder_dragon.png';
                title = t('modal.icon.elderDragon');
                break;
            case 'Baron':
                icon = '/images/icon_popup_baron_square.png';
                title = t('modal.icon.baron');
                break;
            case 'Riftherald':
                icon = '/images/icon_popup_sruriftherald_square.png';
                title = t('modal.icon.riftherald');
                break;
            case 'Turret':
                if (side === 'blue') icon = '/images/icon_popup_turret_blue_square.png'; else icon = '/images/icon_popup_turret_red_square.png';
                title = t('modal.icon.turret');
                break;
            case 'Inhibitor':
                if (side === 'blue') icon = '/images/icon_popup_inhibitor_blue_square.png'; else icon = '/images/icon_popup_inhibitor_red_square.png';
                title = t('modal.icon.inhibitor');
                break;
            case 'Execution':
                icon = '/images/ico_crossbones.svg';
                title = t('modal.icon.execution');
                break;
            default:
                icon = '/images/icon_non_item.svg';
                title = t('modal.icon.noImg');
                break;
        }
        return name !== 'Execution' ? (<img src={icon} alt={name} className="imgIcons" title={title}/>) : (
            <div className="execution">
                <img src={icon} alt={name} className="imgIcons" title={title}/>
            </div>);
    };

    const findChamp = (target) => {
        if (target) {


            const targetSorted = Object.entries(target)
                .sort(([, a], [, b]) => b - a)
                .reduce((r, [k, v]) => ({...r, [k]: v}), {});
            const targetEntries = Object.entries(targetSorted);


            let champIds = [];
            let champNums = [];
            for (const el of targetEntries) {
                switch (el[0]) {
                    case 'B_Top':
                        const getBlueTopId = match?.participants_list?.find((data) => data.side === "BLUE" && data.position === 'Top');
                        champIds.push(getBlueTopId.champion_id);
                        break;
                    case 'B_Jun':
                        const getBlueJungleId = match?.participants_list?.find((data) => data.side === "BLUE" && data.position === 'Jungle');
                        champIds.push(getBlueJungleId.champion_id);
                        break;
                    case 'B_Mid':
                        const getBlueMidId = match?.participants_list?.find((data) => data.side === "BLUE" && data.position === 'Middle');
                        champIds.push(getBlueMidId.champion_id);
                        break;
                    case 'B_Bot':
                        const getBlueBotId = match?.participants_list?.find((data) => data.side === "BLUE" && data.position === 'Bot');
                        champIds.push(getBlueBotId.champion_id);
                        break;
                    case 'B_Sup':
                        const getBlueSupId = match?.participants_list?.find((data) => data.side === "BLUE" && data.position === "Supporter");
                        champIds.push(getBlueSupId.champion_id);
                        break;
                    case 'R_Top':
                        const getRedTopId = match?.participants_list?.find((data) => data.side === "RED" && data.position === 'Top');
                        champIds.push(getRedTopId.champion_id);
                        break;
                    case 'R_Jun':
                        const getRedJungle = match?.participants_list?.find((data) => data.side === "RED" && data.position === 'Jungle');
                        champIds.push(getRedJungle.champion_id);
                        break;
                    case 'R_Mid':
                        const getRedMiddle = match?.participants_list?.find((data) => data.side === "RED" && data.position === 'Middle');
                        champIds.push(getRedMiddle.champion_id);
                        break;
                    case 'R_Bot':
                        const getRedBot = match?.participants_list?.find((data) => data.side === "RED" && data.position === 'Bot');
                        champIds.push(getRedBot.champion_id);
                        break;
                    case 'R_Sup':
                        const getRedSup = match?.participants_list?.find((data) => data.side === "RED" && data.position === "Supporter");
                        champIds.push(getRedSup.champion_id);
                        break;
                    case 'DRAGON':
                        champIds.push('Dragon');
                        break;
                    case 'Elder_Dragon':
                        champIds.push('Elder_Dragon');
                        break;
                    case 'BARON_NASHOR':
                        champIds.push('Baron');
                        break;
                    case 'RIFTHERALD':
                        champIds.push('Riftherald');
                        break;
                    case 'TOWER':
                        champIds.push('Turret');
                        break;
                    case 'INHIBITOR':
                        champIds.push('Inhibitor');
                        break;
                    case 'None':
                        champIds.push('Execution');
                        break;
                    case '':
                        champIds.push('Execution');
                        break;
                    default:
                        break;
                }
                champNums.push(el[1]);
            }

            return {champIds, champNums};
        }
        return {champIds: [], champNums: 0};
    };

    const initialFunc = useCallback(() => {
        /*** ITEM ***/
        let minArr = [];
        modalData.item_seq_list.forEach((item) => {
            minArr.push(item.minute);
        });
        setMinute([...new Set(minArr)]); // min(분) 배열 구해서 중복 제거

        /*** SKILL ***/
        let skillQ = [];
        let skillW = [];
        let skillE = [];
        let skillR = [];
        let skillPlus = [];
        let qCount = 0;
        let wCount = 0;
        let eCount = 0;
        let rCount = 0;
        let qAllCount = getMaxCount('Q', modalData.champion_id);
        let wAllCount = getMaxCount('W', modalData.champion_id);
        let eAllCount = getMaxCount('E', modalData.champion_id);
        let rAllCount = getMaxCount('R', modalData.champion_id);
        let successArr = [];

        const getId = (id) => {
            switch (id) {
                case 1 :
                    return "Q";
                case 2 :
                    return "W";
                case 3 :
                    return "E";
                case 4 :
                    return "R";
                default :
                    return "Q";
            }
        }


        modalData.skill_seq_list.forEach((item, index) => {
            let level = index + 1;
            if (item.type === 'EVOLVE') {
                skillPlus.pop();
                skillPlus.push(getId(item.skill_id));
            }
            if (item.type === "SKILL_LEVEL_UP" || item.type === "NORMAL") {
                if (item.skill_id === 1) {
                    skillQ.push(level);
                    skillW.push(0);
                    skillE.push(0);
                    skillR.push(0);
                    skillPlus.push(0);

                    qCount++;
                    if (qCount === qAllCount) {
                        successArr.push({
                            skill: 'Q', icon: 1,
                        });
                    }
                } else if (item.skill_id === 2) {
                    skillQ.push(0);
                    skillW.push(level);
                    skillE.push(0);
                    skillR.push(0);
                    skillPlus.push(0);
                    wCount++;
                    if (wCount === wAllCount) {
                        successArr.push({
                            skill: 'W', icon: 2,
                        });
                    }
                } else if (item.skill_id === 3) {
                    skillQ.push(0);
                    skillW.push(0);
                    skillE.push(level);
                    skillR.push(0);
                    skillPlus.push(0);
                    eCount++;
                    if (eCount === eAllCount) {
                        successArr.push({
                            skill: 'E', icon: 3,
                        });
                    }
                } else if (item.skill_id === 4) {
                    skillQ.push(0);
                    skillW.push(0);
                    skillE.push(0);
                    skillR.push(level);
                    skillPlus.push(0);
                    rCount++;
                    if (rCount === rAllCount) {
                        successArr.push({
                            skill: 'R', icon: 4,
                        });
                    }
                }
            }
        });
        setSkills({skillQ, skillW, skillE, skillR, skillPlus});

        let countArr = [{
            skill: 'Q', count: qCount, icon: 1,
        }, {
            skill: 'W', count: wCount, icon: 2,
        }, {
            skill: 'E', count: eCount, icon: 3,
        },];
        countArr.sort(function (a, b) {
            return b.count - a.count;
        });

        if (successArr.length === 0) {
            // 모든 스킬 마스터 X
            setMaster(countArr);
        } else if (successArr.length === 3) {
            // 모든 스킬 마스터 O
            setMaster(successArr);
        } else {
            // 한개 or 두개 스킬 마스터 O
            let successSkill = '';
            let skillArr = [];
            successArr.forEach((item) => {
                successSkill += item.skill;
            });
            countArr.forEach((item) => {
                if (!successSkill.includes(item.skill)) {
                    skillArr.push(item);
                }
            });
            let newArr = successArr.concat(skillArr);
            setMaster(newArr);
        }

        /*** RUNE ***/
        let perkPrimary = {};
        let perkSub = {};
        runeArr.forEach((item) => {
            if (item.id === modalData.rune_detail_dict.perk_primary_style) {
                perkPrimary = item;
            } else if (item.id === modalData.rune_detail_dict.perk_sub_style) {
                perkSub = item;
            }
        });
        setRunes({perkPrimary, perkSub});
    }, [modalData, runeArr]);


    const initialAiFunc = useCallback(() => {
    }, []);

    useEffect(() => {
        if (match !== undefined) {
            initialAiFunc();
        } else {
            initialFunc();
        }
    }, [initialAiFunc, initialFunc, match]);

    return (<ModalContainer className={open ? 'openModal' : 'closeModal'}>
        <div className="dimm" onClick={() => dispatch(openModal(false, {}, ''))}></div>
        {match !== undefined ? (<div className="modal aiscores">
            <div className="title">
                <h4>{t('modal.aiscores.title')}</h4>
                <p dangerouslySetInnerHTML={{__html: sanitizer(t('modal.aiscores.text'))}}></p>
            </div>
            {modalData?.is_runaway ? (<div className="aiscore">
                <div className="away">
                    <p>{t('modal.aiscores.escape')}</p>
                </div>
            </div>) : (<div className="aiscore">
                {durationCheck() && (<div className="box lane">
                    <h5>
                        {t('modal.aiscores.lanePhase')} <span>(0~15{t('summoner.minute')})</span>
                    </h5>
                    <div className={`ai_info ${modalData.is_win ? 'win' : 'lose'}`}>
                        <div className="col01">
                            <strong>{scoreColor(Math.trunc(modalData?.lane_stat_dict.ai_score))}</strong>
                            <p>
                                {modalData.lane_stat_dict.ai_score_rank === 1 ? (<span>
                                    <i>1</i>st
                                </span>) : modalData.lane_stat_dict.ai_score_rank === 2 ? (<span>
                                    <i>2</i>nd
                                </span>) : (<span>
                                    <i>{modalData.lane_stat_dict.ai_score_rank}</i>th
                                </span>)}
                            </p>
                            <TierTag
                                fontSize={'11px'}
                                padding={'4px 4px'}
                                tier={modalData.tier}
                                division={modalData.division}
                            />
                        </div>
                        <div className="col02">
                            <dl>
                                <dt className={`${modalData.is_runaway ? 'away' : 'home'}`}>
                                    <ChampionImageWithLane champion_id={modalData.champion_id}/>
                                    <span>{modalData.lane_stat_dict.champion_level}</span>
                                </dt>
                                <dd>
                                    <ImageSpell
                                        spellId={modalData.spell_id_dict.spell_1}
                                        smite={modalData.smite}
                                    />
                                    <ImageSpell
                                        spellId={modalData.spell_id_dict.spell_2}
                                        smite={modalData.smite}
                                    />
                                </dd>
                                <dd>
                                    <ImageRune runeId={modalData.rune_detail_dict.perk_0}/>
                                    <ImageRune runeId={modalData.rune_detail_dict.perk_sub_style}/>
                                </dd>
                            </dl>
                            <p className={`${modalData.is_runaway ? 'away' : 'home'}`}>
                                {modalData.summonerName}
                            </p>
                        </div>
                        <div className="col03">
                            <dl>
                                <dt>
                                    {modalData.lane_deaths === 0 ? (<span
                                        className="kda_color perfect">Perfect</span>) : (kdaColor(getKda(modalData.lane_stat_dict.kills, modalData.lane_stat_dict.deaths, modalData.lane_stat_dict.assists)?.toFixed(2)))}
                                    <p>
                                        <span> {modalData.lane_stat_dict.kills} </span>/
                                        <span
                                            className="death"> {modalData.lane_stat_dict.deaths} </span>/
                                        <span> {modalData.lane_stat_dict.assists} </span>
                                    </p>
                                </dt>
                                <dd>
                                    <span> {modalData.lane_stat_dict.cs} </span>CS (
                                    {csPerMinute(modalData.lane_stat_dict.cs, '00:15:00')}/{t('summoner.minute')})
                                </dd>
                            </dl>
                        </div>
                        <div className="col04">
                            <p>
                                <span>{modalData?.lane_stat_dict?.kill_point?.toFixed(0)}%</span> {t('summoner.kp')}
                            </p>
                            <div className="vision">
                        <span>
                          <img src="/images/ico_ward01.svg" alt="구매한 와드 수"/>
                            {modalData.lane_stat_dict.vision_wards_bought}
                        </span>
                                <span>
                          <img src="/images/ico_ward02.svg" alt="설치한 와드 수"/>
                                    {modalData.lane_stat_dict.wards_placed}
                        </span>
                                <span>
                          <img src="/images/ico_ward03.svg" alt="제거한 와드 수"/>
                                    {modalData.lane_stat_dict.wards_killed}
                        </span>
                            </div>
                        </div>
                    </div>
                    <div className="ai_detail">
                        <div className="t_head">
                            <div className="row">
                                <div className="row_col01">{t('modal.aiscores.item')}</div>
                                <div className="row_col02">{t('modal.aiscores.count')}</div>
                                <div className="row_col03">{t('modal.aiscores.target')}</div>
                                <div className="row_col04">AI-Score</div>
                            </div>
                        </div>
                        <div className="t_body">
                            {/* 솔로 킬 */}
                            <div
                                className={`row positive ${lane_ai_score_detail_dict?.solo_kill_dict.total_num === 0 ? 'off' : 'on'}`}
                            >
                                <div className="row_col01">
                                    <span>{t('modal.aiscores.soloKill')}</span>
                                </div>
                                <div className="row_col02">

                                    <span>{lane_ai_score_detail_dict?.solo_kill_dict.total_num}</span>
                                </div>
                                <div className="row_col03">
                                    <div className="target">
                                        {findChamp(lane_ai_score_detail_dict?.solo_kill_dict?.from).champIds.map((item, index) => (
                                            <div className="item" key={index}>
                                                <ChampionImageWithLane champion_id={item}/>
                                                <span>
                                  {findChamp(lane_ai_score_detail_dict?.solo_kill_dict?.from).champNums[index]}
                                </span>
                                            </div>))}
                                    </div>
                                </div>
                                <div className="row_col04">
                                    <span>{lane_ai_score_detail_dict?.solo_kill_dict.total_score?.toFixed(1)}</span>
                                </div>
                            </div>
                            {/* 협동 킬 */}
                            <div
                                className={`row positive ${lane_ai_score_detail_dict?.assist_kill_dict.total_num === 0 ? 'off' : 'on'}`}
                            >
                                <div className="row_col01">
                                    <span>{t('modal.aiscores.duoKill')}</span>
                                </div>
                                <div className="row_col02">
                                    <span>{lane_ai_score_detail_dict?.assist_kill_dict.total_num}</span>
                                </div>
                                <div className="row_col03">
                                    <div className="target">
                                        {findChamp(lane_ai_score_detail_dict?.assist_kill_dict?.from).champIds.map((item, index) => (
                                            <div className="item" key={index}>
                                                <ChampionImageWithLane champion_id={item}/>
                                                <span>
                                  {findChamp(lane_ai_score_detail_dict?.assist_kill_dict?.from).champNums[index]}
                                </span>
                                            </div>))}
                                    </div>
                                </div>
                                <div className="row_col04">
                                    <span>{lane_ai_score_detail_dict?.assist_kill_dict.total_score?.toFixed(1)}</span>
                                </div>
                            </div>
                            {/* 어시스트 */}
                            <div
                                className={`row positive ${lane_ai_score_detail_dict?.assist_dict.total_num === 0 ? 'off' : 'on'}`}
                            >
                                <div className="row_col01">
                                    <span>{t('modal.aiscores.assist')}</span>
                                </div>
                                <div className="row_col02">
                                    <span>{lane_ai_score_detail_dict?.assist_dict.total_num}</span>
                                </div>
                                <div className="row_col03">
                                    <div className="target">
                                        {findChamp(lane_ai_score_detail_dict?.assist_dict?.from).champIds.map((item, index) => (
                                            <div className="item" key={index}>
                                                <ChampionImageWithLane champion_id={item}/>
                                                <span>{findChamp(lane_ai_score_detail_dict?.assist_dict?.from).champNums[index]}</span>
                                            </div>))}
                                    </div>
                                </div>
                                <div className="row_col04">
                                    <span>{lane_ai_score_detail_dict?.assist_dict.total_score?.toFixed(1)}</span>
                                </div>
                            </div>
                            {/* 오브젝트 */}
                            <div
                                className={`row positive ${lane_ai_score_detail_dict?.object_kill_dict.total_num === 0 ? 'off' : 'on'}`}
                            >
                                <div className="row_col01">
                                    <span>{t('modal.aiscores.objective')}</span>
                                </div>
                                <div className="row_col02">
                                    <span>{lane_ai_score_detail_dict?.object_kill_dict.total_num}</span>
                                </div>
                                <div className="row_col03">
                                    <div className="target">
                                        {findChamp(lane_ai_score_detail_dict?.object_kill_dict?.from).champIds.map((item, index) => (
                                            <div className="item" key={index}>
                                                {item === 'Dragon' || item === 'Elder_Dragon' || item === 'Baron' || item === 'Riftherald' || item === 'Turret' || item === 'Execution' || item === 'Inhibitor' ? (findIcons(item, modalData.side)) : (
                                                    <ChampionImageWithLane champion_id={item}/>)}
                                                <span>
                                  {findChamp(lane_ai_score_detail_dict?.object_kill_dict?.from).champNums[index]}
                                </span>
                                            </div>))}
                                    </div>
                                </div>
                                <div className="row_col04">
                                    <span>{lane_ai_score_detail_dict?.object_kill_dict.total_score?.toFixed(1)}</span>
                                </div>
                            </div>
                            {/* 건물 */}
                            <div
                                className={`row positive ${lane_ai_score_detail_dict?.turret_kill_dict.total_num === 0 ? 'off' : 'on'}`}
                            >
                                <div className="row_col01">
                                    <span>{t('modal.aiscores.structure')}</span>
                                </div>
                                <div className="row_col02">
                                    <span>{lane_ai_score_detail_dict?.turret_kill_dict.total_num}</span>
                                </div>
                                <div className="row_col03">
                                    <div className="target">
                                        {findChamp(lane_ai_score_detail_dict?.turret_kill_dict?.from).champIds.map((item, index) => (
                                            <div className="item" key={index}>
                                                {item === 'Dragon' || item === 'Elder_Dragon' || item === 'Baron' || item === 'Riftherald' || item === 'Turret' || item === 'Execution' || item === 'Inhibitor' ? (modalData.side === 'blue' ? (findIcons(item, 'red')) : (findIcons(item, 'blue'))) : (
                                                    <ChampionImageWithLane champion_id={item}/>)}
                                                <span>
                                  {findChamp(lane_ai_score_detail_dict?.turret_kill_dict?.from).champNums[index]}
                                </span>
                                            </div>))}
                                    </div>
                                </div>
                                <div className="row_col04">
                                    <span>{lane_ai_score_detail_dict?.turret_kill_dict.total_score?.toFixed(1)}</span>
                                </div>
                            </div>
                            <hr/>
                            {/* 솔로 데스 */}
                            <div
                                className={`row negative ${lane_ai_score_detail_dict?.solo_death_dict.total_num === 0 ? 'off' : 'on'}`}
                            >
                                <div className="row_col01">
                                    <span>{t('modal.aiscores.soloDeath')}</span>
                                </div>
                                <div className="row_col02">
                                    <span>{lane_ai_score_detail_dict?.solo_death_dict.total_num}</span>
                                </div>
                                <div className="row_col03">
                                    <div className="target">
                                        {findChamp(lane_ai_score_detail_dict?.solo_death_dict?.from).champIds.map((item, index) => (
                                            <div className="item" key={index}>
                                                {item === 'Execution' ? (findIcons(item, modalData.side)) : (
                                                    <ChampionImageWithLane champion_id={item}/>)}
                                                <span>
                                  {findChamp(lane_ai_score_detail_dict?.solo_death_dict?.from).champNums[index]}
                                </span>
                                            </div>))}
                                    </div>
                                </div>
                                <div className="row_col04">
                                    <span>{lane_ai_score_detail_dict?.solo_death_dict.total_score?.toFixed(1)}</span>
                                </div>
                            </div>
                            {/* 협동 데스 */}
                            <div
                                className={`row negative ${lane_ai_score_detail_dict?.assist_death_dict.total_num === 0 ? 'off' : 'on'}`}
                            >
                                <div className="row_col01">
                                    <span>{t('modal.aiscores.duoDeath')}</span>
                                </div>
                                <div className="row_col02">
                                    <span>{lane_ai_score_detail_dict?.assist_death_dict.total_num}</span>
                                </div>
                                <div className="row_col03">
                                    <div className="target">
                                        {findChamp(lane_ai_score_detail_dict?.assist_death_dict?.from).champIds.map((item, index) => (
                                            <div className="item" key={index}>
                                                <ChampionImageWithLane champion_id={item}/>
                                                <span>
                                    {findChamp(lane_ai_score_detail_dict?.assist_death_dict?.from).champNums[index]}
                                  </span>
                                            </div>))}
                                    </div>
                                </div>
                                <div className="row_col04">
                                    <span>{lane_ai_score_detail_dict?.assist_death_dict.total_score?.toFixed(1)}</span>
                                </div>
                            </div>
                            {/* 오브젝트 */}
                            <div
                                className={`row negative ${lane_ai_score_detail_dict?.object_death_dict.total_num === 0 ? 'off' : 'on'}`}
                            >
                                <div className="row_col01">
                                    <span>{t('modal.aiscores.objective')}</span>
                                </div>
                                <div className="row_col02">
                                    <span>{lane_ai_score_detail_dict?.object_death_dict.total_num}</span>
                                </div>
                                <div className="row_col03">
                                    <div className="target">
                                        {findChamp(lane_ai_score_detail_dict?.object_death_dict?.from).champIds.map((item, index) => (
                                            <div className="item" key={index}>
                                                {item === 'Dragon' || item === 'Elder_Dragon' || item === 'Baron' || item === 'Riftherald' || item === 'Turret' || item === 'Execution' || item === 'Inhibitor' ? (findIcons(item, modalData.side)) : (
                                                    <ChampionImageWithLane champion_id={item}/>)}
                                                <span>
                                    {findChamp(lane_ai_score_detail_dict?.object_death_dict?.from).champNums[index]}
                                  </span>
                                            </div>))}
                                    </div>
                                </div>
                                <div className="row_col04">
                                    <span>{lane_ai_score_detail_dict?.object_death_dict.total_score?.toFixed(1)}</span>
                                </div>
                            </div>
                            {/* 건물 */}
                            <div
                                className={`row negative ${lane_ai_score_detail_dict?.turret_death_dict.total_num === 0 ? 'off' : 'on'}`}
                            >
                                <div className="row_col01">
                                    <span>{t('modal.aiscores.structure')}</span>
                                </div>
                                <div className="row_col02">
                                    <span>{lane_ai_score_detail_dict?.turret_death_dict.total_num}</span>
                                </div>
                                <div className="row_col03">
                                    <div className="target">
                                        {findChamp(lane_ai_score_detail_dict?.turret_death_dict?.from).champIds.map((item, index) => (
                                            <div className="item" key={index}>
                                                {item === 'Dragon' || item === 'Elder_Dragon' || item === 'Baron' || item === 'Riftherald' || item === 'Turret' || item === 'Execution' || item === 'Inhibitor' ? (findIcons(item, modalData.side)) : (
                                                    <ChampionImageWithLane champion_id={item}/>)}
                                                <span>
                                    {findChamp(lane_ai_score_detail_dict?.turret_death_dict?.from).champNums[index]}
                                  </span>
                                            </div>))}
                                    </div>
                                </div>
                                <div className="row_col04">
                                    <span>{lane_ai_score_detail_dict?.turret_death_dict.total_score?.toFixed(1)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="t_foot">
                            <dl>
                                <dt>{t('modal.aiscores.positiveScore')}</dt>
                                <dd className="positive">+{lane_ai_score_detail_dict?.plus_score?.toFixed(1)}</dd>
                            </dl>
                            <dl>
                                <dt>{t('modal.aiscores.negativeScore')}</dt>
                                <dd className="negative">{lane_ai_score_detail_dict?.minus_score?.toFixed(1)}</dd>
                            </dl>
                            <dl>
                                <dt>{t('modal.aiscores.defaultScore')}</dt>
                                <dd className="positive">50.0</dd>
                            </dl>
                        </div>
                        <div className="t_result">
                            <dl>
                                <dt>{t('modal.aiscores.totalLanePhase')}</dt>
                                <dd>{scoreColor((50 + lane_ai_score_detail_dict?.total_score)?.toFixed(1))}</dd>
                            </dl>
                        </div>
                    </div>
                </div>)}
                <div className="box result">
                    <h5>
                        {t('modal.aiscores.result')}{' '}
                        <span>(0~{getTimeHMS(setHms(match.match_basic_dict.game_duration), 'no', currentLang)})</span>
                    </h5>
                    <div className={`ai_info ${modalData.is_win ? 'win' : 'lose'}`}>
                        <div className="col01">
                            <strong>{scoreColor(Math.trunc(modalData.ai_score))}</strong>
                            <p>
                                {modalData.final_stat_dict.ai_score_rank === 1 ? (<>
                                    <i>1</i>st
                                </>) : modalData.final_stat_dict.ai_score_rank === 2 ? (<>
                                    <i>2</i>nd
                                </>) : (<>
                                    <i>{modalData.final_stat_dict.ai_score_rank}</i>th
                                </>)}
                            </p>
                            <TierTag
                                fontSize={'11px'}
                                padding={'4px 4px'}
                                tier={modalData.tier}
                                division={modalData.division}
                            />
                        </div>
                        <div className="col02">
                            <dl>
                                <dt className={`${modalData.is_runaway ? 'away' : 'home'}`}>
                                    <ChampionImageWithLane champion_id={modalData.champion_id}/>
                                    <span>{modalData.final_stat_dict.champion_level}</span>
                                </dt>
                                <dd>
                                    <ImageSpell spellId={modalData.spell_id_dict.spell_1}
                                                smite={modalData.smite}/>
                                    <ImageSpell spellId={modalData.spell_id_dict.spell_2}
                                                smite={modalData.smite}/>
                                </dd>
                                <dd>
                                    <ImageRune runeId={modalData.rune_detail_dict.perk_0}/>
                                    <ImageRune runeId={modalData.rune_detail_dict.perk_sub_style}/>
                                </dd>
                            </dl>
                            <p className={`${modalData.is_runaway ? 'away' : 'home'}`}>
                                {modalData.summonerName}
                            </p>
                        </div>
                        <div className="col03">
                            <dl>
                                <dt>
                                    {modalData.deaths === 0 ? (<span
                                        className="kda_color perfect">Perfect</span>) : (kdaColor(getKda(modalData.final_stat_dict.kills, modalData.final_stat_dict.deaths, modalData.final_stat_dict.assists)?.toFixed(2)))}
                                    <p>
                                        <span> {modalData.final_stat_dict.kills} </span>/
                                        <span className="death"> {modalData.final_stat_dict.deaths} </span>/
                                        <span> {modalData.final_stat_dict.assists} </span>
                                    </p>
                                </dt>
                                <dd>
                                    <span> {modalData.final_stat_dict.cs} </span>CS
                                    ({csPerMinute(modalData.final_stat_dict.cs, setHms(match.match_basic_dict.game_duration))}
                                    /{t('summoner.minute')})
                                </dd>
                            </dl>
                        </div>
                        <div className="col04">
                            <p>
                                <span>{modalData?.final_stat_dict.kill_point?.toFixed(0)}%</span> {t('summoner.kp')}
                            </p>
                            <div className="vision">
                      <span>
                        <img src="/images/ico_ward01.svg" alt="구매한 와드 수"/>
                          {modalData.final_stat_dict.vision_wards_bought}
                      </span>
                                <span>
                        <img src="/images/ico_ward02.svg" alt="설치한 와드 수"/>
                                    {modalData.final_stat_dict.wards_placed}
                      </span>
                                <span>
                        <img src="/images/ico_ward03.svg" alt="제거한 와드 수"/>
                                    {modalData.final_stat_dict.wards_killed}
                      </span>
                            </div>
                        </div>
                    </div>
                    <div className="ai_detail">
                        <div className="t_head">
                            <div className="row">
                                <div className="row_col01">{t('modal.aiscores.item')}</div>
                                <div className="row_col02">{t('modal.aiscores.count')}</div>
                                <div className="row_col03">{t('modal.aiscores.target')}</div>
                                <div className="row_col04">AI-Score</div>
                            </div>
                        </div>
                        <div className="t_body">
                            {/* 솔로 킬 */}
                            <div
                                className={`row positive ${final_ai_score_detail_dict?.solo_kill?.value === 0 ? 'off' : 'on'}`}
                            >
                                <div className="row_col01">
                                    <span>{t('modal.aiscores.soloKill')}</span>
                                </div>
                                <div className="row_col02">
                                    <span>{final_ai_score_detail_dict.solo_kill_dict.total_num}</span>
                                </div>
                                <div className="row_col03">
                                    <div className="target">
                                        {findChamp(final_ai_score_detail_dict.solo_kill_dict?.from).champIds.map((item, index) => (
                                            <div className="item" key={index}>
                                                <ChampionImageWithLane champion_id={item}/>
                                                <span>
                                  {findChamp(final_ai_score_detail_dict.solo_kill_dict?.from).champNums[index]}
                                </span>
                                            </div>))}
                                    </div>
                                </div>
                                <div className="row_col04">
                                    <span>{final_ai_score_detail_dict.solo_kill_dict.total_score?.toFixed(1)}</span>
                                </div>
                            </div>
                            {/* 협동 킬 */}
                            <div
                                className={`row positive ${final_ai_score_detail_dict.assist_kill_dict.total_num === 0 ? 'off' : 'on'}`}
                            >
                                <div className="row_col01">
                                    <span>{t('modal.aiscores.duoKill')}</span>
                                </div>
                                <div className="row_col02">
                                    <span>{final_ai_score_detail_dict.assist_kill_dict.total_num}</span>
                                </div>
                                <div className="row_col03">
                                    <div className="target">
                                        {findChamp(final_ai_score_detail_dict.assist_kill_dict?.from).champIds.map((item, index) => (
                                            <div className="item" key={index}>
                                                <ChampionImageWithLane champion_id={item}/>
                                                <span>
                                  {findChamp(final_ai_score_detail_dict.assist_kill_dict?.from).champNums[index]}
                                </span>
                                            </div>))}
                                    </div>
                                </div>
                                <div className="row_col04">
                                    <span>{final_ai_score_detail_dict.assist_kill_dict.total_score?.toFixed(1)}</span>
                                </div>
                            </div>
                            {/* 어시스트 */}
                            <div
                                className={`row positive ${final_ai_score_detail_dict.assist_dict.total_num === 0 ? 'off' : 'on'}`}
                            >
                                <div className="row_col01">
                                    <span>{t('modal.aiscores.assist')}</span>
                                </div>
                                <div className="row_col02">
                                    <span>{final_ai_score_detail_dict.assist_dict.total_num}</span>
                                </div>
                                <div className="row_col03">
                                    <div className="target">
                                        {findChamp(final_ai_score_detail_dict.assist_dict?.from).champIds.map((item, index) => (
                                            <div className="item" key={index}>
                                                <ChampionImageWithLane champion_id={item}/>
                                                <span>
                                {findChamp(final_ai_score_detail_dict.assist_dict?.from).champNums[index]}
                              </span>
                                            </div>))}
                                    </div>
                                </div>
                                <div className="row_col04">
                                    <span>{final_ai_score_detail_dict.assist_dict.total_score?.toFixed(1)}</span>
                                </div>
                            </div>
                            {/* 오브젝트 */}
                            <div
                                className={`row positive ${final_ai_score_detail_dict.object_kill_dict.total_num === 0 ? 'off' : 'on'}`}
                            >
                                <div className="row_col01">
                                    <span>{t('modal.aiscores.objective')}</span>
                                </div>
                                <div className="row_col02">
                                    <span>{final_ai_score_detail_dict.object_kill_dict.total_num}</span>
                                </div>
                                <div className="row_col03">
                                    <div className="target">
                                        {findChamp(final_ai_score_detail_dict.object_kill_dict?.from).champIds.map((item, index) => (
                                            <div className="item" key={index}>
                                                {item === 'Dragon' || item === 'Elder_Dragon' || item === 'Baron' || item === 'Riftherald' || item === 'Turret' || item === 'Execution' || item === 'Inhibitor' ? (findIcons(item, modalData.side)) : (
                                                    <ChampionImageWithLane champion_id={item}/>)}
                                                <span>
                                  {findChamp(final_ai_score_detail_dict.object_kill_dict?.from).champNums[index]}
                                </span>
                                            </div>))}
                                    </div>
                                </div>
                                <div className="row_col04">
                                    <span>{final_ai_score_detail_dict.object_kill_dict.total_score?.toFixed(1)}</span>
                                </div>
                            </div>
                            {/* 건물 */}
                            <div
                                className={`row positive ${final_ai_score_detail_dict.turret_kill_dict.total_num === 0 ? 'off' : 'on'}`}
                            >
                                <div className="row_col01">
                                    <span>{t('modal.aiscores.structure')}</span>
                                </div>
                                <div className="row_col02">
                                    <span>{final_ai_score_detail_dict.turret_kill_dict.total_num}</span>
                                </div>
                                <div className="row_col03">
                                    <div className="target">
                                        {findChamp(final_ai_score_detail_dict.turret_kill_dict?.from).champIds.map((item, index) => (
                                            <div className="item" key={index}>
                                                {item === 'Dragon' || item === 'Elder_Dragon' || item === 'Baron' || item === 'Riftherald' || item === 'Turret' || item === 'Execution' || item === 'Inhibitor' ? (modalData.side === 'blue' ? (findIcons(item, 'red')) : (findIcons(item, 'blue'))) : (
                                                    <ChampionImageWithLane champion_id={item}/>)}
                                                <span>
                                  {findChamp(final_ai_score_detail_dict.turret_kill_dict?.from).champNums[index]}
                                </span>
                                            </div>))}
                                    </div>
                                </div>
                                <div className="row_col04">
                                    <span>{final_ai_score_detail_dict.turret_kill_dict.total_score?.toFixed(1)}</span>
                                </div>
                            </div>
                            <hr/>
                            {/* 솔로 데스 */}
                            <div
                                className={`row negative ${final_ai_score_detail_dict.solo_death_dict.total_num === 0 ? 'off' : 'on'}`}
                            >
                                <div className="row_col01">
                                    <span>{t('modal.aiscores.soloDeath')}</span>
                                </div>
                                <div className="row_col02">
                                    <span>{final_ai_score_detail_dict.solo_death_dict.total_num}</span>
                                </div>
                                <div className="row_col03">
                                    <div className="target">
                                        {findChamp(final_ai_score_detail_dict.solo_death_dict?.from).champIds.map((item, index) => (
                                            <div className="item" key={index}>
                                                {item === 'Execution' ? (findIcons(item, modalData.side)) : (
                                                    <ChampionImageWithLane champion_id={item}/>)}
                                                <span>
                                  {findChamp(final_ai_score_detail_dict.solo_death_dict?.from).champNums[index]}
                                </span>
                                            </div>))}
                                    </div>
                                </div>
                                <div className="row_col04">
                                    <span>{final_ai_score_detail_dict.solo_death_dict.total_score?.toFixed(1)}</span>
                                </div>
                            </div>
                            {/* 협동 데스 */}
                            <div
                                className={`row negative ${final_ai_score_detail_dict.assist_death_dict.total_num === 0 ? 'off' : 'on'}`}
                            >
                                <div className="row_col01">
                                    <span>{t('modal.aiscores.duoDeath')}</span>
                                </div>
                                <div className="row_col02">
                                    <span>{final_ai_score_detail_dict.assist_death_dict.total_num}</span>
                                </div>
                                <div className="row_col03">
                                    <div className="target">
                                        {findChamp(final_ai_score_detail_dict.assist_death_dict?.from).champIds.map((item, index) => (
                                            <div className="item" key={index}>
                                                <ChampionImageWithLane champion_id={item}/>
                                                <span>
                                  {findChamp(final_ai_score_detail_dict.assist_death_dict?.from).champNums[index]}
                                </span>
                                            </div>))}
                                    </div>
                                </div>
                                <div className="row_col04">
                                    <span>{final_ai_score_detail_dict.assist_death_dict.total_score?.toFixed(1)}</span>
                                </div>
                            </div>
                            {/* 오브젝트 */}
                            <div
                                className={`row negative ${final_ai_score_detail_dict.object_death_dict.total_num === 0 ? 'off' : 'on'}`}
                            >
                                <div className="row_col01">
                                    <span>{t('modal.aiscores.objective')}</span>
                                </div>
                                <div className="row_col02">
                                    <span>{final_ai_score_detail_dict.object_death_dict.total_num}</span>
                                </div>
                                <div className="row_col03">
                                    <div className="target">
                                        {findChamp(final_ai_score_detail_dict.object_death_dict?.from).champIds.map((item, index) => (
                                            <div className="item" key={index}>
                                                {item === 'Dragon' || item === 'Elder_Dragon' || item === 'Baron' || item === 'Riftherald' || item === 'Turret' || item === 'Execution' || item === 'Inhibitor' ? (findIcons(item, modalData.side)) : (
                                                    <ChampionImageWithLane champion_id={item}/>)}
                                                <span>
                                  {findChamp(final_ai_score_detail_dict.object_death_dict?.from).champNums[index]}
                                </span>
                                            </div>))}
                                    </div>
                                </div>
                                <div className="row_col04">
                                    <span>{final_ai_score_detail_dict.object_death_dict.total_score?.toFixed(1)}</span>
                                </div>
                            </div>
                            {/* 건물 */}
                            <div
                                className={`row negative ${final_ai_score_detail_dict.turret_death_dict.total_num === 0 ? 'off' : 'on'}`}
                            >
                                <div className="row_col01">
                                    <span>{t('modal.aiscores.structure')}</span>
                                </div>
                                <div className="row_col02">
                                    <span>{final_ai_score_detail_dict.turret_death_dict.total_num}</span>
                                </div>
                                <div className="row_col03">
                                    <div className="target">
                                        {findChamp(final_ai_score_detail_dict.turret_death_dict?.from).champIds.map((item, index) => (
                                            <div className="item" key={index}>
                                                {item === 'Dragon' || item === 'Elder_Dragon' || item === 'Baron' || item === 'Riftherald' || item === 'Turret' || item === 'Execution' || item === 'Inhibitor' ? (findIcons(item, modalData.side)) : (
                                                    <ChampionImageWithLane champion_id={item}/>)}
                                                <span>
                                  {findChamp(final_ai_score_detail_dict.turret_death_dict?.from).champNums[index]}
                                </span>
                                            </div>))}
                                    </div>
                                </div>
                                <div className="row_col04">
                                    <span>{final_ai_score_detail_dict.turret_death_dict.total_score?.toFixed(1)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="t_foot">
                            <dl>
                                <dt>{t('modal.aiscores.positiveScore')}</dt>
                                <dd className="positive">+{final_ai_score_detail_dict.plus_score?.toFixed(1)}</dd>
                            </dl>
                            <dl>
                                <dt>{t('modal.aiscores.negativeScore')}</dt>
                                <dd className="negative">{final_ai_score_detail_dict.minus_score?.toFixed(1)}</dd>
                            </dl>
                            <dl>
                                <dt>{t('modal.aiscores.defaultScore')}</dt>
                                <dd className="positive">50.0</dd>
                            </dl>
                        </div>
                        <div className="t_result">
                            <dl>
                                <dt>{t('modal.aiscores.totalResult')}</dt>
                                <dd>{scoreColor((50 + final_ai_score_detail_dict.total_score)?.toFixed(1))}</dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>)}
        </div>) : (
            <div className="modal builds">
                <DetailBuildContent modalData={modalData}/>
            </div>)}
    </ModalContainer>);
};

export default React.memo(Modal);
