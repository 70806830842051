import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 6px 12px;
  border-bottom: 1px solid ${props => props.theme.color.default_border_color};
  font-weight: 500;
  font-size: 12px;
  background-color: ${props => props.theme.color.white_100};
`;


const ChampionsDetailTitle = ({children}) => {
    return (
        <Wrapper>
            {children}
        </Wrapper>
    );
};

export default ChampionsDetailTitle;