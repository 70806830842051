import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import styled from "styled-components";
import BalanceAram from "./aram/BalanceAram";
import UseSetCanonical from "../../common/hooks/useSetCanonical";

const Wrapper = styled.div`
`;
const Balance = () => {
    UseSetCanonical();

    // useAdHooks(center_content);

    return (
        <Wrapper>
            <Switch>
                <Route path={"/balance/:game/:position"}>
                    <BalanceAram/>
                </Route>
                <Route path="/balance">
                    <Redirect to={'/balance/aram/all'}/>
                </Route>
            </Switch>
        </Wrapper>
    );
};

export default Balance;
