import React, {useEffect, useRef} from 'react';
import {throttle} from "lodash";

const UseRailScrollEvent = () => {
    const ref = useRef(null)
    const handleScroll = () => {

        if (ref?.current) {
            if (window.scrollY > 138) {
                ref.current.style.position = "fixed";
                ref.current.style.top = '10px'
            } else {
                ref.current.style.position = 'absolute';
            }
        }
    }

    useEffect(() => {
        const scroll = throttle(handleScroll, 100);
        window.addEventListener('scroll', scroll);
        return () => {
            window.removeEventListener('scroll', scroll); //clean up
        };
    }, []);

    return ref
};

export default UseRailScrollEvent;