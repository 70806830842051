import React, {useMemo} from 'react';
import styled, {useTheme} from "styled-components";
import {useTranslation} from "react-i18next";

const UnRegText = styled.span`
  color: ${props => props.theme.color.dark_200};
  font-size: 24px;
  line-height: 29px;
  font-weight: bold;
  margin-top: 20px;
`;
const Extra = styled.span`
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 14px;
  text-align: center;
  white-space: pre;
  color: ${props => props.theme.color.dark_200};
  line-height: 24px;
`;


const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
`;

const Box = styled.div`
  background-color: ${props => props.theme.color.site_main_color};
  width: 1080px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 8px;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.color.default_border_color};
`;


const SummonerName = styled.span`
  color: ${props => props.theme.color.dark_100};
  line-height: 36px;
  font-weight: bold;
  font-size: 30px;
  letter-spacing: 0.6px;
`;
const TagName = styled.span`
  color: ${props => props.theme.color.green_100};
  margin-left: 4px;
`;
const NameText = styled.span`
  color: ${props => props.theme.color.green_100};
`;
const SummonerNotFound = ({summonerName = ''}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const getName = useMemo(() => {
        try {
            if (summonerName.indexOf('-') > 0) {
                const splitName = summonerName.split('-');
                return splitName[0];
            }
            return summonerName;
        } catch (e) {
            return summonerName;
        }
    }, [summonerName]);

    const getTag = useMemo(() => {
        try {
            if (summonerName.indexOf('-') > 0) {
                const splitName = summonerName.split('-');
                return splitName[1];
            }
        } catch (e) {
            return "";
        }
    }, [summonerName]);
    return (
        <ContentWrapper>
            <Box>
                <SummonerName>“<NameText>{getName}
                    {getTag && <TagName>#{getTag}</TagName>}
                </NameText>
                    “
                </SummonerName>
                <UnRegText>{t('summoner.notRegister')}</UnRegText>
                <Extra>{t('summoner.notFound')}</Extra>
                <img src={`${theme?.icon?.path}/ico_logo_w.png`} width={160} height={28}/>
            </Box>
        </ContentWrapper>
    );
};

export default SummonerNotFound;
