import React, {useEffect} from 'react';
import {useDescription, useTitle} from "../../function";

const UseTitleAndDescription = ({title,description}) => {
    const titleUpdate = useTitle(title)
    const descriptionUpdate = useDescription(description)
    useEffect(() => {
        titleUpdate(title)
    }, [title]);

    useEffect(() => {
        descriptionUpdate(description)
    }, [description]);

    return null
};

export default UseTitleAndDescription;