import React, {Suspense} from 'react';
import DailyHeader from "../content/DailyHeader";
import DailyContentTable from "../content/DailyContentTable";
import styled from "styled-components";
import DailyAramFilter from "./DailyAramFilter";

const Wrapper = styled.div`
  width: 100%;
`;

const tempList = [
    {
        summonerName: 'ShowMaker',
        tag: "#kr1",
        aiScore: 100,
        status: 'pro',
        kills: 5.32,
        death: 6.42,
        assist: 8.23,
        damage: 4000,
        pentaKills: 4,
        rank: 1,
    },
    {
        summonerName: 'dsdsds',
        tag: "#asdkljs",
        aiScore: 40,
        status: '-',
        kills: 5.32,
        death: 8.42,
        assist: 8.23,
        damage: 6000,
        pentaKills: 0,
        rank: 2,
    },
    {
        summonerName: 'dsdsds',
        tag: "#asdkljs",
        aiScore: 70,
        status: 'Streamer',
        kills: 5.32,
        death: 2.42,
        assist: 1.23,
        damage: 8000,
        pentaKills: 9,
        rank: 3
    },
    {
        summonerName: 'dsdsds',
        tag: "#ajksnd",
        aiScore: 50,
        status: '-',
        kills: 5.32,
        death: 3.42,
        assist: 18.23,
        damage: 8000,
        pentaKills: 9,
        rank: 4
    }
]
const DailyAramContent = () => {
    return (
        <Wrapper>
            <DailyHeader/>
            <DailyAramFilter />
            <Suspense>
                <DailyContentTable list={tempList}/>
            </Suspense>
        </Wrapper>
    );
};

export default DailyAramContent;