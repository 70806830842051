import React from 'react';
import styled, {useTheme} from "styled-components";
import BigTooltip from "../../../../Tooltip/BigTooltipBox";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  position: relative;
  background: ${props => props.theme.color.site_main_color};
`;

const TopBar = styled.div`
  height: 4px;
  width: 100%;
  background: ${props => {
    let background = 'transparent';
    if (props.backgorund) background = props.backgorund;
    return background;
  }};
`;

const HeaderTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
`;
const HeaderText = styled.span`
  font-weight: bold;
  font-size: 12px;
`;

const AbsoluteWrapper = styled.div`
  position: absolute;
  right: 10px;
`;

const AgainstHeader = ({barColor,synergy, children}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    if (typeof children !== 'string') return null;
    return (
        <Wrapper>
            <TopBar backgorund={barColor}/>
            <HeaderTextWrapper>
                <HeaderText>
                    {children}
                </HeaderText>
                {synergy &&
                <AbsoluteWrapper>

                    <BigTooltip
                        tooltipText={t('championDetailBuild.synergyTooltip')}>
                        <img src={`/images/list_btn_tip.svg`} alt={''}/>
                    </BigTooltip>

                </AbsoluteWrapper>}
            </HeaderTextWrapper>
        </Wrapper>
    );
};

export default AgainstHeader;