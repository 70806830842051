import React, {useState} from 'react';
import styled from "styled-components";
import {bookmarkList, bookmarkListAddPro} from "../../../store/modules/bookmarkList";
import {useDispatch, useSelector} from "react-redux";
import ErrorBox from "../Error";
import {useTranslation} from "react-i18next";

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 3px;
  padding: 0 2px;
  font-size: 12px;
  transition: all 0.2s linear;

  &::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
  }

  &.off {
    opacity: 0.4;

    &:hover {
      opacity: 1;
    }

    &::before {
      background: url('/images/ico_star_off.svg') no-repeat center;
    }
  }

  &.on {
    opacity: 1;

    &::before {
      background: url('/images/ico_star_on.svg') no-repeat center;
    }
  }
}
`;
const AbsoluteBox = styled.div`
  position: absolute;
  width: 300px;
  bottom: 100px;
  z-index: 999999;

  .errorStyle {
    z-index: 999999;
    padding: 14px 0;
  }
`;


const Favorite = ({isSelected, item}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const bookmarkslength = useSelector((state) => state?.bookmarkList?.bookmarks?.length);
    const [openError, setOpenError] = useState(false);


    const opencloseError = () => {
        setOpenError(true);
        setTimeout(() => {
            setOpenError(false);
        }, 2000);
    };

    const toggleBookmark = () => {
        if (isSelected) {
            dispatch(bookmarkList(item, 'remove'));
        } else {
            if (bookmarkslength > 11) {
                opencloseError()
            } else {
                dispatch(bookmarkListAddPro({...item}));
            }

        }
    };


    return (
        <Wrapper className={isSelected ? 'on' : 'off'} onClick={toggleBookmark}>
            {openError &&
                <AbsoluteBox>
                    <ErrorBox
                        infoText={t('error.favorite_excess')}
                    />
                </AbsoluteBox>}
        </Wrapper>
    );
};

export default Favorite;