import React from 'react';
import styled from "styled-components";

const ImgBox = styled.button`

`;


const InfluSns = ({influData}) => {
    const snsSrc = [{
        title: 'twitch',
        src: '/images/icn_sns_twi.svg',
        url: influData?.twitch,
    }, {
        title: 'africa',
        src: '/images/icn_sns_afri.svg',
        url: influData?.afreecatv,
    }, {
        title: 'youtube',
        src: '/images/icn_sns_yout.svg',
        url: influData?.youtube,
    }, {
        title: 'twitter',
        src: '/images/icn_sns_twitter.svg',
        url: influData?.twitter,
    }, {
        title: 'kick',
        src: '/images/icn_sns_kick.svg',
        url: influData?.kick,
    }, {
        title: 'chzzk',
        src: '/images/icn_sns_chzzk.svg',
        url: influData?.chzzk,
    }];


    return (
        <div className={'influ-sns-wrapper'}>
            {snsSrc.map((data, index) => {
                if (data.url !== '-' && data.url) {
                    const onClickImgBox = (event) => {
                        event.stopPropagation();
                        window.open(data.url, "_blank",);
                    }
                    return (
                        <ImgBox className={'influ-sns-icon'} key={'indlue' + index} onClick={onClickImgBox}>
                            <img
                                src={data.src}
                                alt={data.title}
                            />
                        </ImgBox>
                    )
                }
                return null;

            })}
        </div>
    )
};

export default InfluSns;