import React, {useMemo, useState} from 'react';

const laneSortValue = (position) => {
    switch (position) {
        case "bot":
            return 4;
        case "top":
            return 1;
        case "jungle":
            return 2;
        case "middle" :
            return 3;
        case "supporter":
            return 5;
        default:
            return 99
    }
}
const UseChampionBuildHooks = (buildList, selectedLane) => {
    const [coreSelected, onChangeCoreSelected] = useState(0);

    const resetCoreSelected = () => {
        onChangeCoreSelected(0);
    }
    const memoBuildList = useMemo(() => {

        try {
            if (Array.isArray(buildList) && buildList.length > 0) {
                return buildList;
            }
            return [];
        } catch (e) {
            return [];
        }
    }, [buildList]);

    const totalGames = useMemo(() => {
        try {
            return buildList.reduce((sum, cur) => {
                if (['top', 'middle', 'bot', 'jungle', 'supporter'].includes(cur.lane.toLowerCase())) {
                    sum += Number(cur.games)
                }
                return sum;
            }, 0);
        } catch (e) {
            return 0;
        }
    }, [buildList]);

    const lanePick = useMemo(() => {
        try {
            const pickList = buildList.reduce((sum, cur) => {
                if (cur.lane !== 'All') {
                    sum[cur.lane] = {
                        lane: cur.lane,
                        games: cur.games + (sum[cur.lane]?.games || 0),
                    };
                }

                return sum;
            }, {});

            const sortedList = Object.values(pickList).sort((a, b) => {
                const aLaneValue = laneSortValue(a.lane.toLowerCase())
                const bLaneValue = laneSortValue(b.lane.toLowerCase())
                if (aLaneValue > bLaneValue) {
                    return 1;
                } else if (aLaneValue === bLaneValue) {
                    return 0;
                } else if (aLaneValue < bLaneValue) {
                    return -1;
                }
            });
            return sortedList.map((data) => {
                return {
                    ...data,
                    pick_rate: (data.games / totalGames * 100).toFixed(0)
                }
            })

        } catch (e) {
            return [];
        }
    }, [memoBuildList]);


    const coreList = useMemo(() => {
        try {
            const findList = memoBuildList.find((data) => data?.lane?.toLowerCase() === selectedLane);

            if (findList) {
                return findList
            }
            return []
        } catch (e) {
            return []
        }
    }, [memoBuildList,selectedLane]);

    return {
        lanePick,
        resetCoreSelected,
        onChangeCoreSelected,
        coreSelected,
        coreList,
        selectedLane
    }
};

export default UseChampionBuildHooks;