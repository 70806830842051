// 액션 생성

const LANGUAGE = 'translation/LANGUAGE';

// 액션 함수 내보내기
export const translation = (lang) => ({type: LANGUAGE, lang});

// 초기 상태 값
const lang = window.navigator.language.toLowerCase().substring(0, 2);

const initLang = () => {
    const storedCookie = localStorage.getItem(process.env.REACT_APP_LANG_COOKIE);


    if (storedCookie) {
        if (storedCookie === 'vi-VN') {
            return "vi"
        } else if (["ko-KR", "ko"].includes(storedCookie) || storedCookie === 'en') {

            return ["ko-KR", "ko"].includes(storedCookie) ? 'KOR' : "ENG";
        } else {
            return storedCookie
        }
    } else if (lang === 'vi') {
        return 'vi';
    } else if (lang) {
        return lang === 'ko' ? 'KOR' : 'ENG';
    }

    return 'ENG';
}

const initialState = {
    lang: initLang(),
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LANGUAGE:
            let subText = action.lang.toLowerCase().substring(0, 2);

            if (subText) {
                const meta = document.querySelector('meta[http-equiv=content-language]');
                meta.setAttribute('content', subText === 'ko' ? 'ko' : 'en');
            }
            if (subText === 'ko' || subText === 'en') {
                subText = subText === 'ko' ? 'KOR' : 'ENG';
            } else {
                subText = action.lang;
            }

            return {
                lang: subText
            };
        default:
            return state;
    }
};
export default reducer;
