import React from 'react';
import styled from "styled-components";
import TierTag from "../../../../../common/TierTag";
import {threedigits} from "../../../../../../function";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const LPSpan = styled.span`
  font-size: 12px;
  margin-left: 12px;
  color: ${props => props.theme.color.dark_100};
`;

const LPText = styled.span`
  margin-left: 1px;
  font-size: 12px;
  color: ${props => props.theme.color.dark_200};
`;


const RankingTier = ({tier, division, lp}) => {
    return (
        <Wrapper>
            <TierTag
                fontSize={'11px'}
                padding={'4px 4px'}
                tier={tier}
                division={division}
            />
            <LPSpan>{threedigits(lp)}<LPText>LP</LPText></LPSpan>
        </Wrapper>
    );
};

export default RankingTier;
