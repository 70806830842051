import React, {Suspense} from 'react';
import {Header, Loading} from "../common";
import CenterContentBox from "../Champions/ContentWrapper";
import {ErrorBoundary} from "react-error-boundary";
import styled from "styled-components";
import EventContent from "./EventContent";
import useTitleAndDescription from "../../common/hooks/useTitleAndDescription";
import {useTranslation} from "react-i18next";


const Wrapper = styled.div`
`;

function Fallback({error, resetErrorBoundary}) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.

    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    );
}


const Event = () => {
    const {t} = useTranslation();
    useTitleAndDescription({
        title: t('gemagema.headerTitle'),
        description: t('gemagema.headerDesc')
    })
    return (
        <Wrapper>
            <Header/>
            <CenterContentBox disableSponsor={true}>
                <ErrorBoundary FallbackComponent={Fallback}>
                    <Suspense fallback={<Loading/>}>
                        <EventContent/>
                    </Suspense>
                </ErrorBoundary>
            </CenterContentBox>
        </Wrapper>
    );
};

export default Event;