import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.site_main_color};
  margin-bottom: 20px;
  
`;
const Table = styled.table`
  border-style: hidden;

  th, td {
    border: 1px solid ${props => props.theme.color.default_border_color};
    border-collapse: collapse;
  }
`;

const HeaderTr = styled.tr`
  height: 60px;

  th:first-child {
    border-top-left-radius: 6px;
  }

  th:last-of-type {
    border-top-left-radius: 6px;
  }
`;
const HeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 159px;
`;
const ResultDiv = styled.div`
  width: 139px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const ResultSpan = styled.span`
  font-weight: bold;
  font-size: 20px;

`;

const HeaderTh = styled.th`
  vertical-align: middle;
`;

const TeamText = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.color.green_100};
  line-height: 21px;
`;
const HeaderSpan = styled.span`
  font-size: 20px;
  font-weight: bold;
`;
const TeamSpan = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_200};
  line-height: 20px;
  text-transform: capitalize;
`;

const LeaderImage = styled.img`
  border-radius: 6px;
`;
const TeamBody = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 0 0 0 12px;
`;
const TeamBodyTeam = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90px;
`;

const EmptyScoreBox = styled.div`
  width: 100%;
  height: 60px;
  background-color: ${props => props.theme.color.white_200};
`;
const ScoreBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const ScoreSpan = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_100};
`;
const BodyTr = styled.tr`
  height: 60px;
`;
const BodyTd = styled.td`
  vertical-align: middle;
`;
const EventScrimTable = ({scrim, teamArray}) => {
    const {t} = useTranslation()
    return (
        <Wrapper>
            <Table>
                <thead>
                <HeaderTr>
                    <HeaderTh>
                        <HeaderDiv>
                            <HeaderSpan>{t('event.scrim.title')}</HeaderSpan>
                        </HeaderDiv>
                    </HeaderTh>
                    {teamArray.map((data, index) => {
                        return (
                            <HeaderTh key={index}>
                                <HeaderDiv>
                                    <TeamText>
                                        TEAM {index + 1}
                                    </TeamText>
                                    <TeamSpan>{data.leader.name}</TeamSpan>
                                </HeaderDiv>
                            </HeaderTh>
                        )
                    })}
                    <HeaderTh>
                        <ResultDiv>
                            <ResultSpan>{t('event.scrim.result')}</ResultSpan>
                        </ResultDiv>
                    </HeaderTh>
                    <HeaderTh>
                        <ResultDiv>
                            <ResultSpan>{t('event.scrim.winRate')}</ResultSpan>
                        </ResultDiv>
                    </HeaderTh>
                </HeaderTr>
                </thead>
                <tbody>
                {teamArray.map((data, index) => {
                    const scrimData = scrim[index]
                    return (
                        <BodyTr key={index}>
                            <BodyTd>
                                <TeamBody>
                                    <LeaderImage src={`images/event/leader_${data.leader.name}.svg`} width={36}
                                                 height={36} alt={''}/>
                                    <TeamBodyTeam>
                                        <TeamText>
                                            TEAM {index + 1}
                                        </TeamText>
                                        <TeamSpan>{data.leader.name}</TeamSpan>
                                    </TeamBodyTeam>
                                </TeamBody>
                            </BodyTd>
                            <BodyTd>
                                {index === 0 ?
                                    <EmptyScoreBox/>
                                    :
                                    <TeamVersus win={scrimData.team_1_win} lose={scrimData.team_1_lose}/>
                                }
                            </BodyTd>
                            <BodyTd>
                                {index === 1 ?
                                    <EmptyScoreBox/>
                                    :
                                    <TeamVersus win={scrimData.team_2_win} lose={scrimData.team_2_lose}/>
                                }
                            </BodyTd>
                            <BodyTd>
                                {index === 2 ?
                                    <EmptyScoreBox/>
                                    :
                                    <TeamVersus win={scrimData.team_3_win} lose={scrimData.team_3_lose}/>
                                }
                            </BodyTd>
                            <BodyTd>
                                {index === 3 ?
                                    <EmptyScoreBox/>
                                    :
                                    <TeamVersus win={scrimData.team_4_win} lose={scrimData.team_4_lose}/>
                                }
                            </BodyTd>
                            <BodyTd>
                                <ScoreBox>
                                    <ScoreSpan>{scrimData.total_win} - {scrimData.total_lose}</ScoreSpan>
                                </ScoreBox>
                            </BodyTd>
                            <BodyTd>
                                <ScoreBox>
                                    <ScoreSpan>{scrimData.win_per} %</ScoreSpan>
                                </ScoreBox>
                            </BodyTd>
                        </BodyTr>
                    )
                })}

                </tbody>
            </Table>
        </Wrapper>
    );
};

const TeamVersus = ({win,lose}) => {
   if(win === 0 && lose === 0)  {
       return null
   }
   return (
       <ScoreBox>
           <ScoreSpan>{win} - {lose}</ScoreSpan>
       </ScoreBox>
   )
}

export default EventScrimTable;