import axios from "axios";


// https://renew-dev.deeplol.gg/summoner/strm_pro_info?status=pro&name=faker
export const proAndStreamerAPI = async ({status, name}) => {
    const url = new URL(`${process.env.REACT_APP_CACHE_API}summoner/strm_pro_info`);
    url.searchParams.set('name', name);
    url.searchParams.set('status', status);
    const result = await axios.get(url.toString())
    return result.data;
}
