import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import useQuery from "../../../../common/hooks/useQuery";

const TierSelector = styled.select`
  cursor: pointer;
  padding: 6px 10px;
  border-radius: 6px;
  width: ${props => props.width}px;
  height: 32px;
  font-weight: 500;
  font-size: 16px;
  color:${props => props.theme.color.dark_100};
  border-color: ${props => {
    let color =  props.theme.color.dark_200;
    if (props.borderColor) color = props.borderColor;
    return color;
  }};
  -webkit-appearance: none;
  background: ${props => `url("${props.theme.icon.path}/icon_select_arrow.svg")`} no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  background-color: ${props => props.theme.color.site_main_color};

  &:hover {
    background-color: ${props => props.theme.color.white_200};
  }
`;


const SelectWrapper = styled.div`
  position: relative;
`;


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  ${SelectWrapper}:not(:last-of-type) {
    margin-right: 8px;
  }
`;
const ChampionFilter = ({borderColor, selectVersion, currentVersion, selectTier, currentTier, disableTier}) => {
    const oldVersion = ['13.13', '13.12'];
    useDispatch();
    const versions = useSelector((state) => state.champions.championVersions);
    useQuery();

    const tierList = useMemo(() => {
        return oldVersion.includes(currentVersion) ?
            ['Platinum+',
                "Diamond+",
                "Master+",
                "Grandmaster+",
                "Challenger+"]
            :
            ['Emerald+',
                "Diamond+",
                "Master+",
                "Grandmaster+",
                "Challenger+"]
    },[currentVersion]);


    const legionList = ['KR'];


    const onSelectTier = useCallback((e) => {
        if (e?.target?.value && selectTier) {
            selectTier(e.target.value)
        }
    }, [selectTier]);

    const onSelectVersion = useCallback((e) => {
        if (e?.target?.value && selectVersion) {
            selectVersion(e.target.value)
        }
    }, [selectVersion]);


    return (<Wrapper>
        {!disableTier && <SelectWrapper>
            <TierSelector width={144} borderColor={borderColor} onChange={onSelectTier} value={currentTier}>
                {tierList.map((data, index) => (<option value={data} key={index}>{data}</option>))}
            </TierSelector>
        </SelectWrapper>}
        <SelectWrapper>
            <TierSelector width={80} borderColor={borderColor}>
                {legionList.map((data, index) => (<option value={data} key={index}>{data}</option>))}
            </TierSelector>

        </SelectWrapper>
        <SelectWrapper>
            <TierSelector width={80} borderColor={borderColor} onChange={onSelectVersion} value={currentVersion}>
                {versions.map((data, index) => (<option value={data.toString()} key={index}>{data}</option>))}
            </TierSelector>

        </SelectWrapper>
    </Wrapper>);
};

export default ChampionFilter;
