import {GET_SPECTATE_LIVE_FAILURE, GET_SPECTATE_LIVE_REQUEST, GET_SPECTATE_LIVE_SUCCESS} from "../reducer/spectate";

const initialState = {
    live: {
        "spectator_data_list": []
    },
    getSepctateLiveLoading: false,
    getSepctateLiveDone: false,
    getSepctateLiveError: null,
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SPECTATE_LIVE_REQUEST: {
            return {
                ...state,
                getSepctateLiveLoading: true,
                getSepctateLiveDone: false,
                getSepctateLiveError: null,
            }
        }
        case GET_SPECTATE_LIVE_SUCCESS: {
            return {
                ...state,
                live: action.data,
                getSepctateLiveLoading: false,
                getSepctateLiveDone: true,
                getSepctateLiveError: null,
            }
        }
        case GET_SPECTATE_LIVE_FAILURE: {
            return {
                ...state,
                getSepctateLiveLoading: false,
                getSepctateLiveDone: false,
                getSepctateLiveError: action.error,
            }
        }
        default:
            return state;
    }
};
export default reducer;
