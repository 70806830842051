import React from 'react';
import styled from "styled-components";
import {ImageRune} from "../ImageSrc";


const RuneImageBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
const RoundImage = styled.div`
  img {
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    border-radius: 50%;
    background-color: black;
    overflow: hidden;
  }
`;

const BorderRadiusImage = styled.div`
  position: absolute;
  bottom: 0;
  right: ${props => props.absolutePosition};
  background-color: black;
  overflow: hidden;
  border-radius: 50%;
  img {
    width: ${props => props.width}px;
    height: ${props => props.height}px;
  }
`;

const CombinedRuneImages = ({primary,sub,primaryAttr,subAttr,RuneComponent = ImageRune,absolutePosition = '-4px'}) => {
    return (
        <RuneImageBox >
            <RoundImage width={primaryAttr.width} height={primaryAttr.height}>
                <RuneComponent runeId={primary}/>
            </RoundImage>
            <BorderRadiusImage width={subAttr.width} height={subAttr.height} absolutePosition={absolutePosition}>
                <RuneComponent runeId={sub}/>
            </BorderRadiusImage>
        </RuneImageBox>
    );
};

export default CombinedRuneImages;