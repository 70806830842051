import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

const UseChampBuildFunc = () => {
    const buildInfo = useSelector((state) => state?.champions?.build);
    const location = useLocation();
    const urls = useMemo(() => Array.isArray(buildInfo?.build) ? buildInfo.build.map((data) => data.lane.toLowerCase()) : [], [buildInfo]);

    const currentLane = useMemo(() => {
        if (Array.isArray(urls)) {
            if (location.pathname.split("/")[4]) {
                const index = urls.findIndex((data) => data === location.pathname.split("/")[4]);
                if (index < 0) {
                    return -1;
                } else {
                    return index;
                }
            }
        }
        return 0;
    }, [location, urls]);

    const currentBuild = useMemo(() => Array.isArray(buildInfo?.build) && buildInfo?.build[currentLane], [buildInfo?.build, currentLane]);

    return {
        currentBuild
    }
};

export default UseChampBuildFunc;