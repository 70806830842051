import React from 'react';
import CenterContentBox from "../../Champions/ContentWrapper";
import {useSelector} from "react-redux";
import URFSearch from "./urfSearch";
import URFRank from "./urfRank";
import useDispatchChampRank from "../../../common/hooks/useDispatchChampions";
import {GET_CHAMPIONS_RANK_REQUEST} from "../../../store/reducer/champions";


const URFContent = () => {
    useDispatchChampRank(GET_CHAMPIONS_RANK_REQUEST);
    // useDispatchChampRank(GET_CHAMPIONS_RANK_REQUEST);
    const currentLang = useSelector((state) => state.translation.lang)
    return (
        <CenterContentBox>
            <URFSearch key={currentLang}/>
            <URFRank/>
        </CenterContentBox>
    );
};

export default URFContent;
