import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import BigTooltip from "../../../../../../../Tooltip/BigTooltipBox";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextWrapper = styled.div`
  margin-bottom: 2px;
`;

const WardText = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.dark_200};
  font-weight: 500;
`;

const WardInfo = ({bought,placed,removed}) => {
    const {t} = useTranslation();
    return (
        <BigTooltip
            tooltipText={`${t('match.boughtWard')} : ${bought}\n${t('match.placedWard')} : ${placed}\n${t('match.destoryWard')} : ${removed}`}
        >
        <Wrapper>
            <TextWrapper>
                <WardText>{bought}</WardText>
            </TextWrapper>
            <WardText>{placed} / {removed}</WardText>
        </Wrapper>
        </BigTooltip>
    );
};

export default WardInfo;
