import React from 'react';
import styled from "styled-components";
import Dropdown from "../../compound/Dropdown";
import {FlexBox} from "../../ui/styled";
import {AI_SCORE_BAD, AI_SCORE_GOOD, AI_SCORE_PERFECT, AI_SCORE_VERY_BAD} from "../../../styled/scoreColor";
import {useTranslation} from "react-i18next";


const FlexOneBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
const ExplainBox = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  border: 1px solid ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.site_main_color};
  width: 167px;
  padding: 4px 5px 4px 0;
  cursor: pointer;
`;

const ExplainText = styled.span`
  flex: 1;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  color: ${props => props.theme.color.dark_100};
`;

const DropdownAboslute = styled.div`
  right: 0;
  top: 41px;
  z-index: 9999;
  position: absolute;
`;
const DropdownContent = styled.div`
  padding: 16px 0 26px 20px;
  border: 1px solid ${props => props.theme.color.green_100};
  background-color: ${props => props.theme.color.site_main_color};
  border-radius: 5px;
  width: 448px;
`;
const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
`;
const HeaderText = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.dark_100};
  font-weight: bold;
`;
const HeaderExplainText = styled.span`
  color: ${props => props.theme.color.font_7F_89};
  font-size: 12px;
  line-height: 17px;
  white-space: pre-wrap;
  margin-top: 8px;
  margin-bottom: 20px;
`;

const ScoreSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const ScoreEmptyBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 20px;
  font-weight: bold;
  background-color: ${props => props.scoreColor};
  font-size: 12px;
  line-height: 17px;
  color: #fff;
  border-radius: 3px;
  margin-right: 10px;
`;
const ScoreExplainText = styled.span`
  flex: 1;
  color: ${props => props.theme.color.dark_100};
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  white-space: pre-wrap;
`;

const ReletiveBox = styled.div`
  position: relative;
`;
const AIScoreExplain = () => {
    const {t} = useTranslation();
    return (
        <FlexOneBox>
            <ReletiveBox>
                <Dropdown>
                    <Dropdown.Button>
                        <Dropdown.ExplainButton/>
                    </Dropdown.Button>
                    <Dropdown.List>
                        <DropdownAboslute>
                            <DropdownContent>
                                <HeaderSection>
                                    <HeaderText> {t("explain.aiScoreHeader")}</HeaderText>
                                    <HeaderExplainText>{t('explain.aiScoreExplain')}</HeaderExplainText>
                                </HeaderSection>
                                <HeaderSection>
                                    <HeaderText>{t('explain.aiCharHeader')}</HeaderText>
                                    <HeaderExplainText>{t('explain.aiCharExplain')}</HeaderExplainText>
                                </HeaderSection>
                                <ScoreSection>
                                    <FlexBox>
                                        <ScoreEmptyBox scoreColor={AI_SCORE_PERFECT}>100 +</ScoreEmptyBox>
                                        <ScoreExplainText>{t('explain.perfect')}</ScoreExplainText>
                                    </FlexBox>
                                    <FlexBox margin={'8px 0 0 0'}>
                                        <ScoreEmptyBox scoreColor={AI_SCORE_PERFECT}>75~100</ScoreEmptyBox>
                                        <ScoreExplainText>{t('explain.veryGood')}</ScoreExplainText>
                                    </FlexBox>
                                    <FlexBox margin={'8px 0 0 0'}>
                                        <ScoreEmptyBox scoreColor={AI_SCORE_GOOD}>50~74</ScoreEmptyBox>
                                        <ScoreExplainText>{t('explain.good')}</ScoreExplainText>
                                    </FlexBox>
                                    <FlexBox margin={'8px 0 0 0'}>
                                        <ScoreEmptyBox scoreColor={AI_SCORE_BAD}>25~49</ScoreEmptyBox>
                                        <ScoreExplainText>{t('explain.bad')}</ScoreExplainText>
                                    </FlexBox>
                                    <FlexBox margin={'8px 0 0 0'}>
                                        <ScoreEmptyBox scoreColor={AI_SCORE_VERY_BAD}>0~24</ScoreEmptyBox>
                                        <ScoreExplainText>{t('explain.veryBad')}</ScoreExplainText>
                                    </FlexBox>
                                </ScoreSection>
                            </DropdownContent>
                        </DropdownAboslute>
                    </Dropdown.List>
                </Dropdown>
            </ReletiveBox>
        </FlexOneBox>
    );
};

export default AIScoreExplain;