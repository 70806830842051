import React, {useCallback} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {FlexBox} from "../../../../../../../ui/styled";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const DmageText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin-bottom: 2px;
  color: ${props => props.color};
  font-weight: 500;
`;

const IndicatorText = styled.div`
  font-size: 12px;
  transform: scale(0.952);
  opacity: 0.6;
  font-weight: 500;
  color: ${props => props.color};
`;

const Graph = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 4px;
  background-color: ${props => {
    let bgColor = props.theme.color.gray_100;
    if (props.bgColor) bgColor = props.bgColor;
    return bgColor;
  }};
`;
const Filled = styled.div`
  height: 4px;
  width: ${props => props.percent}%;
  background-color: ${props => props.color};
  position: relative;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const PercentText = styled.div`
  color: ${props => props.theme.color.dark_100};
  font-size: 12px;
  opacity: 0.6;
  transform: scale(0.775);
  margin-left: 1px;
  line-height: 15px;
`;

const IndicatorWrapper = styled.div`
  position: absolute;
  bottom: -18px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Absolutediv = styled.div`
  height: 5px;
  position: absolute;
  right: -3.45px;
  top: -8px;
`;

const AbsoluteBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 0;
`;

const ZeroSpan = styled.span`
  white-space: nowrap;
  font-weight: bold;
  font-size: 12px;
`;

const GradeText = styled.span`
    font-size: 12px;
  font-weight: 400;
  color: ${props => props.theme.color.dark_100};
`;

const DamageGraph = ({color, barColor, total, dmg, bgColor, addPercent,  indicator, grade, scale}) => {
    const {t} = useTranslation();
    const calcFilled = useCallback(() => {
        const damage = scale ? scale : dmg;
        return Number(Math.trunc(damage / total * 100));
    }, [total, dmg, scale]);




    return (
        <Wrapper >
            <TextWrapper>
                {grade &&
                <FlexBox justifyContent={'space-between'} width={'100%'} margin={'0 0 7px 0'}>
                    <GradeText>{t("gameAnalytics.damage")}({grade}{t('summoner.grade', {count: grade,ordinal:true})})</GradeText>
                    <GradeText>{total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</GradeText>
                </FlexBox>}
                {indicator ? null :
                    <>
                        <DmageText color={color} >{dmg?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</DmageText>
                        {addPercent &&
                            <PercentText>
                                %
                            </PercentText>
                        }
                    </>
                }
            </TextWrapper>
            <Graph bgColor={bgColor} >
                <Filled percent={calcFilled()} color={barColor}/>
            </Graph>
        </Wrapper>
    );
};

export default DamageGraph;
