import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import MasteryContent from "./MasteryContent";
import {center_content} from "../../ad/bidrich";
import useAdHooks from "../../common/hooks/useAdHooks";
import {Header} from "../common";
import UseSetCanonical from "../../common/hooks/useSetCanonical";


const Wrapper = styled.div`
`;


const Mastery = () => {
    UseSetCanonical();

    const {t} = useTranslation();

    useAdHooks(center_content);


    return (
        <Wrapper>
            <Header/>
            {/*<NotFound/>*/}
            <MasteryContent/>

        </Wrapper>
    );
};

export default Mastery;
