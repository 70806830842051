import React from 'react';
import styled, {useTheme} from "styled-components";
import {useTranslation} from "react-i18next";
import ChampionImageWithLane from "../../../common/ChampionImageWithLane";
import {useSelector} from "react-redux";
import CombinedRuneImages from "../../../common/CombinedRuneImages";
import useAIItemAPI from "../../../../common/hooks/useAIItemAPI";
import {ImageItem} from "../../../common";
import {ColumnBox, FlexBox} from "../../../ui/styled";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  border: 1px solid ${props => props.theme.color.green_100};
  border-radius: 6px;
  justify-content: space-between;
  padding: 10px;
  background-color: ${props => props.theme.color.site_main_color};
`;


const NotSupportWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 122px;
`;

const IngameAIItem = () => {

    const {
        query,
        swapChampArr,
        selectedChamp,
        onChangeSelectedChamp,
        currentPlayer,
    } = useAIItemAPI();

    if (query.isLoading) return <NotSupportWrapper/>
    if (!query?.data?.state) return <AIItemNotSupport/>


    return <IngameLayout swapChampArr={swapChampArr} selectedChamp={selectedChamp}
                         onChangeSelectedChamp={onChangeSelectedChamp} currentPlayer={currentPlayer}
                         itemData={query?.data?.item_ids ?? []} isLoading={query.isLoading}/>
};

const IngameLayout = ({swapChampArr, onChangeSelectedChamp, selectedChamp, currentPlayer, itemData, isLoading}) => {


    return (
        <Wrapper>
            <AIBuildInfo swapChampList={swapChampArr} onChangeSelected={onChangeSelectedChamp}
                         selectedChampId={selectedChamp} currentPlayer={currentPlayer}/>
            {!isLoading && <RuneCore itemData={itemData} currentPlayer={currentPlayer}/>}
        </Wrapper>
    )
}


const BuildInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & + & {
    margin-top: 10px;
  }
`;
const AIGreen = styled.div`
  color: ${props => props.theme.color.site_main_color};
  background-color: ${props => props.theme.color.green_100};
  height: 30px;
  padding: 0 10px;
  border-radius: 6px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const AIChampVs = styled.div`
  background-color: ${props => props.theme.color.white_200};
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  border-radius: 6px;
  margin-left: 7px;
  font-size: 12px;
  font-weight: 500;
`;

const ChampWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
`;

const ChampBox = styled.div`
  & + & {
    margin-left: 3px;
  }

  .imgChamp {
    width: 24px;
    height: 24px;
  }

  .imgDiv {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid ${props => {
      let color = 'transparent';
      if (props.selected) color = props.theme.color.red_100
      return color;
    }};
    opacity: ${props => {
      let opacity = '0.5';
      if (props.selected) opacity = '1'
      return opacity
    }};

    &:hover {
      opacity: 1;
    }
  }
`;
const BigChampBox = styled.div`

  .imgChamp {
    width: 62px;
    height: 62px;
    border-radius: 6px;
  }

  .imgDiv {
    width: 60px;
    height: 60px;
    border: 2px solid ${props => props.theme.color.green_100};
    border-radius: 6px;
  }

  .imgLane {
    width: 20px;
    height: 20px;
  }
`;


const AIBuildInfo = ({selectedChampId, onChangeSelected, swapChampList, currentPlayer}) => {
    const {t} = useTranslation();

    return (
        <BuildInfoWrapper>
            <Row>
                <AIGreen>
                    {t('ingame.predict.aiBuild')}
                </AIGreen>
                {swapChampList.length > 0 &&
                    <AIChampVs>
                        {t('ingame.predict.champVs')}
                        <ChampWrapper>
                            {swapChampList.map((data) => {
                                return (
                                    <ChampBox selected={selectedChampId === data.champion_id}
                                              onClick={() => onChangeSelected(data.champion_id)}
                                              key={data.champion_id}>
                                        <ChampionImageWithLane disabled={true} champion_id={data.champion_id}/>
                                    </ChampBox>
                                )
                            })}
                        </ChampWrapper>
                    </AIChampVs>}
            </Row>
            <Row>
                <BigChampBox>
                    <ChampionImageWithLane champion_id={currentPlayer.champion_id} position={currentPlayer.position}/>
                </BigChampBox>
                <AIInfoText/>
            </Row>
        </BuildInfoWrapper>
    )
}

const InfoText = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
`;
const InfoGreenText = styled.span`
  font-size: 16px;
  color: ${props => props.theme.color.green_100};
  font-weight: bold;
`;
const SummonerName = styled.span`
  font-weight: bold;
  max-width: 16ch;
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;
const AIInfoText = () => {
    const {t} = useTranslation();
    const currentLang = useSelector((state) => state.translation.lang);
    const summoner = useSelector((state) => state.summoner.summoner);


    if (currentLang === 'KOR') {
        return (
            <TextWrapper>
                <InfoText>
                    <SummonerName>
                        {summoner?.summoner_basic_info_dict?.riot_id_name}
                    </SummonerName>
                    {t('ingame.predict.summoner')} <InfoGreenText>
                    {t('ingame.predict.summonerGreen')}
                </InfoGreenText>
                </InfoText>
                <InfoText>
                    최고의 빌드를 추천합니다.
                </InfoText>
            </TextWrapper>
        )
    }


    if (currentLang === 'ja') {
        return (
            <TextWrapper>
                <InfoText>
                    <SummonerName>
                        {summoner?.summoner_basic_info_dict?.riot_id_name}
                    </SummonerName>
                    {t('ingame.predict.summoner')}
                </InfoText>
                <InfoGreenText>
                    {t('ingame.predict.summonerGreen')}
                </InfoGreenText>
            </TextWrapper>
        )
    }

    return (
        <TextWrapper>
            <InfoText>
                For <SummonerName>
                {summoner?.summoner_basic_info_dict?.riot_id_name}
            </SummonerName>
                {t('ingame.predict.summoner')}
            </InfoText>
            <InfoGreenText>
                {t('ingame.predict.summonerGreen')}
            </InfoGreenText>
        </TextWrapper>
    )
}

const RuneBox = styled.div`
  height: 100%;
  min-width: 100px;
  background-color: ${props => props.theme.color.white_200};
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
`;
const RuneWidth = styled.div`
  display: flex;
  flex-direction: row;
  width: 69px;
`;
const RuneText = styled.span`
  font-weight: bold;
  color: ${props => props.theme.color.dark_100};
  margin-top: 10px;
`;
const PlusBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 20px;
  margin: 0 10px;
`;
const ItemCoreWrapper = styled.div`
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  background-color: ${props => props.theme.color.green_200};
  height: 100%;

  .imgItem {
    border: 2px solid ${props => props.theme.color.green_100};
    border-radius: 6px;
  }
`;

const Margin5 = styled.div`
  margin: 0 5px;
  display: flex;
  align-items: center;
  height: 50px;
`;
const BuildText = styled.div`
  font-size: 12px;
  color: ${props => props.theme.color.site_main_color};
  font-weight: 500;
  background-color: ${props => props.theme.color.green_100};
  padding: 4px 16px;
  border-radius: 4px;
  margin-top: 8px;
`;
const RuneCore = ({itemData, currentPlayer}) => {
    const theme = useTheme();
    const {t} = useTranslation()
    const currentLang = useSelector((state) => state.translation.lang);


    return (
        <Row>
            <RuneBox>
                <RuneWidth>
                    <CombinedRuneImages
                        primary={currentPlayer.rune_detail_dict.perk_0}
                        sub={currentPlayer.rune_detail_dict.perk_sub_style}
                        primaryAttr={{width: 51, height: 51}}
                        subAttr={{width: 28, height: 28}}
                        absolutePosition={'-18px'}
                    />
                </RuneWidth>
                <RuneText>{t('ingame.predict.rune')}</RuneText>
            </RuneBox>
            <PlusBox>
                <img src={'images/icn_plus_20px.svg'} width={20} height={20} alt={''}/>
            </PlusBox>
            <ItemCoreWrapper>
                {itemData.map((data, index) => {
                    return (
                        <FlexBox key={index} alignItems={'flex-start'}>
                            <ColumnBox alignItems={'center'}>
                                <ImageItem itemId={Number(data)} width={50} height={50}/>
                                {currentLang === 'KOR' ?
                                    <BuildText>{index + 1} {t('championDetailBuild.core')}</BuildText>
                                    :
                                    <BuildText>{index + 1}{t('summoner.grade', {
                                        count: index + 1,
                                        ordinal: true,
                                    })}</BuildText>
                                }
                            </ColumnBox>
                            {index + 1 !== itemData?.length && <Margin5>
                                <img src={`${theme.icon.path}/icn_forward.svg`} width={14}
                                     height={14}/>
                            </Margin5>}
                        </FlexBox>
                    )
                })}
            </ItemCoreWrapper>
        </Row>
    )
}

const AIGreenAbsolute = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
`;


const NotSupportText = styled.div`
  color: ${props => props.theme.color.dark_200};
  font-size: 16px;
  font-weight: 500;
  white-space: pre;
  text-align: center;
  line-height: 24px;
`;

const AIItemNotSupport = () => {
    const {t} = useTranslation();
    return (
        <NotSupportWrapper>
            <AIGreenAbsolute>
                <AIGreen>
                    {t('ingame.predict.aiBuild')}
                </AIGreen>
            </AIGreenAbsolute>
            <NotSupportText>
                {t('ingame.predict.notSupport')}
            </NotSupportText>
        </NotSupportWrapper>
    )
}

export default IngameAIItem;