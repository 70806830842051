import React, {useMemo} from 'react';
import styled from "styled-components";


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  overflow: hidden;
`;

const Item = styled.div`
  flex: 1;

  background-color: ${props => {
    let color = props.theme.color.site_main_color;
    if (props.selected) color = props.theme.color.green_200;
    return color;
  }};



  border: ${props => {
    let width = '1px';
    if (props.selected) width = '1.5px';
    return width;
  }} solid ${props => {
    let color = props.theme.color.default_border_color;
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};

  border-left: ${props => {
    let width = 0;
    if (props.left) width = '1px';
    if (props.selected) width = '1.5px';
    return width;
  }} solid ${props => {
    let color = props.theme.color.default_border_color;
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};
  border-right: ${props => {
    let width = 0;
    if (props.right) width = '1px';
    if (props.selected) width = '1.5px';
    return width;
  }} solid ${props => {
    let color = props.theme.color.default_border_color;
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};


  &:first-of-type {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &:last-of-type {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:hover {
    background-color: ${props => {
      let color = props.theme.color.white_200;
      if (props.selected) color = props.theme.color.green_200;
      return color;
    }};
  }

  overflow: hidden;
`;

const ButtonList = ({selected, list, onClick, Component}) => {
    const RenderComponent = Component ? Component : DefaultItemComponent;
    const selectedIndex = useMemo(() => {
        return list.findIndex((data) => data.value === selected);
    }, [selected,list]);
    return (
        <Wrapper>
            {list.map((data, index) => {
                return (
                    <Item selected={selected === data.value} key={index} left={(selectedIndex + 1) !== index}
                          right={(list.length - 1) === index}
                    >
                        <RenderComponent selected={selected} onClick={onClick} data={data}/>
                    </Item>
                )
            })}
        </Wrapper>
    );
};

const DefaultWrapper = styled.div`
  cursor: pointer;
  height: 38px;
  width: 78px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => {
    let color = props.theme.color.site_main_color;
    if (props.selected) color = props.theme.color.green_200;
    return color;
  }};
  color: ${props => {
    let color = props.theme.color.dark_200;
    if (props.selected) color = props.theme.color.green_100;
    return color
  }};
  font-weight: ${props => {
    let weight = 500;
    if (props.selected) weight = 'bold';
    return weight
  }};

  &:hover {
    background-color: ${props => {
      let color = props.theme.color.white_200;
      if (props.selected) color = props.theme.color.green_200;
      return color;
    }};
  }
`;

const DefaultItemComponent = ({selected, onClick, data}) => {
    return (
        <DefaultWrapper onClick={() => onClick(data)} selected={selected === data.value}>
            {data.title}
        </DefaultWrapper>
    )
}

export default ButtonList;