// 액션 생성
import {
    GET_CHAMP_INFO_FAILURE,
    GET_CHAMP_INFO_REQUEST,
    GET_CHAMP_INFO_SUCCESS,
    GET_CHAMPIONS_BY_SEASON_FAILURE,
    GET_CHAMPIONS_BY_SEASON_REQUEST,
    GET_CHAMPIONS_BY_SEASON_SUCCESS,
    GET_GAME_ANALYTICS_PATCH_FAILURE,
    GET_GAME_ANALYTICS_PATCH_REQUEST,
    GET_GAME_ANALYTICS_PATCH_SUCCESS,
    GET_INGAME_CHECK_FAILURE,
    GET_INGAME_CHECK_REQUEST,
    GET_INGAME_CHECK_SUCCESS,
    GET_INGAME_INFO_FAILURE,
    GET_INGAME_INFO_REQUEST,
    GET_INGAME_INFO_STOP_LOADING,
    GET_INGAME_INFO_SUCCESS,
    GET_INGAME_INFO_SUMMONER_SUCCESS,
    GET_INGAME_UPDATE_FAILURE,
    GET_INGAME_UPDATE_REQUEST,
    GET_INGAME_UPDATE_SUCCESS,
    GET_MATCH_BY_QUEUE_FAILURE,
    GET_MATCH_BY_QUEUE_REQUEST,
    GET_MATCH_BY_QUEUE_SUCCESS,
    GET_MATCH_FAILURE,
    GET_MATCH_REQUEST,
    GET_MATCH_SUCCESS,
    GET_MORE_MATCHES_FAILURE,
    GET_MORE_MATCHES_REQUEST,
    GET_MORE_MATCHES_SUCCESS,
    GET_MULTI_LIST_FAILURE,
    GET_MULTI_LIST_REQUEST,
    GET_MULTI_LIST_SUCCESS,
    GET_MULTI_LIST_UPDATE_REQUEST,
    GET_MULTI_LIST_UPDATE_SUCCESS,
    GET_MULTI_SUMMONER_LP_CHART_FAILURE,
    GET_MULTI_SUMMONER_LP_CHART_REQUEST,
    GET_MULTI_SUMMONER_LP_CHART_SUCCESS,
    GET_SHORT_LINK_FAILURE,
    GET_SHORT_LINK_REQUEST,
    GET_SHORT_LINK_SUCCESS,
    GET_SUMMONER_AI_FAILURE,
    GET_SUMMONER_AI_REQUEST,
    GET_SUMMONER_AI_SUCCESS,
    GET_SUMMONER_CHAMPION_ARAM_FAILURE,
    GET_SUMMONER_CHAMPION_ARAM_REQUEST,
    GET_SUMMONER_CHAMPION_ARAM_SUCCESS,
    GET_SUMMONER_CHAMPION_URF_FAILURE,
    GET_SUMMONER_CHAMPION_URF_REQUEST,
    GET_SUMMONER_CHAMPION_URF_SUCCESS,
    GET_SUMMONER_FAILURE,
    GET_SUMMONER_INFO_FAILURE,
    GET_SUMMONER_INFO_REQUEST,
    GET_SUMMONER_INFO_SUCCESS,
    GET_SUMMONER_LP_CHART_REQUEST,
    GET_SUMMONER_LP_CHART_SUCCESS,
    GET_SUMMONER_PENTAKILL_FAILURE,
    GET_SUMMONER_PENTAKILL_REQUEST,
    GET_SUMMONER_PENTAKILL_SUCCESS,
    GET_SUMMONER_REAL_TIME_FAILURE,
    GET_SUMMONER_REAL_TIME_REQUEST,
    GET_SUMMONER_REAL_TIME_SUCCESS,
    GET_SUMMONER_REQUEST,
    GET_SUMMONER_SUCCESS,
    GET_UPDATE_CHAMPION_FAILURE,
    GET_UPDATE_CHAMPION_REQUEST,
    GET_UPDATE_CHAMPION_SUCCESS,
    GET_UPDATE_MATCHES_FAILURE,
    GET_UPDATE_MATCHES_REQUEST,
    GET_UPDATE_MATCHES_SUCCESS,
    INIT_SHORT_LINK,
    INIT_SUMMONER,
    RESET_SUMMONER_CHAMPIONS_BY,
    SET_SHORT_LINK_FAILURE,
    SET_SHORT_LINK_REQUEST,
    SET_SHORT_LINK_SUCCESS,
    SET_TIER_CUT,
    UPDATE_SUMMONER_INFO_FAILURE,
    UPDATE_SUMMONER_INFO_REQUEST,
    UPDATE_SUMMONER_INFO_SUCCESS,
    UPDATE_TIME_BY_DELAY,
} from "../reducer/summoner";
import {GET_MASTERY_BUILD_FAILURE, GET_MASTERY_BUILD_REQUEST, GET_MASTERY_BUILD_SUCCESS} from "../reducer/champions";

const SUMMONER = 'summoner/SUMMONER';

// 액션 함수 내보내기


// 초기 상태 값
const initialState = {
    info: {},
    summoner: {},
    summonerStatus: {
        season: 0,
    },
    tierCut:{},
    summonerARAM: {},
    summonerURF: {},
    ingameStatus: false,
    updateStatus: {},
    tierChart: {},
    match: [],
    singleMatch: {},
    withOrigin: {},
    withOutOrigin: {},
    setShortLink: {},
    shortLink: {},
    autoComplete: {},
    multiList: [],
    multiLPChart: [],
    pentaKill: [],
    gameSummaryPatch: [],
    ingame: {
        basic: {},
        part: [],
        blueTeam: [],
        redTeam: [],
    },
    dailyAi: {},
    mastery: [],

    getShortLinkLoading: false,
    getShortLinkDone: false,
    getShortLinkError: null,

    setShortLinkLoading: false,
    setShortLinkDone: false,
    setShortLinkError: null,

    getSummonerLoading: false,
    getSummonerDone: false,
    getSummonerError: null,

    getSummonerInfoLoading: false,
    getSummonerInfoDone: false,
    getSummonerInfoError: null,

    getMoreMatchesLoading: false,
    getMoreMatchesDone: false,
    getMoreMatchesError: null,

    getUpdateStatusLoading: false,
    getUpdateStatusDone: false,
    getUpdateStatusError: null,

    getChampInfoLoading: false,
    getChampInfoDone: false,
    getChampInfoError: null,

    getMatchByQueueLoading: false,
    getMatchByQueueDone: false,
    getMatchByQueueError: null,

    updateSummonerInfoLoading: false,
    updateSummonerInfoDone: false,
    updateSummonerInfoError: null,

    getMatchLoading: false,
    getMatchDone: false,
    getMatchError: null,

    getMultiListLoading: false,
    getMultiListDone: false,
    getMultiListError: null,

    getMultiListUpdateLoading: false,
    getMultiListUpdateDone: false,
    getMultiListUpdateError: null,

    getLPChartLoading: false,
    getLPChartDone: false,
    getLPChartError: false,

    getIngameCheckLoading: false,
    getIngameCheckDone: false,
    getIngameCheckError: false,

    getMultiLPChartLoading: false,
    getMultiLPChartDone: false,
    getMultiLPChartError: false,

    getChampionsBySeasonLoading: false,
    getChampionsBySeasonDone: false,
    getChampionsBySeasonError: null,

    getGameAnalyticsPatchLoading: false,
    getGameAnalyticsPatchDone: false,
    getGameAnalyticsPatchError: null,

    getSummonerPentaKillLoading: false,
    getSummonerPentaKillDone: false,
    getSummonerPentaKillError: null,

    getIngameInfoLoading: false,
    getIngameInfoDone: false,
    getIngameInfoError: null,

    getIngameInfoSummonerLoading: false,
    getIngameInfoSummonerDone: false,
    getIngameInfoSummonerError: null,

    getIngameUpdateLoading: false,
    getIngameUpdateDone: false,
    getIngameUpdateError: null,

    getMasteryBuildLoading: false,
    getMasteryBuildDone: false,
    getMasteryBuildError: null,

    getSummonerChampionAramLoading: false,
    getSummonerChampionAramDone: false,
    getSummonerChampionAramError: false,

    getSummonerChampionURFLoading: false,
    getSummonerChampionURFDone: false,
    getSummonerChampionURFError: false,

    getSummonerRealTimeLoading: false,
    getSummonerRealTimeDone: false,
    getSummonerRealTimeError: false,


    getUpdateChampionLoading: false,
    getUpdateChampionDone: false,
    getUpdateChampionError: false,


    getUpdateMatchesLoading: false,
    getUpdateMatchesDone: false,
    getUpdateMatchesError: false,

    getSummonerAILoading: false,
    getSummonerAIDone: false,
    getSummonerAIError: false,
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_MATCH_REQUEST : {
            return {
                ...state,
                getMatchLoading: true,
                getMatchDone: false,
                getMatchError: null,
            }
        }
        case GET_MATCH_SUCCESS: {
            return {
                ...state,
                singleMatch: action.data,
                getMatchLoading: false,
                getMatchDone: true,
            }
        }
        case GET_MATCH_FAILURE: {
            return {
                ...state,
                getMatchLoading: false,
                getMatchError: action.error,
            }
        }
        case GET_MATCH_BY_QUEUE_REQUEST: {
            const noLoading = action?.data?.noLoading;
            return {
                ...state,
                getMatchByQueueLoading: noLoading ? false : true,
                getMatchByQueueDone: noLoading ? true : false,
                getMatchByQueueError: null,
            }
        }
        case GET_MATCH_BY_QUEUE_SUCCESS: {

            return {
                ...state,
                match: [...action.data.match],
                getMatchByQueueLoading: false,
                getMatchByQueueDone: true,
            }
        }
        case GET_MATCH_BY_QUEUE_FAILURE: {
            return {
                ...state,
                getMatchByQueueLoading: false,
                getMatchByQueueError: action.error,
            }
        }
        case UPDATE_SUMMONER_INFO_REQUEST: {
            return {
                ...state,
                updateSummonerInfoLoading: true,
                updateSummonerInfoDone: false,
                updateSummonerInfoError: null,
            }
        }
        case UPDATE_SUMMONER_INFO_SUCCESS: {

            return {
                ...state,
                updateStatus: {...action.data.updateStatus},
                updateSummonerInfoLoading: false,
                updateSummonerInfoDone: true,
            }
        }
        case UPDATE_SUMMONER_INFO_FAILURE: {
            return {
                ...state,
                updateSummonerInfoLoading: false,
                updateSummonerInfoError: action.error,
            }
        }
        case GET_SUMMONER_INFO_REQUEST: {
            const noLoading = action?.data?.noLoading;
            return {
                ...state,
                getSummonerInfoLoading: !noLoading,
                getSummonerInfoDone: false,
                getSummonerInfoError: null,
            }
        }
        case GET_SUMMONER_INFO_SUCCESS: {

            return {
                ...state,
                getSummonerInfoLoading: false,
                getSummonerInfoDone: true,
            }
        }
        case GET_SUMMONER_INFO_FAILURE: {
            return {
                ...state,
                getSummonerInfoLoading: false,
                getSummonerInfoError: action.error,
            }
        }
        case GET_SUMMONER_REQUEST: {
            return {
                ...initialState,
                getSummonerLoading: true,
                getSummonerDone: false,
                getSummonerError: null,
            }
        }

        case GET_SUMMONER_SUCCESS: {
            return {
                ...state,
                updateStatus: {...action.data.updateStatus},
                summoner: {...action.data.summoner},
                getSummonerLoading: false,
                getSummonerDone: true,
            }
        }
        case GET_SUMMONER_FAILURE: {
            return {
                ...state,
                getSummonerLoading: false,
                getSummonerError: action.error,
            }
        }
        case GET_CHAMP_INFO_REQUEST: {
            return {
                ...state,
                getChampInfoLoading: true,
                getChampInfoDone: false,
                getChampInfoError: null,
            }
        }
        case GET_CHAMP_INFO_SUCCESS: {
            return {
                ...state,
                withOrigin: {...action.data.withOrigin},
                withOutOrigin: {...action.data.withOutOrigin},
                getChampInfoLoading: false,
                getChampInfoDone: true,
            }
        }
        case GET_CHAMP_INFO_FAILURE: {
            return {
                ...state,
                getChampInfoLoading: false,
                getChampInfoError: action.error,
            }
        }
        case GET_MORE_MATCHES_REQUEST: {
            return {
                ...state,
                getMoreMatchesLoading: true,
                getMoreMatchesDone: false,
                getMoreMatchesError: null,
            }
        }
        case GET_MORE_MATCHES_SUCCESS: {
            return {
                ...state,
                match: [...state.match, ...action.data.match],
                getMoreMatchesLoading: false,
                getMoreMatchesDone: true,
            }
        }
        case GET_MORE_MATCHES_FAILURE: {
            return {
                ...state,
                getMoreMatchesError: action.error,
            }
        }
        case GET_SHORT_LINK_REQUEST: {

            return {
                ...state,
                getShortLinkLoading: true,
                getShortLinkDone: false,
                getShortLinkError: null,
            }
        }
        case GET_SHORT_LINK_SUCCESS: {

            return {
                ...state,
                getShortLinkLoading: false,
                getShortLinkDone: true,
                shortLink: {...action.data},
            }
        }
        case GET_SHORT_LINK_FAILURE: {
            return {
                ...state,
                getShortLinkLoading: false,
                getShortLinkError: action.error,
            }
        }
        case SET_SHORT_LINK_REQUEST: {

            return {
                ...state,
                getShortLinkDone: false,
                setShortLinkLoading: true,
                setShortLinkDone: false,
                setShortLinkError: null,
            }
        }
        case SET_SHORT_LINK_SUCCESS: {

            return {
                ...state,
                setShortLinkLoading: false,
                setShortLinkDone: true,
                setShortLink: {...action.data},
            }
        }
        case SET_SHORT_LINK_FAILURE: {
            return {
                ...state,
                setShortLinkLoading: false,
                setShortLinkError: action.error,
            }
        }
        case INIT_SHORT_LINK : {
            return {
                ...state,
                setShortLinkLoading: false,
                setShortLinkDone: false,
                setShortLinkError: null,
                getMoreMatchesLoading: false,
                getMoreMatchesDone: false,
                getMoreMatchesError: null,
                shortLink: "",
            }
        }

        case GET_MULTI_LIST_REQUEST: {
            return {
                ...state,
                multiList: [],
                getMultiListLoading: true,
                getMultiListDone: false,
                getMultiListError: null,
            }
        }
        case GET_MULTI_LIST_SUCCESS: {

            return {
                ...state,
                multiList: [...action.data.list],
                getMultiListLoading: false,
                getMultiListDone: true,
            }
        }
        case GET_MULTI_LIST_FAILURE: {
            return {
                ...state,
                getMultiListLoading: false,
                getMultiListError: action.error,
            }
        }
        case GET_MULTI_LIST_UPDATE_REQUEST: {
            return {
                ...state,
                getMultiListUpdateLoading: true,
                getMultiListUpdateDone: false,
                getMultiListUpdateError: null,
            }
        }
        case GET_MULTI_LIST_UPDATE_SUCCESS: {
            return {
                ...state,
                multiList: [...action.data.list],
                getMultiListUpdateLoading: false,
                getMultiListUpdateDone: true,
            }
        }
        case GET_SUMMONER_LP_CHART_REQUEST: {
            return {
                ...state,
                getLPChartLoading: true,
                getLPChartDone: false,
                getLPChartError: null,
            }
        }
        case GET_SUMMONER_LP_CHART_SUCCESS: {
            return {
                ...state,
                getLPChartLoading: false,
                getLPChartDone: true,
                tierChart: action.data.tierChart || null,
            }
        }
        case GET_INGAME_CHECK_REQUEST: {
            return {
                ...state,
                getIngameCheckLoading: true,
                getIngameCheckDone: false,
                getIngameCheckError: null,
            }
        }
        case GET_INGAME_CHECK_SUCCESS: {
            return {
                ...state,
                getIngameCheckLoading: false,
                getIngameCheckDone: true,
                ingameStatus: action.data.ingameStatus,
            }
        }
        case GET_INGAME_CHECK_FAILURE: {
            return {
                ...state,
                getIngameCheckLoading: false,
                getIngameCheckError: action.error,
            }
        }
        case GET_MULTI_SUMMONER_LP_CHART_REQUEST: {
            return {
                ...state,
                getMultiLPChartLoading: true,
                getMultiLPChartDone: false,
                getMultiLPChartError: false,
            }
        }
        case GET_MULTI_SUMMONER_LP_CHART_SUCCESS: {

            return {
                ...state,
                multiLPChart: [...action.data.list],
                getMultiLPChartLoading: false,
                getMultiLPChartDone: true,
            }
        }
        case GET_MULTI_SUMMONER_LP_CHART_FAILURE: {
            return {
                ...state,
                getMultiLPChartLoading: false,
                getMultiLPChartError: action.error,
            }
        }
        case GET_CHAMPIONS_BY_SEASON_REQUEST: {
            return {
                ...state,
                getChampionsBySeasonLoading: true,
                getChampionsBySeasonDone: false,
                getChampionsBySeasonError: null,
            }
        }
        case GET_CHAMPIONS_BY_SEASON_SUCCESS: {
            return {
                ...state,
                summonerStatus: {...action.data.summonerStatus,season:action.data.season},
                getChampionsBySeasonLoading: false,
                getChampionsBySeasonDone: true,
            }
        }
        case GET_CHAMPIONS_BY_SEASON_FAILURE: {
            return {
                ...state,

                getChampionsBySeasonLoading: false,
                getChampionsBySeasonError: action.error,
            }
        }
        case GET_GAME_ANALYTICS_PATCH_REQUEST: {
            return {
                ...state,
                getGameAnalyticsPatchLoading: true,
                getGameAnalyticsPatchDone: false,
                getGameAnalyticsPatchError: null,
            }
        }
        case GET_GAME_ANALYTICS_PATCH_SUCCESS: {
            const copyArray = [...state.gameSummaryPatch];
            copyArray.push(action.data);
            return {
                ...state,
                gameSummaryPatch: [...copyArray],
                getGameAnalyticsPatchLoading: false,
                getGameAnalyticsPatchDone: true,
            }
        }
        case GET_GAME_ANALYTICS_PATCH_FAILURE: {

            return {
                ...state,

                getGameAnalyticsPatchLoading: false,
                getGameAnalyticsPatchError: action.error,
            }
        }
        case GET_SUMMONER_PENTAKILL_REQUEST: {
            return {
                ...state,
                getSummonerPentaKillLoading: true,
                getSummonerPentaKillDone: false,
                getSummonerPentaKillError: null,
            }
        }
        case GET_SUMMONER_PENTAKILL_SUCCESS: {

            return {
                ...state,
                pentaKill: [...action.data],
                getSummonerPentaKillLoading: false,
                getSummonerPentaKillDone: true,
            }
        }
        case GET_SUMMONER_PENTAKILL_FAILURE: {
            return {
                ...state,
                pentaKill: [],
                getSummonerPentaKillLoading: false,
                getSummonerPentaKillError: action.error,
            }
        }
        case GET_INGAME_INFO_SUMMONER_SUCCESS: {
            return {
                ...state,
                getIngameInfoSummonerLoading: false,
                getIngameInfoSummonerDone: true,
                ingame: {
                    basic: {},
                    part: [],
                    blueTeam: [],
                    redTeam: [],
                    ...action.data,
                }
            }
        }
        case GET_INGAME_INFO_REQUEST: {
            return {
                ...state,
                getIngameInfoSummonerLoading: true,
                getIngameInfoSummonerDone: false,
                getIngameInfoSummonerError: null,
                getIngameInfoLoading: true,
                getIngameInfoDone: false,
                getIngameInfoError: null,
            }
        }
        case GET_INGAME_INFO_SUCCESS: {
            return {
                ...state,
                getIngameInfoLoading: false,
                getIngameInfoDone: true,
                ingame: {
                    ...state.ingame,
                    ...action.data,
                }
            }
        }
        case GET_INGAME_INFO_FAILURE: {
            return {
                ...state,
                getIngameInfoLoading: false,
                getIngameInfoError: action.error,
            }
        }
        case GET_INGAME_INFO_STOP_LOADING: {
            return {
                ...state,
                getIngameInfoLoading: false,
                getIngameInfoDone: true,
            }
        }
        case GET_INGAME_UPDATE_REQUEST: {
            return {
                ...state,
                getIngameUpdateLoading: true,
                getIngameUpdateDone: false,
                getIngameUpdateError: null,
            }
        }
        case GET_INGAME_UPDATE_SUCCESS: {
            return {
                getIngameUpdateLoading: false,
                getIngameUpdateDone: true,
            }
        }
        case GET_INGAME_UPDATE_FAILURE: {
            return {
                getIngameUpdateLoading: false,
                getIngameUpdateError: action.error,
            }
        }
        case INIT_SUMMONER: {
            return {
                ...initialState
            }
        }
        case UPDATE_TIME_BY_DELAY: {
            return {
                ...state,
                updateStatus: {
                    ...state.updateStatus,
                    updated_timestamp: action.data,
                }
            }
        }
        case GET_MASTERY_BUILD_REQUEST: {
            return {
                ...state,
                mastery: [],
                getMasteryBuildLoading: true,
                getMasteryBuildError: null,
                getMasteryBuildDone: false,
            }
        }
        case GET_MASTERY_BUILD_SUCCESS: {
            return {
                ...state,
                mastery: action.data.model,
                getMasteryBuildLoading: false,
                getMasteryBuildError: null,
                getMasteryBuildDone: true,
            }
        }
        case GET_MASTERY_BUILD_FAILURE: {
            return {
                ...state,
                getMasteryBuildLoading: false,
                getMasteryBuildError: action.error,
            }
        }
        case GET_SUMMONER_CHAMPION_ARAM_REQUEST: {
            return {
                ...state,
                getSummonerChampionAramLoading: true,
                getSummonerChampionAramDone: false,
                getSummonerChampionAramError: null,
            }
        }
        case GET_SUMMONER_CHAMPION_ARAM_SUCCESS: {
            return {
                ...state,
                summonerARAM: {...action.data},
                getSummonerChampionAramLoading: false,
                getSummonerChampionAramDone: true,
            }
        }
        case GET_SUMMONER_CHAMPION_ARAM_FAILURE: {
            return {
                ...state,
                getSummonerChampionAramLoading: false,
                getSummonerChampionAramError: action.error,
            }
        }
        case GET_SUMMONER_CHAMPION_URF_REQUEST: {
            return {
                ...state,
                getSummonerChampionURFLoading: true,
                getSummonerChampionURFDone: false,
                getSummonerChampionURFError: null,
            }
        }
        case GET_SUMMONER_CHAMPION_URF_SUCCESS: {
            return {
                ...state,
                getSummonerChampionURFLoading: false,
                summonerURF: {...action.data},
                getSummonerChampionURFDone: true,
            }
        }
        case GET_SUMMONER_CHAMPION_URF_FAILURE: {
            return {
                ...state,
                getSummonerChampionURFLoading: false,
                getSummonerChampionURFError: action.error,
            }
        }
        case GET_SUMMONER_REAL_TIME_REQUEST: {
            return {
                ...state,
                getSummonerRealTimeLoading: true,
                getSummonerRealTimeDone: false,
                getSummonerRealTimeError: false,
            }
        }
        case GET_SUMMONER_REAL_TIME_SUCCESS: {
            const copySummoner = {...state.summoner};
            copySummoner.challenges_dict = {};
            copySummoner.season_tier_info_dict = {};
            return {
                ...state,
                summoner: {
                    ...copySummoner,
                    ...action.data.model,
                },
                getSummonerRealTimeLoading: false,
                getSummonerRealTimeDone: true,
            }
        }
        case GET_SUMMONER_REAL_TIME_FAILURE: {
            return {
                ...state,
                getSummonerRealTimeLoading: false,
                getSummonerRealTimeError: action.error,
            }
        }
        case GET_UPDATE_CHAMPION_REQUEST: {
            return {
                ...state,
                getUpdateChampionLoading: true,
                getUpdateChampionDone: false,
                getUpdateChampionError: false,
            }
        }
        case GET_UPDATE_CHAMPION_SUCCESS: {
            return {
                ...state,
                getUpdateChampionLoading: false,
                getUpdateChampionDone: true,
            }
        }
        case GET_UPDATE_CHAMPION_FAILURE: {
            return {
                ...state,
                getUpdateChampionLoading: false,
                getUpdateChampionError: action.error,
            }
        }
        case GET_UPDATE_MATCHES_REQUEST: {
            return {
                ...state,
                getUpdateMatchesLoading: true,
                getUpdateMatchesDone: false,
                getUpdateMatchesError: false,
            }
        }
        case GET_UPDATE_MATCHES_SUCCESS: {
            return {
                ...state,
                getUpdateMatchesLoading: false,
                getUpdateMatchesDone: true,
            }
        }
        case GET_UPDATE_MATCHES_FAILURE: {
            return {
                ...state,
                getUpdateMatchesLoading: false,
                getUpdateMatchesError: action.error,
            }
        }
        case GET_SUMMONER_AI_REQUEST: {
            return {
                ...state,
                getSummonerAILoading: true,
                getSummonerAIDone: false,
                getSummonerAIError: false,
            }
        }
        case GET_SUMMONER_AI_SUCCESS: {
            return {
                ...state,
                getSummonerAILoading: false,
                getSummonerAIDone: true,
                dailyAi: action.data.dailyAi
            }
        }
        case GET_SUMMONER_AI_FAILURE: {
            return {
                ...state,
                getSummonerAILoading: false,
                getSummonerAIError: true,
            }
        }
        case SET_TIER_CUT: {
            return {
                ...state,
                tierCut: {...action.data.tierCut}
            }
        }
        case RESET_SUMMONER_CHAMPIONS_BY: {
            return {
                ...state,
                getChampionsBySeasonLoading: true,
                getChampionsBySeasonDone: false,
                getChampionsBySeasonError: null,
            }
        }
        case SUMMONER:
            return {
                info: action.info,
            };
        default:
            return state;
    }
};
export default reducer;
