import _ from 'lodash';

// 액션 생성
const BOOKMARKLIST = 'bookmarkList/BOOKMARKLIST';
const BOOKMARKLIST_ADD_PRO = 'bookmarkList/BOOKMARKLIST_ADD_PRO';
// 액션 함수 내보내기
export const bookmarkList = (bookmarks, flag) => ({ type: BOOKMARKLIST, bookmarks, flag });
export const bookmarkListAddPro = (item) => ({type: BOOKMARKLIST_ADD_PRO, data: item});
// 초기 상태 값
const initialState = {
  bookmarks: [],
  flag: 'add',
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BOOKMARKLIST:
        let result = [];
        const arr = state.bookmarks.slice(-12);
        if(action.flag === 'add') {
            result = _.uniqBy([...arr, action.bookmarks], (e) => {
                if(e?.type ==='influencer' ) {
                    return e?.url_name
                }
                return e?.summoner_basic_info_dict?.summoner_id
            })
        } else {
            result = arr.filter((data) => data?.type !== 'influencer' ? data?.summoner_basic_info_dict?.riot_id_name !== action?.bookmarks?.summoner_basic_info_dict?.riot_id_name : data?.url_name !== action?.bookmarks?.url_name)
        }
      return {
        bookmarks: [...result]
      }
      case BOOKMARKLIST_ADD_PRO: {
          const arr = state.bookmarks.slice(-12);
          const copyArr = [...arr];
          const filtered = copyArr.filter((data, index) => data.type === 'influencer' ? data.url_name !== action.data.url_name : true);
          filtered.push({...action.data, type: 'influencer'});
          return {
              bookmarks: [...filtered]
          }
      }
    default:
        if(state.bookmarks.length > 9) {
            return {
                ...state,
                bookmarks: state.bookmarks.slice(-9),
            }
        } else {
            return state;
        }
  }
};

export default reducer;
