import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import AramContent from "./AramContent";
import {Header} from "../common";
import UseSetCanonical from "../../common/hooks/useSetCanonical";


const Wrapper = styled.div`
`;


const Aram = () => {
    UseSetCanonical();
    const {t} = useTranslation();


    // useAdHooks(champion_center_content);


    return (
        <Wrapper>
            <Header/>
            {/*<NotFound/>*/}
            <AramContent/>

        </Wrapper>
    );
};

export default Aram;
