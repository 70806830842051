import {useSelector} from "react-redux";
import useSortArray from "../../../../../common/hooks/useSortArray";
import React, {useEffect, useMemo} from "react";
import styled from "styled-components";
import AramBalanceTableHeader from "./AramBalanceTableHeader";
import AramBalanceTableContent from "./AramBalanceTableContent";
import {useParams} from "react-router-dom";
import useScrollPagination from "../../../../../common/hooks/useScrollPagination";
import useChoNameVisible from "../../../../../common/hooks/useChoNameVisible";
import {sortChampData} from "../../../../../function";
import ChampionsEmpty from "../../../../common/ChampionsEmpty";


const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TBody = styled.tbody`
  tr:nth-child(n+${props => props.page + 3}) {
    display: none;
  }
`;



const AramBalanceTable = ({list = [], choName, searchText}) => {

    const initSortList = {
        win_rate: {
            value: 'win_rate', sortType: true,
        }, pick_rate: {
            value: 'pick_rate', sortType: true,
        }, damage_dealt: {
            value: 'damage_dealt', sortType: true,
        }, damage_taken: {
            value: 'damage_taken', sortType: true, reverseSort: true,
        }, healing: {
            value: 'healing', sortType: true,
        }, shield_mod: {
            value: 'shield_mod', sortType: true,
        }, tenacity: {
            value: 'tenacity', sortType: true,
        }, energy_regeneration: {
            value: 'energy_regeneration', sortType: true,
        }, ability_haste: {
            value: 'ability_haste', sortType: true,
        }, attack_speed_scaling: {
            value: 'attack_speed_scaling', sortType: true,
        }, patch_number: {
            value: 'patch_number', sortType: true,reverseSort: true
        }, champion_name_kr: {
            value: "champion_name_kr", sortType: false,reverseSort:true,
        }, champion_name_en: {
            value: "champion_name_en", sortType: false, reverseSort:true,
        },
    }


    const currentLang = useSelector((state) => state.translation.lang);
    const initSort = useMemo(() => {
        return [{
            value: currentLang === 'champion_name', sortType: true
        }];
    },[currentLang])


    const version = useSelector((state) => state.gameVersion.version);
    const {position} = useParams();
    const [pageCount, initPage] = useScrollPagination();
    const {sort, sortList, onClickSort} = useSortArray({
        initSort: initSort, initSortList: initSortList,
    });
    const visibleMap = useChoNameVisible({
        choName, search: searchText, list: list,
    });



    const sortedMap = useMemo(() => sortChampData(visibleMap, [...sort, ...initSort]), [visibleMap, sort, initSort]);
    const maxDamageDealt = useMemo(() => Math.max(0, ...list.map((data) => Math.abs(data.damage_dealt))),[list]);
    const maxDamageTaken = useMemo(() => Math.max(0, ...list.map((data) => Math.abs(data.damage_taken))),[list]);
    useEffect(() => {
        initPage();
    }, [choName, searchText,position]);



    // if (ranks.length === 0) return (<InternalServerError errorText={"There are no data"}/>);

    return (
        <div>
            {sortedMap.length === 0 ? <ChampionsEmpty/> :
                <Table>
                    <colgroup>
                        <col width={'3%'}/>
                        <col width={'15%'}/>
                        <col width={'4%'}/>
                        <col width={'5%'}/>
                        <col width={'5%'}/>
                        <col width={'15%'}/>
                        <col width={'15%'}/>
                        <col/>
                        <col/>
                        <col/>
                        <col/>
                        <col/>
                        <col/>
                    </colgroup>
                    <thead>
                    <AramBalanceTableHeader
                        currentSort={sort}
                        onClickSort={onClickSort}
                        sortData={sortList}
                    />
                    </thead>
                    <TBody>

                        {sortedMap.slice(0, pageCount + 2).map((data, index) => {
                            return (
                                <AramBalanceTableContent
                                    index={index}
                                    haste={data?.ability_haste}
                                    correction={data.aram_correction}
                                    atkSpeedScale={data.attack_speed_scaling}
                                    damageDealt={data.damage_dealt}
                                    damageTaken={data.damage_taken}
                                    energyReg={data.energy_regeneration}
                                    healing={data.healing}
                                    pickRate={data.pick_rate}
                                    shieldMod={data.shield_mod}
                                    tenacity={data.tenacity}
                                    winRate={data.win_rate}
                                    key={data.champion_id + position}
                                    champion_id={data.champion_id}
                                    version={version}
                                    maxDamageDealt={maxDamageDealt}
                                    maxDamageTaken={maxDamageTaken}
                                />
                            )
                        })}
                    </TBody>
                </Table>}
        </div>
    );
};


export default AramBalanceTable;
