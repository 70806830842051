import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {NotFoundContainer} from '../styled/CommonStyle';

const NotFound = () => {
    const {t} = useTranslation();

    return (
        <NotFoundContainer>
            <h1>404</h1>
            <p>
                본 서비스에 일시적인 오류가 발생했습니다. <br/>
                홈페이지 이용을 불편하게 해드려 죄송합니다. <br/>
                잠시 후에 다시 이용을 부탁드리며, 고객센터를 통해 문의해 주시면 <br/>
                빠르게 처리해드리겠습니다. <br/>
                감사합니다.
            </p>
            <p>
                we're sorry, the requested page couldnt' be found. <br/>
                If you followed a link, please contact us.
            </p>
            <Link to="/" style={{marginTop: '20px', fontSize: '18px'}}>
                {t('buttons.home')}
            </Link>
        </NotFoundContainer>
    );
};

export default NotFound;
