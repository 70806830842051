import React, {useCallback, useState} from 'react';
import {useTranslation} from "react-i18next";
import useInput from "../../../../common/hooks/useInput";
import ChampionsDetailAgainstTable from "../ChampionsDetailAgainstTable";
import {useTheme} from "styled-components";

const ChampionsDetailAgainstEasy = ({version,currentBuild}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const [currentSort, setCurrentSort] = useInput([{value: "win_rate", sortType: false}]);
    const [sortList, setSortList] = useState({
        games: {
            value: "games",
            sortType: true,
        },
        win_rate: {
            value: "win_rate",
            sortType: false,
        },
    });


    const onClickHeader = useCallback((sort) => {

        if (typeof sort === 'object') {
            const copyData = {...sort};
            const copySortData = {...sortList};
            if (copyData) {
                if(copySortData[copyData.value]) {
                    const allOff = allOffObject(copySortData);
                    copyData.sortType = !copyData.sortType;
                    allOff[copyData.value].sortType = copyData.sortType;
                    setCurrentSort([{...copyData}]);
                    setSortList(copySortData);
                }
            }
        }
    }, [sortList]);


    const allOffObject = useCallback((copyData) => {
        if (typeof copyData === 'object') {
            const copy = {...copyData};
            Object.keys(copy).forEach((key) => {
                copy[key].sortType = true;
            })
            return copy;
        }
        return copyData;
    }, [])

    return (
        <ChampionsDetailAgainstTable
            sortList={sortList}
            onClickHeader={onClickHeader}
            currentSort={currentSort}
            champions={currentBuild?.strong_against
            }
            title={t('championDetailBuild.easyAgainst')}
            barColor={theme.color.blue_100}
            width={'330px'}
            version={version}
        />
    );
};

export default ChampionsDetailAgainstEasy;