import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import {getLPScore, onStorePuuId} from "../../../../function";
import _ from "lodash";
import {ColumnBox, FlexBox, FlexOneBox} from "../../../ui/styled";
import {ChartLp} from "../../../charts";
import ChampionImageWithLane from "../../../common/ChampionImageWithLane";
import InfluInfo from "../../../summoner/Profile/InfluInfo";
import TierTag from "../../../common/TierTag";


const Header = styled.div`
  padding: 9px 14px;
  background-color: ${props => props.theme.color.background_F1F};
  color: ${props => props.teamColor};
  border-bottom: 1px solid ${props => props.theme.color.default_border_color};
  border-top: 1px solid ${props => props.theme.color.default_border_color};
  border-right: 1px solid ${props => props.theme.color.default_border_color};
  font-size: 12px;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
`;

const NameText = styled.span`
  font-weight: 500;
  font-size: 12px;
  padding-bottom: 2px;
  margin-left: 7px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const Wrapper = styled.div`

  .imgChamp {
    width: 42px;
    height: 42px;
  }
  .imgDiv {
    border-radius: 7px;
  }

`;

const TierAndInfluWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 7px;

  .influ-name {
    font-size: 12px;
    opacity: 0.6;
  }
`;

const BorderColumnBox = styled(ColumnBox)`
  border-right: 1px solid ${props => props.theme.color.default_border_color};
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 1px solid ${props => props.theme.color.default_border_color};
  min-height: 180px;
`;

const IngameTeamChart = ({chartList, teamName, teamColor, region}) => {
    const getChartOptions = useCallback((data) => {
            let originalArr = []; // 원래 data값을 배열로
            let calculatedArr = []; // 계산된 lp 값
            let dateArr = []; // xAxis categories 값
            let tierArr = []; // tier, order 배열
            let grandMasterValue = 2950;
            let challengerValue = 4000;
            let grandMaxValue = 4000;
            let challengerMaxvalue = 10000;


            for (const obj in data) {

                if (data[obj].tier === "GRANDMASTER") {
                    grandMasterValue = Math.min(grandMasterValue, data[obj].league_points + 2800);
                    grandMaxValue = Math.max(grandMaxValue, data[obj].league_points + 2800);
                }
                if (data[obj].tier === "CHALLENGER") {
                    challengerValue = Math.min(challengerValue, data[obj].league_points + 3100);
                    challengerMaxvalue = Math.max(challengerMaxvalue, data[obj].league_points + 2800);
                }
            }


            for (const i in data) {
                originalArr.push(data[i]);
                // 티어별 order값
                let tierOrder = 0;
                if (data[i].tier === 'IRON') tierOrder = 1;
                else if (data[i].tier === 'BRONZE') tierOrder = 2;
                else if (data[i].tier === 'SILVER') tierOrder = 3;
                else if (data[i].tier === 'GOLD') tierOrder = 4;
                else if (data[i].tier === 'PLATINUM') tierOrder = 5;
                else if (data[i].tier === 'DIAMOND') tierOrder = 6;
                else if (data[i].tier === 'MASTER') tierOrder = 7;
                else if (data[i].tier === 'GRANDMASTER') tierOrder = 8;
                else if (data[i].tier === 'CHALLENGER') tierOrder = 9;


                tierArr.push({
                    tier: data[i].tier,
                    order: tierOrder,
                });

                // 계산된 lp로 입력
                calculatedArr.push(getLPScore(data[i].tier, data[i].division, data[i].league_points, grandMasterValue, challengerValue));

                // xAxis categories (날짜데이터)
                // let idxDate = new Date(i * 1000);
                // let idxDate = getGameTime(i, currentLang);
                dateArr.push(data[i].timestamp);
            }


            // 객체배열 중복제거
            tierArr = _.uniqBy(tierArr, 'tier');
            tierArr.sort(function (a, b) {
                // order 순으로 정렬
                return a.order - b.order;
            });


            let yTickPositions = {
                GRANDMASTER: grandMasterValue,
                CHALLENGER: challengerValue,
            }


            if(yTickPositions.GRANDMASTER === 0 && yTickPositions.CHALLENGER === 0) {
                yTickPositions.GRANDMASTER = 400;
                yTickPositions.CHALLENGER = 800;
            }

            // 티어별 yAxis tickPositions 값 넣기


            // yTickPositions 앞뒤로 400 추가 (왜 하는거지?)
            // let minTP = yTickPositions[0];
            // let maxTP = yTickPositions[yTickPositions.length - 1];
            // if (minTP !== 0) yTickPositions.unshift(minTP - 400);
            // if (maxTP < 2400) yTickPositions.push(maxTP + 400);
            // else yTickPositions.push(maxTP + 1000);

            // 내림차순 정렬


            return {originalArr, calculatedArr, dateArr, yTickPositions};
        },
        []);

    const chartData = useMemo(() => {
        try {
            return chartList.map((data) => {
                return {
                    ...data,
                    lpChart: getChartOptions(data?.ranked_solo_5x5)
                };
            }, []);

        } catch (e) {
            return [];
        }
    }, [chartList]);

    const isToday = useCallback((timestamp) => {
        if (typeof timestamp === 'number') {
            const multiplyTime = timestamp * 1000;
            const time = new Date(multiplyTime).getTime();
            //1660070056000
            //1660094662488
            const now = Date.now();
            const zeroHour = new Date(now);
            zeroHour.setHours(0, 0, 0, 0);
            if (time > zeroHour.getTime() && time < now) {
                return true
            } else {
                return false;
            }
        }
    }, []);


    const setTodayLP = (array, rankDict) => {
        if (array[array.length - 1]?.timestamp) {
            if (isToday(array[array.length - 1].timestamp)) {
                array[array.length - 1] = {
                    timestamp: Date.now() / 1000,
                    tier: rankDict.tier,
                    division: rankDict.division,
                    league_points: rankDict.league_points,
                }
            }
        }
        return array;
    }

    if(chartData.length === 0) return null;

    return (
        <Wrapper>
            <Header teamColor={teamColor}>
                {teamName}
            </Header>
            <FlexBox alignItems={'flex-start'}>
                {chartData.map((data,index) => {
                    const tier = data.summoner?.season_tier_info_dict?.ranked_solo_5x5?.tier;
                    const division = data.summoner?.season_tier_info_dict?.ranked_solo_5x5?.division;
                    const league_points = data.summoner?.season_tier_info_dict?.ranked_solo_5x5?.league_points;
                    const summonerName = data.summoner?.riot_id_name;
                    const puuId = data.summoner?.summoner_basic_info_dict.puu_id;
                    const influData = data.summoner.summoner_basic_info_dict.pro_streamer_info_dict;

                    const onClickSummonerName = (e) => {
                        e.stopPropagation();
                        onStorePuuId(summonerName, region, puuId);
                    };
                    return (
                        <FlexOneBox justifyContent={'flex-start'} key={puuId + "ingameChart"}>
                            <BorderColumnBox width={'100%'}>
                                <FlexBox padding={'10px 12px'}>
                                    <ChampionImageWithLane
                                        champion_id={data.summoner.champion_id}
                                    />
                                    <NameWrapper>
                                        <FlexBox>
                                            <TierTag
                                                tier={tier}
                                                division={division}
                                            />
                                            <NameText onClick={onClickSummonerName}>{summonerName}</NameText>
                                        </FlexBox>
                                        {influData.status &&
                                            <TierAndInfluWrapper>
                                                <InfluInfo
                                                    showLinks={false}
                                                    influData={influData}
                                                />
                                            </TierAndInfluWrapper>}
                                    </NameWrapper>
                                </FlexBox>
                                <Content>
                                    <ChartLp tierChart={setTodayLP(data.lpChart,{tier,division,league_points})} reverse={true} width={220} height={165}/>
                                </Content>
                            </BorderColumnBox>
                        </FlexOneBox>
                    )
                })}
            </FlexBox>
        </Wrapper>
    );
};

const isEquals = (prev,next) => {
    return prev.chartList.length === next.chartList.length;
}
export default React.memo(IngameTeamChart,isEquals);