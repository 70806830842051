import {LANGUAGE_FOR_OS} from "./types";


const splitLang = (region) => {
    let language = region;
    if (region !== LANGUAGE_FOR_OS.ZHCN && region !== LANGUAGE_FOR_OS.ZHTW) {
        language = region?.split('-')?.[0];
    }
    return language;
}


export const getDataDragonRegion = (region) => {
    const language = splitLang(region);
    switch (language) {
        case 'KOR' :
            return 'ko_KR';
        case "ENG" :
            return 'en_US';
        case LANGUAGE_FOR_OS["ko-KR"] :
            return 'ko_KR'
        case LANGUAGE_FOR_OS.ko :
            return 'ko_KR'
        case LANGUAGE_FOR_OS.VI:
            return 'vi_VN'
        case LANGUAGE_FOR_OS.ZHTW:
            return 'zh_TW'
        case LANGUAGE_FOR_OS.ZHCN:
            return 'zh_CN'
        case LANGUAGE_FOR_OS.DA:
            return 'de_DE'
        case LANGUAGE_FOR_OS.RU:
            return 'ru_RU'
        case LANGUAGE_FOR_OS.DE:
            return 'de_DE'
        case LANGUAGE_FOR_OS.ES:
            return 'es_ES'
        case LANGUAGE_FOR_OS.FR:
            return 'fr_FR'
        case LANGUAGE_FOR_OS.HU:
            return 'hu_HU'
        case LANGUAGE_FOR_OS.IT:
            return 'it_IT'
        case LANGUAGE_FOR_OS.JA:
            return 'ja_JP'
        case LANGUAGE_FOR_OS.PL:
            return 'pl_PL'
        case LANGUAGE_FOR_OS.PT:
            return 'pt_BR'
        case LANGUAGE_FOR_OS.RO:
            return 'ro_RO'
        case LANGUAGE_FOR_OS.TH:
            return 'th_TH'
        default:
            return 'en_US'
    }
}


export const getOriginalNames = (region) => {
    const language = splitLang(region);
    switch (language) {
        case 'KOR' :
            return '한국어';
        case 'ENG' :
            return 'English'
        case 'ko' :
            return '한국어';
        case 'en' :
            return 'English';
        case LANGUAGE_FOR_OS.VI :
            return 'Tiếng Việt';
        case LANGUAGE_FOR_OS.DA :
            return "Dansk"
        case LANGUAGE_FOR_OS.DE :
            return "Deutsch"
        case LANGUAGE_FOR_OS.ES :
            return "Español"
        case LANGUAGE_FOR_OS.FI :
            return "Suomalainen"
        case LANGUAGE_FOR_OS.FR :
            return "Français"
        case LANGUAGE_FOR_OS.HU :
            return "Magyar"
        case LANGUAGE_FOR_OS.IT :
            return "Italiano"
        case LANGUAGE_FOR_OS.JA :
            return "日本語"
        case LANGUAGE_FOR_OS.NL :
            return "Nederlands"
        case LANGUAGE_FOR_OS.NORWAY :
            return "Norsk"
        case LANGUAGE_FOR_OS.PL :
            return "Język polski"
        case LANGUAGE_FOR_OS.PT :
            return "Português"
        case LANGUAGE_FOR_OS.RO :
            return "Română"
        case LANGUAGE_FOR_OS.RU :
            return "Русский"
        case LANGUAGE_FOR_OS.SR :
            return "српски језик"
        case LANGUAGE_FOR_OS.SV :
            return "Svenska"
        case LANGUAGE_FOR_OS.TH :
            return "ไทย"
        case LANGUAGE_FOR_OS.TR :
            return "Türkçe"
        case LANGUAGE_FOR_OS.ZHCN :
            return "简体中文"
        case LANGUAGE_FOR_OS.ZHTW :
            return "繁體中文"
    }
}

