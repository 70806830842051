import React from 'react';
import {FlexBox} from "../../../ui/styled";
import styled from "styled-components";
import useChampionFunc from "../../../../common/hooks/useChampionFunc";
import {useTranslation} from "react-i18next";


const LeagueText = styled.span`
  line-height: 18px;
  font-size: 11px;
  opacity: 0.7;
`;

const Bar = styled.div`
  width: 1px;
  height: 10px;
  background-color: #707070;
  opacity: 0.6;
  margin: 0 9px;
`;


const TextBox = styled(FlexBox)`
  height: 18px;
`;


const LeagueTeam = ({team, league, status, otp}) => {
    const {t} = useTranslation();
    const {getChampionName} = useChampionFunc();



    if (status === 'pro') {
        if (league && team) {
            return (
                <TextBox className={'league-box'}>
                    <LeagueText>{team}</LeagueText>
                    <Bar/>
                    <LeagueText>{league}</LeagueText>
                </TextBox>
            )
        }
        if (team) {
            return (
                <TextBox className={'league-box'}>
                    <LeagueText>{team}</LeagueText>
                </TextBox>
            )
        }

        if (league) {
            return (
                <TextBox className={'league-box'}>
                    <LeagueText>{league}</LeagueText>
                </TextBox>
            )
        }

    } else if (status === 'Streamer' && otp) {
        return (
            <TextBox className={'league-box'}>
                <LeagueText>{t('live.mastery', {champion: getChampionName(otp)})}</LeagueText>
            </TextBox>
        )
    }
    return (
        <TextBox>
        </TextBox>
    );
};

export default LeagueTeam;