import React from 'react';
import './index.css';
import TagManager from 'react-gtm-module';
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "./store/reducer/store";
import {BrowserRouter} from "react-router-dom";
import {createRoot} from "react-dom/client";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {ErrorBoundary} from "react-error-boundary";
import App from "./App";
// GTM
const tagManagerArgs = {
    gtmId: 'GTM-MNLW34G',
};
TagManager.initialize(tagManagerArgs);

const rootElement = document.getElementById('root')
// react-snap (pre-rendering)
const root = createRoot(rootElement)

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus:false
        }
    }
});

function Fallback({error, resetErrorBoundary}) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.

    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    );
}


root.render(
    <ErrorBoundary FallbackComponent={Fallback}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                {/*<I18nextProvider i18n={window.navigator.language && i18n}>*/}
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <App/>
                        {/*<Maintenance/>*/}
                    </BrowserRouter>
                </QueryClientProvider>
                {/*</I18nextProvider>*/}
            </PersistGate>
        </Provider>
    </ErrorBoundary>
);