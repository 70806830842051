import React, {useCallback, useState} from 'react';
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {THEME_TYPE} from "../../../store/modules/theme";

const Wrapper = styled.button`
  position: relative;
  margin-right: 15px;
`;

const Button = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  background-color: ${props => {
    let color = '#3C466B';
    return color;
  }};
`;

const DivImg = styled.button`
  width: 24px;
  height: 24px;
  background: url(${props => props.src}) no-repeat;
`;
const AbsoluteIcon = styled.div`
  position: absolute;
  top: 0;
  right: -5px;
  width: 5px;
  height: 5px;
  border-radius: 100%;
`;
const ThemeSwitch = () => {
    const [onHover, setOnHover] = useState(false)
    const theme = useSelector((state) => state.theme.color);
    const dispatch = useDispatch();

    const onChangeTheme = useCallback(() => {
        dispatch({
            type: THEME_TYPE,
            data: theme === 'light' ? 'dark' : 'light',
        })
    }, [theme]);

    const handleMouseOut = () => {
        setOnHover(false);
    }

    const handleMouseOver = () => {
        setOnHover(true)
    }
    return (
        <Wrapper onClick={onChangeTheme} isDark={theme !== 'light'} onMouseOver={handleMouseOver}
                 onMouseOut={handleMouseOut}>
            <DivImg src={`./images/icon_${theme}_${onHover ? 'hover' : 'b'}.svg`} width={24}/>
        </Wrapper>
    );
};

export default ThemeSwitch;