import React, {useLayoutEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {GET_CHAMPIONS_VERSIONS_REQUEST} from "../../store/reducer/champions";

const useDispatchChampVersion = () => {
    const {
        getChampionVersionsLoading,
        getChampionVersionsDone,
        getChampionVersionsError,
        versions,
    } = useSelector((state) => state.champions);
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        dispatch({
            type: GET_CHAMPIONS_VERSIONS_REQUEST,
        })
    }, []);

    return {getChampionVersionsLoading, getChampionVersionsDone, getChampionVersionsError, data: versions}
};

export default useDispatchChampVersion;
