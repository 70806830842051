import React, {useMemo} from 'react';
import SummonerMasteryItem from "../SummonerMasteryItem";
import {ImageItem} from "../../../common";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const ImgBox = styled.div`
  position: relative;
  margin-right: 8px;
  .imgItem {
    margin-right: 0;
  }
`;

const AbsoluteBox = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.8);
  opacity: 1;
  width: 14px;
  height: 14px;
  border: 1px solid #010101;
  line-height: 11px;
  color: #fff;
`;

const CounterText = styled.span`
  font-size: 11px;
`
const SummonerMasteryStartItem = ({list}) => {
    const {t} = useTranslation();

    const reduceArray = useMemo(() => {
        if (Array.isArray(list)) {
            return list.reduce((sum1, data) => {
                const array = data.start_item.reduce((sum, cur) => {
                    const findIndex = sum.findIndex((findNumber) => Number(findNumber?.itemId) === Number(cur));
                    if (findIndex > -1) {
                        sum[findIndex] = {
                            ...sum[findIndex],
                            count: sum[findIndex].count + 1
                        }
                    } else {
                        sum.push({
                            itemId: Number(cur),
                            count: 1,
                        })
                    }
                    return sum;
                }, []);
                sum1.push({
                    ...data,
                    start_item: array,
                });
                return sum1;
            }, [])
        } else {
            return [];
        }
    }, [list]);

    return (
        <SummonerMasteryItem title={t('summoner.mastery.startItem')} list={reduceArray} minHeight={'120px'}>
            {(data) => data.start_item.map((item, index) => {
                return (
                    <ImgBox key={item.itemId + "startItem" + index}>
                        <ImageItem itemId={Number(item.itemId)}/>
                        {item?.count && item.count > 1 &&
                            <AbsoluteBox>
                                <CounterText>{item.count}</CounterText>
                            </AbsoluteBox>}
                    </ImgBox>)
            })}
        </SummonerMasteryItem>
    );
};

export default SummonerMasteryStartItem;