import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {ChampContainer} from '../../styled/SummonerStyle';
import Cateogry from "../common/Category";
import styled from "styled-components";
import ChampInfo from "./Champ/ChampInfo";
import ChampRenderBy from "./Champ/ChampRenderBy";
import {
    GET_CHAMPIONS_BY_SEASON_SUCCESS,
    GET_SUMMONER_CHAMPION_ARAM_REQUEST,
    GET_SUMMONER_CHAMPION_ARAM_SUCCESS,
    GET_SUMMONER_CHAMPION_URF_REQUEST,
    RESET_SUMMONER_CHAMPIONS_BY
} from "../../store/reducer/summoner";
import {Loading} from "../common";
import UseSetCanonical from "../../common/hooks/useSetCanonical";
import useTitleAndDescription from "../../common/hooks/useTitleAndDescription";
import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {getSummonerARAMStatus, getSummonerStatus} from "../../store/saga/summoner";
import ChampSynergy from "./Champ/ChampSynergy";


const CategoryWrapper = styled.div`
  padding: 12.5px 15px 7.5px;
  border-bottom-width: 1px;
  border-bottom-color: ${props => props.theme.color.gray_200};
  border-style: solid;
  background: ${props => props.theme.color.site_main_color};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const InfoWrapper = styled.div`
  padding-top: 15px;
  padding-inline: 15px;
  background: ${props => props.theme.color.site_main_color};
`;

const LoadingWrapper = styled.div`
  background: ${props => props.theme.color.site_main_color};
  height: 300px;
  border-radius: 6px;
`;


const Champ = ({seasonSelected, setSeasonSelected, bannerClicked, setBannerClicked}) => {
    UseSetCanonical();
    const {userName, region,} = useParams();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const summoner = useSelector((state) => state.summoner.summoner);

    const currentSeason = useSelector((state) => state.imageStore.currentSeason);

    const [categorySelected, setCategorySelected] = useState(0);

    useSelector((state) => state.translation.lang);
    const {
        summonerStatus,
        getSummonerInfoLoading,
        getChampionsBySeasonLoading,
        getSummonerChampionAramLoading,
        getSummonerChampionURFLoading,
        getSummonerInfoDone,
    } = useSelector((state) => state.summoner);
    useEffect(() => {
        window.scroll(0, 0);
    }, []);


    const query = useQuery({
        queryKey: ['summoner/champion', userName, region,],
        queryFn: async () => {



            const obj = {
                platform_id: region,
                puu_id: summoner.summoner_basic_info_dict.puu_id,
                season: currentSeason === 22 ? 21 : currentSeason,
            }

            if (summonerStatus.season === seasonSelected && summonerStatus.season === obj.season) {
                if (summonerStatus?.counter_champion_stats) {
                    setCategorySelected(0);
                } else {
                    const aram = await getSummonerARAMStatus(obj);
                    if (aram !== 404) {
                        dispatch({
                            type: GET_SUMMONER_CHAMPION_ARAM_SUCCESS,
                            data: aram,
                        })
                        setCategorySelected(3)
                    }
                }
                return {};
            }

            dispatch({
                type: RESET_SUMMONER_CHAMPIONS_BY
            })

            const result = await getSummonerStatus(obj)

            if (result === 404) {
                const aram = await getSummonerARAMStatus(obj);
                if (aram !== 404) {
                    dispatch({
                        type: GET_SUMMONER_CHAMPION_ARAM_SUCCESS,
                        data: aram,
                    })
                    setCategorySelected(3)
                }
            } else {
                dispatch({
                    type: GET_CHAMPIONS_BY_SEASON_SUCCESS,
                    data: {
                        summonerStatus: {...result},
                        season: obj.season,
                    }
                })
                setCategorySelected(0);
            }
            return {}
        }
    })

    // useEffect(() => {
    //     if (summonerStatus.season !== seasonSelected) {
    //         dispatch({
    //             type: GET_CHAMPIONS_BY_SEASON_REQUEST,
    //             data: {
    //                 platform_id: region,
    //                 puu_id: summoner.summoner_basic_info_dict.puu_id,
    //                 season: currentSeason === 18 ? 19 : currentSeason,
    //             }
    //         })
    //     }
    // }, []);

    const onClickCategory = useCallback((index) => {
        if (typeof index === 'number') {
            setCategorySelected(index);
        }

        if (index === 3) {
            dispatch({
                type: GET_SUMMONER_CHAMPION_ARAM_REQUEST,
                data: {
                    season: Number(seasonSelected) === 22 ? 21 : Number(seasonSelected),
                }
            })
        } else if (index === 4) {
            dispatch({
                type: GET_SUMMONER_CHAMPION_URF_REQUEST,
                data: {
                    season: Number(seasonSelected) === 22 ? 21 : Number(seasonSelected),
                }
            })
        }

    }, []);

    const champCategory = [{
        title: t('champions.category.myChamp'),
        key: 'myChamp',
        info: t('champions.info.myChamp'),
        extra: t('champions.info.myChampExtra'),
        value: 'counter_champion_stats',
        isNew: false,
    }, {
        title: t('champions.category.enemyChamp'),
        key: 'enemyChamp',
        info: t('champions.info.enemyChamp'),
        extra: t('champions.info.enemyChampExtra'),
        value: 'counter_champion_stats_reverse',
        isNew: false,
    }, {
        title: t('champions.category.synergy'),
        key: 'synergyChamp',
        info: t('champions.info.synergy'),
        extra: t('champions.info.synergyExtra'),
        value: 'synergy_champion_stats',
        isNew: false,
    }, {
        title: t('tabs.aram'),
        key: 'aramChamp',
        info: "",
        extra: "",
        value: 'counter_champion_stats',
        isNew: false,
    }, {
        title: t('tabs.urf'),
        key: 'urfChamp',
        info: "",
        extra: "",
        value: 'counter_champion_stats',
        isNew: false,
    }];

    const seasonList = [
        {
            title: t('season.season2024-3'),
            value: 23,
        },

        {
            title: t('season.season2024-2'),
            value: 21,
        },
        {
            title: t('season.season2024'),
            value: 19,
        },
        {
            title: t('season.season2023split'),
            value: 17,
        },
        {
            title: t('season.season2023'),
            value: 15,
        },
        {
            title: t('season.preseason2023'),
            value: 14,
        },
        {
            title: t('season.season2022'),
            value: 13,
        },
        {
            title: t('season.preseason2022'),
            value: 12,
        },
    ]


    const currentUserName = useMemo(() => {
        if (userName && summoner) {
            return summoner?.summoner_basic_info_dict?.riot_id_name;
        } else {
            return userName;
        }
    }, [summoner, userName]);

    const currentTagName = useMemo(() => {
        if (userName && summoner) {
            return summoner?.summoner_basic_info_dict?.riot_id_tag_line
        } else {
            return '#';
        }
    }, [summoner, userName])

    const currentProName = useMemo(() => {
        if (userName && summoner) {
            return summoner?.summoner_basic_info_dict?.pro_streamer_info_dict?.name
        } else {
            return '';
        }
    }, [summoner, userName])

    useTitleAndDescription({
        title: t('header.titleSummonerChampion', {
            summoner: decodeURI(currentUserName),
            tag: currentTagName,
            pro: currentProName,
        }),
        description: t('header.titleSummonerChampionDescription', {
            summoner: decodeURI(currentUserName),
            tag: currentTagName,
            pro: currentProName,
            location: region,
        }),
    })

    if (getSummonerInfoLoading && !getSummonerInfoDone || query.isLoading) return (
        <ChampContainer>
            <LoadingWrapper>
                <Loading/>
            </LoadingWrapper>
        </ChampContainer>)


    if (categorySelected === 2) {
        return (
            <>

                {(getSummonerInfoLoading || getChampionsBySeasonLoading || getSummonerChampionAramLoading || getSummonerChampionURFLoading) ? (
                    <LoadingWrapper>
                        <Loading/>
                    </LoadingWrapper>
                ) : (
                    <ChampSynergy
                        categorySelected={categorySelected}
                        onClickCategory={onClickCategory}
                        selectedSeason={seasonSelected}
                        onChangeSelectedSeason={setSeasonSelected}
                        currentCategory={categorySelected}
                        champCategory={champCategory}
                        seasonList={seasonList}
                    />
                )}
            </>
        )
    }


    return (
        <ChampContainer>
            <CategoryWrapper>
                <Cateogry
                    onClickCategory={onClickCategory}
                    selected={categorySelected}
                    list={champCategory}
                />
            </CategoryWrapper>
            <InfoWrapper>
                <ChampInfo
                    extra={champCategory[categorySelected].extra}
                    infoText={champCategory[categorySelected].info}
                />
            </InfoWrapper>
            {(getSummonerInfoLoading || getChampionsBySeasonLoading || getSummonerChampionAramLoading || getSummonerChampionURFLoading) ? (
                <LoadingWrapper>
                    <Loading/>
                </LoadingWrapper>
            ) : (
                <ChampRenderBy
                    bannerClicked={bannerClicked}
                    setBannerClicked={setBannerClicked}
                    selectedSeason={seasonSelected}
                    onChangeSelectedSeason={setSeasonSelected}
                    currentCategory={categorySelected}
                    champCategory={champCategory}
                    seasonList={seasonList}
                />

            )}
        </ChampContainer>
    );
};

export default Champ;
