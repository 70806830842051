import React, {useCallback} from 'react';
import {useTranslation} from "react-i18next";

const UseLaneTrans = () => {
    const {t} = useTranslation();
    const laneTrans = useCallback((data) => {
        switch (data) {
            case "top": {
                return t('championAnalyze.top')
            }
            case "jungle" : {
                return t('championAnalyze.jungle')
            }
            case "middle" : {
                return t('championAnalyze.mid')
            }
            case "bot" : {
                return t('championAnalyze.bot')
            }
            case "supporter": {
                return t('championAnalyze.support')
            }
            case "aram": {
                return t("championsHistory.title.aram")
            }
            case "singular": {
                return "단일 챔피언"
            }
            default:
                return ""
        }
    }, []);
    return laneTrans
};

export default UseLaneTrans;