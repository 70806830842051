import React, {lazy, Suspense, useCallback, useState} from "react";
import styled from "styled-components";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import ChampionsHeader from "../../../Champions/ChampionsContent/ChampionsHeader";
import ChampionsInput from "../../../Champions/ChampionsContent/ChampionsInput";
import ChampionsCname from "../../../Champions/ChampionsContent/ChampionsSearch/ChampionsCName";
import ChampionsWhiteWrapper from "../../../Champions/ChampionsContent/ChampionsWhiteWrapper";
import {Loading} from "../../../common";

const ChampionsList = lazy(() => import("../../../Champions/ChampionsContent/ChampionsSearch/ChampionsList"));


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 482.98px;
  margin-right: 5px;

  .champions-bar {
    border-top: 0;
  }
`;

const SearchInputWrapper = styled.div`
  margin-bottom: 10px;
`;

const URFSearch = () => {
    const history = useHistory();
    const {t} = useTranslation();
    const [choVisible, setChoVisible] = useState(false);
    const [indivisualVisible, setindivisualVisible] = useState('');
    const championData = useSelector((state) => state?.imageStore.champions);

    const onClickCname = useCallback((value) => {
        if (value !== undefined && (typeof value === 'string' || typeof value === 'boolean')) {
            setChoVisible(value);
        }
    }, []);

    const onChangeINidivisualVisible = useCallback((value) => {
        setindivisualVisible(value)
    }, []);


    const onClickChamp = useCallback((champName) => {
        history.push(`/champions/${champName}/build/urf`)
    }, []);


    return (
        <Wrapper>
            <ChampionsHeader
                flexD={'column'}
                alignItems={'stretch'}
            >
                <SearchInputWrapper>
                    <ChampionsInput
                        inputValue={indivisualVisible}
                        setInputValue={onChangeINidivisualVisible}
                    />
                </SearchInputWrapper>
                <ChampionsCname
                    onClick={onClickCname}
                />
            </ChampionsHeader>
            <ChampionsWhiteWrapper>
                <Suspense fallback={<Loading/>}>
                    <ChampionsList
                        laneVisible={'all'}
                        championData={championData}
                        inidivisualVisible={indivisualVisible}
                        choVisible={choVisible}
                        onClickChamp={onClickChamp}
                    />
                </Suspense>
            </ChampionsWhiteWrapper>
        </Wrapper>
    );
};

export default URFSearch;
