import React, {useState} from 'react';
import styled from "styled-components";
import Tooltip from "react-tooltip-lite";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  position: relative;
  margin-right: 15px;

`;

const DivImg = styled.button`
  width: 24px;
  height: 24px;
  background: url(${props => props.src}) no-repeat;
`;


const ContentTooltip = styled(Tooltip)`
  .react-tooltip-lite {
    border: 1px solid ${props => props.theme.color.default_border_color};
    background-color: ${props => props.theme.color.site_main_color};
    padding: 7px 10px !important;
    border-radius: 6px;
    font-size: 14px;
    white-space: nowrap;
    width: max-content !important;
    color: ${props => props.theme.color.dark_100};
    font-weight: 500;
  }
`;
const ProRequestSwitch = () => {
    const {t} = useTranslation();
    const [onHover, setOnHover] = useState(false)
    const theme = useSelector((state) => state.theme.color);

    const handleMouseOut = () => {
        setOnHover(false);
    }

    const handleMouseOver = () => {
        setOnHover(true)
    }


    return (
        <Wrapper onClick={() => {
            window.open('https://deeplol.atlassian.net/servicedesk/customer/portal/1/group/1/create/40')
        }} onMouseOver={handleMouseOver}
                 onMouseOut={handleMouseOut}>
            <ContentTooltip content={t('content.pro')} arrow={false} direction={'down'} zIndex={99999999} hoverDelay={50}
                            distance={5}>
                <DivImg src={`./images/icn_prostrm_${theme}_${onHover ? 'hover' : 'b'}.svg`} width={24}/>
            </ContentTooltip>
        </Wrapper>
    );
};

export default ProRequestSwitch