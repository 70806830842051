import React from 'react';
import styled, {useTheme} from "styled-components";
import DownArrow from "../TagWithArrow/DownArrow";

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-right: 10px;
  overflow: hidden;

  select {
    &:hover {
      background-color: ${props => props.theme.color.white_200};
    }
    cursor: pointer;
    width: 159px;
    color: ${props => props.theme.color.dark_100};
    border: 1px solid ${props => props.theme.color.default_border_color};
    height: 40px;
    border-radius: 6px;
    padding: 0 20px 0 8px;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    //background: ${props => `url("${props.theme.icon.path}/icon_select_arrow.svg")`} no-repeat;
    //background-position: calc(100% - 0.75rem) center !important;
  }
  option {
    background: ${props => props.theme.color.site_main_color};
    color:${props => props.theme.color.dark_100};
  }
`;

const AbsoluteBox = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10px;
`;



const RankingDropBox = ({onChange, list, selected, value, title}) => {
    const theme = useTheme();
    return (
        <SelectWrapper>
            <AbsoluteBox>
                <DownArrow size={3} color={theme.color.dark_100}/>
            </AbsoluteBox>
            <select onChange={onChange}>
                {Array.isArray(list) && list.map((data, index) => {
                    return <option value={value(data)}
                                   selected={selected(data)}
                                   key={index}>{title(data)}</option>
                })}
            </select>
        </SelectWrapper>
    );
};

export default RankingDropBox;
