import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import styled, {useTheme} from "styled-components";
import ChampArrow from "../../../ChampArrow";
import TooltipWrapper from "../../../../../common/TooltipWrapper";
import ChampionImageWithLane from "../../../../../common/ChampionImageWithLane";
import {ImageLane} from "../../../../../common";
import {hundredCheck, kdaColor, scoreColor} from "../../../../../../function";
import DamageGraph from "../../../../DetailTab/GameAnalytics/GameSummary/Table/TableContent/DamageGraph";
import WinrateGuage from "../../../WinrateGuage";
import BigTooltip from "../../../../../Tooltip/BigTooltipBox";


const DamageWrapper = styled.div`
  margin-inline: 16px;
`;

const AllChampWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const SmallImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  margin-right: 6px;
`;

const ImageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
`;



const TableARAM = ({
                       sort, data, handleSort, currentOrder, sortOrder, mostDamage, leastDamage
                   }) => {
    const {t} = useTranslation();
    const theme = useTheme();

    // 상세보기


    // 상세보기의 더보기


    const calcWinRateScore = useCallback((value) => {
        if (Number(value) === 100) {
            return Number(value);
        } else {
            return value;
        }
    }, []);

    if (Array.isArray(data) && data.length < 1) return null;

    return (<>
        <table className="table">
            <colgroup>
                <col width="40px"/>
                <col width="*"/>
                <col width="70px"/>
                <col width="130px"/>
                <col width="105px"/>
                <col width="115px"/>
                <col width="115px"/>
                <col width="56px"/>
                <col width="75px"/>
                <col width="40px"/>
                <col width="40px"/>
                <col width="40px"/>
                <col width="40px"/>
            </colgroup>
            <thead>
            <tr>
                <th rowSpan="2">
                    <div className="t-cell">
                        <span className="name">#</span>
                    </div>
                </th>
                <th rowSpan="2" className={`sort ${currentOrder}`}
                    onClick={() => handleSort(sortOrder.champion_name)}>
                    <div className="t-cell">
                        <ChampArrow
                            filterSelected={sortOrder.champion_name.value === sort[0].value}
                            title={t('tabs.champions')}
                            arrow={sortOrder.champion_name.sortType}
                        />
                        {/*<span className="name">{t('tabs.champions')}</span>*/}
                        {/*<span className="arrow"></span>*/}
                    </div>
                </th>
                <th
                    rowSpan="2"
                    className={`sort ${currentOrder}`}
                    onClick={() => handleSort(sortOrder?.games)}
                >
                    <div className="t-cell">
                        <ChampArrow
                            filterSelected={sortOrder?.games.value === sort[0].value}
                            title={t('tabs.games')}
                            arrow={sortOrder?.games.sortType}
                        />
                    </div>
                </th>
                <th
                    rowSpan="2"
                    className={`sort ${currentOrder}`}
                    onClick={() => handleSort(sortOrder.win_rate)}
                >
                    <div className="t-cell">
                        <ChampArrow
                            filterSelected={sortOrder?.win_rate.value === sort[0].value}
                            extra={t('tabs.winrate')}
                            title={t('tabs.winlose')}
                            arrow={sortOrder?.win_rate?.sortType}
                        />
                    </div>
                </th>
                <th rowSpan="2" className={`sort ${currentOrder}`} onClick={() => handleSort(sortOrder.kda)}>
                    <div className="t-cell">
                        <ChampArrow
                            filterSelected={sortOrder?.kda.value === sort[0].value}
                            title={'KDA'}
                            arrow={sortOrder?.kda.sortType}
                        />
                    </div>
                </th>
                <th className={`sort ${currentOrder}`}
                    onClick={() => handleSort(sortOrder?.damage_dealt_per_min)}>
                    <div className={"t-cell"}>
                        <ChampArrow
                            filterSelected={sortOrder?.damage_dealt_per_min.value === sort[0].value}
                            title={t('tabs.damage')}
                            arrow={sortOrder?.damage_dealt_per_min.sortType}
                        />

                    </div>
                </th>
                <th className={`sort ${currentOrder}`}
                    onClick={() => handleSort(sortOrder?.damage_taken_per_min)}>
                    <div className={"t-cell"}>
                        <ChampArrow
                            filterSelected={sortOrder?.damage_taken_per_min.value === sort[0].value}
                            title={t('tabs.takenDamage')}
                            arrow={sortOrder?.damage_taken_per_min.sortType}
                        />
                    </div>
                </th>
                <th
                    rowSpan="2"
                    className={`sort ${currentOrder}`}
                    onClick={() => handleSort(sortOrder?.cs_per_min)}
                >
                    <TooltipWrapper
                        tooltipText={t('tabs.csMExplain')}
                    >
                        <div className="t-cell">
                            <ChampArrow
                                filterSelected={sortOrder?.cs_per_min.value === sort[0].value}
                                title={t('tabs.csm')}
                                arrow={sortOrder?.cs_per_min.sortType}
                            />
                        </div>
                    </TooltipWrapper>
                </th>
                <th
                    className={`sort ${currentOrder}`}
                    onClick={() => handleSort(sortOrder?.damage_per_team)}>
                    <div className={"t-cell"}>
                        <TooltipWrapper
                            tooltipText={t('tabs.dsTooltip')}
                        >
                            <ChampArrow
                                filterSelected={sortOrder?.damage_per_team.value === sort[0].value}
                                title={'DMG%'}
                                arrow={sortOrder?.damage_per_team.sortType}
                            />
                        </TooltipWrapper>
                    </div>
                </th>

                <th
                    className={`sort ${currentOrder}`}
                    onClick={() => handleSort(sortOrder?.double_kills)}>
                    <ImageBox>
                        <BigTooltip tooltipText={t("summoner.multiKill.double")}>
                            <img src={`/images/ico_damage_d_table_nor.svg`} alt={''} width={20} height={20}/>
                        </BigTooltip>
                    </ImageBox>
                </th>
                <th
                    className={`sort ${currentOrder}`}
                    onClick={() => handleSort(sortOrder?.triple_kills)}>
                    <ImageBox>
                        <BigTooltip tooltipText={t("summoner.multiKill.triple")}>
                            <img src={`/images/ico_damage_t_table_nor.svg`} alt={''} width={20} height={20}/>
                        </BigTooltip>
                    </ImageBox>
                </th>
                <th
                    className={`sort ${currentOrder}`}
                    onClick={() => handleSort(sortOrder?.quadra_kills)}>
                    <ImageBox>
                        <BigTooltip tooltipText={t("summoner.multiKill.quadra")}>
                            <img src={`/images/ico_damage_q_table_nor.svg`} alt={''} width={20} height={20}/>
                        </BigTooltip>
                    </ImageBox>
                </th>
                <th
                    className={`sort ${currentOrder}`}
                    onClick={() => handleSort(sortOrder?.penta_kills)}>
                    <ImageBox>
                        <BigTooltip tooltipText={t("summoner.multiKill.penta")}>
                            <img src={`/images/ico_damage_p_table_nor.svg`} alt={''} width={20} height={20}/>
                        </BigTooltip>
                    </ImageBox>
                </th>







            </tr>
            </thead>
            <tbody>
            {data.map((row, index) => {
                return (<React.Fragment key={index + "ARAM"}>
                    <tr className={'close'}>
                        <td>
                            <span className="normal">{index + 1}</span>
                        </td>
                        <td>
                            <div className="champ">
                                {row?.champion_id ? <ChampionImageWithLane champion_id={row.champion_id}
                                                                           enableChampName={true}/> :
                                    <AllChampWrapper>
                                        <SmallImageBox>
                                            <img
                                                src={'./images/icon_s_position_all.svg'}
                                                width={14}
                                                height={14}
                                                alt={''}
                                            />
                                        </SmallImageBox>
                                        <span>{t('champions.allChamp')}</span>
                                    </AllChampWrapper>}
                            </div>
                        </td>
                        {data[0].lane !== undefined && (<td>
                            <ImageLane lane={row.lane} color={'dark'}/>
                        </td>)}
                        <td>
                            <span className="bold">{row.games}</span>
                        </td>
                        <td>
                            <div className="winlose">
                                <div className="graph">
                                    <WinrateGuage
                                        win={row.wins}
                                        lose={row.losses}
                                    />
                                </div>
                                <div className="winrate">
                                                <span
                                                    className="bold">{scoreColor(hundredCheck(row.win_rate.toFixed(1)))}</span>%
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="kda">
                                <span className="bold">{kdaColor(row.kda.toFixed(2))}</span>
                                <p>
                                    ({row.kills.toFixed(1)} / {row.deaths.toFixed(1)} / {row.assists.toFixed(1)})
                                </p>
                            </div>
                        </td>
                        <td>
                            <DamageWrapper>
                                <DamageGraph
                                    bgColor={theme.color.gray_100}
                                    color={theme.color.dark_200}
                                    barColor={'#EF4D8A'}
                                    dmg={row.damage_dealt_per_min.toFixed(0)}
                                    total={mostDamage?.toFixed(0)}
                                />
                            </DamageWrapper>
                        </td>
                        <td>
                            <DamageWrapper>
                                <DamageGraph
                                    bgColor={theme.color.gray_100}
                                    color={theme.color.dark_200}
                                    barColor={'#7C8698'}
                                    dmg={row.damage_taken_per_min.toFixed(0)}
                                    total={leastDamage?.toFixed(0)}
                                />
                            </DamageWrapper>
                        </td>

                        <td>
                            <span className="normal">{row?.cs_per_min?.toFixed(1)}</span>
                        </td>
                        <td>
                            <span className="normal">{row?.damage_per_team?.toFixed(1)}%</span>
                        </td>
                        <td>
                            <ImageBox>
                                <span className="normal">{Math.floor(row?.double_kills)}</span>
                            </ImageBox>
                        </td>

                        <td>
                            <ImageBox>
                                <span className="normal">{Math.floor(row?.triple_kills)}</span>
                            </ImageBox>
                        </td>
                        <td>
                            <ImageBox>
                                <span className="normal">{Math.floor(row?.quadra_kills)}</span>
                            </ImageBox>
                        </td>
                        <td>
                            <ImageBox>
                                <span className="normal">{Math.floor(row?.penta_kills)}</span>
                            </ImageBox>
                        </td>


                    </tr>
                </React.Fragment>);
            })}
            </tbody>
        </table>
    </>);
};

export default TableARAM;
