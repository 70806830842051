import React from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import ChampRenderTableARAM from "../ChampRenderTableARAM";
import RenderTable from "../ChampRenderTable";


const ChampRenderBy = ({
                           currentCategory,
                           champCategory,
                           onChangeSelectedSeason,
                           selectedSeason,
                           setBannerClicked,
                           bannerClicked,
                           seasonList
                       }) => {
    const {summonerARAM, summonerURF} = useSelector((state) => state.summoner);
    const {t} = useTranslation();


    if (currentCategory === 0 || currentCategory === 1) {
        return (
            <RenderTable
                setBannerClicked={setBannerClicked}
                bannerClicked={bannerClicked}
                seasonList={seasonList}
                onChangeSelectedSeason={onChangeSelectedSeason}
                selectedSeason={selectedSeason}
                currentCategory={currentCategory}
                champCategory={champCategory}
            />
        );
    } else if (currentCategory === 3) {
        return <ChampRenderTableARAM
            summonerARAM={summonerARAM}
            currentCategory={currentCategory}
            champCategory={champCategory}
            onChangeSelectedSeason={onChangeSelectedSeason}
            selectedSeason={selectedSeason}
            seasonList={seasonList}
        />
    } else if (currentCategory === 4) {
        return <ChampRenderTableARAM
            summonerARAM={summonerURF}
            currentCategory={currentCategory}
            champCategory={champCategory}
            onChangeSelectedSeason={onChangeSelectedSeason}
            selectedSeason={selectedSeason}
            seasonList={seasonList}
        />
    }

    return null;

};

export default ChampRenderBy;
