import React from 'react';

import styled from "styled-components";
import {useTranslation} from "react-i18next";
import RankingTableHeader
    from "../../../../../Champions/ChampionsContent/ChampionsRank/ChampionsRankingTable/RankingTableHeader";

const TableHeaderWrapper = styled.tr`
  height: 40px;
  border-color: ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.white_100};
  border-style: solid;
  border-bottom-width: 1px;
`;

const DamageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const AramRankTableHeader = ({onClickSort, sortData, currentSort}) => {
    const {t} = useTranslation()

    return (
        <TableHeaderWrapper>
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                sort={sortData.rank}
                title={t('championsRanking.ranking')}
            />
            <RankingTableHeader
                onClickSort={onClickSort}
                title={t('championsRanking.champion')}
                disableBar={true}
            />
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                sort={sortData.tier}
                title={t('championsRanking.tier')}/>
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                sort={sortData.win_rate}
                title={t('championsRanking.winRate')}/>
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                title={t('championsRanking.pickRate')}
                sort={sortData.pick_rate}
            />
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                sort={sortData.dpm}
                title={"DPM"}
                tooltip={t('championsRanking.dpmTooltip')}
            />
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                sort={sortData.pentakills}
                title={t('championsRanking.pentaKill')}/>
        </TableHeaderWrapper>
    );
};

export default AramRankTableHeader;
