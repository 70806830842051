import React, {useCallback} from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  padding-top: 1px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const KdaBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const RatioTag = styled.div`
  min-width: 4ch;
  display: flex;
  justify-content: center;
  border-radius: 6px;
  margin-top: 8px;
`;

const RatioText = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${props => {
    let color = props.theme.color.gray_400;
    if (props.score === 'Perfect') {
      color = props.theme.color.yellow_100;
    } else if (props.score >= 4.0) {
      color = props.theme.color.yellow_100;
    } else if (props.score >= 3.0) {
      color = props.theme.color.blue_400;
    } else if (props.score >= 2.0) {
      color = props.theme.color.green_300;
    }
    return color;
  }
  };
`;

const KdaText = styled.span`
  min-width: 2ch;
  text-align: center;
  font-size: 12px;
  color: ${props => props.theme.color.dark_200};
  font-weight: 500;
`;

const DivideText = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.dark_200};
  font-weight: 500;
`;

const KdaTable = ({kill, death, assist, runaway}) => {


        const getKda = useCallback(() => {
            if (death > 0 || runaway) {
                const calDeath = death || 1;
                return ((kill + assist) / calDeath).toFixed(2);
            }
            return 'Perfect'
        }, [kill, death, assist])

        return (
            <>
                <Wrapper>
                    <KdaBox>
                        <KdaText>{kill}</KdaText>
                        <DivideText>/</DivideText>
                        <KdaText>{death}</KdaText>
                        <DivideText> / </DivideText>
                        <KdaText>{assist}</KdaText>
                    </KdaBox>
                    <RatioTag>
                        <RatioText score={getKda()}>
                            {getKda()}
                        </RatioText>
                    </RatioTag>
                </Wrapper>
            </>
        );
    }
;

export default KdaTable;
