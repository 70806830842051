import {createGlobalStyle} from 'styled-components';
import reset from 'styled-reset';
import {AI_SCORE_BAD_TEXT, AI_SCORE_GOOD_TEXT, AI_SCORE_PERFECT_TEXT, AI_SCORE_VERY_BAD_TEXT} from "./scoreColor";

const GlobalStyles = createGlobalStyle`
  ${reset}

  ;
  * {
    box-sizing: border-box;
    outline: 0;
  }

  html, body {
    font-family: Pretendard, 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: 400;
    background: transparent;
    color: ${props => props.theme.color.dark_100};
  }

  body {
    background-color: ${props => props.theme.color.site_background_color};
    overflow-y: scroll;
    min-width: 1080px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  img {
    vertical-align: top;
  }

  button {
    cursor: pointer;
    border: 0;
    background: transparent;
    padding: 0;
  }

  input,
  button {
    font: inherit;
  }

  select {
    font: inherit;
    appearance: none;
  }

  .non_image {
    display: inline-block;
    width: fit-content;
    height: fit-content;
  }

  /* 티어 컬러 */
  .tier_color {
    &.challenger {
      color: #EE7A00;
    }

    &.grandmaster {
      color: #C23449;
    }

    &.master {
      color: #863DA1;
    }

    &.diamond {
      color: #2898C9;
    }

    &.emerald {
      color: #12C3C9;
    }

    &.platinum {
      color: #1DA794;
    }

    &.gold {
      color: #BA8D26;
    }

    &.silver {
      color: #6A79AB;
    }

    &.bronze {
      color: #876B26;
    }

    &.iron {
      color: #948A7E;
    }

    &.unrank {
      color: #7F7F7F;
    }
  }

  /* 티어 뱃지 */
  .tier_badge {
    width: 24px;
    display: inline-block;
    height: 14px;
    font-size: 12px;
    font-weight: 400;
    color: ${props => props.theme.color.site_main_color};
    text-align: center;
    border-radius: 7px;

    &.challenger {
      font-size: 14px;
      background: transparent linear-gradient(180deg, #FFBE27 0%, #FC5E00 100%);
    }

    &.grandmaster {
      width: 24px;
      font-size: 11px;
      background: transparent linear-gradient(180deg, #DE435B 0%, #9A2A3B 100%);
    }

    &.master {
      font-size: 11px;
      background: transparent linear-gradient(180deg, #A54CC5 0%, #793791 100%);
    }

    &.diamond {
      background: transparent linear-gradient(180deg, #429EC8 0%, #2E708E 100%);
    }

    &.platinum {
      background: transparent linear-gradient(180deg, #38B8A7 0%, #298074 100%);
    }

    &.gold {
      background: transparent linear-gradient(180deg, #DEAD3B 0%, #98782C 100%);
    }

    &.silver {
      background: transparent linear-gradient(180deg, #8691B5 0%, #5E657D 100%);
    }

    &.bronze {
      background: transparent linear-gradient(180deg, #A2843C 0%, #715C27 100%);
    }

    &.iron {
      background: transparent linear-gradient(180deg, #818181 0%, #636363 100%);
    }

    &.unranked {
      background-color: #D0D3DF;
      color: #656565;
    }
  }

  /* 점수 컬러 */
  .score_color {
    &.verygood {
      color: ${AI_SCORE_PERFECT_TEXT};
    }

    &.good {
      color: ${AI_SCORE_GOOD_TEXT};
    }

    &.bad {
      color: ${AI_SCORE_BAD_TEXT};
    }

    &.verybad {
      color: ${AI_SCORE_VERY_BAD_TEXT};
    }
  }


  /* KDA 컬러 */
  .kda_color {
    font-weight: 500;

    &.perfect {
      color: #F19000;
    }

    &.high {
      color: #F19000;
    }

    &.highMiddle {
      color: #3571E9;
    }

    &.middle {
      color: #259A7E;
    }

    &.low {
      color: #69788D;
    }
  }

  /* mvp, ace 뱃지 */
  .crown {
    display: inline-block;
    width: 22px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &.mvp {
      background-image: url('/images/ico_crown_gold_w.svg');
    }

    &.ace {
      background-image: url('/images/ico_crown_silver_w.svg');
    }
  }

  /* 승급전 */
  .promos {
    position: relative;
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      background: #707070;
      z-index: 1;
    }

    span {
      position: relative;
      z-index: 2;
      display: flex !important;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      background-color: #c0c9d1;
      border: 2px solid ${props => props.theme.color.site_main_color};
      border-radius: 50%;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
      font-size: 11px;
      font-weight: 400;
      color: ${props => props.theme.color.site_main_color};

      &.W {
        background-color: #5092cc;
      }

      &.L {
        background-color: ${props => props.theme.color.red_300};
      }

      &.N {
        font-size: 0;
        text-indent: -9999px;
      }
    }
  }
  div {
    unicode-bidi: isolate;
  }

  /* 오른의 업그레이드 아이템 */
  .upgrade {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: url('https://raw.communitydragon.org/11.15/game/assets/items/itemmodifiers/bordertreatmentornn.png') no-repeat center;
      background-size: cover;
    }
  }

`;

export default GlobalStyles;
