import React from 'react';
import {useTranslation} from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
  top: -42px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: max-content;
  padding: 12px 30px;
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
`;

const ShareSpan = styled.span`
  color: #65cbff;
`;

const SharedMessage = () => {
    const {t} = useTranslation();
    return (
        <Wrapper>
            <ShareSpan>{t('multi.clipboard')}</ShareSpan>
        </Wrapper>
    );
};

export default SharedMessage;