import React, {useState} from 'react';
import styled from "styled-components";
import FilterArrow from "../../../common/FilterArrow";

const ArrowTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  width: 100%;
  justify-content: center;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;


const HeaderSelectedBar = styled.div`
  position: absolute;
  bottom: 0;
  width: ${props => {
    if (props.selected) {
      return '100%';
    } else {
      return 0;
    }
  }};
  display: block;
  height: 3px;
  background: ${props => props.theme.color.green_100};
  transition: ${props => {
    if (props.selected) {
      return 'width 200ms linear;';
    }
    return '';
  }};
  overflow: hidden;
`;

const ChampArrow = ({title, arrow = false, extra, filterSelected, disableBar}) => {
    const [onHover, setOnHover] = useState(false);

    const handleMouseOut = () => {
        setOnHover(false);
    }

    const handleMouseOver = () => {
        setOnHover(true)
    }

    const RenderBar = () => {
        if (disableBar) {
            return <></>
        }
        if (filterSelected) {
            return <HeaderSelectedBar selected={filterSelected}/>
        } else {
            return <HeaderSelectedBar selected={onHover}/>
        }
    }
    return (
        <Wrapper onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
            <ArrowTitleWrapper >
                <span className="name">{title}
                    {extra &&
                        <i>
                            ({extra})
                        </i>
                    }
                </span>
                <FilterArrow arrow={arrow} selected={filterSelected}/>
            </ArrowTitleWrapper>
            <RenderBar/>
        </Wrapper>
    );
};

export default ChampArrow;
