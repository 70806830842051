import React, {useCallback} from 'react';
import {ColumnBox, FlexBox, FlexOneBox} from "../../../ui/styled";
import RegionTag from "../../../common/NewSearch/RegionTag";
import styled from "styled-components";
import SummaryTimer from "./summaryTimer";
import SpectateSummarySummoner from "./summarySummoner";
import {createClientLiveURL} from "../../../../common/blob/clientIngame";
import {useTranslation} from "react-i18next";
import {getRegionServer} from "../../../../function";
import useQueueId from "../../../../common/hooks/useQueueId";

const MapTitle = styled.span`
  font-size: 12px;
  font-weight: 500;
`;



const SummonerText = styled.span`
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
`;


const PurpleButton = styled.button`
  background-color: ${props => props.theme.color.background_fff_60};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 65px;
  padding: 0 2px;
  color: ${props => props.theme.color.font_5F_EA};
  font-size: 12px;
  line-height: 18px;
  border: 1px solid ${props => props.theme.color.font_5F};
  border-radius: 6px;

  & + & {
    margin-top: 8px;
  }
`;


const Wrapper = styled(FlexBox)`
  cursor: pointer;
  background-color: ${props => {
    let color = props.theme.color.site_main_color;
    if (props.isOpen) color = props.theme.color.background_F0;
    return color;
  }};

  &:hover {
    background-color: ${props => props.theme.color.background_F0};
  }
`;

const SpectateLiveSummary = ({
                                 player1,
                                 player2,
                                 mostLikedPlayer,
                                 encryptKey,
                                 matchId,
                                 platformId,
                                 startTime,
                                 isOpen,
                                 queueId,
                             }) => {
    const {t} = useTranslation();
    const {getMatchName} = useQueueId();
    const onClickClientSpec = (event) => {
        try {
            event.stopPropagation();
            const clientUrl = createClientLiveURL(encryptKey, matchId, platformId);
            const link = document.createElement('a');
            link.href = clientUrl;
            link.download = `deeplol_${encryptKey.substring(0, 6)}.bat`
            link.click();
            link.remove();
            window.URL.revokeObjectURL(clientUrl);
        } catch (e) {
            console.log(e);
        }
    }

    const onClickIngame = useCallback((event) => {
        event.stopPropagation();
        const url = `/summoner/${getRegionServer(platformId).toLowerCase()}/${mostLikedPlayer.riot_id_name}-${mostLikedPlayer.riot_id_tag_line}/ingame`;
        window.open(url, "_blank", "noreferrer");
    }, [mostLikedPlayer, platformId]);
    return (
        <Wrapper width={'100%'} padding={'0 12px'} justifyContent={'flex-start'} height={'72px'} isOpen={isOpen}>
            <FlexBox width={'100%'} alignItems={'center'}>
                <FlexOneBox>
                    <MapTitle>{getMatchName(queueId)}</MapTitle>
                    <SpectateSummarySummoner summoner={player1}/>
                </FlexOneBox>
                <ColumnBox margin={'0 36px'} height={'52px'} alignItems={'center'}>
                    <RegionTag
                        disableHover={true}
                        borderRadius={'3px'}
                        width={'34px'}
                        height={'20px'}
                        selected={getRegionServer(platformId)}
                        isDisabled={false}
                        title={getRegionServer(platformId)}
                    />
                    <SummonerText>VS</SummonerText>
                    <SummaryTimer initTimer={startTime ? startTime : Math.trunc(new Date() / 1000)}/>
                </ColumnBox>
                <FlexOneBox>
                    <SpectateSummarySummoner reverse={true} summoner={player2}/>
                    <ColumnBox justifyContent={'center'} alignItems={'center'}>
                        <PurpleButton onClick={onClickClientSpec}>{t('live.spectate')}</PurpleButton>
                        <PurpleButton onClick={onClickIngame}>{t('live.ingame')}</PurpleButton>
                    </ColumnBox>
                </FlexOneBox>
            </FlexBox>
        </Wrapper>
    );
};

export default SpectateLiveSummary;