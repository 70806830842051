import React from 'react';
import styled from "styled-components";
import BigTooltip from "../../../../Tooltip/BigTooltipBox";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${props => props.wrapperBackgroundColor};
`;

const RuneImage = styled.img`
  border-radius: 50%;
  border: ${(props => props.border)}px solid ${props => {
    let color = '#E66060';
    if (typeof props.borderColor === "string") color = props.borderColor;
    if (props.disalbed) color = 'transparent';
    return color;
  }};
  background-color: ${props => props.imgBackgroundColor};
  opacity: ${props => {
    let opacity = '1';
    if (props.disalbed) opacity = '0.3';
    return opacity;
  }};
  ${props => props.disalbed && "mix-blend-mode: luminosity;"}
`;

const RuneTitle = styled.span`
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;

`;

const ChampionDetailRuneImages = ({
                                      imageBorder = 2,
                                      width = 32,
                                      height = 32,
                                      image,
                                      title,
                                      tooltip,
                                      className,
                                      disalbed = false,
                                      borderColor,
                                      disableTooltip,
                                      imgBackgroundColor = "#000",
                                      wrapperBackgroundColor = 'transparent',
                                  }) => {
    return (
        <BigTooltip tooltipText={tooltip} disabled={disableTooltip} textAlign={'left'}>
            <Wrapper className={className} disalbed={disalbed} wrapperBackgroundColor={wrapperBackgroundColor}>
                <RuneImage
                    imgBackgroundColor={imgBackgroundColor}
                    border={disalbed ? false : imageBorder}
                    borderColor={borderColor}
                    disalbed={disalbed}
                    width={width}
                    height={height}
                    src={`${process.env.REACT_APP_IMG}img/${image}?w=76&h=76&f=webp`}
                    alt={'rune'}
                    onError={(e) => e.target.src = "/images/icon_non_item.svg"}
                />
                {title && <RuneTitle>{title}</RuneTitle>}
            </Wrapper>
        </BigTooltip>
    );
};

export default ChampionDetailRuneImages;
