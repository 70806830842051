import axios from "axios";

export function customAxios(retryCount) {
    const axiosInstance = axios.create();
    const onFulfilled = (res) => res;
    const retry = (errorConfig) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(axiosInstance.request(errorConfig));
            }, 2000);
        });
    }

    const onRejected = (error) => {
        retryCount--;
        if (error.config && retryCount && error?.response?.data?.status === 2) {
            return retry(error.config);
        }

        return Promise.reject(error);
    };
    axiosInstance.interceptors.response.use(
        onFulfilled,
        onRejected,
    );
    return axiosInstance;
}
