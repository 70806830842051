import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import useQueryRegion from "./useQueryRegion";

const UseDispatchMastery = (request, obj) => {
    const dispatch = useDispatch();
    const [region] = useQueryRegion();

    useEffect(() => {
        if (region) {
            dispatch({
                type: request,
                data: {
                    ...obj,
                    region : region,
                }
            });
        }
    }, [region,obj?.lane]);

    return null;

};

export default UseDispatchMastery;