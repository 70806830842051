import React, {useCallback, useEffect, useState} from 'react';
import {getIngameTime} from "../../../../function";
import styled from "styled-components";


const Timer = styled.span`
  font-size: 12px;
  opacity: 0.6;
`;

const SummaryTimer = ({initTimer}) => {
    const [summaryTimer, setSummaryTimer] = useState(Math.trunc((Date.now() / 1000)) - initTimer);

    const getTime = useCallback(() => {
        setSummaryTimer(prev => prev + 1);
    }, []);
    useEffect(() => {
        let interval = setInterval(() => {
            getTime();
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);
    return (
        <Timer>{getIngameTime(summaryTimer)}</Timer>
    );
};

export default SummaryTimer;