import React, {useMemo} from 'react';
import styled, {useTheme} from "styled-components";
import ListButton from "../../../common/ListButton";
import {FlexBox} from "../../../ui/styled";

const LaneTag = styled.div`
  cursor: pointer;
  min-width: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: ${props => {
    let background = 'transparent';
    if (props.selected) background = props.theme.color.default_border_color;
    return background;
  }};
  color: ${props => {
    let color = props.theme.color.font_6A_EA;
    if (props.selected) color = props => props.theme.color.font_fff;
    return color;
  }};
`;


const LaneBox = styled(LaneTag)`
  opacity: ${props => {
    let opacity = '0.6';
    if (props.selected) opacity = '1';
    return opacity;
  }};

  img {
    margin-right: 4px;
  }
`;

const ValueWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: 5px;
`;

const ValueText = styled.span`
  font-size: 14px;
  font-weight: 500;
`;
const PercentText = styled.span`
  font-size: 14px;
  margin-left: 1px;
`;

const AllLangSpan = styled.span`
  font-size: 14px;
`;

const ChampLane = ({selectedLane, customList, onChangePosition, mapValue, valueWidth, noPercent, allText}) => {
    const theme = useTheme();
    const basicList = useMemo(() => ['All', 'Top', 'Jungle', 'Middle', 'Bot', 'Supporter'], []);
    const laneName = useMemo(() => {
        if (customList) {
            return customList;
        }
        return basicList;
    }, [customList]);

    const getIconName = (lane) => {
        const currentTheme = theme.color.theme;
        if (currentTheme === 'light') {
            return selectedLane.toLowerCase() === lane.toLowerCase() ? "" : ""
        } else {
            return ""
        }
    }

    const selectedIndex = useMemo(() => {
        return laneName.findIndex((data) => data?.toLowerCase() === selectedLane?.toLowerCase());
    }, [selectedLane]);
    return <>
        {laneName.map((lane, index) => {

            if (typeof selectedLane === 'string' && typeof onChangePosition === 'function') {
                return (
                    <ListButton
                        key={lane}
                        selected={selectedLane.toLowerCase() === lane.toLowerCase()}
                        onClick={() => onChangePosition(lane)}
                        className={'laneTag'}
                        index={index}
                        itemLength={laneName.length}
                        selectedIndex={selectedIndex}
                    >
                        <FlexBox>
                            {(lane === 'All' && allText) ?
                                <AllLangSpan>ALL</AllLangSpan>
                                :
                                <img
                                    alt={""}
                                    width={16} height={16}
                                    src={`images/icon_s_position_${lane.toLowerCase()}${getIconName(lane.toLowerCase())}.svg`}
                                    onError={(e) => e.target.style.display = 'none'}
                                />
                            }
                            {(mapValue && mapValue(lane)) &&
                                <ValueWrapper width={valueWidth}>
                                    <ValueText>
                                        {mapValue(lane)}
                                    </ValueText>
                                    {!noPercent &&
                                        <PercentText>
                                            %
                                        </PercentText>}
                                </ValueWrapper>}
                        </FlexBox>
                    </ListButton>
                )
            } else {
                return null;
            }
        })}
    </>;
};

export default ChampLane;
