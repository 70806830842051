import React from 'react';
import styled from "styled-components";
import UpArrow from "./UpArrow";
import DownArrow from "./DownArrow";

const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  flex-direction: row;
  padding: 4px 7px;
  background-color: ${props => props.theme.color.white_201};
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
`;
const ValueText = styled.span`
  text-align: center;
  font-size: 10px;
  color: ${props => props.theme.color.dark_100};
`;
const PercentText = styled.span`
  font-size: 10px;
  color: ${props => props.theme.color.dark_100};
  opacity: 0.6;
`;

const NewText = styled.span`
  font-size: 10px;
  color: #528BEE;
`;

const TagWithArrow = ({baseValue, value, percent = '%', fixed = 1, multiply = 100}) => {

    if(parseFloat((Math.abs(value - baseValue) * multiply).toFixed(fixed)) === 0) return null;


    if(value === 999) return <Tag>
        <NewText>NEW</NewText>
    </Tag>
    return (
        <Tag>
            {baseValue < value ? <UpArrow
                color={'#1CA484'}
            /> : <DownArrow
                color={'#EC5F5F'}
            />}
            <TextWrapper>
                <ValueText>{((Math.abs(value - baseValue)) * multiply).toFixed(fixed)}<PercentText>{percent}</PercentText></ValueText>
            </TextWrapper>
        </Tag>
    );
};

export default TagWithArrow;