import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {searchList} from '../../store/modules/searchList';
import {bookmarkList, bookmarkListAddPro} from '../../store/modules/bookmarkList';
import {StorageContainer} from '../../styled/CommonStyle';
import styled from "styled-components";
import ErrorBox from "./Error";
import TierTag from "./TierTag";
import InfluIcon from "../summoner/Profile/InfluInfo/InfluIcon";


const AbsoluteBox = styled.div`
  width: 100%;
  padding: 10px;
  height: 100%;
  position: absolute;
  top: 50px;
  z-index: 99;

  .errorStyle {
    padding: 10px;
  }
`;

const NodataWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  color: ${props => props.theme.color.dark_100};
  background-color: ${props => props.theme.color.site_main_color};
`;

const RegionTagWrapper = styled.div`
  .tier_badge {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 24px;
    height: 14px;
    line-height: 15px;
    font-size: 11px;
    border-radius: 5px;

    &.challenger {
      font-size: 11px;
    }

    &.grandmaster {
      width: 24px;
      font-size: 11px;

    }

    &.master {
      font-size: 11px;
    }
  }

`;

const TagText = styled.span`
  color: ${props => props.theme.color.dark_200};
  font-size: 14px;
  margin-left: 5px;
`;
const SearchList = ({
                        fullWidth, onSelect = () => {
    }
                    }) => {
    const dispatch = useDispatch();
    const bookmarks = useSelector((state) => state.bookmarkList.bookmarks);
    const region = useSelector((state) => state.region.result);
    const searchs = useSelector((state) => state.searchList.searchs);
    const [openError, setOpenError] = useState(false);
    const viewSearchs = [...searchs].reverse().slice(0, 10);
    const {t} = useTranslation();


    // 북마크 추가/제거
    const toggleBookmark = (item) => {
        let arr = bookmarks.filter((itm) => itm?.summoner_basic_info_dict?.summoner_id === item?.summoner_basic_info_dict?.summoner_id);
        arr.length !== 0 ? dispatch(bookmarkList(item, 'remove'))
            :
            bookmarks.length > 9 ? opencloseError() :
                dispatch(bookmarkList({
                    ...item,
                    region: item.region ? item.region : region
                }, 'add'));
    };

    const toggleProBookmark = (item) => {
        let arr = bookmarks.filter((itm) => itm.type === 'influencer' && itm?.url_name === item?.url_name);
        arr.length !== 0 ? dispatch(bookmarkList(item, 'remove'))
            :
            bookmarks.length > 9 ? opencloseError() :
                dispatch(bookmarkListAddPro({
                    ...item,
                }));
    }

    // 최근검색 항목 제거
    const removeSearch = (item) => {
        dispatch(searchList(item, 'remove'));
    };

    const opencloseError = () => {
        setOpenError(true);
        setTimeout(() => {
            setOpenError(false);
        }, 2000);
    };


    return (
        <StorageContainer fullWidth={fullWidth} className={'searchListContainer'}>
            {openError &&
                <AbsoluteBox>
                    <ErrorBox
                        infoText={t('error.favorite_excess')}
                    />
                </AbsoluteBox>}
            {searchs.length !== 0 ? (
                <ul className="search_list">
                    {viewSearchs.map((item, index) => {
                        if (item.type === 'influencer') {
                            const isFavoriteInflue = bookmarks.find((data) => data.url_name === item.url_name);
                            return (
                                <li key={index}>
                                    <InfluIcon status={item.status}/>
                                    <p className="txt" onClick={onSelect}>
                                        <Link
                                            to={`/${item.url_tag}/${item.url_name}`}
                                            className="common_search">
                                            {item.name}
                                        </Link>
                                    </p>
                                    <button
                                        className={`bookmark ${isFavoriteInflue ? 'on' : 'off'}`}
                                        onClick={() => toggleProBookmark(item)}
                                    >
                                        {t('buttons.favorite')}
                                    </button>
                                    <button className="remove" onClick={() => removeSearch(item)}>
                                        {t('buttons.delete')}
                                    </button>
                                </li>
                            )
                        }

                        const bookmarkArr = bookmarks.filter((itm) => item?.summoner_basic_info_dict?.summoner_id === itm?.summoner_basic_info_dict?.summoner_id);
                        return (
                            <li key={index}>
                                <RegionTagWrapper>
                                    <TierTag
                                        fontSize={'11px'}
                                        padding={'4px 4px'}
                                        tier={item?.season_tier_info_dict?.ranked_solo_5x5?.tier}
                                        division={item?.season_tier_info_dict?.ranked_solo_5x5?.division}
                                    />
                                </RegionTagWrapper>
                                <p className="txt" onClick={onSelect}>
                                    <Link
                                        to={`/summoner/${item.region ? item.region : region}/${item?.summoner_basic_info_dict?.riot_id_name}-${item?.summoner_basic_info_dict?.riot_id_tag_line}`}
                                        className="common_search">
                                        {decodeURI(item?.summoner_basic_info_dict?.riot_id_name)}<TagText>#{decodeURI(item?.summoner_basic_info_dict?.riot_id_tag_line)}</TagText>
                                    </Link>
                                </p>
                                <button
                                    className={`bookmark ${bookmarkArr.length !== 0 ? 'on' : 'off'}`}
                                    onClick={() => toggleBookmark(item)}
                                >
                                    {t('buttons.favorite')}
                                </button>
                                <button className="remove" onClick={() => removeSearch(item)}>
                                    {t('buttons.delete')}
                                </button>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <NodataWrapper>
                    <p className="no_list">{t('persist.noRecentSearch')}</p>
                </NodataWrapper>
            )}
        </StorageContainer>
    );
};

export default SearchList;
