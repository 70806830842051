import axios from "axios";
import {getRegion} from "../function";


export const summonerSearchAPI = async ({platform_id, riot_id_name}) => {
    const url = new URL(`${process.env.REACT_APP_CACHE_API}summoner/list`);
    url.searchParams.set('platform_id', getRegion(platform_id));
    url.searchParams.set('riot_id_name', riot_id_name);
    const result = await axios.get(url.toString())
    return result.data;
}