import React, {useCallback, useLayoutEffect} from 'react';
import useQuery from "./useQuery";
import useInput from "./useInput";
import {getRegionDefaultAll} from "../../function";

const DEFAULT_REGION = 'All'
const UseQueryRegion = () => {
    const [currentMastery, setCurrentMastery] = useInput(DEFAULT_REGION);
    const query = useQuery();
    const region = query.get('region');

    const getMasteryLane = useCallback(() => {
        if (region === 'null') {
            return DEFAULT_REGION;
        }
        if (!region) {
            return DEFAULT_REGION;
        }
        return getRegionDefaultAll(region)
    }, [region]);

    useLayoutEffect(() => {
        setCurrentMastery(getMasteryLane());
    }, [getMasteryLane]);


    return [currentMastery]
};

export default UseQueryRegion;