import React, {useCallback, useMemo, useState} from 'react';
import styled from "styled-components";
import RankListLI from "./RankListLI";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const ListUL = styled.ul`
  overflow: hidden;
  height: 40px;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-around;

  li:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  li:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;


const ChampionsRankLaneSelect = ({onClick, lane}) => {
    const {t} = useTranslation();
    const list = useMemo(() => [t('championAnalyze.top'), t('championAnalyze.jungle'), t('championAnalyze.mid'), t('championAnalyze.bot'), t('championAnalyze.support')], [t]);
    const laneName = useMemo(() => ['Top', 'Jungle', 'Middle', 'Bot', 'Supporter'], [])
    const [selected, setSelected] = useState(laneName.findIndex((data) => data.toLowerCase() === lane.toLowerCase()));


    const onClickLi = useCallback((value, lane) => {
        if (value !== undefined && lane !== undefined) {
            setSelected(value);
            onClick(value, lane)
        }
    }, []);


    return (
        <Wrapper>
            <ListUL>
                {list.map((data, index) => {
                    return (
                        <RankListLI
                            selectedIndex={selected}
                            lane={laneName[index]}
                            last={list.length === (index + 1)}
                            key={data + "laneData"}
                            data={data}
                            index={index}
                            onClick={onClickLi}
                            selected={selected === index}
                        />
                    )
                })}
            </ListUL>
        </Wrapper>
    );
};

export default ChampionsRankLaneSelect;
