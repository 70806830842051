import React from 'react';
import styled from "styled-components";

const SectionTitleWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
`;

const SectionTitleText = styled.span`
  color: ${props => props.theme.color.green_100};
  font-weight: bold;
  font-size: 20px;
  line-height: ${props => {
    let height = '16px'
    if (props.lang === 'ja') height = '20px'
    return height
  }};
  width: ${props => {
    let width = '100%';
    if (props.lang === 'ja') {
      width = '15px';
    }
    return width
  }};
  text-align: center;
`;

const ChampionsListHeader = ({data, lang}) => {
    return (
        <SectionTitleWrapper>
            <SectionTitleText lang={lang}>
                {data}
            </SectionTitleText>
        </SectionTitleWrapper>
    );
};

export default React.memo(ChampionsListHeader);