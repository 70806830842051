import {sortChampData} from "../function";

export default class MasteryRankTableModel {


    constructor(list, sort) {
        const calculateList = this.calculateList(list);
        this._list = calculateList;
        this._sort = sort;
    }


    get sort() {
        return this._sort;
    }

    get list() {
        return this._list;
    }

    get sortedList() {
        return sortChampData(this.list, this.sort);
    }

    calculateList(list) {
        try {
            return list.map((mapData) => ({
                ...mapData
            }));
        } catch (e) {
            return [];
        }
    }

}
