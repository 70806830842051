import React from 'react';
import CenterContentBox from "../../Champions/ContentWrapper";
import useDispatchChampRank from "../../../common/hooks/useDispatchChampions";
import {GET_CHAMPIONS_RANK_REQUEST} from "../../../store/reducer/champions";
import AramSearch from "./AramSearch";
import AramRank from "./AramRank";
import {useSelector} from "react-redux";


const AramContent = () => {
    useDispatchChampRank(GET_CHAMPIONS_RANK_REQUEST);
    const currentLang = useSelector((state) => state.translation.lang)
    return (
        <CenterContentBox>
            <AramSearch key={currentLang}/>
            <AramRank/>
        </CenterContentBox>
    );
};

export default AramContent;
