import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import {capitalizeLetter, prevTierAndDivision, sortChampData} from "../../../function";
import {useSelector} from "react-redux";
import BigTooltip from "../../Tooltip/BigTooltipBox";


const DictWrapper = styled.div`
  background: #38466D;
  padding: 0 3px;
  height: 17px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  opacity: 1;

  &.challenger {
    background-color: #FFD940;
    color: #2660E6;
  }

  &.grandmaster {
    background-color: #EBB4C4;
    color: #B9354A;
  }

  &.master {
    background-color: #D3B3EA;
    color: #8D41A9;
  }

  &.diamond {
    background-color: #A7CDF2;
    color: #0063BB;
  }

  &.platinum {
    background-color: #97CBC4;
    color: #2A8492;
  }

  &.emerald {
    background-color: #7FD6A7;
    color: #21855A;
  }

  &.gold {
    background-color: #DBC798;
    color: #A17716;
  }

  &.silver {
    background-color: #B7BBCB;
    color: #626B8A;
  }

  &.bronze {
    background-color: #C0AA9B;
    color: #815639;
  }

  &.iron {
    background-color: #A7A097;
    color: #514330;
  }

  &.unrank {
    background-color: #D1D4DB;
  }
`;

const DictText = styled.span`
  display: inline-block;
  font-weight: 500;
  font-size: 11px;
  text-transform: capitalize;
`;

const Year = styled.i`
  position: relative;
  font-size: 11px;
  display: inline-block;
  color: inherit;
`;

const Wrapper = styled.div`

  display: flex;
  flex-direction: ${props => {
    let direction = 'row';
    if (props.reverse) direction = 'row-reverse';
    return direction;
  }};
  justify-content: flex-end;

`;
const SplitBox = styled.span`
  margin-right: 3px;
`;
const Split = styled.span`
  position: absolute;
  top: 0;
`;

const Box = styled.div`
  & + & {
    margin-left: 6px;
  }
`;

const SummonerDict = ({dict, reverse, onlyLatest}) => {
    const currentSeason = useSelector((state) => state.imageStore.currentSeason);

    const seasonYearList = [
        {
            key: 11,
            value: 2021
        },
        {
            key: 12,
            value: 2022
        },
        {
            key: 13,
            value: 2022
        },
        {
            key: 14,
            value: 2023
        },
        {
            key: 15,
            value: 2023,
            split: 1
        },
        {
            key: 17,
            value: 2023,
            split: 2,
        },
        {
            key: 18,
            value: 2024,
            split: 1,
        },
        {
            key: 19,
            value: 2024,
            split: 1,
        },
        {
            key: 21,
            value: 2024,
            split: 2
        }
    ]

    const getYear = useCallback((season) => {
        if (season) {
            const result = seasonYearList.find((data) => Number(data.key) === Number(season));
            if (result) {
                return result.value;
            }
            return season;
        }
    }, []);
    const getSplit = useCallback((season) => {
        if (season) {
            const result = seasonYearList.find((data) => Number(data.key) === Number(season));
            if (result) {
                return result.split;
            }
            return season;
        }
    }, []);

    const sortedDict = useMemo(() => {

        if (Array.isArray(dict) && dict.length > 0) {
            if (!onlyLatest) {
                const sorted = sortChampData(dict, [{sortType: false, value: 'season'}]);
                if (sorted) {
                    return sorted;
                }
            } else {
                const filtered = dict.at(-1);
                if (filtered) {
                    return [filtered]
                }
            }
        }

        return [];
    }, [dict, currentSeason]);


    return (
        <Wrapper reverse={reverse}>
            {sortedDict.map((item, index) => {
                const split = getSplit(item.season);
                if (item.lp === -1) {
                    return (
                        <Box key={index + 'dict'}>
                            <DictWrapper className={`${item.tier ? (item.tier).toLowerCase() : ''}  `}>
                                <Year>{getYear(item.season)}{split &&
                                    <SplitBox><Split>-</Split></SplitBox>}{split && split} &nbsp;
                                    <DictText>{prevTierAndDivision(item.tier, item.division)} </DictText>
                                </Year>
                            </DictWrapper>
                        </Box>
                    )
                }
                return (
                    <Box key={index + 'dict'}>
                        <BigTooltip
                            tooltipText={`<span style="color: #41DFE0; font-size: 12px; font-weight: 500;">${capitalizeLetter(prevTierAndDivision(item.tier, item.division))} ${item.lp}LP</span>`}>
                            <DictWrapper className={`${item.tier ? (item.tier).toLowerCase() : ''}  `}>
                                <Year>{getYear(item.season)}{split &&
                                    <SplitBox><Split>-</Split></SplitBox>}{split && split} &nbsp;
                                    <DictText>{prevTierAndDivision(item.tier, item.division)} </DictText>
                                </Year>
                            </DictWrapper>
                        </BigTooltip>
                    </Box>
                );
            })}
        </Wrapper>
    );
};

export default SummonerDict;
