import React, {useCallback, useLayoutEffect} from 'react';
import useInput from "./useInput";
import {useSelector} from "react-redux";
import useQuery from "./useQuery";

const useQueryVersion = () => {
    const versions = useSelector((state) => state.champions.championVersions);
    const [currentVersion, onChangeCurrentVersion] = useInput(versions[0]);
    const query = useQuery();
    const queryVersion = query.get('version');


    const encodeVersion = useCallback(() => {
        return encodeURIComponent(query.get('version'));
    }, [queryVersion]);

    const calculateVersion = useCallback(() => {
        if (encodeVersion() === 'null') {
            return versions[0];
        }
        if (!encodeVersion()) {
            return versions[0];
        }
        return encodeVersion()
    }, [versions,encodeVersion]);





    useLayoutEffect(() => {
        onChangeCurrentVersion(calculateVersion());
    },[calculateVersion]);

    return [currentVersion, onChangeCurrentVersion];

};


export default useQueryVersion;
