import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {debounce} from "lodash";

const useScrollPagination = () => {
    const [sliceMultiple, onChangeMultiple] = useState(1);
    const sliceCount = useMemo(() => (Math.trunc(window.screen.height / 64) + 1 > 24 ? Math.trunc(window.screen.height / 64) + 1 : 25), [window]);

    const handlePagenation = useCallback(() => {
        const scrollTop = document.documentElement.scrollTop;
        if ((scrollTop !== 0) && sliceMultiple === 1) {
            onChangeMultiple(99);
        }
    }, [sliceMultiple, sliceCount]);

    const initMultiple = useCallback(() => {
        onChangeMultiple(1);
    },[]);

    useEffect(() => {
        window.addEventListener("scroll", debounce(handlePagenation,200));
        return () => {
            window.removeEventListener("scroll", handlePagenation);
        };
    }, []);


    return [sliceMultiple * sliceCount,initMultiple];
};

export default useScrollPagination;
