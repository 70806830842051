import React from 'react';


const SmallInfluInfo = ({status}) => {

    if (status === 'pro') {
        return <img src={'/images/icon_small_pro.svg'} alt={""} width={14} height={14}/>
    }

    if (status === 'Streamer') {
        return <img src={'/images/icon_small_streamer.svg'} alt={""} width={14} height={14}/>
   }
    return status;
};

export default SmallInfluInfo;