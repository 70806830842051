import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.site_main_color};
  border-radius: 6px;
  overflow: hidden;
`;

const ChampionsWhiteWrapper = ({children}) => {
    return (
        <Wrapper className={'champions-wrapper'}>
            {children}
        </Wrapper>
    );
};

export default ChampionsWhiteWrapper;
