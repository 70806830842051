import React, {useState} from 'react';
import styled from "styled-components";
import {ImageRune} from "../../../common";
import {BuildImageItem} from "../../../common/ImageSrc";
import useTransBuildTitle from "../../../../common/hooks/useTransBuildTitle";
import {ColumnBox} from "../../../ui/styled";
import {useTranslation} from "react-i18next";
import {threedigits} from "../../../../function";

const Wrapper = styled.div`
  width: 100%;
`;
const SelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SelectorItemWrapper = styled.button`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  border-radius: 6px 6px 0 0;
  border: 1px solid ${props => {
    let color = props.theme.color.default_border_color
    if (props.selected) color = props.theme.color.green_100
    return color
  }};
  border-bottom: 0;
  align-items: center;
  justify-content: flex-start;
  height: 49px;
  padding: 10px;
  background-color: ${props => {
    let background = props.theme.color.site_main_color
    return background
  }};

  &:hover {
    background-color: ${props => {
      let background = props.theme.color.white_200
      if (props.selected) background = props.theme.color.site_main_color
      return background
    }};
  }


  .imgRune {
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }

  & + & {
    margin-left: 5px;
  }

`;

const ItemWrapper = styled.div`
  margin-left: 5px;

  opacity: ${props => {
    let opacity = '0.6';
    if (props.isHover) opacity = 1;
    if (props.selected) opacity = 1;
    return opacity
  }};

  .imgItem {
    border-radius: 4px;
  }
`;

const SelectedSpan = styled.span`
  font-size: 12px;
  white-space: nowrap;
  opacity: ${props => {
    let opacity = '0.6';
    if (props.isHover) opacity = 1;
    if (props.selected) opacity = 1;
    return opacity
  }};
  color: ${props => {
    let color = props.theme.color.dark_100
    if (props.selected) color = props.theme.color.green_100;
    return color;
  }};
  font-weight: bold;
`;

const ScrollButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${props => props.theme.color.site_main_color};
  padding-left: 10px;
`;

const ScrollButton = styled.button`
  border: 1px solid ${props => {
    return props.theme.color.default_border_color
  }};
  border-radius: 6px;

  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${props => props.theme.color.white_200};
  }

  & + & {
    margin-left: 10px;
  }
`;
const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const RuneWrapper = styled.div`
  border: 1px solid ${props => props.theme.color.green_100};
  border-radius: 0 6px 6px 6px;
`;

const BuildContent = ({children, list, coreSelected, onChangeCoreSelected}) => {


    if (!Array.isArray(list) || list?.length < 1) {
        return null
    }

    return (
        <Wrapper>
            <FilterWrapper>
                <SelectorWrapper>
                    {list.map((data, index) => {
                        return (
                            <SelectedItem key={index} selected={index === coreSelected}
                                          title={data.title}
                                          onClick={() => onChangeCoreSelected(index)}
                                          item={data.main_item}
                                          rune={data.main_rune}
                                          winRate={(data.win_rate * 100).toFixed(1)}
                                          games={data.games}
                            />
                        )
                    })}
                </SelectorWrapper>
            </FilterWrapper>
            <RuneWrapper>
                {children}
            </RuneWrapper>
        </Wrapper>
    );
};


const AbsoluteBox = styled.div`
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: ${props => props.theme.color.site_main_color};
`;

const SelectedGreenBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: ${props => props.theme.color.green_100};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

const RuneBox = styled.div`
  opacity: ${props => {
    let opacity = '0.6';
    if (props.isHover) opacity = 1;
    if (props.selected) opacity = 1;
    return opacity
  }};
  background-color: #000;
  border-radius: 50%;
`;

const GamesText = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.dark_200};
  margin-top: 1px;
`;

const WinRateBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 3px;
  min-width: 44px;
  border-radius: 6px;
  background-color: ${props => {
    let color = props.theme.color.red_400
    if (props.winValue >= 50) color = props.theme.color.blue_300
    return color;
  }};
  color: ${props => {
    let color = props.theme.color.red_100
    if (props.winValue >= 50) color = props.theme.color.blue_100
    return color;
  }};
  font-size: 12px;
  font-weight: bold;
`;
const SelectedItem = ({selected, title, onClick, item, rune, games, winRate}) => {

    const {t} = useTranslation()
    const [onHover, setOnHover] = useState(false);
    const {translateTitle} = useTransBuildTitle()

    const handleMouseOut = () => {
        setOnHover(false);
    }

    const handleMouseOver = () => {
        setOnHover(true)
    }

    return (
        <SelectorItemWrapper selected={selected} onClick={onClick} onMouseOver={handleMouseOver}
                             onMouseOut={handleMouseOut} isHover={onHover}>
            {selected && <SelectedGreenBar/>}
            <RuneBox selected={selected} isHover={onHover}>
                <ImageRune runeId={rune}/>
            </RuneBox>
            <ItemWrapper selected={selected} isHover={onHover}>
                <BuildImageItem itemId={item} width={28} height={28}/>
            </ItemWrapper>
            <ColumnBox alignItems={'flex-start'} justifyContent={'space-between'} height={'28px'} margin={'0 8px 0 8px'}>
                <SelectedSpan selected={selected} isHover={onHover}>
                    {translateTitle(title)}
                </SelectedSpan>
                <GamesText>
                    {threedigits(games)} {t('ingame.games')}
                </GamesText>
            </ColumnBox>
            <WinRateBox winValue={winRate}>
                {winRate} %
            </WinRateBox>
            {selected && <AbsoluteBox/>}
        </SelectorItemWrapper>

    )
}

export default BuildContent;