import React from 'react';
import SummonerMasteryChampion from "./SummonerMasteryChampion";
import SummonerMasteryLane from "./SummonerMasteryLane";
import SummonerMasteryPatch from "./SummonerMasteryPatch";
import SummonerMasteryShare from "./SummonerMasteryShare";
import SummonerMasteryOverview from "./SummonerMasteryOverview";
import SummonerMasteryRunes from "./SummonerMasteryRunes";
import {useSelector} from "react-redux";
import {Loading} from "../../common";
import ChampionsEmpty from "../../common/ChampionsEmpty";
import styled from "styled-components";
import {useParams} from "react-router-dom";

const LoadingWrapper = styled.div`
  height: 200px;
`;

const SummonerMasteryProvider = ({children}) => {
    const {
        getMasteryBuildLoading,
        getMasteryBuildError,
        mastery
    } = useSelector((state) => state.summoner);
    const params = useParams();




    if (getMasteryBuildError) {
        return <>Error...</>
    }
    if (getMasteryBuildLoading) {
        return (
            <LoadingWrapper>
                <Loading/>
            </LoadingWrapper>
        )
    }

    if (mastery.length === 0) {
        return <ChampionsEmpty/>;
    }

    return (
        <div>
            {children}
        </div>
    );
};


SummonerMasteryProvider.Champion = SummonerMasteryChampion;
SummonerMasteryProvider.LaneFilter = SummonerMasteryLane;
SummonerMasteryProvider.PatchFilter = SummonerMasteryPatch;
SummonerMasteryProvider.Share = SummonerMasteryShare;
SummonerMasteryProvider.Overview = SummonerMasteryOverview;
SummonerMasteryProvider.Runes = SummonerMasteryRunes;


export default SummonerMasteryProvider;