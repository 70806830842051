import React, {useMemo} from 'react';
import styled from "styled-components";
import DailyRankingItem from "./DailyRankingItem";
import useAiRankingAPI from "../../../common/hooks/useAiRankingAPI";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

const DailyRankingList = () => {
    const {aiRanking} = useAiRankingAPI()

    const list = useMemo(() => ({...aiRanking?.data}), [aiRanking?.data]);

    if (!list) return null
    if (aiRanking.isLoading) return null
    return (
        <Wrapper>
            <DailyRankingItem tier={'Challenger'} list={list?.challenger_list?.slice(0, 10)}/>
            <DailyRankingItem tier={'Grandmaster'} list={list?.grandmaster_list?.slice(0, 10)}/>
            <DailyRankingItem tier={'Master'} list={list.master_list.slice(0, 10)}/>
            <DailyRankingItem tier={'Diamond'} list={list.diamond_list.slice(0, 10)}/>
            <DailyRankingItem tier={'Emerald'} list={list.emerald_list.slice(0, 10)}/>
            <DailyRankingItem tier={'Platinum'} list={list.platinum_list.slice(0, 10)}/>
            <DailyRankingItem tier={'Gold'} list={list.gold_list.slice(0, 10)}/>
            <DailyRankingItem tier={'Silver'} list={list.silver_list.slice(0, 10)}/>
            <DailyRankingItem tier={'Bronze'} list={list.bronze_list.slice(0, 10)}/>
            <DailyRankingItem tier={'Iron'} list={list.iron_list.slice(0, 10)}/>
        </Wrapper>
    );
};

export default DailyRankingList;