import React, {useMemo} from 'react';
import styled from "styled-components";

const Block = styled.div`
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre;
  flex-wrap: wrap;
  font-size: 12px;
`;


const Content = styled.span`
  color: ${props => {
    if (props.colorReverse) {
      let color = '#1CA484';
      if (props.value > 0) return '#E96767';
      return color;
    } else {
      let color = '#E96767';
      if (props.value > 0) return '#1CA484';
      return color;
    }

  }};
`;
const Percent = styled.span`
  font-size: 11px;
  margin-left: 2px;
`;

const BalanceText = ({children, colorReverse, noPercent}) => {
    const calValue = useMemo(() => {
        if (children > 0) {
            return `+${children}`
        } else if (children === 0) {
            return "";
        } else {
            return `${children}`;
        }
    }, [children]);


    return (
        <Block>
            {calValue &&
                <Content value={children} colorReverse={colorReverse}>
                    {calValue}
                    {!noPercent && <Percent>%</Percent>}
                </Content>}
        </Block>
    );
};

export default React.memo(BalanceText);
