import React, {useCallback, useMemo, useState} from 'react';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {useTranslation} from 'react-i18next';
import DetailShareTag from "./MatchDetail/DetailShareTag";
import styled, {useTheme} from "styled-components";
import DetailHideSummonerName from "./MatchDetail/DetailHideSummonerName";
import {isArena, isSwarm, setLaneIndex, sortChampData} from "../../function";
import DetailBuild from "./DetailTab/DetailBuild";
import RenderAnalytics from "./DetailTab/RenderAnalytics";


const TabHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .react-tabs {
    &__tab-list {
      display: flex;
      align-items: center;
      margin: 6px 0;
    }

    &__tab {
      cursor: pointer;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      min-width: 115px;
      height: 28px;
      padding: 0 8px;
      border-radius: 5px;
      font-size: 12px;
      border: 1px solid ${props => props.theme.color.default_border_color};
      color: ${props => props.theme.color.dark_200};
      background-color: ${props => props.theme.color.site_main_color};

      &:hover {
        background-color: ${props => props.theme.color.white_200};
      }

      &--selected {
        background-color: ${props => props.theme.color.green_200};
        color: ${props => props.theme.color.green_100};
        border-color: ${props => props.theme.color.green_100};
        font-weight: bold;

        &:hover {
          background-color: ${props => props.theme.color.green_200};
        }
      }

      & + .react-tabs__tab {
        margin-left: 5px;
      }
    }

  }
`;

const HeaderWrapper = styled.div`
  display: ${props => props.visible};
`;


const ContentWrapper = styled.div`
  border: 1px solid ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.site_main_color};
  border-radius: 6px;
  overflow: hidden;
`;

const ButtonWrapper = styled.div`
  padding: 7px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${props => props.theme.color.default_border_color};
`;


const MatchDetail = ({
                         summoner,
                         match,
                         myMatch,
                         openIndex,
                         region,
                         isUrf,
                         hide,
                         onChangeHide,
                         queueId,
                         setOpenIndex
                     }) => {
    const theme = useTheme();
    const sortLane = [{
        value: 'laneIndex',
        sortType: false,
    }];
    const defaultTabIndex = useMemo(() => {
        try {
            if (match) {
                const team = match.participants_list.filter((data) => data.side === myMatch.side);
                const addPositionValueTeam = team.map((data) => ({...data,laneIndex: setLaneIndex(data.position)}))
                const sortedTeam =  sortChampData(addPositionValueTeam, sortLane);
                const findIndex = sortedTeam.findIndex((data) => data.puu_id === myMatch.puu_id);
                return myMatch.side === 'BLUE' ? findIndex : findIndex + 5;
            }
            return 0;
        } catch (e) {
            return 0;
        }
    }, [match, myMatch]);
    const [buildIndex, setBuildIndex] = useState(defaultTabIndex);

    const resetIndex = useCallback(() => {
        setBuildIndex(defaultTabIndex);
    }, [defaultTabIndex]);


    const onChangeBuildIndex = useCallback((summoner) => {
        const team = match.participants_list.filter((data) => data.side === summoner.side);
        const addPositionValueTeam = team.map((data) => ({...data,laneIndex: setLaneIndex(data.position)}))
        const sortedTeam =  sortChampData(addPositionValueTeam, sortLane);
        const findIndex = sortedTeam.findIndex((data) => data.puu_id === summoner.puu_id);
        const result = summoner.side === 'BLUE' ? findIndex : findIndex + 5;
        setBuildIndex(result);
        setOpenIndex(1)
    }, [match]);

    const onChangeBuildIndexPara = useCallback((value) => {
        setBuildIndex(value);
    }, []);

    const {t} = useTranslation();

    const renderBuild = useMemo(() => {
        return (queueId === 900 || isArena(queueId) || isSwarm(queueId))
    }, [queueId]);


    return (
        <HeaderWrapper
            borderColor={myMatch.is_win ? theme.color.font_5D : theme.color.font_E3}>
            <Tabs
                selectedIndex={openIndex}
            >
                <TabHeaderWrapper>
                    <TabList>
                        <Tab onClick={() => setOpenIndex(0)}>{t('tabs.aiAnalysis')}</Tab>
                        {!renderBuild && <Tab onClick={() => {
                            setOpenIndex(1)
                        }}>{t('tabs.build')}
                        </Tab>}
                    </TabList>
                </TabHeaderWrapper>
                <TabPanel>
                    <ContentWrapper>

                        <RenderAnalytics queueId={queueId} myMatch={myMatch} match={match}
                                         onChangeBuildIndex={onChangeBuildIndex} isUrf={isUrf} region={region}
                                         hide={hide}/>

                        <ButtonWrapper>
                            <DetailShareTag
                                region={region}
                                summoner={summoner}
                                match={match}
                            />
                            <DetailHideSummonerName
                                hide={hide}
                                onChangeHide={onChangeHide}
                            />
                        </ButtonWrapper>

                    </ContentWrapper>
                </TabPanel>
                {!renderBuild && <TabPanel>
                    <ContentWrapper>
                        <DetailBuild match={match.participants_list} myMatch={myMatch} resetIndex={resetIndex}
                                     customIndex={buildIndex} onChangeBuildIndex={onChangeBuildIndexPara}/>
                    </ContentWrapper>
                </TabPanel>}
            </Tabs>
        </HeaderWrapper>
    );
};

export default React.memo(MatchDetail);
