import React, {useCallback} from 'react';
import ColorHeader from "../ColorHeader";
import styled, {useTheme} from "styled-components";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

const BalanceBlock = styled.div`
  position: relative;
  padding: 6px 20px;
  background-color: ${props => props.theme.color.site_main_color};
  border: 1px solid ${props => props.theme.color.green_100};
  color: ${props => props.theme.color.dark_100};
  border-radius: 6px;
  cursor: pointer;
  
  &:hover {
    background-color: ${props => props.theme.color.white_200};
  }
`;

const AbsoluteBox = styled.div`
  top: -8px;
  right: -8px;
  position: absolute;
`;

const ChampionsAramHeader = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const theme = useTheme();
    const list = [{
        title: t('header.navSummonerRift'),
        to: "/champions",
        backgroundColor: theme.color.background_4AA
    }, {
        title: t('header.navAram'),
        to: "/aram",
        backgroundColor: theme.color.background_5F_2E
    }];


    const onClickBalance = useCallback(() => {
        history.push('/balance/aram');
    },[]);

    return (
        <ColorHeader
            list={list}
        >
            <BalanceBlock onClick={onClickBalance}>{t('championDetailBuild.balance.balanceAramHeader')}
                <AbsoluteBox>
                    <img
                        src={'/images/new_badge.svg'}
                        width={18}
                        height={18}
                        alt={""}
                    />
                </AbsoluteBox>
            </BalanceBlock>
        </ColorHeader>
    );
};

export default ChampionsAramHeader;
