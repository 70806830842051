import React from 'react';
import styled from "styled-components";
import SummonerMasteryTable from "./SummonerMasteryTable";
import {ColumnBox, FlexBox} from "../../ui/styled";
import {ImageItem, ImageRune} from "../../common";
import {useTranslation} from "react-i18next";
import {hundredCheck, threedigits} from "../../../function";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  background: ${props => props.theme.color.site_main_color};

  .imgRune {
    background-color: #000;
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }

  tbody {
    tr {
      cursor: pointer;
      border-bottom: 1px solid ${props => props.theme.color.default_border_color};
    }

    tr:nth-child(even) {
      background-color: ${props => props.theme.color.background_F9};
    }

    tr:hover {
      outline: 1px solid ${props => props.theme.color.green_100};
      outline-offset: -1px;
      background-color: ${props => props.theme.color.green_200};
      z-index: 10;
    }

    .selected {
      outline: 1px solid ${props => props.theme.color.green_100};
      outline-offset: -1px;
      background-color: ${props => props.theme.color.green_200} !important;
    }
  }
`;

const AbsoluteBar = styled.div`
  position: absolute;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: ${props => props.theme.color.green_100};
`;

const ContentTD = styled.td`
  position: relative;
  vertical-align: middle;

  .imgItem {
  }
`;

const PurpleSpan = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.dark_100};
  font-weight: 500;
`;
const GamesSpan = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_200};
`;
const Percent = styled.span`
  color: ${props => props.theme.color.dark_200};
  font-size: 12px;
  font-weight: 500;
  margin-left: 1px;
`;
// const YellowWrapper = styled.div`
//   border: 2px solid ${props => {
//     let color = 'transparent';
//     if (props.isMythic) color = '#FCB000';
//     return color
//   }};
//   border-radius: 6px;
//   margin-left: 8px;
//   overflow: hidden;
// `;

const MarginMythicItem = styled.div`
  margin-left: 5px;

  .imgItem {
    border-radius: 6px;
  }
`;
const PickRateSpan = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.font_58};
  font-weight: 500;
`;

const AllText = styled.span`
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
  max-width: 8ch;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ValueText = styled.span`
  font-size: 12px;
  color: ${props => {
    let color = props.theme.color.red_100;
    if (props.value > 50) {
      color = props.theme.color.blue_100;
    }
    return color;
  }};
  font-weight: 500;
`;


const SummonerMasteryRuneCore = ({coreList, coreSelected, onChangeCoreSelected}) => {
    const {t} = useTranslation();
    return (
        <Wrapper>
            <SummonerMasteryTable title={t('summoner.mastery.runeItem')}
                                  headers={[{title: t('summoner.mastery.games'), col: '20%'}, {
                                      title: t('summoner.mastery.coreWinRate'),
                                      col: '20%'
                                  }]} list={coreList} selected={(data, index) => coreSelected === index}
                                  onClick={(_, index) => onChangeCoreSelected(index)}>
                {(data, index) => {
                    const winValue = (data.win_rate * 100).toFixed(1);
                    const pickValue = (data.rune.pick_rate * 100).toFixed(1);
                    return (
                        <React.Fragment key={index}>
                            <ContentTD>
                                {coreSelected === index && <AbsoluteBar/>}
                                <FlexBox padding={'8px 0 8px 7.5px'}>
                                    {index === 0 ?
                                        <FlexBox height={'32px'}>
                                            <img
                                                src={'./images/icon_s_position_all.svg'}
                                                width={16}
                                                height={16}
                                                alt={""}
                                            />
                                            <AllText>{t('tabs.summary')}</AllText>
                                        </FlexBox>
                                        :
                                        <>
                                            <ImageRune runeId={data.rune_main}/>
                                            <MarginMythicItem>
                                                <ImageItem itemId={data.core_item} width={28} height={28}/>
                                            </MarginMythicItem>
                                        </>
                                    }
                                </FlexBox>
                            </ContentTD>
                            <ContentTD>
                                {index === 0 ?
                                    <FlexBox justifyContent={'center'}>
                                        <PickRateSpan>-</PickRateSpan>
                                    </FlexBox>
                                    :
                                    <ColumnBox alignItems={'center'}>
                                        <GamesSpan>{threedigits(data.games)}</GamesSpan>
                                    </ColumnBox>
                                }
                            </ContentTD>
                            <ContentTD>
                                <FlexBox justifyContent={'center'}>
                                    {index === 0 ? <PickRateSpan>-</PickRateSpan> :
                                        <ValueText
                                            value={winValue}>{hundredCheck(winValue)}<Percent>%</Percent></ValueText>}
                                </FlexBox>
                            </ContentTD>
                        </React.Fragment>
                    )
                }}
            </SummonerMasteryTable>
        </Wrapper>
    );
};

export default SummonerMasteryRuneCore;