import styled from "styled-components";
import React from "react";
import ListButton from "../../common/ListButton";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  height: 26px;
`;

const Item = styled.div`
  cursor: pointer;
  text-align: center;
  min-width: ${props => props.minWidth || '77'}px;
  height: 26px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;


  &:not(:last-of-type) {
    border-right: 1px solid ${props => props.theme.color.default_border_color};
  }

  background-color: ${props => {
    let color = props.theme.color.background_F2;
    if (props.selected) color = props.theme.color.default_border_color;
    return color;
  }};
  color: ${props => {
    let color = props.theme.color.white_300;
    if (props.selected) color = '#ffffff';
    return color;
  }};

  font-size: 12px;
`;

const DailyFilterButtonList = ({selected, onClick, list, minWidth}) => {
    const onClickButton = (value) => {
        if (onClick) {
            onClick(value)
        }
    };


    if (!Array.isArray(list) || list?.length === 0) return null;

    const selectedIndex = list.findIndex((data) => data.value.toLowerCase() === selected.toLowerCase());
    return (
        <Wrapper>
            {list.map((data, index) => {
                return (
                    <ListButton
                        fontSize={'12px'}
                        width={minWidth}
                        itemLength={list.length}
                        selectedIndex={selectedIndex}
                        index={index}
                        selected={selected.toLowerCase() === data.value.toLowerCase()}
                        key={data.value}
                        onClick={() => onClickButton(data.value)}
                    >{data.title}</ListButton>
                )
            })}
        </Wrapper>
    );
};

export default DailyFilterButtonList;