import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {
    AI_SCORE_BAD_TEXT,
    AI_SCORE_GOOD_TEXT,
    AI_SCORE_PERFECT_TEXT,
    AI_SCORE_VERY_BAD_TEXT
} from "../../../styled/scoreColor";
import React, {useState} from "react";
import {onStorePuuId} from "../../../function";
import DailyRankingCrown from "./DailyRankingCrown";
import ChampionImageWithLane from "../../common/ChampionImageWithLane";
import {FlexBox} from "../../ui/styled";
import SmallInfluInfo from "../../common/SmallInfluInfo";

const DailyRankingItemContentWrapper = styled.div`
  height: 100%;
  background-color: ${props => props.theme.color.site_main_color};
`;

const EmptyDailyRanking = styled(DailyRankingItemContentWrapper)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  white-space: pre-wrap;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
`

const DailyRankingItemContent = ({list, tier}) => {
    const {platform} = useParams();
    const {t} = useTranslation()
    if (list.length === 0) {
        return (
            <EmptyDailyRanking>
                <span>{t('daily.empty')}</span>
            </EmptyDailyRanking>
        )
    }
    return (
        <DailyRankingItemContentWrapper>
            {list.map((data, index) => {
                return (
                    <DailyRankingItemContentItem
                        key={"DailyRankingContent" + data.riot_id_name + data.riot_id_tag_line + platform + data?.match_id}
                        rank={index + 1}
                        status={data.pro_streamer_status}
                        name={data.riot_id_name}
                        proName={data.pro_streamer_name}
                        score={data.ai_score}
                        tag={data.riot_id_tag_line}
                        platform={platform}
                        championId={data.champion_id}
                    />
                )
            })}
        </DailyRankingItemContentWrapper>
    )
}

const CrownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30px;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 38.7px;
  align-items: center;
  width: 100%;

  &:hover {
    background-color: ${props => props.theme.color.white_200};
  }

  .imgChamp {
    width: 30px;
    height: 30px;
  }

  .imgDiv {
    border-radius: 100%;
  }
`;
const ItemText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_100};
  margin-left: 5px;
  cursor: pointer;
  padding-bottom: 2px;

  &:hover {
    text-decoration: underline;
  }
`;
const ItemTextWithAnimation = styled(ItemText)`
  animation: smoothAppear 0.3s;

  @keyframes smoothAppear {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  min-width: 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
  min-width: 0;
`;
const ScoreBox = styled.div`
  text-align: center;
  width: 38px;
  font-weight: bold;
  font-size: 12px;
  color: ${props => {
    let color = AI_SCORE_VERY_BAD_TEXT;
    if (props.score >= 75) {
      color = AI_SCORE_PERFECT_TEXT
    } else if (props.score >= 50) {
      color = AI_SCORE_GOOD_TEXT;
    } else if (props.score >= 25) {
      color = AI_SCORE_BAD_TEXT;
    }
    return color;
  }};
`;
const DailyRankingItemContentItem = ({rank, status, name, tag, proName, score = 0, platform, championId}) => {
    const [onHover, setOnHover] = useState(false);

    const handleMouseOver = () => {
        if (proName) {
            setOnHover(true);
        }
    };

    const handleMouseOut = () => {
        if (proName) {
            setOnHover(false);
        }
    };
    const onClickSummoner = () => {
        onStorePuuId(`${name}-${tag}`, platform)
    }
    return (
        <ItemWrapper>
            <CrownWrapper>
                <DailyRankingCrown rank={rank}/>
            </CrownWrapper>
            <NameWrapper onMouseOver={handleMouseOver}
                         onMouseOut={handleMouseOut}>
                <ChampionImageWithLane champion_id={championId}/>
                {status ?
                    <TextWrapper>
                        {onHover ?
                            <ItemTextWithAnimation onClick={onClickSummoner}>{name}</ItemTextWithAnimation>
                            :
                            <TextWrapper>
                                <FlexBox margin={'0 0 0 5px'}>
                                    <SmallInfluInfo status={status}/>
                                </FlexBox>
                                <ItemText>{proName}</ItemText>
                            </TextWrapper>
                        }
                    </TextWrapper>
                    :
                    <ItemText onClick={onClickSummoner}>{name}</ItemText>}
            </NameWrapper>
            <ScoreBox score={score}>{Math.round(Number(score))?.toFixed(0)}</ScoreBox>
        </ItemWrapper>
    )
}

export default DailyRankingItemContent