import {sortChampData} from "../function";

export default class AramRankTableModel {


    constructor(list, sort) {
        const calculateList = this.calculateList(list);
        this._list = calculateList;
        this._sort = sort;
        this._highestValue = this.calculateHighest(calculateList);
    }


    get sort() {
        return this._sort;
    }

    get list() {
        return this._list;
    }

    get sortedList() {
        return sortChampData(this.list, this.sort).filter((data) => data.tier !== 0);
    }

    get highestValue() {
        return this._highestValue;
    }


    calculateList(list) {
        try {
            return list.map((mapData) => ({
                ...mapData?.performance_dict.Aram,
                champion_id: mapData.champion_id,
            }));
        } catch (e) {
            return [];
        }
    }

    calculateHighest(list) {
        try {
            const dpmList = list.map((data) => parseInt(data.dpm));
            return Math.max(0,...dpmList);
        } catch (e) {
            return 0
        }
    }

}
