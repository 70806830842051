import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  height: ${props => props.percent + "%"};
  width: 14px;
  background-color: ${props => props.barColor};
`;

const HeightGraph = ({barColor,percent}) => {
    return (
        <Wrapper percent={percent} barColor={barColor}/>
    );
};

export default HeightGraph;
