import React from 'react';
import ChampionsCname from "../../../../Champions/ChampionsContent/ChampionsSearch/ChampionsCName";
import styled from "styled-components";

const CnameWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const AramBalanceCname = ({setChoName}) => {
    return (
        <CnameWrapper>
            <ChampionsCname
                width={100}
                onClick={setChoName}
                full={true}
            />
        </CnameWrapper>
    );
};

export default AramBalanceCname;
