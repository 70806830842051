export class IngameError {


    constructor(msg) {
        this.msg = msg;
    }

    get status() {
        switch (this.msg) {
            case "notPlaying" :
                return 403;
            case "notSupport" :
                return 443;
            default :
                return 500;
        }
    }

}
