import React, {useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import dompurify from 'dompurify';

import {TooltipContainer} from '../../styled/CommonStyle';
import ChampionSkillImage from "./ChampionSkillImage";
import BigTooltip from "../Tooltip/BigTooltipBox";
import styled, {useTheme} from "styled-components";
import {useTranslation} from "react-i18next";

// 챔피언 data (현재 안씀)


// 챔피언 이미지 컴포넌트
export const ImageChamp = ({champId}) => {
    const theme = useTheme();
    const version = useSelector((state) => state.gameVersion.version);
    const dataSelector = useSelector((state) => state.imageStore.champions);

    const imgObj = useMemo(() => {
        if (dataSelector.length !== 0) {
            if (dataSelector.filter((item) => item.champion_id === `${champId}`).length !== 0) {
                return dataSelector.find((item) => item.champion_id === `${champId}`);
            }
        }
        return {};
    }, [champId, dataSelector]);

    return (<>
        {Object.keys(imgObj).length > 0 ? (<Tooltip dataTip={imgObj} dataFor="imgChamp">
            <img
                src={`${process.env.REACT_APP_IMG}${version}/img/champion/${imgObj.image_name}?w=64&h=64&f=webp`}
                className="imgChamp"
                alt={imgObj.image_name}
            />
        </Tooltip>) : champId === 0 ? <div className="non_image">
            <img src={`${theme.icon.path}/icon_cell_all.svg`} className="imgChamp" alt={imgObj}/>
        </div> : (<div className="non_image">
            <img src="/images/icon_non_item.svg" className="imgChamp" alt={imgObj}/>
        </div>)}
    </>);
};

// 룬 이미지 컴포넌트
export const ImageRune = ({runeId}) => {
    const dataSelector = useSelector((state) => state.imageStore.runes);
    const [imgObj, setImgObj] = useState('');
    useLayoutEffect(() => {
        if (dataSelector.length !== 0) {
            for (const el of dataSelector) {
                if (el.id === runeId) {
                    setImgObj(el);
                } else {
                    const ids = el.slots[0].runes.filter((itm) => itm.id === runeId);
                    if (ids.length !== 0) {
                        setImgObj(ids[0]);
                    } else {
                        el.slots.forEach((slot) => {
                            slot.runes.forEach((rune) => {
                                if (rune.id === runeId) {
                                    setImgObj(rune);
                                }
                            });
                        });
                    }
                }
            }
        }
    }, [runeId, dataSelector]);


    return (<>
        {imgObj.length !== 0 ? (<BigTooltip
            tooltipText={`<div style="color: #46B9AA; font-weight: 500; width: 100%;">${imgObj.name}</div>${imgObj.longDesc ? "\n" + imgObj.longDesc : ""}`}
            textAlign={'left'}>
            <img
                src={`${process.env.REACT_APP_IMG}img/${imgObj.icon}?w=56&h=56&f=webp`}
                className="imgRune"
                alt={imgObj.name}
                onError={(e) => e.target.src = "/images/icon_non_item.svg"}
            />
        </BigTooltip>) : (<div className="non_image">
            <img src="/images/icon_non_item.svg" className="imgRune" alt={imgObj}/>
        </div>)}
    </>);
};

export const ChampionRune = ({runeId}) => {
    const dataSelector = useSelector((state) => state.champions.buildRune);
    const [imgObj, setImgObj] = useState('');
    useLayoutEffect(() => {
        if (dataSelector.length !== 0) {
            for (const el of dataSelector) {
                if (el.id === runeId) {
                    setImgObj(el);
                } else {
                    const ids = el.slots[0].runes.filter((itm) => itm.id === runeId);
                    if (ids.length !== 0) {
                        setImgObj(ids[0]);
                    } else {
                        el.slots.forEach((slot) => {
                            slot.runes.forEach((rune) => {
                                if (rune.id === runeId) {
                                    setImgObj(rune);
                                }
                            });
                        });
                    }
                }
            }
        }
    }, [runeId, dataSelector]);


    return (<>
        {imgObj.length !== 0 ? (<BigTooltip
            tooltipText={`<div style="color: #46B9AA; font-weight: 500; width: 100%;">${imgObj.name}</div>${imgObj.longDesc ? "\n" + imgObj.longDesc : ""}`}
            textAlign={'left'}>
            <img
                src={`${process.env.REACT_APP_IMG}img/${imgObj.icon}?w=56&h=56&f=webp`}
                className="imgRune"
                alt={imgObj.name}
                onError={(e) => e.target.src = "/images/icon_non_item.svg"}
            />
        </BigTooltip>) : (<div className="non_image">
            <img src="/images/icon_non_item.svg" className="imgRune" alt={imgObj}/>
        </div>)}
    </>);
}

// 스펠 이미지 컴포넌트
export const ImageSpell = ({spellId, smite, width, height}) => {
    const version = useSelector((state) => state.gameVersion.version);
    const dataSelector = useSelector((state) => state.imageStore.spells);
    const [imgObj, setImgObj] = useState('');

    useLayoutEffect(() => {
        if (Object.keys(dataSelector).length !== 0) {
            setImgObj(Object.values(dataSelector).filter((item) => item.key === `${spellId}`)[0]);
        }
        return () => {
            setImgObj('');
        };
    }, [spellId, dataSelector]);


    if (imgObj.length === 0) return (<div className="non_image">
        <img src="/images/icon_non_item.svg" className="imgSpell" alt={imgObj}/>
    </div>)

    if (spellId === 11) return (<BigTooltip
        tooltipText={`<div style="color: #46B9AA;font-weight: 500; width: 100%; margin-bottom: 5px;">${imgObj.name}</div>\n${imgObj.description}`}
        textAlign={'left'}>
        {smite === 'B' ? (<img
            src="https://raw.communitydragon.org/pbe/game/assets/spells/icons2d/smite_blue.png"
            className="imgSpell"
            alt="smite_blue"
        />) : smite === 'R' ? (<img
            src="https://raw.communitydragon.org/pbe/game/assets/spells/icons2d/smite_red.png"
            className="imgSpell"
            alt="smite_red"
        />) : smite === 'SCORCHCLAU_PUP' ? (<img
            src="https://raw.communitydragon.org/latest/game/data/spells/icons2d/1101_smite.png"
            className="imgSpell"
            alt="smite_red"
        />) : smite === 'GUSTWALKER_HATCHLING' ? (<img
            src="https://raw.communitydragon.org/latest/game/data/spells/icons2d/1102_smite.png"
            className="imgSpell"
            alt="smite_red"
        />) : smite === 'MOSSTOMPER_SEEDLING' ? (<img
            src="https://raw.communitydragon.org/latest/game/data/spells/icons2d/1103_smite.png"
            className="imgSpell"
            alt="smite_red"
        />) : (<img
            src={`${process.env.REACT_APP_IMG}${version}/img/spell/${imgObj.image.full}?w=56&h=56&f=webp`}
            className="imgSpell"
            alt={imgObj.name}
        />)}
    </BigTooltip>)


    return (<>
        <BigTooltip
            tooltipText={`<div style="color: #46B9AA; font-weight: 500; width: 100%; margin-bottom: 5px;">${imgObj.name}</div>${imgObj.description}`}
            textAlign={'left'}>
            <img
                src={`${process.env.REACT_APP_IMG}${version}/img/spell/${imgObj.image.full}?w=56&h=56&f=webp`}
                onError={(e) => e.target.src = "/images/icon_non_item.svg"}
                className="imgSpell"
                alt={imgObj.name}
                width={width}
                height={height}
            />
        </BigTooltip>
    </>);
};

// 아이템 이미지 컴포넌트
export const ImageItem = ({itemId, width, height, upgrade = true}) => {
    const version = useSelector((state) => state.gameVersion.version);
    const dataSelector = useSelector((state) => state.imageStore.items);
    const {t} = useTranslation();

    const imgObj = useMemo(() => {
        if (Object.keys(dataSelector).length !== 0) {
            let number = Object.entries(dataSelector).filter((item) => item[0] === `${itemId}`)[0];
            if (number !== undefined) {
                return (itemId === 0 ? 0 : number[1]);
            }
        }
        return 0;
    }, [dataSelector, itemId]);


    const renderItemCost = () => {
        if (imgObj?.gold?.total > 0) {
            return `\n\n${t('item.cost')}: <span style="color: #F3A634; font-weight: 500; font-size: 12px;">${imgObj.gold.total}<span style="margin-left: 2px;">(${imgObj.gold.sell})</span></span>`;
        }
        return ""
    }

    return (
        <>
            {imgObj.length !== 0 && imgObj !== 0 ? (<>
                <BigTooltip
                    tooltipText={`<div style="color: #46B9AA;font-weight: 500; width: 100%; margin-bottom: 5px;">${imgObj.name}</div>\n${imgObj.description}${renderItemCost()}`}
                    textAlign={'left'}>
                    <img
                        src={`${process.env.REACT_APP_IMG}${version}/img/item/${imgObj.image.full}?w=56&h=56&f=webp`}
                        className="imgItem"
                        alt={imgObj.name}
                        width={width}
                        height={height}
                        onError={(e) => e.target.src = "/images/icon_non_item.svg"}
                    />
                </BigTooltip>
            </>) : (<div className="non_image">
                <img src="/images/icon_non_item.svg" className="imgItem" alt={imgObj} width={width}
                     height={height}/>
            </div>)}
        </>);
};


const SummonerItemEmpty = styled.div`
  background-color: ${props => props.backgroundColor};
  aspect-ratio: 1;
  opacity: 0.3;
`;

export const SummonerItem = ({itemId, width, height, upgrade = true, emptyColor}) => {
    const [isError,setIsError] = useState(false)
    const version = useSelector((state) => state.gameVersion.version);
    const dataSelector = useSelector((state) => state.imageStore.items);
    const {t} = useTranslation();

    const imgObj = useMemo(() => {
        if (Object.keys(dataSelector).length !== 0) {
            let number = Object.entries(dataSelector).filter((item) => item[0] === `${itemId}`)[0];
            if (number !== undefined) {
                return (itemId === 0 ? 0 : number[1]);
            }
        }
        return 0;
    }, [dataSelector, itemId]);


    const renderItemCost = () => {
        if (imgObj?.gold?.total > 0) {
            return `\n\n${t('item.cost')}: <span style="color: #F3A634; font-weight: 500; font-size: 12px;">${imgObj.gold.total}<span style="margin-left: 2px;">(${imgObj.gold.sell})</span></span>`;
        }
        return ""
    }

    if(isError) {
        return <SummonerItemEmpty className="imgItem" backgroundColor={emptyColor}/>
    }

    return (
        <>
            {imgObj.length !== 0 && imgObj !== 0 ? (<>
                <BigTooltip
                    tooltipText={`<div style="color: #46B9AA;font-weight: 500; width: 100%; margin-bottom: 5px;">${imgObj.name}</div>\n${imgObj.description}${renderItemCost()}`}
                    textAlign={'left'}>
                    <img
                        src={`${process.env.REACT_APP_IMG}${version}/img/item/${imgObj.image.full}?w=56&h=56&f=webp`}
                        className="imgItem"
                        alt={imgObj.name}
                        width={width}
                        height={height}
                        onError={(e) => setIsError(true)}
                    />
                </BigTooltip>
            </>) : (<SummonerItemEmpty className="imgItem" backgroundColor={emptyColor}>

            </SummonerItemEmpty>)}
        </>);
};

export const BuildImageItem = ({itemId, width, height, upgrade = true}) => {
    const version = useSelector((state) => state.champions.buildVersion);
    const dataSelector = useSelector((state) => state.champions.buildItem);
    const {t} = useTranslation()

    const imgObj = useMemo(() => {
        if (Object.keys(dataSelector).length !== 0) {
            let number = Object.entries(dataSelector).filter((item) => item[0] === `${itemId}`)[0];
            if (number !== undefined) {
                return (itemId === 0 ? 0 : number[1]);
            }
        }
        return 0;
    }, [dataSelector, itemId]);


    const renderItemCost = () => {
        if (imgObj?.gold?.total > 0) {
            return `\n\n${t('item.cost')}: <span style="color: #F3A634; font-weight: 500; font-size: 12px;">${imgObj.gold.total}<span style="margin-left: 2px;">(${imgObj.gold.sell})</span></span>`;
        }
        return ""
    }

    return (
        <>
            {imgObj.length !== 0 && imgObj !== 0 ? (<>
                <BigTooltip
                    tooltipText={`<div style="color: #46B9AA;font-weight: 500; width: 100%; margin-bottom: 5px;">${imgObj.name}</div>\n${imgObj.description}${renderItemCost()}`}
                    textAlign={'left'}>
                    <img
                        src={`${process.env.REACT_APP_IMG}${version}.1/img/item/${imgObj.image.full}?w=56&h=56&f=webp`}
                        className="imgItem"
                        alt={imgObj.name}
                        width={width}
                        height={height}
                        onError={(e) => e.target.src = "/images/icon_non_item.svg"}
                    />
                </BigTooltip>
            </>) : (<div className="non_image">
                <img src="/images/icon_non_item.svg" className="imgItem" alt={imgObj} width={width}
                     height={height}/>
            </div>)}
        </>);
};

// 스킬 data

// 스킬 이미지 컴포넌트
export const ImageSkill = ({champId, num}) => {
    const version = useSelector((state) => state.gameVersion.version);
    const dataSelector = useSelector((state) => state.imageStore.champions);
    const currentChampions = useMemo(() => {
        return dataSelector.find((data) => Number(data.champion_id) === Number(champId));
    }, [dataSelector, champId]);


    if (!currentChampions) return <img src="/images/icon_non_item.svg" className="imgSkill" alt={""}/>;
    return (<ChampionSkillImage
        reverse={true}
        margin={'0 5px 0 0'}
        width={36}
        height={36}
        spell={currentChampions?.spells[num]}
        version={version}
        img={currentChampions?.spells[num]?.spell_img}
    />);
};

const LaneBackGround = styled.div`
`;
// 라인 이미지 컴포넌트
export const ImageLane = ({lane, color}) => {
    const theme = useTheme();

    if (color === 'dark') {
        if (lane) {
            return (<LaneBackGround>
                <img
                    src={`images/icon_s_position_${lane?.toLowerCase()}.svg`}
                    className="imgLane"
                    alt={lane}
                />
            </LaneBackGround>);
        }
    }


    if (lane) {
        return (<LaneBackGround>
            <img
                src={`images/icon_s_position_${lane?.toLowerCase()}_w.svg`}
                className="imgLane"
                alt={lane}
            />
        </LaneBackGround>);
    }
    return null;
};

export const ChampionImageLane = ({lane, color}) => {
    const theme = useTheme();
    if (lane) {
        return (<LaneBackGround>
            <img
                src={`images/icon_s_position_${lane?.toLowerCase()}_w.svg`}
                className="imgLane"
                alt={lane}
            />
        </LaneBackGround>);
    }
    return null;
};

// 오브젝트 이미지 컴포넌트
export const ImageMonster = ({obj, only, noIcon = false}) => {
    const [imgName, setImgName] = useState(typeof obj === 'string' && obj.toLowerCase());


    useEffect(() => {
        if (typeof obj === 'string') {
            setImgName(obj.toLowerCase());
        }

    }, [obj]);


    if (typeof imgName !== 'string') return null;


    if (imgName === 'eye of riftherald') {
        return <img
            src={`${process.env.REACT_APP_IMG}12.23.1/img/item/3513.png?w=56&h=56&f=webp`}
            className="imgMonster"
            alt={obj}
        />
    }

    return (<>
        {imgName?.indexOf(only) !== -1 ? (<img
            src={`/images/icon_objectives_${imgName.toLowerCase()}.svg`}
            className="imgMonster dragon"
            alt={obj}
        />) : (<>
            {noIcon ? (<img
                src={`/images/icon_objectives_${imgName?.toLowerCase()}.png`}
                className="imgMonster"
                alt={obj}
            />) : (<img
                src={`/images/icon_objectives_${imgName?.toLowerCase()}.svg`}
                className="imgMonster"
                alt={obj}
            />)}
        </>)}
    </>);
};

// 툴팁
const Tooltip = ({children, dataTip, dataFor}) => {
    useSelector((state) => state.translation.lang);
    const sanitizer = dompurify.sanitize;

    if (dataFor === 'imgRune') {
        // 룬 툴팁
        return (<TooltipContainer className="twrap">
            {children}
            <div className="tooltip">
                <h5>{dataTip.name}</h5>
                {dataTip.longDesc !== undefined && (
                    <p dangerouslySetInnerHTML={{__html: sanitizer(dataTip.longDesc)}}></p>)}
            </div>
        </TooltipContainer>);
    } else if (dataFor === 'imgSpell') {
        // 스펠 툴팁
        return (<TooltipContainer className="twrap">
            {children}
            <div className="tooltip">
                <h5>{dataTip.name}</h5>
                <p dangerouslySetInnerHTML={{__html: sanitizer(dataTip.description)}}></p>
            </div>
        </TooltipContainer>);
    } else if (dataFor === 'imgItem') {
        // 아이템 툴팁
        return (<TooltipContainer className="twrap">
            {children}
            <div className="tooltip">
                <h5>{dataTip.name}</h5>
                <p dangerouslySetInnerHTML={{__html: sanitizer(dataTip.description)}}></p>
            </div>
        </TooltipContainer>);
    } else if (dataFor === 'imgChamp') {
        // 챔피언 툴팁
        return (<TooltipContainer className="twrap">
            {children}
            <div className="tooltip">
                <h5>{dataTip.champion_name}</h5>
            </div>
        </TooltipContainer>);
    } else if (dataFor === 'imgSkill') {
        // 스킬 툴팁
        return (<TooltipContainer className="twrap">
            {children}
            <div className="tooltip">
                <h5>{dataTip.name}</h5>
                <p dangerouslySetInnerHTML={{__html: sanitizer(dataTip.description)}}></p>
            </div>
        </TooltipContainer>);
    }

    return <>{children}</>;
};
