import React, {useCallback} from 'react';
import FilterBox from "../../../../common/FilterBox";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

const mapList = ["all", "recent"];

const AramBalanceFilter = ({position}) => {
    const {t} = useTranslation();
    const history = useHistory();


    const customSelected = useCallback((data, index) => {
        if (index === 0 && !position) {
            return true;
        }
        return mapList[index] === position;
    }, [position]);


    const onClick = useCallback((_, lane) => {
        history.push(`/balance/aram/${lane}`)
    }, []);

    return (
        <FilterBox
            visibleText={true}
            disableSetSelected={true}
            imageSrc={() => ""}
            list={[t("championDetailBuild.balance.allChampion"), t("championDetailBuild.balance.recentChange")]}
            mapTitle={(data, index) => mapList[index]}
            onClick={onClick}
            customSelected={customSelected}
        />
    );
};

export default AramBalanceFilter;
