import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import useQueryTier from "./useQueryTier";
import useQueryVersion from "./useQueryVersion";

const useDispatchChampRank = (request) => {
    const dispatch = useDispatch();
    const [tier] = useQueryTier();
    const [version] = useQueryVersion();
    const [valid,setValid] = useState(true);
    const tierList = ['Emerald+',
        "Diamond+",
        "Master+",
        "Grandmaster+",
        "Challenger+"]

    const versions = useSelector((state) => state.champions.championVersions);


    useEffect(() => {
        if (tier && version && versions.length > 0) {
            const isValidVersion = versions.includes(String(version));
            const isValidTier = tierList.includes(tier);

            if (isValidVersion && isValidTier) {
                dispatch({
                    type: request,
                    data: {
                        version: version,
                        tier: tier,
                    }
                });
            } else {
                setValid(false);
            }
        }
    }, [version, tier,versions]);

    return valid;
};

export default useDispatchChampRank;
