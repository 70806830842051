import React, {useMemo} from 'react';
import CenterBox from "../../../common/CenterBox";
import AramBalanceMain from "../AramBalanceMain";
import {useParams} from "react-router-dom";
import useDispatchAramBalance from "../../../../common/hooks/useDispatchAramBalance";
import {Loading} from "../../../common";
import styled from "styled-components";
import {patchOrder} from "../../../../function";
import {useSelector} from "react-redux";
import useChampionFunc from "../../../../common/hooks/useChampionFunc";
import AdComponent from "../../../ad/AdComponent";
import {center_content} from "../../../../ad/bidrich";
import useTitleAndDescription from "../../../../common/hooks/useTitleAndDescription";
import {useTranslation} from "react-i18next";

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30vh;
`;
const BalanceAramContent = () => {
    const {t} = useTranslation();
    const {position} = useParams();
    const {data, getAramBalanceLoading} = useDispatchAramBalance();
    const currentLang = useSelector((state) => state.translation.lang);
    const {championData} = useChampionFunc()


    const dataMemo = useMemo(() => {
        try {
            if (position === 'all') {
                return data?.total_aram_balance?.map((data) => {
                    return {...data, patch_number: patchOrder(data.aram_correction)}
                })
            } else if (position === 'recent') {
                const filterPatched = data.total_aram_balance.filter((data) => data.aram_correction !== '-');
                return filterPatched.map((data) => {
                    return {...data, patch_number: patchOrder(data.aram_correction)}
                })
            }
            return [];
        } catch (e) {
        }
        return [];
    }, [data, position]);


    useTitleAndDescription({
        title: t('header.titleAramBalance',{
            version: data?.version
        }),
        description: t('header.titleAramBalanceDescription')
    })

    if (championData.length === 0) {
        return (
            <LoadingWrapper>
                <Loading/>
            </LoadingWrapper>
        )
    }

    if (getAramBalanceLoading) {
        return (<LoadingWrapper>
            <Loading/>
        </LoadingWrapper>)
    }


    return (
        <AdComponent playwire={center_content}>

            <CenterBox>
                <AramBalanceMain
                    key={currentLang + "aramBalanceMain"}
                    list={dataMemo}
                    version={data.version}
                />
            </CenterBox>

        </AdComponent>
    );
};

export default BalanceAramContent;
