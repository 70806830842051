import React from 'react';
import styled from "styled-components";


const HeaderInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props =>{
      let content = 'flex-start';
      if(props.noIcon) content = 'center';
      return content;
  }};
  flex: 1;
`;

const LaneWrapper = styled.div`

`;

const HeaderImage = styled.img`
  width: 16px;
  height: 16px;
`;
const LaneImage = styled.img`

`;
const HeaderText = styled.span`
  font-weight: 500;
  font-size: 12px;
  margin-left: 6px;
  color: ${props => props.fontColor};
`;


const BestContentHeader = ({title, lane, icon, noIcon = false, fontColor,whiteLane}) => {
    return (
        <>
            <HeaderInfo noIcon={noIcon}>
                <HeaderImage src={icon}/>
                <HeaderText fontColor={fontColor}>{title}</HeaderText>
            </HeaderInfo>
            {!noIcon && <LaneWrapper>
                <LaneImage src={`/images/icon_s_position_${whiteLane ? `${lane.toLowerCase()}_w`: lane.toLowerCase()}.svg`}/>
            </LaneWrapper>}
        </>
    );
};

export default BestContentHeader;
