import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import {getIngameTime} from "../../../../../function";

const TimerBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TimerText = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

const LiveBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px 9px;
  background-color: #EA5A5A;
  border-radius: 6px;
  margin-right: 5px;
`;

const LiveText = styled.div`
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 1px;
`;

const LiveBlock = ({initTimer}) => {
    const [ingameTime,setIngameTime] = useState(Math.trunc((Date.now() / 1000)) - initTimer);

    const getTime = useCallback(() => {
        setIngameTime(prev => prev + 1);
    }, []);

    useEffect(() => {
        let interval = setInterval(() => {
            getTime();
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);


    return (
        <TimerBox>
            <LiveBox>
                <LiveText>LIVE</LiveText>
            </LiveBox>
            <TimerText>{getIngameTime(ingameTime)}</TimerText>
        </TimerBox>
    );
};

export default LiveBlock;