import React from 'react';
import {Header} from "../common";
import CenterContentBox from "../Champions/ContentWrapper";
import SummonerSearchTable from "./SummonerSearchTable";
import UseSetCanonical from "../../common/hooks/useSetCanonical";


const SummonerSearch = () => {

    UseSetCanonical();

    return (
        <div>
            <Header/>
            <CenterContentBox disableSponsor={true}>
                <SummonerSearchTable/>
            </CenterContentBox>
        </div>
    );
};

export default SummonerSearch;