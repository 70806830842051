import React from 'react';
import styled from "styled-components";

const DownArrowDiv = styled.div`
  width: 0;
  height: 0;
  border-top: ${props => props.size + props.tick +"px"} solid ${props => {
    let color = '#E96767';
    if (props.color) color = props.color;
    return color;
  }};
  border-left: ${props => props.size + "px"} solid transparent;
  border-right: ${props => props.size + "px"} solid transparent;
  border-bottom: 0 solid transparent;
`;

const DownArrow = ({color = '#E96767', size = 3.5,tick=1.5}) => {
    return (
        <DownArrowDiv color={color} size={size} className={'down-arrow'} tick={tick}/>
    );
};

export default DownArrow;
