import React from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";

const CategorySpan = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: ${props => {
   let weight = 500;   
   if(props.selected) weight = 'bold';
   return weight
  }};
  color: ${props => {
    let color = props.theme.color.dark_200;
    if (props.selected) color = props => props.theme.color.white_300;
    return color;
  }};
  text-align: center;
  font-size: 18px;
  padding-inline: 38px;
  padding-block: 6px;
  background: ${props => {
    let background = 'transparent';
    if (props.selected) background = props.theme.color.green_100;
    return background
  }};
  border-radius: 6px;

  &:hover {
    background-color: ${props => {
      let color = props.theme.color.white_200
      if (props.selected) color = props.theme.color.green_100
      return color;
    }};
  }

  & + & {
    margin-left: 5px;
  }
`;
const TabItem = ({to, selected, children, onClick}) => {
    return (
        <CategorySpan to={to} selected={selected} onClick={onClick}>{children}</CategorySpan>
    );
};

export default TabItem;