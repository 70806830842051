import axios from "axios";
import {getRegion} from "../function";

export const _fetch_throttle = async ({summonerId}) => {
    const result = await axios.get(`https://throttle-api.deeplol-gg.workers.dev/?summonerId=${summonerId}`);
    return result;
}

export const _fetch_check_update = async ({puu_id, platform_id}) => {
    const param = {
        "puu_id": puu_id,
        "platform_id": getRegion(platform_id),
    }

    const result = await axios.post(`${process.env.REACT_APP_UPDATE_API}match/check-refresh`, param, {
        timeout: 30000,
    })
    return result.data;
}


export const _fetch_insert_event_match = async ({puu_id, }) => {
    const result = await axios.get(`${process.env.REACT_APP_UPDATE_API}summoner/insert_strm_pro_challenge?puu_id=${puu_id}`)
    return result.data
}

export const _fetch_refresh_matches = async ({puu_id, platform_id, queue_type, start_idx, count}) => {
    const param = {
        "puu_id": puu_id,
        "platform_id": getRegion(platform_id),
        "queue_type": queue_type,
        "start_idx": start_idx,
        "count": count
    }
    const result = await axios.post(`${process.env.REACT_APP_UPDATE_API}match/refresh-matches`, param)
    return result.data.includes('completed');
}


export const _fetch_refresh_tier = async ({puu_id, platform_id}) => {
    const param = {
        "puu_id": puu_id,
        "platform_id": getRegion(platform_id)
    }
    const result = await axios.post(`${process.env.REACT_APP_UPDATE_API}match/refresh_tier`, param)
    return result.data === 'completed';

}


export const _fetch_refresh_champion = async ({puu_id, platform_id, queue_type, season}) => {
    const param = {
        "puu_id": puu_id,
        "platform_id": getRegion(platform_id),
        "queue_type": queue_type,
        "season": season
    }
    const result = await axios.post(`${process.env.REACT_APP_UPDATE_API}match/refresh-champion-stat`, param)
    return result.data === 'completed';
}
