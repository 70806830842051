import React, {useMemo, useState} from 'react';
import styled, {useTheme} from "styled-components";
import {useTranslation} from "react-i18next";
import RankingTableHeader from "../../Ranking/RankingContent/RankingTable/RankingTableContent/RankingTableHeader";
import DailyRankingCrown from "./DailyRankingCrown";
import {FlexBox} from "../../ui/styled";
import ChampionImageWithLane from "../../common/ChampionImageWithLane";
import InfluIcon from "../../summoner/Profile/InfluInfo/InfluIcon";
import DamageGraph from "../../summoner/DetailTab/GameAnalytics/GameSummary/Table/TableContent/DamageGraph";
import useAiRankingAPI from "../../../common/hooks/useAiRankingAPI";
import {useParams} from "react-router-dom";
import {getOnlyDecimal, isArabic, onStorePuuId} from "../../../function";
import {
    AI_SCORE_BAD_TEXT,
    AI_SCORE_GOOD_TEXT,
    AI_SCORE_PERFECT_TEXT,
    AI_SCORE_VERY_BAD_TEXT
} from "../../../styled/scoreColor";

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;
  overflow: hidden;
`;

const Table = styled.table`
  width: 100%;
`;

const ContentTR = styled.tr`
  overflow: hidden;
  background-color: ${props => props.theme.color.site_main_color};

  &:not(:first-child) {
    border-top: ${props => {
      let border = props.theme.color.default_border_color;
      let color = props.theme.color.gray_200
      let result = '0px';
      if (border !== '0px') result = `1px solid ${color}`;
      return result;
    }};
  }

  &:hover {
    background-color: ${props => props.theme.color.white_200};
  }
`;

const Content = styled.td`
  height: 48px;
  vertical-align: middle;

  .imgChamp {
    width: 42px;
    height: 42px;
  }

  .imgDiv {
    border-radius: 6px;
  }
`;

const Thead = styled.thead`
  border-bottom: 1px solid ${props => props.theme.color.default_border_color};
`;


const TBody = styled.tbody`
`;
const SummonerNameText = styled.span`
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  
  &:hover {
    text-decoration: underline;
  }

`;

const SummonerNameTextWithAnimation = styled(SummonerNameText)`
  animation: smoothAppear 0.3s;

  @keyframes smoothAppear {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ContentSpan = styled.span`
  font-weight: 500;
  font-size: 14px;
  margin-inline: 3px;
  color: ${props => {
    let color = props.theme.color.dark_100;
    if (props.color) color = props.color;
    return color;
  }};
`;
const SplashSpan = styled.span`
  font-size: 14px;
  color: ${props => props.theme.color.dark_100};
`

const TagText = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.color.dark_200};
  margin-left: 3px;
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 16px;
  width: 250px;

`;

const HoverBox = styled.div`
  cursor: pointer;

  &:hover {
    span {
      text-decoration: underline;
    }
  }
`;

const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DailyContentTable = () => {
    const {tier, queue, platform} = useParams();
    const {aiRanking} = useAiRankingAPI()
    const list = useMemo(() => {
        try {
            let result = aiRanking.data[`${tier}_list`]
            if (queue === 'aram') {
                result = aiRanking?.data?.aram_list
            }
            if (Array.isArray(result)) {
                return result
            }
            return [];
        } catch (e) {
            console.log("Error")
            return [];
        }
    }, [aiRanking]);
    const {t} = useTranslation();
    const sortedRankingList = useMemo(() => {
        if (Array.isArray(list)) {
            return list;
        } else {
            return [];
        }
    }, [list]);

    const maxDamage = useMemo(() => {
        try {
            return Math.max(0, ...list.map((data) => Math.abs(data.damage)))
        } catch (e) {
            return 0
        }
    }, [list]);


    if (list.length < 1) return null

    return (
        <Wrapper>
            <Table>
                <Thead>
                    <tr>
                        <RankingTableHeader>{t('ranking.rank')}</RankingTableHeader>
                        <RankingTableHeader>{t('ranking.summoner')}</RankingTableHeader>
                        <RankingTableHeader>AI-Score</RankingTableHeader>
                        <RankingTableHeader>KDA</RankingTableHeader>
                        <RankingTableHeader>{t('gameAnalytics.damage')}</RankingTableHeader>
                        <RankingTableHeader last={true}>{t('championsRanking.pentaKill')}</RankingTableHeader>
                    </tr>
                </Thead>
                <TBody>
                    {sortedRankingList.map((data, index) => {
                        return (
                            <DailyContentTableItem
                                data={data}
                                key={"tableContent" + index + platform + data.riot_id_name + data.riot_id_tag_line + data?.match_id}
                                queue={queue}
                                rank={index + 1}
                                platform={platform}
                                maxDamage={maxDamage}
                            />
                        )
                    })}

                </TBody>
            </Table>
        </Wrapper>
    );
};


const ScoreText = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: ${props => {
    let color = AI_SCORE_VERY_BAD_TEXT;
    if (props.score >= 75) {
      color = AI_SCORE_PERFECT_TEXT
    } else if (props.score >= 50) {
      color = AI_SCORE_GOOD_TEXT;
    } else if (props.score >= 25) {
      color = AI_SCORE_BAD_TEXT;
    }
    return color;
  }};
`;
const DecimalText = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

const DailyContentTableItem = ({data, queue, platform, rank, maxDamage}) => {
    const [onHover, setOnHover] = useState(false);
    const theme = useTheme();

    const onClickSummoner = (data) => {
        onStorePuuId(`${data.riot_id_name}-${data.riot_id_tag_line}`, platform)
    }

    const handleMouseOver = () => {
        if (data.pro_streamer_status && data.pro_streamer_status !== '-') {
            setOnHover(true);
        }
    };

    const handleMouseOut = () => {
        if (data.pro_streamer_status && data.pro_streamer_status !== '-') {
            setOnHover(false);
        }
    };

    return (
        <ContentTR onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
            <Content>
                <FlexBox width={'80px'} justifyContent={'center'}>
                    <DailyRankingCrown rank={rank}/>
                </FlexBox>
            </Content>
            <Content>
                <FlexBox width={'330px'} padding={'0 0 0 20px'}>
                    <FlexBox margin={'0 10px 0 0'}>
                        <ChampionImageWithLane champion_id={data.champion_id}
                                               position={queue === 'aram' ? "" : data.lane}/>
                    </FlexBox>
                    <TextWrapper>
                        <HoverBox onClick={() => onClickSummoner(data)}>

                            {(data.pro_streamer_status && data.pro_streamer_status !== '-') ?
                                <FlexBox>
                                    {onHover ?
                                        <SummonerNameCompo name={data.riot_id_name} tag={data.riot_id_tag_line}/>
                                        :
                                        <FlexBox>
                                            <FlexBox margin={'0 5px 0 0'}>
                                                <InfluIcon status={data.pro_streamer_status}/>
                                            </FlexBox>
                                            <SummonerNameText>{data.pro_streamer_name}</SummonerNameText>
                                        </FlexBox>
                                    }
                                </FlexBox>
                                :
                                <SummonerNameCompo name={data.riot_id_name} tag={data.riot_id_tag_line}/>
                            }
                        </HoverBox>
                    </TextWrapper>
                </FlexBox>
            </Content>
            <Content>
                <FlexBox width={'180px'} justifyContent={'center'}>
                    <ScoreText
                        score={data.ai_score}>{Math.floor(data.ai_score)}<DecimalText>.{getOnlyDecimal(data.ai_score, 1)}</DecimalText></ScoreText>
                </FlexBox>
            </Content>
            <Content>
                <FlexBox width={'180px'} justifyContent={'center'}>
                    <ContentWrapper>
                        <ContentSpan>{data.kills}</ContentSpan>
                        <SplashSpan>/</SplashSpan>
                        <ContentSpan
                            color={theme.color.red_300}>{data.deaths}</ContentSpan>
                        <SplashSpan>/</SplashSpan>
                        <ContentSpan>{data.assists}</ContentSpan>
                    </ContentWrapper>
                </FlexBox>
            </Content>
            <Content>
                <FlexBox width={'180px'} padding={'0 30px'}>
                    <DamageGraph
                        dmg={data.damage}
                        total={maxDamage}
                        color={theme.color.dark_200}
                        bgColor={theme.color.gray_100}
                        barColor={'#EF4D8A'}
                    />
                </FlexBox>
            </Content>
            <Content>
                <FlexBox width={'130px'} justifyContent={'center'}>
                    <ContentSpan>{data.penta_kills > 0 ? data.penta_kills : '-'}</ContentSpan>
                </FlexBox>
            </Content>
        </ContentTR>
    )
}


const SummonerNameCompo = ({name, tag}) => {

    if (isArabic(name) || isArabic(tag)) {
        return (
            <TagWrapper>
                <SummonerNameText>{name}</SummonerNameText>
                <TagText>#{tag}</TagText>
            </TagWrapper>
        )
    }


    return (
        <SummonerNameText>{name}<TagText>#{tag}</TagText></SummonerNameText>
    )
}

export default DailyContentTable;



