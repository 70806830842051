import React, {useMemo} from 'react';
import styled from "styled-components";
import AginstHeader from "./AginstHeader";
import ChampionsDetailTitle from "../ChampionsDetailTitle";
import {sortChampData} from "../../../../function";
import AgainstTableContent from "./AgainstTableContent";
import AgainstTableHeader from "./AgainstTableHeader";
import {useTranslation} from "react-i18next";


const Wrapper = styled.div`
  border: 1px solid ${props => props.theme.color.default_border_color};
  width: ${props => props.width || 1};
  border-radius: 6px;
  overflow: hidden;
  ${props => {
    if (!props.width) return 'flex:1'
  }};

`;

const ContentWrapper = styled.div`
  border-top: 1px solid ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.site_main_color};
`;


const OverflowWrapper = styled.div`
  max-height: 480px;
  overflow-y: auto;
  margin-right: 3px;
  background-color: ${props => props.theme.color.white_200};

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    width: 6px;
    border-radius: 6px;
    background-color: ${props => props.theme.color.purple_200};
  }

  ::-webkit-scrollbar-track {
    width: 10px;
    margin: 3px;
    background-color: ${props => props.theme.color.purple_300};
    border-radius: 6px;
  }
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 483px;
  background-color: ${props => props.theme.color.white_201};
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_200};
`;


const ChampionsDetailAgainstTable = ({
                                         synergy,
                                         sortList,
                                         onClickHeader,
                                         currentSort,
                                         champions,
                                         version,
                                         width,
                                         CHAMPION_TABLE_FLEX = 0.6,
                                         GAMES_TABLE_FLEX = 0.4,
                                         WINRATE_TABLE_FLEX = 0.2,
                                         SYNERGY_TABLE_FLEX,
                                         title,
                                         barColor
                                     }) => {
    const {t} = useTranslation()
    const sortChamp = useMemo(() => {
        if (Array.isArray(champions)) {
            return sortChampData(champions, currentSort)
        } else {
            return [];
        }
    }, [champions, currentSort]);


    return (
        <Wrapper className={"againstTable"} width={width}>
            <AginstHeader barColor={barColor} synergy={synergy}>
                {title}
            </AginstHeader>
            <ContentWrapper>
                <ChampionsDetailTitle>
                    <AgainstTableHeader
                        currentSort={currentSort}
                        sort={sortList}
                        onClickHeader={onClickHeader}
                        length={Array.isArray(champions) ? champions.length : 0}
                        CHAMPION_TABLE_FLEX={CHAMPION_TABLE_FLEX}
                        WINRATE_TABLE_FLEX={WINRATE_TABLE_FLEX}
                        GAMES_TABLE_FLEX={GAMES_TABLE_FLEX}
                        SYNERGY_TABLE_FLEX={SYNERGY_TABLE_FLEX}
                    />
                </ChampionsDetailTitle>
                {sortChamp.length === 0 && <EmptyWrapper>
                    {t('championDetailBuild.empty')}
                </EmptyWrapper>}
                {Array.isArray(champions) &&
                    <OverflowWrapper>
                        {sortChamp.map((champion, index) => {
                            return <AgainstTableContent
                                length={sortChamp.length}
                                key={index}
                                pickRate={(champion.pick_rate * 100).toFixed(1)}
                                winRate={(champion.win_rate * 100).toFixed(1)}
                                lane={champion.lane}
                                games={champion?.games}
                                synergy={champion?.synergy}
                                champion_id={champion.enemy_champion_id}
                                version={version}
                                CHAMPION_TABLE_FLEX={CHAMPION_TABLE_FLEX}
                                WINRATE_TABLE_FLEX={WINRATE_TABLE_FLEX}
                                GAMES_TABLE_FLEX={GAMES_TABLE_FLEX}
                                SYNERGY_TABLE_FLEX={SYNERGY_TABLE_FLEX}
                            />
                        })}
                    < /OverflowWrapper>
                }

            </ContentWrapper>
        </Wrapper>
    );
};

export default ChampionsDetailAgainstTable;