import axios from "axios";
import {getRegion} from "../function";


const transGameType = (value) => {
    if (value === 'rank') {
        return '420'
    }
    if (value === 'aram') {
        return '450'
    }
    console.log("Wrong GameType")
    return 450
}

export const aiRankingAPI = async ({gameType, platformId, tier}) => {
    const url = new URL(`${process.env.REACT_APP_CACHE_API}aiScore-ranking/full_today`);
    url.searchParams.set('platform_id', getRegion(platformId));
    const result = await axios.get(url.toString())
    return result.data;
}


export const aiRankingAPIYesterday = async ({gameType, platformId, tier}) => {
    const url = new URL(`${process.env.REACT_APP_CACHE_API}aiScore-ranking/full_yesterday`);
    url.searchParams.set('platform_id', getRegion(platformId));
    const result = await axios.get(url.toString())
    return result.data;
}