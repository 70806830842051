// 액션 생성
export const _NOTICE = 'modal/Notice';
export const _HEADER_IMAGE = 'header/image';

export const noticeChange = (data) => ({type: _NOTICE, data});

// 액션 함수 내보내기

// 초기 상태 값
const initialState = {
    notice: 'text',
    headerImage: 0,
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case _NOTICE: {
            return {
                ...state,
                notice: action.data,
            }
        }
        case _HEADER_IMAGE: {
            return {
                ...state,
                headerImage: action.data,
            }
        }
        default:
            return state;
    }
};
export default reducer;
