import React, {useCallback, useMemo, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import CNameLI from "./NameLI";
import {useSelector} from "react-redux";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: ${props => props.theme.color.site_main_color};
  border: 1px solid ${props => props.theme.color.default_border_color};
  padding: 5px;
`;

const NameUL = styled.ul`
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;


const FullNameUL = styled(NameUL)`
  li {
    width: auto;
    flex: 1;
  }
`;


const EngAlphaWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  border-bottom: 1px solid ${props => props.theme.color.default_border_color};
  border-right: 1px solid ${props => props.theme.color.default_border_color};
  border-left: 1px solid ${props => props.theme.color.default_border_color};
  height: 40px;
  margin-left: 31px;
  border-radius: 0 0 5px 5px;
`;

const AlphabetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;

`;
const FirstAlphabetBorderBox = styled.div`
  width: 34px;
  margin-right: 5px;
`;
const KoreanWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;

`;

const FullWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 30px;
  width: 100%;
`;


const SecondLayWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;

  li {
    width: 24px;
    height: 30px;
  }
`;

const JaLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;

  li {
    height: 30px;
    width: 53px;
  }
`;

const ColumnListBox = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  flex: 1;
`;

const ChampionsCname = ({onClick, width, full}) => {
    const [selected, setSelected] = useState(0);
    const {i18n, t} = useTranslation();
    const allName = useMemo(() => ['전체', 'ALL', '全体'], [])
    const korName = useMemo(() => ['ㄱ', 'ㄴ', 'ㄷ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅅ', 'ㅇ', 'ㅈ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'], []);
    const fullEngName = useMemo(() => ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'], []);
    const fullJpName = useMemo(() => ['ア行', 'カ行', 'サ行', 'タ行', 'ナ行', 'ハ行', 'マ行', 'ヤ行', 'ラ行', 'ワヲン行','その他'], []);
    const currentLang = useSelector((state) => state.translation.lang);
    const fullRenderName = useMemo(() => {
        if (currentLang === 'ja') {
            return fullJpName
        } else if (currentLang === 'KOR') {
            return korName
        }
        return fullEngName
    }, [i18n.language]);

    const allRenderName = useMemo(() => {
        if (currentLang === 'ja') {
            return allName[2]
        } else if (currentLang === 'KOR') {
            return allName[0]
        }
        return allName[1]
    }, [i18n.language])

    const onClickLi = useCallback((value, data) => {
        if (value !== undefined && data !== undefined) {
            setSelected(value);

            if (data === allName[0] || data === allName[1] || data === allName[2]) {
                onClick(false);
            } else {
                onClick(data);
            }

        }
    }, []);

    if (full) {
        return (
            <Wrapper className={'champion-cname'}>
                <FullNameUL>
                    <FullWrapper>
                        <CNameLI
                            onClick={onClickLi}
                            selected={selected === 0}
                            index={0}
                            data={currentLang === 'KOR' ? allName[0] : allName[1]}
                        />
                        {Array.isArray(fullRenderName) && fullRenderName.map((data, index) => {
                            return (
                                <CNameLI
                                    key={data + "choData"}
                                    onClick={onClickLi}
                                    selected={selected === index + 1}
                                    index={index + 1}
                                    data={data}
                                />
                            )
                        })}
                    </FullWrapper>
                </FullNameUL>
            </Wrapper>
        )
    }


    if (currentLang === 'KOR') {
        return (
            <Wrapper className={'champion-cname'}>
                <NameUL>
                    <FirstAlphabetBorderBox>
                        <CNameLI
                            onClick={onClickLi}
                            selected={selected === 0}
                            index={0}
                            data={allName[0]}
                        />
                    </FirstAlphabetBorderBox>
                    <SecondLayWrapper>
                        {korName.map((data, index) => {
                            return (
                                <CNameLI
                                    key={data + "choData"}
                                    onClick={onClickLi}
                                    selected={selected === index + 1}
                                    index={index + 1}
                                    data={data}
                                />
                            )
                        })}
                    </SecondLayWrapper>
                </NameUL>
            </Wrapper>
        )
    }


    if (currentLang === 'ja') {
        return (
            <Wrapper className={'champion-cname'}>
                <NameUL>
                    <AlphabetWrapper>
                        <FirstAlphabetBorderBox>
                            <CNameLI
                                onClick={onClickLi}
                                selected={selected === 0}
                                index={0}
                                data={allName[2]}
                            />
                        </FirstAlphabetBorderBox>
                        <ColumnListBox>
                            <JaLayerWrapper>
                                {fullRenderName.map((data, index) => {
                                    return (
                                        <CNameLI
                                            key={data + "choData"}
                                            onClick={onClickLi}
                                            selected={selected === index + 1}
                                            index={index + 1}
                                            data={data}
                                        />
                                    )
                                })}
                            </JaLayerWrapper>
                        </ColumnListBox>
                    </AlphabetWrapper>
                </NameUL>
            </Wrapper>

        )
    }


    return (
        <Wrapper className={'champion-cname'}>
            <NameUL>
                <AlphabetWrapper>
                    <FirstAlphabetBorderBox>
                        <CNameLI
                            onClick={onClickLi}
                            selected={selected === 0}
                            index={0}
                            data={allName[1]}
                        />
                    </FirstAlphabetBorderBox>
                    <ColumnListBox>
                        <SecondLayWrapper>
                            {fullRenderName.map((data, index) => {
                                return (
                                    <CNameLI
                                        key={data + "choData"}
                                        onClick={onClickLi}
                                        selected={selected === index + 1}
                                        index={index + 1}
                                        data={data}
                                    />
                                )
                            })}
                        </SecondLayWrapper>
                    </ColumnListBox>
                </AlphabetWrapper>
            </NameUL>
        </Wrapper>
    );
};


export default React.memo(ChampionsCname);
