import React, {useCallback, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ChampSearchInput = styled.input`
  width: 200px;
  height: 40px;
  padding-right: 22px;
  border: 1px solid ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.white_201};
  border-radius: 6px;
  padding-left: 6px;
  color: ${props => props.theme.color.dark_200};
  background-image: ${props => `url('${props.theme.icon.path}/ico_search_w.svg')`};
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: center;
  background-size: 18px 18px;

  &&::placeholder {
    color: ${props => props.theme.color.dark_200};
  }
`;

const ChampSearchWrapper = ({children,onChangeInput}) => {
    const {t} = useTranslation();
    const [champName,setChampName] = useState("");

    // const onChangeInput = () => {};
    const handleInput = useCallback((e) => {
        const value = e?.target?.value;
        if (typeof value === 'string') {
            setChampName(value);
            onChangeInput(value);
        }
    },[onChangeInput]);

    return (
        <SearchWrapper>
            <ChampSearchInput
                autoComplete={'off'}
                type="text"
                value={champName}
                onChange={handleInput}
                placeholder={t('champions.searchChampion')}
            />
            {children}
        </SearchWrapper>
    );
};

export default ChampSearchWrapper;
