import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {GET_MAIN_VIDEO_FAILURE, GET_MAIN_VIDEO_REQUEST, GET_MAIN_VIDEO_SUCCESS} from "../reducer/video";

const getMainVideoDateAPI = () => {
    const url = `${process.env.REACT_APP_CACHE_API}video/main/weeks`;
    return axios.get(url).then(res => res.data);
}

const getMainVideoObjAPI = (action) => {

    if (typeof action?.week === 'string' && action?.week) {
        const url = `${process.env.REACT_APP_CACHE_API}video/main/exists?weeks=${action.week}`;
        return axios.get(url).then(res => res.data);
    }
    return {
        title: ""
    }
}


function* getMainVideo() {
    try {
        const getMainVideoDate = yield call(getMainVideoDateAPI);
        const mainVideo = yield call(getMainVideoObjAPI, getMainVideoDate);
        yield put({
            type: GET_MAIN_VIDEO_SUCCESS,
            data: mainVideo,
        })

    } catch (e) {
        yield put({
            type: GET_MAIN_VIDEO_FAILURE,
            error: e,
        })
    }
}

function* watchGetRanking() {
    yield takeLatest(GET_MAIN_VIDEO_REQUEST, getMainVideo)
}


export default function* videoSaga() {
    yield all([
        fork(watchGetRanking),
    ])
}
