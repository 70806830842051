import React, {useCallback} from 'react';
import styled from "styled-components";
import EmblemImage from "../../EmblemImage";
import {FlexBox} from "../../ui/styled";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import DailyRankingItemContent from "./DailyRankingItemContent";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 440px;
  width: 208px;
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;
  overflow: hidden;
`;
// Main Content
const DailyRankingItem = ({tier, list}) => {

    return (
        <Wrapper>
            <DailyRankingItemHeader tier={tier}/>
            <DailyRankingItemContent list={list} tier={tier}/>
        </Wrapper>
    );
};

const HeaderWrapper = styled.button`
  border-bottom: 1px solid ${props => props.theme.color.default_border_color};
  padding: 9px 10px;
  background-color: ${props => props.theme.color.site_main_color};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: ${props => props.theme.color.white_200};
  }
`;

const TierText = styled.span`
  margin-left: 10px;
  font-weight: bold;
  font-size: 18px;
`;

const DailyRankingItemHeader = ({tier}) => {
    const {t} = useTranslation();
    const history = useHistory();
    const {platform, queue} = useParams();
    const onClickHeader = () => {
        if (typeof tier === 'string') {
            history.push(`/daily/ai/${platform}/${queue}/${tier.toLowerCase()}`)
        }
    }

    const tierTrans = useCallback((data) => {
        switch (data.trim()) {
            case "Iron": {
                return t('gameAnalytics.iron')
            }
            case "Bronze": {
                return t('gameAnalytics.bronze')
            }
            case "Silver": {
                return t('gameAnalytics.silver')
            }
            case "Gold": {
                return t('gameAnalytics.gold')
            }
            case "Platinum": {
                return t('gameAnalytics.platinum')
            }
            case "Emerald": {
                return t('gameAnalytics.emerald')
            }
            case "Diamond" : {
                return t('gameAnalytics.diamond')
            }
            case "Master" : {
                return t('gameAnalytics.master')
            }
            case "Grandmaster" : {
                return t('gameAnalytics.grandmaster')
            }
            case "Challenger": {
                return t('gameAnalytics.challenger')
            }
            default:
                return ""
        }
    }, []);

    return (
        <HeaderWrapper onClick={onClickHeader}>
            <FlexBox>
                <EmblemImage tier={tier} height={32} width={32}/>
                <TierText className={`tier_color ${tier.toLowerCase()}`}>{tierTrans(tier)}</TierText>
            </FlexBox>
            <img src={'/images/icn_plus_1.svg'} width={12} height={12} alt={''}/>
        </HeaderWrapper>
    )
}



export default DailyRankingItem;