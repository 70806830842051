import {GET_AUTO_COMPLETE_FAILURE, GET_AUTO_COMPLETE_REQUEST, GET_AUTO_COMPLETE_SUCCESS} from "../reducer/summoner";

const initialState = {
    autoComplete: [],
    influData: {pro: [], streamer: []},
    getAutoCompleteLoading: false,
    getAutoCompleteDone: false,
    getAutoCompleteError: null,

};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_AUTO_COMPLETE_REQUEST : {
            return {
                ...state,
                autoComplete: [],
                influData: {...initialState.influData},
                getAutoCompleteLoading: true,
                getAutoCompleteDone: false,
                getAutoCompleteError: null,
            }
        }
        case GET_AUTO_COMPLETE_SUCCESS: {
            return {
                ...state,
                autoComplete: [...action.data.autoComplete],
                influData: {...action.data.influData},
                getAutoCompleteLoading: false,
                getAutoCompleteDone: true,
            }
        }
        case GET_AUTO_COMPLETE_FAILURE: {
            return {
                ...state,
                getAutoCompleteLoading: false,
                getAutoCompleteError: action.error,
            }
        }

        default:
            return state;
    }
};
export default reducer;
