import React from 'react';
import {useQuery} from "@tanstack/react-query";
import {getTierCutAPI} from "../../store/saga/summoner";

const useTierCutAPI = (platform_id) => {
    const query = useQuery({
        queryKey: ['tierCut', platform_id],
        queryFn: async () => {
            return await getTierCutAPI({
                platform_id: platform_id,
            })
        },
        retry: 0,
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 30
    })
    return {
        tierCut: query,
    }
};

export default useTierCutAPI;