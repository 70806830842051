import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {Header} from "../../common";
import styled from "styled-components";
import BalanceAramContent from "./BalanceAramContent";
import {Route, Switch} from "react-router-dom";

const Wrapper = styled.div``;

const BalanceAram = () => {
    const {t} = useTranslation();

    useEffect(() => {
        window.scroll(0, 0);
    }, []);


    return (
        <Wrapper>
            <Header/>
            {/*<NotFound/>*/}
            <Switch>
                <Route path={"/balance/aram/:position"}>
                    <BalanceAramContent/>
                </Route>
            </Switch>
        </Wrapper>
    );
};

export default BalanceAram;
