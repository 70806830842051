import React, {useCallback} from 'react';
import SummonerMasteryProvider from "../../compound/summonerMasteryProvider";
import {FlexBox} from "../../ui/styled";
import styled, {useTheme} from "styled-components";
import useMasteryFunc from "../../../common/hooks/useMasteryFunc";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import UseSetCanonical from "../../../common/hooks/useSetCanonical";
import useChampionFunc from "../../../common/hooks/useChampionFunc";
import useTitleAndDescription from "../../../common/hooks/useTitleAndDescription";
import useSummonerTitleData from "../../../common/hooks/useSummonerTitleData";

const HeaderText = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

const ServerText = styled.span`
  font-size: 14px;
  color: ${props => props.theme.color.dark_200};
  font-weight: 500;
`;
const ShareInfoText = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.dark_200};
`;
const TierSelector = styled.select`
  padding: 10px;
  border-radius: 6px;
  height: 40px;
  min-width: 80px;
  font-weight: 500;
  line-height: 1.3;
  color: ${props => props.theme.color.dark_100};
  border-color: ${props => {
    let color = '#313131';
    if (props.borderColor) color = props.borderColor;
    return color;
  }};
  -webkit-appearance: none;
  background: ${props => `url("${props.theme.icon.path}/icon_select_arrow.svg")`} no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  background-color: ${props => props.theme.color.site_main_color};
`;


const TitleBox = styled.div`
  height: 40px;
  width: 100%;
  padding: 0 15px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.color.default_border_color};
  justify-content: space-between;
`;

const HeaderWrapper = styled.div`
  border-radius: 6px;
  border: 1px solid ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.site_main_color};
  margin-top: 10px;
`;
const SummonerMastery = () => {
    const {getChampionName} = useChampionFunc();
    const {
        mastery,
        versionList,
        versionArray,
        onChangeVersion,
        selectedLane,
        onChangeLane,
        overview,
        coreList,
        lanePick,
        onChangeChampion,
        championIndex,
        onChangeCoreSelected,
        coreSelected
    } = useMasteryFunc();
    UseSetCanonical();
    const {t} = useTranslation();
    const {region} = useParams();
    const {currentProName, currentTagName, currentUserName} = useSummonerTitleData();
    const championName = getChampionName(mastery[championIndex]?.champion_id)


    useTitleAndDescription({
        title: t('header.titleSummonerOtp', {
            champion: championName,
            pro: currentProName,
            summoner: currentUserName,
            tag: currentTagName,
        }),
        description: t('header.titleSummonerOtpDescription', {
            champion: championName,
            version: versionList[versionArray],
            pro: currentProName,
            summoner: currentUserName,
            tag: currentTagName,
        })
    })

    const onChangeSelect = useCallback((e) => {
        onChangeVersion(e.target.value)
    }, [versionArray]);
    const theme = useTheme();
    return (
        <SummonerMasteryProvider>
            <HeaderWrapper>
                <TitleBox>
                    <HeaderText>{t('summoner.mastery.champSelect')}<ServerText> ({region.toUpperCase()} {t('summoner.mastery.server')})</ServerText></HeaderText>

                    <ShareInfoText>
                        {t('summoner.mastery.shareInfo')}
                    </ShareInfoText>
                </TitleBox>
                <FlexBox padding={'15px'}>
                    {mastery.map((data, index) => {
                        return (
                            <SummonerMasteryProvider.Champion
                                winRate={data?.data_list[0]?.win_rate}
                                key={data.champion_id}
                                selected={index === championIndex}
                                champion_id={data.champion_id}
                                games={data.totalGames}
                                onClick={() => onChangeChampion(data.champion_id)}/>
                        )
                    })}
                </FlexBox>
            </HeaderWrapper>
            <FlexBox justifyContent={'space-between'} width={'100%'} margin={'10px 0 10px 0'}>
                <SummonerMasteryProvider.LaneFilter selectedLane={selectedLane}
                                                    setSelectedLane={onChangeLane} pickList={lanePick}/>
                <FlexBox>

                    <FlexBox margin={'0 8px 0 0'}>
                        <TierSelector value={versionArray} borderColor={theme.color.default_border_color}
                                      onChange={onChangeSelect}>
                            {versionList.map((data, index) => (<option value={index} key={index}>{data}</option>))}
                        </TierSelector>
                        {/*{versionList.map((data, index) => {*/}
                        {/*    return <SummonerMasteryProvider.PatchFilter*/}
                        {/*        key={data}*/}
                        {/*        patch={data}*/}
                        {/*        selected={versionArray === index}*/}
                        {/*        onClick={() => onChangeVersion(index)}/>*/}
                        {/*})}*/}
                    </FlexBox>

                    <SummonerMasteryProvider.Share/>
                </FlexBox>
            </FlexBox>

            <SummonerMasteryProvider.Runes
                coreList={coreList}
                coreSelected={coreSelected}
                onChangeCoreSelected={onChangeCoreSelected}
                champId={mastery[championIndex]?.champion_id}
            />
        </SummonerMasteryProvider>
    );
};

export default SummonerMastery;