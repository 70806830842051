import React from 'react';
import styled from "styled-components";
import {ColumnBox} from "../../../ui/styled";
import {useTranslation} from "react-i18next";

const Title = styled.span`
  font-size: 26px;
  line-height: 38px;
  font-weight: 500;
`;
const TitleInfo = styled.span`
  opacity: 0.6;
  line-height: 20px;
`;
const SpectateLiveTitle = () => {
    const {t} = useTranslation();
    return (
        <ColumnBox height={'auto'}>
            <Title>{t('live.title')}</Title>
            <TitleInfo>{t('live.info')}</TitleInfo>
        </ColumnBox>
    );
};

export default SpectateLiveTitle;