// 액션 생성
const SEARCHLIST = 'searchList/SEARCHLIST';
const SEARCHLIST_ADD_PRO = 'searchList/searchListAddPro';

// 액션 함수 내보내기

export const addSearchListProStreamerList = (value) => ({type: SEARCHLIST_ADD_PRO, data: value})
export const searchList = (searchs, flag) => ({type: SEARCHLIST, searchs, flag});
export const removeSearchList = () => ({})

// 초기 상태 값
const initialState = {
    searchs: [],
    flag: 'add',
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCHLIST:
            const arr = state.searchs.slice(-12);
            let filtered;
            if (action.flag === 'add') {
                filtered = arr.filter((data, index) => data.type !== 'influencer' ? data?.summoner_basic_info_dict?.riot_id_name !== action?.searchs?.summoner_basic_info_dict?.riot_id_name : true);
                filtered.push(action.searchs);
            } else {
                filtered = arr.filter((data) => data?.type !== 'influencer' ? data?.summoner_basic_info_dict?.riot_id_name !== action?.searchs?.summoner_basic_info_dict?.riot_id_name : data?.url_name !== action?.searchs?.url_name)
            }
            return {
                searchs: [...filtered],
            };
        case SEARCHLIST_ADD_PRO: {
            const arr = state.searchs.slice(-12);
            const copyArr = [...arr];
            const filtered = copyArr.filter((data, index) => data.type === 'influencer' ? data.url_name !== action.data.url_name : true);
            filtered.push({...action.data, type: 'influencer'});
            return {
                searchs: [...filtered]
            }
        }
        default:
            if (state.searchs.length > 12) {
                return {
                    ...state,
                    searchs: state.searchs.slice(-12),
                }
            } else {
                return state;
            }
    }
};

export default reducer;
