import {all, call, delay, fork, put, select, takeLatest, takeLeading} from "redux-saga/effects";
import {
    GET_AUTO_COMPLETE_FAILURE,
    GET_AUTO_COMPLETE_REQUEST,
    GET_AUTO_COMPLETE_SUCCESS,
    GET_CHAMP_INFO_FAILURE,
    GET_CHAMP_INFO_REQUEST,
    GET_CHAMP_INFO_SUCCESS,
    GET_CHAMPIONS_BY_SEASON_FAILURE,
    GET_CHAMPIONS_BY_SEASON_REQUEST,
    GET_CHAMPIONS_BY_SEASON_SUCCESS,
    GET_GAME_ANALYTICS_PATCH_SUCCESS,
    GET_INGAME_CHECK_REQUEST,
    GET_INGAME_CHECK_SUCCESS,
    GET_INGAME_INFO_FAILURE,
    GET_INGAME_INFO_REQUEST,
    GET_INGAME_INFO_SUCCESS,
    GET_INGAME_INFO_SUMMONER_SUCCESS,
    GET_MATCH_BY_QUEUE_FAILURE,
    GET_MATCH_BY_QUEUE_REQUEST,
    GET_MATCH_BY_QUEUE_SUCCESS,
    GET_MATCH_FAILURE,
    GET_MATCH_REQUEST,
    GET_MATCH_SUCCESS,
    GET_MORE_MATCHES_FAILURE,
    GET_MORE_MATCHES_REQUEST,
    GET_MORE_MATCHES_SUCCESS,
    GET_MULTI_LIST_REQUEST,
    GET_MULTI_LIST_SUCCESS,
    GET_MULTI_LIST_UPDATE_REQUEST,
    GET_MULTI_LIST_UPDATE_SUCCESS,
    GET_MULTI_SUMMONER_LP_CHART_REQUEST,
    GET_MULTI_SUMMONER_LP_CHART_SUCCESS,
    GET_SHORT_LINK_REQUEST,
    GET_SHORT_LINK_SUCCESS,
    GET_SUMMONER_AI_FAILURE,
    GET_SUMMONER_AI_REQUEST,
    GET_SUMMONER_AI_SUCCESS,
    GET_SUMMONER_CHAMPION_ARAM_FAILURE,
    GET_SUMMONER_CHAMPION_ARAM_REQUEST,
    GET_SUMMONER_CHAMPION_ARAM_SUCCESS,
    GET_SUMMONER_CHAMPION_URF_FAILURE,
    GET_SUMMONER_CHAMPION_URF_REQUEST,
    GET_SUMMONER_CHAMPION_URF_SUCCESS,
    GET_SUMMONER_FAILURE,
    GET_SUMMONER_INFO_FAILURE,
    GET_SUMMONER_INFO_REQUEST,
    GET_SUMMONER_INFO_SUCCESS,
    GET_SUMMONER_LP_CHART_REQUEST,
    GET_SUMMONER_LP_CHART_SUCCESS,
    GET_SUMMONER_REAL_TIME_FAILURE,
    GET_SUMMONER_REAL_TIME_REQUEST,
    GET_SUMMONER_REAL_TIME_SUCCESS,
    GET_SUMMONER_REQUEST,
    GET_SUMMONER_SUCCESS,
    GET_UPDATE_CHAMPION_FAILURE,
    GET_UPDATE_CHAMPION_REQUEST,
    GET_UPDATE_CHAMPION_SUCCESS,
    GET_UPDATE_MATCHES_FAILURE,
    GET_UPDATE_MATCHES_REQUEST,
    GET_UPDATE_MATCHES_SUCCESS,
    SET_SHORT_LINK_REQUEST,
    SET_SHORT_LINK_SUCCESS,
    SET_TIER_CUT,
    UPDATE_SUMMONER_INFO_FAILURE,
    UPDATE_SUMMONER_INFO_REQUEST,
    UPDATE_SUMMONER_INFO_SUCCESS
} from "../reducer/summoner";
import axios from "axios";
import {addTag, getRegion, isOtherDefaultTag, replaceBlockUnicode, setSummonerName} from "../../function";
import {END, eventChannel} from "redux-saga";
import {SSEFix} from "../../common/moduleFork/sseFix";
import {customAxios} from "../../function/customAxios";
import {IngameError} from "../../common/model/InagameError";
import {GET_MASTERY_BUILD_FAILURE, GET_MASTERY_BUILD_REQUEST, GET_MASTERY_BUILD_SUCCESS} from "../reducer/champions";
import {_fetch_refresh_champion, _fetch_refresh_matches, _fetch_refresh_tier} from "../../common/api";
import {Buffer} from 'buffer/';


const divisionConvert = (division) => {
    if (division === 1) {
        return "I"
    } else if (division === 2) {
        return "II"
    } else if (division === 3) {
        return "III"
    } else if (division === 4) {
        return "IV"
    }
    return 'I';
}

const rankValue = (tier, division) => {
    const lowerCaseTier = tier.toLowerCase();
    if (tier === "") {
        return "unranked"
    }
    if (lowerCaseTier === 'master' || lowerCaseTier === 'grandmaster' || lowerCaseTier === 'challenger') {
        return tier;
    } else {
        return `${tier} ${divisionConvert(division)}`
    }
}
export const getTierCutAPI = (action) => {
    const url = new URL(`${process.env.REACT_APP_CACHE_API}summoner/tier_cut_off`);
    url.searchParams.set('platform_id', getRegion(action.platform_id));
    return axios.get(url.toString()).then((res) => res.data).catch(() => {
        return {
            challenger_1st: 1000,
            challenger_cut_off: 1000,
            grandmaster_cut_off: 1000,
        }
    });

}

const getMasteryBuildAPI = (action) => {
    const url = new URL(`${process.env.REACT_APP_CACHE_API}champion/master_build`);
    url.searchParams.set('summoner_id', action.summoner_id);
    url.searchParams.set('platform_id', getRegion(action.platformId));
    // url.searchParams.set('summoner_id','_hIvaT4l_IZ_dNR11MpLEjnMbzXzQZk-mrDX5Cl1v-Jf6TE')
    return axios.get(url.toString()).then((res) => res.data.data);
}
const getIngameModelApi = (part) => {
    const top_feature = part[0].model_feature;
    const jungle_feature = part[1].model_feature;
    const middle_feature = part[2].model_feature;
    const bot_feature = part[3].model_feature;
    const supporter_feature = part[4].model_feature;

    const tier_dict = {
        BLUE_Top: rankValue(part[0].blue.season_tier_info_dict.ranked_solo_5x5.tier, part[0].blue.season_tier_info_dict.ranked_solo_5x5.division),
        RED_Top: rankValue(part[0].red.season_tier_info_dict.ranked_solo_5x5.tier, part[0].red.season_tier_info_dict.ranked_solo_5x5.division),
        BLUE_Jungle: rankValue(part[1].blue.season_tier_info_dict.ranked_solo_5x5.tier, part[1].blue.season_tier_info_dict.ranked_solo_5x5.division),
        RED_Jungle: rankValue(part[1].red.season_tier_info_dict.ranked_solo_5x5.tier, part[1].red.season_tier_info_dict.ranked_solo_5x5.division),
        BLUE_Middle: rankValue(part[2].blue.season_tier_info_dict.ranked_solo_5x5.tier, part[2].blue.season_tier_info_dict.ranked_solo_5x5.division),
        RED_Middle: rankValue(part[2].red.season_tier_info_dict.ranked_solo_5x5.tier, part[2].red.season_tier_info_dict.ranked_solo_5x5.division),
        BLUE_Bot: rankValue(part[3].blue.season_tier_info_dict.ranked_solo_5x5.tier, part[3].blue.season_tier_info_dict.ranked_solo_5x5.division),
        RED_Bot: rankValue(part[3].red.season_tier_info_dict.ranked_solo_5x5.tier, part[3].red.season_tier_info_dict.ranked_solo_5x5.division),
        BLUE_Supporter: rankValue(part[4].blue.season_tier_info_dict.ranked_solo_5x5.tier, part[4].blue.season_tier_info_dict.ranked_solo_5x5.division),
        RED_Supporter: rankValue(part[4].red.season_tier_info_dict.ranked_solo_5x5.tier, part[4].red.season_tier_info_dict.ranked_solo_5x5.division),
    };

    const modelData = {
        top_feature, jungle_feature, middle_feature, bot_feature, supporter_feature, tier_dict,
    }


    return axios.post(`${process.env.REACT_APP_CACHE_API}ingame/model`, JSON.stringify(modelData), {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
        },
    }).then((res) => res.data);

}

const getPartAPI = ({players, currentSeason, queue, region, position, now_id, map}) => {
    const bluePlayer = players.find((data) => data.side === 'BLUE');
    const redPlayer = players.find((data) => data.side === 'RED');


    return axios.get(`${process.env.REACT_APP_CACHE_API}ingame/part?blue_puu_id=${bluePlayer?.puu_id}&blue_summoner_id=${bluePlayer?.summoner_id}&blue_champion_id=${bluePlayer?.champion_id}&position=${bluePlayer?.position}&red_puu_id=${redPlayer?.puu_id}&red_summoner_id=${redPlayer?.summoner_id}&red_champion_id=${redPlayer?.champion_id}&queue_id=${queue}&season=${currentSeason}&platform_id=${getRegion(region)}&now_match_id=${now_id}&map_id=${map}`).then((res) => {
        const result = res.data;
        return {
            ...result, blue: {
                ...result.blue, ...bluePlayer,
                "champion_counter_stats": result.champion_counter_stats,
                "model_feature": result.model_feature
            }, red: {
                ...result.red, ...redPlayer,
                "champion_counter_stats": result.champion_counter_stats,
                "model_feature": result.model_feature
            }, position,
        }
    });
}


const cloudflare_ingame_basic_api = ({puu_id, region, onlyBasic}) => {
    const apiRequest = customAxios(3);
    const formData = new FormData();
    formData.append('puu_id', puu_id);
    formData.append('platform_id', getRegion(region))
    return apiRequest
        .post(`https://ingame-check.deeplol-gg.workers.dev/`, formData).then((res) => res.data);
}

const cloudflare_ingame_basic_info_api = ({puu_id, region, basic}) => {
    const apiRequest = customAxios(3);
    const formData = new FormData();
    formData.append('puu_id', puu_id);
    formData.append('platform_id', getRegion(region))
    formData.append('basic', JSON.stringify(basic));
    return apiRequest
        .post(`https://ingame-basic-info.deeplol-gg.workers.dev/`, formData).then((res) => res.data);
}


const ingameBasicAPI = ({puu_id, region, season,match_id}) => {
    const apiRequest = customAxios(3);
    // const formData = new FormData();
    // formData.append('ingame_data', JSON.stringify(cloudflare_ingame));
    const url = new URL(`${process.env.REACT_APP_CACHE_API}ingame/ingame_info`);
    url.searchParams.set('puu_id', puu_id)
    url.searchParams.set('platform_id', getRegion(region))
    url.searchParams.set('season', season)
    url.searchParams.set('match_id',String(match_id))
    return apiRequest.get(url.toString()).then((res) => res.data);
}


const createSSEChannel = (action) => {

    return eventChannel(emit => {
        let retry = 1;
        const eventStream = new SSEFix(`${process.env.REACT_APP_NEW_API}match/matches`, {
            payload: JSON.stringify(action),
        });

        eventStream.addEventListener("error", (e) => {
            const data = e?.detail?.data;
            if (data) {
                if (JSON.parse(data).status === 1) {
                    emit({
                        error: JSON.parse(data).status
                    })
                }
                if (JSON.parse(data).status === 2 && retry) {
                    setTimeout(() => {
                        eventStream.stream();
                    }, 2000);
                    retry--;
                } else if (!retry) {
                    emit({
                        error: JSON.parse(data).status,
                    })
                }
            } else {
                eventStream.close();
            }
        });

        eventStream.addEventListener("message", (e) => {
            // Fallback handler for undefined event types


            const percent = e.data;

            if (percent?.includes("update")) {
                emit(100);
                emit(END);
            }

            if (Number(percent) === 100) {
                emit(percent);
                emit(END);
            }
        });
        eventStream.stream();
        return () => eventStream.removeEventListener();
    })
}


const mutliListAPI = (action) => {
    const url = `${process.env.REACT_APP_CACHE_API}multisearch/multisearch?puu_id=${action.puu_id}&season=${action.season}`;
    return axios.get(url).then(async (res) => {
        if (res.data && typeof res.data === 'object') {
            return {...res.data, summoner: {...action.summoner,}}
        }
    });
}

const getShortLinkAPI = (action) => {
    // const currentLang = getRegion(action.region);
    // const url = 'https://scrimapi.deeplol.gg/api/getshortlink/' + encodeURIComponent(action.short);
    // return axios.get(url).then(res => ({...res.data, region: currentLang}));
}

const setShortLinkAPI = (action) => {
    throw "issue"
    // if (action) {
    //     const url = 'https://scrimapi.deeplol.gg/api/getsummoners/' + action.summoner;
    //     return axios.get(url).then(res => res.data);
    // } else {
    //     throw "unexpected name";
    // }

}


const getChampInfoAPI = (action) => {
    let url;
    const changeRegion = getRegion(action.region);
    if (action.category === 0) {
        url = `${process.env.REACT_APP_API}summoner_stat_tap?summonerId=${action.summoner_id}&season=${action.season}&region=${changeRegion}`
    } else if (action.category === 1) {
        url = `${process.env.REACT_APP_API}summoner_stat_tap_enemy?summonerId=${action.summoner_id}&season=${action.season}&region=${changeRegion}`
    } else {
        throw 'unexpected category';
    }

    return axios.get(url).then((res) => res.data.result);
}

const getSummonerName = (action) => {
    if (action?.puu_id) {
        const searchName = setSummonerName(action?.puu_id);
        const changeRegion = getRegion(action.region);
        const url = `${process.env.REACT_APP_CACHE_API}summoner/summoner-name?puu_id=${encodeURI(searchName)}&platform_id=${changeRegion}`;

        return axios.get(url).then((result) => {
            if (!result.data.error) {
                return `${result.data.riot_id_name}-${result.data.riot_id_tag_line}`;
            } else {
                throw result.data.error
            }
        }).catch(() => {
            return action.username;
        });
    } else {
        return action.username;
    }

}

export const getProAndStreamerSeachAPI = (action) => {
    const splitSummoner = action.username.split('#');
    const summoner_name = setSummonerName(splitSummoner[0])
    const tag = splitSummoner[1] || ""
    const searchName = summoner_name.trim().replace(/\s/gi, '');

    const url = new URL(`${process.env.REACT_APP_CACHE_API}summoner/pro-search-auto-complete`);
    url.searchParams.set('search_string', summoner_name);
    url.searchParams.set('riot_id_tag_line', tag);

    if (searchName.length < 2) {
        return {
            streamer: [],
            pro: [],
        }
    }
    return axios.get(url.toString()).then((result) => {
        if (!result.data.error) {
            return result.data;
        } else {
            throw result.data.error
        }
    }).catch((e) => {
        console.log("Pro search Error", e);
        return {
            streamer: [],
            pro: [],
        }
    });

}


export const getSearchSummonerAPI = (action) => {
    const removeUnicodeBlock = replaceBlockUnicode(action.username)
    const splitSummoner = removeUnicodeBlock.split('#');
    const summoner_name = setSummonerName(splitSummoner[0])
    const tag = splitSummoner[1] || ""
    const changeRegion = getRegion(action.region);
    const url = new URL(`${process.env.REACT_APP_CACHE_API}summoner/summoner-search-auto-complete`);

    url.searchParams.set('riot_id_name', summoner_name);
    url.searchParams.set('riot_id_tag_line', tag);
    url.searchParams.set('platform_id', changeRegion);

    const korReg = /[ㄱ-ㅎ|가-힣]/;
    if (korReg.test(summoner_name) && summoner_name.length === 1) {
        return [];
    }
    if (Buffer.byteLength(summoner_name) < 3) {
        return [];
    }
    return axios.get(url.toString()).then((result) => {
        return result.data.summoners
    }).catch(() => {
        return [];
    });
}


const getSummonerAPI = (action) => {
    const splitName = action.username.split(/-[^-]+$/);
    let splitTagName = action.username.match(/-[^-]+$/);
    if (!splitTagName) {
        splitTagName = [addTag(action.region)];
    }
    const searchName = setSummonerName(splitName[0]);
    const tag = splitTagName[0].replace("-", "");
    const changeRegion = getRegion(action.region);
    const url = new URL(`${process.env.REACT_APP_CACHE_API}summoner/summoner`);
    url.searchParams.set('riot_id_name', encodeURI(searchName));
    url.searchParams.set('riot_id_tag_line', tag);
    url.searchParams.set('platform_id', changeRegion);

    const korReg = /[ㄱ-ㅎ|가-힣]/;
    if (korReg.test(searchName) && searchName.length === 1) {
        return 404;
    }
    if (Buffer.byteLength(searchName) < 3) {
        return 404;
    }
    if (isOtherDefaultTag(changeRegion, tag)) {
        return 404;
    }

    return axios.get(url.toString()).then(async (result) => {
        if (!result.data.error) {
            const rank = await getSummonerRank({
                region: action.region,
                summoner_id: result.data.summoner_basic_info_dict.summoner_id,
            });
            return {...result.data, ...rank};
        } else {
            throw result.data.error
        }
    }).catch(() => {
        return 404;
    });
}

const getSummonerRank = async (action) => {
    // const url = `${process.env.REACT_APP_API}summoner_stat?summonerId=${action.summoner_id}&region=${action.region}&season=13`
    const summoner_id = action.summoner_id;
    const region = getRegion(action.region);
    const url = new URL(`${process.env.REACT_APP_CACHE_API}summoner/summoner-realtime`);
    url.searchParams.set('platform_id', region);
    url.searchParams.set('summoner_id', summoner_id);
    // 소환사 stat 정보
    return await axios.get(url.toString())
        .then((res) => {
            return res.data;
        }).catch((() => {
            return {}
        }));
}


const getSummonerCached = (action) => {
    const changeRegion = getRegion(action.region);
    const url = `${process.env.REACT_APP_CACHE_API}ingame/summoner-cached?summoner_id=${action.summoner_id}&platform_id=${changeRegion}`;

    return axios.get(url).then(async (result) => {
        if (!result.data.error) {
            return {...result.data};
        } else {
            throw result.data.error
        }
    }).catch((e) => {
        const statusCode = e.response.status;
        if (statusCode === 404 || statusCode === 501) {
            return 404;
        }
    });
}

export const getSummonerStatus = (action) => {
    // const url = `${process.env.REACT_APP_API}summoner_stat?summonerId=${action.summoner_id}&region=${action.region}&season=13`
    const url = `${process.env.REACT_APP_CACHE_API}summoner/champion-stat?puu_id=${action.puu_id}&season=${action.season}&platform_id=${getRegion(action.platform_id)}`
    // 소환사 stat 정보
    return axios.get(url)
        .then((res) => {
            return res.data;
        }).catch(((e) => {
            return e.response.status;
        }));
}

export const getSummonerARAMStatus = (action) => {
    // const url = `${process.env.REACT_APP_API}summoner_stat?summonerId=${action.summoner_id}&region=${action.region}&season=13`
    const url = `${process.env.REACT_APP_CACHE_API}summoner/champion-stat-aram?puu_id=${action.puu_id}&season=${action.season}&platform_id=${getRegion(action.platform_id)}`
    // 소환사 stat 정보
    return axios.get(url)
        .then((res) => {
            return res.data;
        }).catch(((e) => {
            return e.response.status;
        }));
}
const getSummonerURFStatus = (action) => {
    // const url = `${process.env.REACT_APP_API}summoner_stat?summonerId=${action.summoner_id}&region=${action.region}&season=13`
    const url = `${process.env.REACT_APP_CACHE_API}summoner/champion-stat-urf?puu_id=${action.puu_id}&season=${action.season}&platform_id=${getRegion(action.platform_id)}`
    // 소환사 stat 정보
    return axios.get(url)
        .then((res) => {
            return res.data;
        }).catch(((e) => {
            return e.response.status;
        }));
}


const getUpdateStatus = (action) => {
    const url = new URL(`${process.env.REACT_APP_CACHE_API}summoner/updated-time`);
    url.searchParams.set('puu_id', action.puu_id);
    url.searchParams.set('platform_id', getRegion(action.region));
    return axios.get(url.toString())
        .then((res) => res.data);
}

const getMatchInfoAPI = (action) => {
    const changeRegion = getRegion(action.region);
    const url = `${process.env.REACT_APP_CACHE_API}match/matches?puu_id=${action.puu_id}&platform_id=${changeRegion}&offset=${action.offset}&count=${action.matchLength}&queue_type=${action.queueNum}&champion_id=${action.champId}&only_list=1&last_updated_at=${action.match_creation_time}`
    return axios.get(url).then((res) => res.data.match_id_list).catch((() => false));
}
const getSingleMatch = (action) => {
    const changeRegion = getRegion(action.region);
    const url = `${process.env.REACT_APP_CACHE_API}match/match-cached?match_id=${action.match_id}&platform_id=${changeRegion}`;
    return axios.get(url).then((res) => res.data).catch((error) => console.log(error));
}

const getMatchDataAPI = (action) => {
    const changeRegion = getRegion(action.region);
    const url = `${process.env.REACT_APP_CACHE_API}match/match-cached?match_id=${action.matchId}&platform_id=${changeRegion}`
    return axios.get(url).then((res) => res.data);
}

const getTierChartAPI = (action) => {
    const changeRegion = getRegion(action.region);
    return axios.get(`${process.env.REACT_APP_CACHE_API}summoner/tier-chart?summoner_id=${action.summoner_id}&platform_id=${changeRegion}&last_match_id=${action.last_id}`)
        .then((res) => ({
            ...res.data, ...action,
        })).catch((() => false));
}


const gameAnalyticsPatch = (action) => {
    const url = `${process.env.REACT_APP_CACHE_API}match/patch?version=${action.version}`;
    return axios.get(url).then((res) => res.data);
}


function* getMoreMatches(action) {
    try {


        const summoner = yield select((state) => state.summoner.summoner);
        const match = yield select((state) => state.summoner.match);

        const matchInfoArgs = {
            puu_id: summoner?.summoner_basic_info_dict?.puu_id,
            region: action.data.region,
            offset: action.data.offset,
            matchLength: 20,
            queueNum: action.data.queueNum,
            champId: action.data.champId,
            match_creation_time: 1,
        }

        const matchArr = yield call(getMatchInfoAPI, matchInfoArgs)


        // yield put({
        //     type: GET_SUMMONER_AI_REQUEST,
        //     data: {
        //         platform_id: matchInfoArgs.region,
        //         puu_id: matchInfoArgs.puu_id,
        //     }
        // })

        const getMatches = yield all(matchArr.map((data) => {
            return call(getSingleMatch, {
                match_id: data.match_id, region: matchInfoArgs.region, puu_id: matchInfoArgs.puu_id,
                match_creation_time: data.match_creation_time,
            })
        }))

        yield put({
            type: GET_MORE_MATCHES_SUCCESS, data: {
                match: getMatches || [],
            }
        })


    } catch (e) {
        console.log(e);
        yield put({
            type: GET_MORE_MATCHES_FAILURE, error: e,
        })
    }
}

export const summonerAIAPI = async ({platform_id, puu_id}) => {
    const url = new URL(`${process.env.REACT_APP_CACHE_API}aiScore-ranking/summoner`);
    url.searchParams.set('platform_id', getRegion(platform_id));
    url.searchParams.set('puu_id', puu_id);
    const result = await axios.get(url.toString())
    return result.data;
}


function* getSummoner(action) {
    try {
        const nameCheck = yield call(getSummonerName, action.data);
        localStorage.removeItem("tempPuuId");

        let getSummonerObj = {
            ...action?.data, username: nameCheck
        }

        // if (getSummonerObj.username.indexOf('#') === -1 && getSummonerObj.username.indexOf('-') === -1) {
        //     const summonerOneTag = yield call(getSearchSummonerAPI, getSummonerObj);
        //
        //     if (summonerOneTag.length === 1) {
        //         getSummonerObj.username = `${summonerOneTag[0].riot_id_name}-${summonerOneTag[0].riot_id_tag_line}`
        //     }
        // }

        const [summoner, tierCut] = yield all([call(getSummonerAPI, getSummonerObj), call(getTierCutAPI, {platform_id: getSummonerObj.region})]);
        const regionAndSummonerId = {
            summoner_id: summoner?.summoner_basic_info_dict?.summoner_id,
            puu_id: summoner?.summoner_basic_info_dict?.puu_id,
            region: action.data.region,
            season: action.data.currentSeason,
        }
        const updateStatus = yield call(getUpdateStatus, regionAndSummonerId)

        if (typeof summoner === "number") throw summoner;


        if (action?.data?.matchId) {
            yield put({
                type: GET_MATCH_REQUEST, data: {
                    match: {
                        region: action.data.region,
                        matchId: action.data.matchId,
                        puu_id: summoner.summoner_basic_info_dict.puu_id,
                    }
                }
            });
        }


        yield put({
            type: GET_SUMMONER_SUCCESS, data: {
                summoner: summoner,
                updateStatus: updateStatus,
            }
        })
        yield put({
            type: SET_TIER_CUT, data: {
                tierCut: tierCut,
            }
        })
        yield put({
            type: GET_MASTERY_BUILD_REQUEST,
            data: {
                platform_id: regionAndSummonerId.region, summoner_id: regionAndSummonerId.summoner_id,
            }
        })


        // yield all([put({
        //     type: GET_SUMMONER_INFO_REQUEST, data: {
        //         summoner: summoner, ...action.data,
        //     }
        // })])

    } catch (e) {
        console.log("?????????????????", e);
        yield put({
            type: GET_SUMMONER_FAILURE, error: e,
        })
    }

}


//Deprecated,to do not usable on new api
function* getChampInfo(action) {
    try {
        const summoner = yield select((state) => state.summoner.summoner);
        const champData = yield select((state) => state.imageStore.champions);
        const lang = yield select((state) => state.translation.lang);
        const currentSeason = yield select((state) => state.imageStore.currentSeason);
        const champInfo = yield call(getChampInfoAPI, {
            summoner_id: summoner.summoner_id,
            region: action.data.region,
            category: action.data.category,
            season: currentSeason,
        });

        if (champInfo) {
            //챔피언 이름 붙이기
            for (let attr in champInfo) {
                for (let position in champInfo[attr]) {
                    if (champInfo[attr].hasOwnProperty(position)) {
                        champInfo[attr][position] = champInfo[attr][position].map((data, index) => {
                            const getChampionData = champData.find((champ, index) => Number(champ.champion_id) === Number(data.champion_id));
                            let detail = [];
                            if (data.detail.length > 0) {
                                detail = data.detail.map((detail, index) => {
                                    const detailData = champData.find((champ, index) => Number(champ.champion_id) === Number(detail.enemyId));
                                    return {
                                        ...detail,
                                        enemy_name_kr: detailData.champion_name_kr,
                                        enemy_name_en: detailData.champion_name_en,
                                    }
                                })
                            }
                            return {
                                ...data,
                                detail: [...detail],
                                champion_name_kr: getChampionData.champion_name_kr,
                                champion_name_en: getChampionData.champion_name_en,
                            }
                        })
                    }
                }
            }


            const sortFilter = ['games', 'winrate'];
            sortFilter.push(lang === 'KOR' ? 'champion_name_kr' : 'champion_name_en');

            const withOrigin = champInfo['with_position'];
            const withOutOrigin = champInfo['without_position'];

            yield put({
                type: GET_CHAMP_INFO_SUCCESS, data: {
                    withOrigin: {...withOrigin}, withOutOrigin: {...withOutOrigin},
                }
            })
        }


    } catch (e) {
        console.error(e);
        yield put({
            type: GET_CHAMP_INFO_FAILURE, error: e,
        })
    }
}

function* getIngameCheck(action) {
    try {
        const ingame = yield select((state) => state.summoner.ingame);
        const currentSeason = yield select((state) => state.imageStore.currentSeason);
        const obj = {
            puu_id: action.data.puu_id, region: action.data.region,
        }

        const cloudflare_ingame_baisc = yield call(cloudflare_ingame_basic_api, {...obj});
        if (cloudflare_ingame_baisc.status.status_code !== 200) throw new IngameError("notPLaying");


        yield put({
            type: GET_INGAME_CHECK_SUCCESS,
            data: {ingameStatus: true}
        });

        if(!action.data.fetchAll) {
            return;
        }
        // const cloudflare_full_data = yield call(cloudflare_ingame_basic_info_api, {
        //     ...obj,
        //     basic: cloudflare_ingame_baisc
        // });
        const ingameBasic = yield call(ingameBasicAPI, {...obj, season: currentSeason,match_id: cloudflare_ingame_baisc.gameId});


        if (!ingameBasic.playing) {
            throw new IngameError('notPlaying');
        }

        if (!ingameBasic.support) {
            throw new IngameError('notSupport');
        }


        if (ingame?.basic?.start_timestamp !== ingameBasic.start_timestamp) {


            yield put({
                type: GET_INGAME_INFO_REQUEST,
                data: {
                    ...obj,
                    ingameBasic: {...ingameBasic, map: cloudflare_ingame_baisc.mapId},
                }

            })
        }


    } catch (e) {
        console.log("ingameCheckError", e);
        yield put({
            type: GET_INGAME_CHECK_SUCCESS, data: {ingameStatus: false},
        })
    }
}

function* getSummonerInfo(action) {
    try {
        const regionAndSummonerId = {
            summoner_id: action.data.summoner?.summoner_basic_info_dict?.summoner_id,
            puu_id: action.data.summoner?.summoner_basic_info_dict?.puu_id,
            region: action.data.region,
            platform_id: action.data.region,
            season: action.data.currentSeason,
        }


        const isIngame = window.location.href.includes('/ingame');

        yield all([
            put({
                type: GET_CHAMPIONS_BY_SEASON_REQUEST,
                data: {
                    ...regionAndSummonerId,
                }
            }),
            // call(getSummonerStatus, {...regionAndSummonerId,}),
            // call(getSummonerARAMStatus, regionAndSummonerId),
            // call(getSummonerURFStatus, regionAndSummonerId),
            put({
                type: GET_INGAME_CHECK_REQUEST, data: {
                    puu_id: regionAndSummonerId.puu_id, region: regionAndSummonerId.region,
                    fetchAll: isIngame,
                }
            }),
            put({
                type: GET_MATCH_BY_QUEUE_REQUEST, data: {
                    queueNum: action?.data?.queue_id || 'ALL',
                    champId: 0,
                    region: action.data.region,
                    noLoading: Boolean(action?.data?.noLoading),
                    match_creation_time: 0,
                }
            }),
        ]);


        yield put({
            type: GET_SUMMONER_INFO_SUCCESS, data: {
                summonerStatus: null,

            }
        })

    } catch (error) {
        yield put({
            type: GET_SUMMONER_INFO_FAILURE, data: error,
        })
    }
}


function* getLPChart(action) {
    try {

        const regionAndSummonerId = {
            summoner_id: action.data.summoner_id,
            puu_id: action.data.puu_id,
            region: action.data.region,
            last_id: action.data.last_id
        }

        const getTierChart = yield call(getTierChartAPI, regionAndSummonerId);

        yield put({
            type: GET_SUMMONER_LP_CHART_SUCCESS, data: {
                tierChart: getTierChart || null,
            }
        })
    } catch (e) {
        console.log(e);
    }
}

function* updateSummonerInfo(action) {
    try {
        const summoner = yield select((state) => state.summoner.summoner);

        const [upateStatus, summonerInfo] = yield all([call(getUpdateStatus, {
            puu_id: summoner?.summoner_basic_info_dict?.puu_id, region: action.data.region,
        }), call(getSummonerAPI, {
            username: summoner.summoner_basic_info_dict?.riot_id_name, region: action.data.region
        })]);


        yield all([
            put({
                type: GET_SUMMONER_SUCCESS, data: {
                    summoner: summonerInfo
                }
            }),
            put({
                type: GET_SUMMONER_INFO_REQUEST, data: {
                    summoner: summonerInfo, ...action.data,
                    noLoading: true,
                }
            }), put({
                type: UPDATE_SUMMONER_INFO_SUCCESS, data: {
                    summoner: summonerInfo, updateStatus: upateStatus
                }
            })

        ])

    } catch (error) {
        console.error(error);
        yield put({
            type: UPDATE_SUMMONER_INFO_FAILURE, error: error,
        })
    }
}

const matchCompare = (oldList, newList) => {
    const newListArray = newList.map((data) => data.match_id)
    const oldListArray = oldList.map((data) => data?.match_basic_dict?.match_id)

    return newListArray.find((data) => !oldListArray.includes(data)) || false
}

function* getMatchByQueue(action) {
    try {
        const callChamp = action?.data?.callChamp || false;
        const summoner = yield select((state) => state.summoner.summoner);
        const match = yield select((state) => state.summoner.match);


        const matchInfoArgs = {
            puu_id: summoner?.summoner_basic_info_dict?.puu_id,
            region: action.data.region,
            matchLength: 20,
            offset: 0,
            queueNum: action.data.queueNum,
            champId: action.data.champId,
            match_creation_time: action.data.match_creation_time || 0,
        }
        const getMatchInfo = yield call(getMatchInfoAPI, matchInfoArgs)


        const last_id = getMatchInfo?.[0]?.match_id || 0;
        const regionAndSummonerId = {
            summoner_id: summoner?.summoner_basic_info_dict?.summoner_id,
            puu_id: summoner?.summoner_basic_info_dict?.puu_id,
            region: action.data.region,
            last_id: last_id,
        }


        if (callChamp) {
            yield put({
                type: GET_CHAMPIONS_BY_SEASON_REQUEST,
                data: {
                    puu_id: regionAndSummonerId.puu_id,
                    season: action.data.season,
                    platform_id: regionAndSummonerId.region,
                }
            })
        }
        yield all([
            put({
                type: GET_SUMMONER_LP_CHART_REQUEST, data: {
                    ...regionAndSummonerId,
                }
            }),
            // put({
            //     type: GET_SUMMONER_AI_REQUEST,
            //     data: {
            //         platform_id: regionAndSummonerId.region,
            //         puu_id: regionAndSummonerId.puu_id,
            //     }
            // })
        ])


        const getMatches = yield all(getMatchInfo.map((data) => {
            return call(getSingleMatch, {
                match_creation_time: data.match_creation_time,
                match_id: data.match_id, region: matchInfoArgs.region, puu_id: matchInfoArgs.puu_id,
            })
        }))


        const filtered = getMatches.filter((data) => data?.participants_list?.length > 0);

        yield put({
            type: GET_MATCH_BY_QUEUE_SUCCESS, data: {
                match: filtered || [],
            }
        })

    } catch (e) {
        console.error(e);
        yield put({
            type: GET_MATCH_BY_QUEUE_FAILURE, error: e,
        })
    }
}

function* getMatch(action) {
    try {

        const getMatchData = yield call(getMatchDataAPI, {
            ...action.data.match,

        });

        yield put({
            type: GET_MATCH_SUCCESS, data: getMatchData,
        })

    } catch (e) {
        yield put({
            type: GET_MATCH_FAILURE, error: e,
        })
    }
}

function* liveSummonerIngame() {
    try {
        // const liveData = yield call(getIngameDataAPI, action.data);

    } catch (e) {
    }
}

function* getShortLink(action) {
    try {
        throw "issue"
        // const getShortLinkData = yield call(getShortLinkAPI, action.data);
        // yield put({
        //     type: GET_SHORT_LINK_SUCCESS, data: getShortLinkData,
        // })
    } catch (e) {
        yield put({
            type: GET_SHORT_LINK_SUCCESS, data: {},
        })
    }
}

function* setShortLink(action) {
    try {
        const getShortLink = yield call(setShortLinkAPI, action.data)
        yield put({
            type: SET_SHORT_LINK_SUCCESS, data: getShortLink,
        })
    } catch (e) {

    }
}

function* getAutoComplete(action) {
    try {
        yield delay(500);
        let [getSummoner, influData] = yield all([call(getSearchSummonerAPI, {
            ...action.data,
            getRank: true
        }), call(getProAndStreamerSeachAPI, action.data)]);

// ⁦⁦awdkskdkdkdkdkds⁩ #⁦5832
//     ⁦⁦건강한코어⁩      #⁦KR1
//     ⁦⁦건강한코어⁩ #⁦KR1
//         let getSummoner = [];
//         const influData = yield  call(getProAndStreamerSeachAPI, action.data);

        yield put({
            type: GET_AUTO_COMPLETE_SUCCESS, data: {
                autoComplete: getSummoner,
                influData: influData,
            }
        })


    } catch (e) {
        yield put({
            type: GET_AUTO_COMPLETE_FAILURE, error: e,
        })
    }
}

function* multiListFetch(summoners, updateCheck = [], preview) {
    const multiList = yield all(summoners.map((summoner, index) => {
        if (preview && updateCheck && !(updateCheck[index] === 'available')) {
            return preview[index];
        }
        return call(mutliListAPI, {
            summoner: summoner,
            puu_id: summoner?.summoner_basic_info_dict?.puu_id,
            season: summoner?.currentSeason,
            summoner_id: summoner?.summoner_basic_info_dict?.summoner_id,
        })
    }));
    return multiList;
}

function* getMultiList(action) {
    try {

        const summonersArray = action?.data?.summoners?.split(/,|%2C/g);
        if (Array.isArray(summonersArray) && summonersArray.length > 0) {

            const summoners = yield all(summonersArray.map((name) => {
                const summonerName = decodeURIComponent(name).replace('#', '-');
                return call(getSummonerAPI, {
                    username: decodeURIComponent(summonerName), region: action.data.region
                })
            }));

            const reduceArray = summoners.reduce((sum, cur) => {
                if (cur !== 404 && typeof cur === 'object') {
                    sum.push({...cur, currentSeason: action.data.currentSeason});
                }
                return sum;
            }, []);


            const nameList = reduceArray.map((data) => encodeURIComponent(`${data.summoner_basic_info_dict.riot_id_name} #${data.summoner_basic_info_dict.riot_id_tag_line}`));

            yield put({
                type: SET_SHORT_LINK_REQUEST, data: {
                    summoner: encodeURIComponent(nameList.toString()),
                }
            })
            //             type: SET_SHORT_LINK_REQUEST,
            // data: {
            //     summoner: encodeURIComponent(splitedName.toString()),

            const multiList = yield multiListFetch(reduceArray);
            yield put({
                type: GET_MULTI_SUMMONER_LP_CHART_REQUEST, data: {
                    list: [...multiList], region: action.data.region,
                }
            })

            yield put({
                type: GET_MULTI_LIST_SUCCESS, data: {
                    list: [...multiList],
                }
            });


            yield put({
                type: GET_MULTI_LIST_UPDATE_REQUEST,
            })

            // const updateCheck = yield all(reduceArray.map((summoner) => call(_fetch_check_update, {
            //     puu_id: summoner?.summoner_basic_info_dict?.puu_id,
            //     platform_id: action.data.region,
            // })));
            //
            // yield all(
            //     all(reduceArray.map((summoner, index) => {
            //         if (!(updateCheck[index] === 'available')) {
            //             return null;
            //         }
            //         return call(_fetch_refresh_matches, {
            //             "queue_type": "RANKED_SOLO_5x5",
            //             "start_idx": 0,
            //             "count": 10,
            //             puu_id: summoner?.summoner_basic_info_dict?.puu_id,
            //             platform_id: getRegion(action.data.region),
            //         })
            //     })),
            //     all(reduceArray.map((summoner, index) => {
            //         if (!(updateCheck[index] === 'available')) {
            //             return null;
            //         }
            //         return call(_fetch_refresh_champion, {
            //             puu_id: summoner?.summoner_basic_info_dict?.puu_id,
            //             platform_id: action.data.region,
            //         })
            //     }))
            // )

            // const updatedList = yield multiListFetch(reduceArray, updateCheck, multiList);
            yield put({
                type: GET_MULTI_LIST_UPDATE_SUCCESS, data: {
                    list: [...multiList],
                }
            })
        }

    } catch (e) {
        console.log(e);
    }
}

function* getMultiLPChart(action) {
    try {

        const summonerList = action.data.list;

        const tierList = yield all(summonerList.map((data) => {

            const regionAndSummonerId = {
                summoner_id: data?.summoner?.summoner_basic_info_dict?.summoner_id,
                puu_id: data?.summoner?.summoner_basic_info_dict?.puu_id,
                region: action?.data?.region,
            }
            return call(getTierChartAPI, regionAndSummonerId);
        }));


        yield put({
            type: GET_MULTI_SUMMONER_LP_CHART_SUCCESS, data: {
                list: [...tierList],
            }
        })
    } catch (e) {

    }
}

function* championsBySeason(action) {
    try {
        const obj = {
            puu_id: action.data.puu_id, season: action.data.season,
            platform_id: action.data.platform_id,
        }

        const result = yield call(getSummonerStatus, obj);


        yield put({
            type: GET_CHAMPIONS_BY_SEASON_SUCCESS, data: {
                summonerStatus: result,
                season: obj.season,
            }

        })

    } catch (e) {
        yield put({
            type: GET_CHAMPIONS_BY_SEASON_FAILURE,
            error: e,
        })
        console.log(e);
    }
}

function* getGameAnalyticsPatch(action) {
    try {
        const obj = {
            version: action.data.version,
        }
        const result = yield call(gameAnalyticsPatch, obj);

        yield put({
            type: GET_GAME_ANALYTICS_PATCH_SUCCESS, data: {
                version: obj.version, patch_correction_list: result.patch_correction_list,
            }
        })
    } catch (e) {
        console.log(e);
    }
}

function* getIngameInfo(action) {
    try {
        const matchInfo = yield select((state) => state.summoner.match);
        const me = yield select((state) => state.summoner.summoner);
        yield select((state) => state.summoner.tierChart);
        const currentSeason = yield select((state) => state.imageStore.currentSeason);

        const last_id = matchInfo?.[0]?.match_basic_dict?.match_id || 0;
        const obj = {
            summonerId: action.data.summonerId, region: action.data.region
        };

        const ingameBasic = action.data.ingameBasic;


        // const ingameSummonerList = yield all(ingameBasic.participants_list.map((data) => {
        //     if (me.summoner_basic_info_dict.riot_id_name === data.riot_id_name) {
        //         return me;
        //     }
        //
        //     return call(getSummonerCached, ({
        //         summoner_id: data.summoner_id, region: action.data.region, last_id,
        //     }))
        // }));

        // const positionObj = getIngamePositionObj(sortChampData(ingameBasic.participants_list, [{
        //     value: 'teamId',
        //     sortType: false
        // }]));

        const queue = ingameBasic.queue_id;
        const now_id = action.data.match_id || 0
        // const partObj = {
        //     currentSeason, queue, region: obj.region, now_id, map: ingameBasic.map
        // }

        const ingameSummonerList = ingameBasic.participants_list;

        // let [ingameSummonerList, part] = yield all([
        //     all(ingameBasic.participants_list.map((data) => {
        //         if (me.summoner_basic_info_dict.riot_id_name === data.riot_id_name) {
        //             return {
        //                 summoner: {
        //                     summoner_basic_info_dict: me.summoner_basic_info_dict
        //                 },
        //                 realtime: {
        //                     challenges_dict: me.challenges_dict,
        //                     season_tier_info_dict: me.season_tier_info_dict
        //                 }
        //             };
        //         }
        //
        //         return call(getSummonerCached, ({
        //             summoner_id: data.summoner_id, region: action.data.region, last_id,
        //         }))
        //     })),
        //     all([call(getPartAPI, {
        //             players: positionObj.Top, ...partObj, position: "Top"
        //         }), call(getPartAPI, {
        //             players: positionObj.Jungle, ...partObj, position: "Jungle"
        //         }), call(getPartAPI, {
        //             players: positionObj.Middle, ...partObj, position: "Middle"
        //         }), call(getPartAPI, {
        //             players: positionObj.Bot, ...partObj, position: "Bot"
        //         }), call(getPartAPI, {players: positionObj.Supporter, ...partObj, position: "Supporter"})]
        //     )]
        // );

        // const partAddRank = part.map((data) => {
        //     const blueSummonerId = data.blue.summoner_id
        //     const redSummonerId = data.red.summoner_id;
        //
        //     const bluePlayer = ingameSummonerList.find((summoner) => summoner?.summoner?.summoner_basic_info_dict?.summoner_id === blueSummonerId);
        //     const redPlayer = ingameSummonerList.find((summoner) => summoner?.summoner?.summoner_basic_info_dict?.summoner_id === redSummonerId);
        //     return {
        //         ...data,
        //         blue: {
        //             ...bluePlayer.summoner,
        //             ...bluePlayer.realtime,
        //             ...data.blue,
        //         },
        //         red: {
        //             ...redPlayer.summoner,
        //             ...redPlayer.realtime,
        //             ...data.red,
        //         }
        //     }
        // })

        let blueTeam = ingameSummonerList.filter((data) => data.side === 'BLUE');
        let redTeam = ingameSummonerList.filter((data) => data.side === 'RED');
        // const combinedTeam = [...blueTeam, ...redTeam];
        // const filterZero = combinedTeam.filter((data) => data.summoner_info_dict.ai_score_avg === 0);


        // if (filterZero.length > 0) {
        //     yield getIngameUpdate({
        //         data: {
        //             list: filterZero,
        //             region: action.data.region
        //         }
        //     });
        //     const filterZeroSummoner = yield all(filterZero.map((data) => {
        //         return call(getSummonerCached, ({
        //             summoner_id: data.summoner_id, region: action.data.region,
        //         }))
        //     }));
        //
        //     console.log(filterZeroSummoner);
        //     const combinedSummoner = ingameSummonerList.map((data) => {
        //         const isZero = filterZeroSummoner.find((item) => item.summoner_basic_info_dict.summoner_id === data.summoner_basic_info_dict.summoner_id);
        //         if (isZero) {
        //             return isZero;
        //         }
        //         return data;
        //     });
        //
        //
        //     console.log(combinedSummoner);
        //
        //     const zeroPosition = getIngamePositionObj(sortChampData(combinedSummoner, [{
        //         value: "side", sortType: false
        //     }]), ingameBasic.participants_list);
        //
        //     part = yield all([call(getPartAPI, {
        //         players: zeroPosition.Top, ...partObj, position: "Top"
        //     }), call(getPartAPI, {
        //         players: zeroPosition.Jungle, ...partObj, position: "Jungle"
        //     }), call(getPartAPI, {
        //         players: zeroPosition.Middle, ...partObj, position: "Middle"
        //     }), call(getPartAPI, {
        //         players: zeroPosition.Bot, ...partObj, position: "Bot"
        //     }), call(getPartAPI, {players: zeroPosition.Supporter, ...partObj, position: "Supporter"})]);
        //
        //     blueTeam = part.map((data) => data.blue);
        //     redTeam = part.map((data) => data.red);
        // }
        yield put({
            type: GET_INGAME_INFO_SUMMONER_SUCCESS, data: {
                basic: ingameBasic, blueTeam, redTeam,
            }
        })
        // const model = yield call(getIngameModelApi, partAddRank);

        // const ingameChartList = yield all(ingameBasic.participants_list.map((data) => {
        //     if (me.summoner_basic_info_dict.summoner_id === data.summoner_id) {
        //         return {
        //             ...meChart,
        //             summoner: {...me},
        //             summoner_id: data.summoner_id,
        //             region: action.data.region,
        //         };
        //     }
        //
        //     return call(getTierChartIngameAPI, ({
        //         summoner_id: data.summoner_id, region: action.data.region, summoner: {
        //             ...data,
        //         }
        //     }))
        // }));
        //
        // const ingameChartListSide = ingameChartList.map((data) => {
        //     const blueFind = blueTeam.find((summoner) => summoner.summoner_id === data.summoner_id);
        //     if (blueFind) {
        //         return {
        //             ...data, summoner: blueFind, side: blueFind?.side
        //         }
        //     } else {
        //         const redFind = redTeam.find((summoner) => summoner.summoner_id === data.summoner_id);
        //         return {
        //             ...data, summoner: redFind, side: redFind?.side,
        //         }
        //     }
        // }, []);


        // const ingameChartListBlue = ingameChartListSide.filter((data) => data.side === 'BLUE');
        // const ingameChartListRed = ingameChartListSide.filter((data) => data.side === 'RED');

        //
        yield put({
            type: GET_INGAME_INFO_SUCCESS, data: {
                model: {},
            }
        })


    } catch (e) {
        console.log(e);
        yield put({
            type: GET_INGAME_INFO_FAILURE, error: e,
        })
    }

}

function* getMasteryBuild(action) {
    try {
        const summoner_id = action.data.summoner_id
        const platformId = action?.data?.platform_id;
        const obj = {
            summoner_id,
            platformId,
        }
        const model = yield call(getMasteryBuildAPI, obj);
        yield put({
            type: GET_MASTERY_BUILD_SUCCESS,
            data: {
                model,
            }
        })
    } catch (e) {
        yield put({
            type: GET_MASTERY_BUILD_FAILURE,
            data: {
                error: e,
            }
        })
    }
}


function* getSummonerChampionAram(action) {
    try {
        const summoner = yield select((state) => state.summoner.summoner);
        const puu_id = summoner?.summoner_basic_info_dict?.puu_id;
        const season = action?.data?.season;
        const obj = {
            puu_id,
            season,
            platform_id: action.data.platform_id
        }
        const model = yield call(getSummonerARAMStatus, obj);
        yield put({
            type: GET_SUMMONER_CHAMPION_ARAM_SUCCESS,
            data: model,
        })
    } catch (e) {
        yield put({
            type: GET_SUMMONER_CHAMPION_ARAM_FAILURE,
            data: {
                error: e,
            }
        })
    }
}


function* getSummonerChampionUrf(action) {
    try {
        const summoner = yield select((state) => state.summoner.summoner);
        const puu_id = summoner?.summoner_basic_info_dict?.puu_id;
        const season = action?.data?.season;
        const obj = {
            puu_id,
            season,
            platform_id: action.data.platform_id
        }
        const model = yield call(getSummonerURFStatus, obj);
        yield put({
            type: GET_SUMMONER_CHAMPION_URF_SUCCESS,
            data: model
        })
    } catch (e) {
        yield put({
            type: GET_SUMMONER_CHAMPION_URF_FAILURE,
            error: e
        })
    }
}

function* summonerRealTime(action) {
    try {
        const update = yield call(_fetch_refresh_tier, action.data);
        if (update) {
            const model = yield call(getSummonerRank, action.data);
            yield put({
                type: GET_SUMMONER_REAL_TIME_SUCCESS,
                data: {
                    model,
                }
            })
        } else {
            throw "SUMMONER_REAL_TIME_ERROR";
        }
    } catch (e) {
        console.log("failure", e)
        yield put({
            type: GET_SUMMONER_REAL_TIME_FAILURE,
            error: e,

        })
    }
}


function* getUpdateChamp(action) {
    try {
        const update = yield call(_fetch_refresh_champion, action.data);
        if (update) {
            yield put({
                type: GET_CHAMPIONS_BY_SEASON_REQUEST,
                data: {
                    ...action.data,
                }
            });
            yield put({
                type: GET_UPDATE_CHAMPION_SUCCESS,
            })
        }
    } catch (e) {
        yield put({
            type: GET_UPDATE_CHAMPION_FAILURE,
            error: e,

        })
    }
}


function* getUpdateMatches(action) {
    try {
        const [match, champ] = yield all([call(_fetch_refresh_matches, action.data), call(_fetch_refresh_champion, action.data)]);
        if (match && champ) {
            yield put({
                type: GET_MATCH_BY_QUEUE_REQUEST,
                data: {
                    ...action.data,
                    queueNum: action?.data?.queue_id || 'ALL',
                    champId: 0,
                    region: action.data.platform_id,
                    noLoading: Boolean(action?.data?.noLoading),
                    callChamp: true,
                    match_creation_time: 1,
                }
            })
            yield put({
                type: GET_UPDATE_MATCHES_SUCCESS,
            })
        }
    } catch (e) {
        yield put({
            type: GET_UPDATE_MATCHES_FAILURE,
            error: e,

        })
    }
}

function* getSummonerAI(action) {
    try {
        const obj = {
            platform_id: action.data.platform_id,
            puu_id: action.data.puu_id
        }
        const dailyAi = yield call(summonerAIAPI, obj);

        yield put({
            type: GET_SUMMONER_AI_SUCCESS,
            data: {
                dailyAi: dailyAi
            }
        })
        // const temp = [
        //     {
        //         "puu_id": "TTg8NfznEJYEsK-B0CtUALidmIprjLUrjlH_CTlgu_Iq0iPr30FR4glWDi0mB945uEfkWrWXL9hZnA",
        //         "match_id": "KR_7033751704",
        //         "game_type": 420,
        //         "champion_id": 777,
        //         "lane": "Middle",
        //         "kills": 7,
        //         "deaths": 1,
        //         "assists": 8,
        //         "ai_score": 82.05,
        //         "ranking": 2
        //     },
        //     {
        //         "puu_id": "TTg8NfznEJYEsK-B0CtUALidmIprjLUrjlH_CTlgu_Iq0iPr30FR4glWDi0mB945uEfkWrWXL9hZnA",
        //         "match_id": "KR_7033976268",
        //         "game_type": 420,
        //         "champion_id": 64,
        //         "lane": "Jungle",
        //         "kills": 17,
        //         "deaths": 1,
        //         "assists": 16,
        //         "ai_score": 86.22,
        //         "ranking": 3
        //     },
        //     {
        //         "puu_id": "TTg8NfznEJYEsK-B0CtUALidmIprjLUrjlH_CTlgu_Iq0iPr30FR4glWDi0mB945uEfkWrWXL9hZnA",
        //         "match_id": "KR_7033976268",
        //         "game_type": 420,
        //         "champion_id": 64,
        //         "lane": "Jungle",
        //         "kills": 17,
        //         "deaths": 1,
        //         "assists": 16,
        //         "ai_score": 86.22,
        //         "ranking": 3
        //     },
        //     {
        //         "puu_id": "TTg8NfznEJYEsK-B0CtUALidmIprjLUrjlH_CTlgu_Iq0iPr30FR4glWDi0mB945uEfkWrWXL9hZnA",
        //         "match_id": "KR_7033976268",
        //         "game_type": 420,
        //         "champion_id": 64,
        //         "lane": "Jungle",
        //         "kills": 17,
        //         "deaths": 1,
        //         "assists": 16,
        //         "ai_score": 86.22,
        //         "ranking": 3
        //     },
        //     {
        //         "puu_id": "TTg8NfznEJYEsK-B0CtUALidmIprjLUrjlH_CTlgu_Iq0iPr30FR4glWDi0mB945uEfkWrWXL9hZnA",
        //         "match_id": "KR_7033976268",
        //         "game_type": 420,
        //         "champion_id": 64,
        //         "lane": "Jungle",
        //         "kills": 17,
        //         "deaths": 1,
        //         "assists": 16,
        //         "ai_score": 86.22,
        //         "ranking": 3
        //     },
        //     {
        //         "puu_id": "TTg8NfznEJYEsK-B0CtUALidmIprjLUrjlH_CTlgu_Iq0iPr30FR4glWDi0mB945uEfkWrWXL9hZnA",
        //         "match_id": "KR_7033976268",
        //         "game_type": 420,
        //         "champion_id": 64,
        //         "lane": "Jungle",
        //         "kills": 17,
        //         "deaths": 1,
        //         "assists": 16,
        //         "ai_score": 86.22,
        //         "ranking": 3
        //     },
        //     {
        //         "puu_id": "TTg8NfznEJYEsK-B0CtUALidmIprjLUrjlH_CTlgu_Iq0iPr30FR4glWDi0mB945uEfkWrWXL9hZnA",
        //         "match_id": "KR_7033976268",
        //         "game_type": 420,
        //         "champion_id": 64,
        //         "lane": "Jungle",
        //         "kills": 17,
        //         "deaths": 1,
        //         "assists": 16,
        //         "ai_score": 86.22,
        //         "ranking": 3
        //     },
        //     {
        //         "puu_id": "TTg8NfznEJYEsK-B0CtUALidmIprjLUrjlH_CTlgu_Iq0iPr30FR4glWDi0mB945uEfkWrWXL9hZnA",
        //         "match_id": "KR_7033976268",
        //         "game_type": 420,
        //         "champion_id": 64,
        //         "lane": "Jungle",
        //         "kills": 17,
        //         "deaths": 1,
        //         "assists": 16,
        //         "ai_score": 86.22,
        //         "ranking": 3
        //     }
        // ]
    } catch (e) {
        console.log(e)
        yield put({
            type: GET_SUMMONER_AI_FAILURE,
        })
    }
}


function* watchGetMoreMatches() {
    yield takeLatest(GET_MORE_MATCHES_REQUEST, getMoreMatches)
}

function* watchGetMatches() {
    yield takeLatest(GET_SUMMONER_REQUEST, getSummoner)
}

function* watchGetChampInfo() {
    yield takeLatest(GET_CHAMP_INFO_REQUEST, getChampInfo)
}

function* watchGetSummonerInfo() {
    yield takeLatest(GET_SUMMONER_INFO_REQUEST, getSummonerInfo);
}


function* watchUpdateSummonerInfo() {
    yield takeLatest(UPDATE_SUMMONER_INFO_REQUEST, updateSummonerInfo);
}


function* watchGetMatchByQueue() {
    yield takeLatest(GET_MATCH_BY_QUEUE_REQUEST, getMatchByQueue);
}

function* watchLiveSummonerIngame() {
    yield takeLatest("??????", liveSummonerIngame);
}

function* watchGetMatch() {
    yield takeLatest(GET_MATCH_REQUEST, getMatch);
}

function* watchShortLink() {
    yield takeLatest(GET_SHORT_LINK_REQUEST, getShortLink);
}

function* watchSetShortLink() {
    yield takeLatest(SET_SHORT_LINK_REQUEST, setShortLink);
}

function* watchAutoComplete() {
    yield takeLatest(GET_AUTO_COMPLETE_REQUEST, getAutoComplete);
}

function* watchGetMultiList() {
    yield takeLatest(GET_MULTI_LIST_REQUEST, getMultiList);
}

function* watchLPchart() {
    yield takeLatest(GET_SUMMONER_LP_CHART_REQUEST, getLPChart);
}

function* watchGetIngameCheck() {
    yield takeLeading(GET_INGAME_CHECK_REQUEST, getIngameCheck);
}

function* watchGetMultiLPChart() {
    yield takeLatest(GET_MULTI_SUMMONER_LP_CHART_REQUEST, getMultiLPChart);
}

function* watchGetChampionsBySeason() {
    yield takeLatest(GET_CHAMPIONS_BY_SEASON_REQUEST, championsBySeason);
}


function* watchGetIngameInfo() {
    yield takeLeading(GET_INGAME_INFO_REQUEST, getIngameInfo);
}

function* watchGetMasteryBuild() {
    yield takeLatest(GET_MASTERY_BUILD_REQUEST, getMasteryBuild);
}

function* watchSummonerChampionAram() {
    yield takeLatest(GET_SUMMONER_CHAMPION_ARAM_REQUEST, getSummonerChampionAram);
}

function* watchSummonerChampionUrf() {
    yield takeLatest(GET_SUMMONER_CHAMPION_URF_REQUEST, getSummonerChampionUrf);
}


function* watchSummonerRealTime() {
    yield takeLatest(GET_SUMMONER_REAL_TIME_REQUEST, summonerRealTime);
}

function* watchUpdateChamp() {
    yield takeLatest(GET_UPDATE_CHAMPION_REQUEST, getUpdateChamp);
}

function* watchUpdateMatches() {
    yield takeLatest(GET_UPDATE_MATCHES_REQUEST, getUpdateMatches);
}

function* watchSummonerAI() {
    yield takeLatest(GET_SUMMONER_AI_REQUEST, getSummonerAI)
}


export default function* summonerSaga() {
    yield all([
        fork(watchGetMoreMatches),
        fork(watchGetMatches),
        fork(watchGetChampInfo),
        fork(watchGetSummonerInfo),
        fork(watchUpdateSummonerInfo),
        fork(watchGetMatchByQueue),
        fork(watchLiveSummonerIngame),
        fork(watchGetMatch),
        fork(watchShortLink),
        fork(watchSetShortLink),
        fork(watchAutoComplete),
        fork(watchGetMultiList),
        fork(watchLPchart),
        fork(watchGetIngameCheck),
        fork(watchGetMultiLPChart),
        fork(watchGetChampionsBySeason),
        fork(watchGetIngameInfo),
        fork(watchGetMasteryBuild),
        fork(watchSummonerChampionUrf),
        fork(watchSummonerChampionAram),
        fork(watchSummonerRealTime),
        fork(watchUpdateChamp),
        fork(watchUpdateMatches),
        fork(watchSummonerAI),
    ])
}