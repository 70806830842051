// 액션 생성
const GAME_VERSION = 'gameVersion/GAME_VERSION';
export const CLOUDFLARE_VERSION = 'gameVersion/CLOUDFLARE_VERSION'

// 액션 함수 내보내기
export const changeVersion = (version,versionList) => ({ type: GAME_VERSION, version,versionList});

// 초기 상태 값
const initialState = {
  version: '13.3.1',
  cloudflareVersion: '13.3',
  versionList:[],
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLOUDFLARE_VERSION: {
      return {
        ...state,
        cloudflareVersion: action.data
      }
    }
    case GAME_VERSION:
      return {
        ...state,
        version: action.version,
        versionList: action.versionList
      };
    default:
      return state;
  }
};
export default reducer;
