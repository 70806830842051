import React, {useCallback, useMemo} from 'react';
import {ChampionImageLane} from "../ImageSrc";
import styled, {useTheme} from "styled-components";
import MatchChampImg from "../../summoner/MathItem/ChampImg";
import {useSelector} from "react-redux";
import useChampionFunc from "../../../common/hooks/useChampionFunc";
import BigTooltip from "../../Tooltip/BigTooltipBox";
import {useTranslation} from "react-i18next";
import swarm_champ from "../../../store/static_json/swarm_champ";

const ChampImageWrapper = styled.div`
  position: relative;
  display: flex;

  ${props => !props.disabled ? `
  &:hover {
    scale: 1.2;
    transition: scale .2s ease-in-out;
  }
  ` : ""}
  .imgDiv {
    z-index: 21;

  }

  .imgLane {
    border-bottom-right-radius: 4px;
  }
`;

const ChampImageAbsoluteLane = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  aspect-ratio: 1;
  justify-content: center;
  z-index: 23;
  bottom: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.7);
`;

const ChampImageAbsoluteLaneLeft = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  aspect-ratio: 1;
  justify-content: center;
  z-index: 23;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
`;
const ChampionAbsoluteCount = styled.div`
  padding: 1px;
  min-width: 2ch;
  position: absolute;
  display: flex;
  aspect-ratio: 1;
  align-items: center;
  justify-content: center;
  z-index: 1;
  bottom: 0;
  right: 0;
  background-color: #000;
`;

const ChampImageAbsoluteLevel = styled.div`
  padding: 1px;
  min-width: 2ch;
  position: absolute;
  display: flex;
  aspect-ratio: 1;
  align-items: center;
  justify-content: center;
  z-index: 1;
  bottom: 0;
  right: 0;
  background-color: #000;
  border: 1px solid #fff;
`;
const LevelText = styled.span`
  color: #fff;
  font-size: 10px;
  opacity: 1;
`;

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

`;

const ChampNameWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  padding-bottom: 1px;
  font-weight: 500;
`;
const ChampName = styled.span`
  margin-left: 6px;
  font-weight: normal;

  &:hover {
    text-decoration: underline;
  }

`;


const BanSign = styled.div`
  position: absolute;
  width: 2px;
  height: 32px;
  background-color: #fff;
  transform: rotate(45deg);
  top: 0;
  right: 15px;
`;


const ChampionImageWithLane = ({
                                   emptyHelmet,
                                   disabled,
                                   champion_id,
                                   position,
                                   positionLeft,
                                   absoluteWidth,
                                   borderRadius,
                                   disableImage,
                                   fullImage,
                                   level,
                                   enableChampName,
                                   navigateToMastery,
                                   count,
                                   banSign,
                               }) => {

    const {t} = useTranslation();
    const dataSelector = useSelector((state) => state.imageStore.champions);
    const {getChampionName} = useChampionFunc();
    const theme = useTheme();
    const championData = useMemo(() => {
        if (dataSelector.length !== 0) {
            let id = champion_id
            if (champion_id > 3000) {
                const findChamp = swarm_champ.find((data) => data.swarmChampionId === champion_id);
                if (findChamp) {
                    id = findChamp.championId
                }
            }
            if (dataSelector.filter((item) => item.champion_id === `${id}`).length !== 0) {
                return dataSelector.find((item) => item.champion_id === `${id}`);
            }
        }
        return {};
    }, [champion_id, dataSelector]);


    const checkPosition = useCallback(() => {
        if (position) {
            return position?.toUpperCase() !== 'ALL'
        } else {
            return false;
        }

    }, [position]);

    const navigateToBuild = useCallback((e) => {
        if (!disabled) {
            e.stopPropagation();
            const champName = championData?.champion_name_en?.replace(/\s|&|willump|!|/gi, "")?.toLowerCase();
            if (!champName) {
                return;
            }
            if (navigateToMastery) {
                window.open(`/champions/${champName}/mastery/all?region=${navigateToMastery}`);
            } else if (championData.champion_name_en) {
                window.open(`/champions/${champName}/build`);
            }
        }
    }, [championData, disabled]);


    return (
        <Wrapper>
            <ChampImageWrapper champId={champion_id} borderRadius={borderRadius} disabled={disabled}
                               onClick={navigateToBuild}>
                <BigTooltip
                    fontweight={'bold'}
                    textColor={'#17c791'}
                    tooltipText={`<div style="color: #46B9AA;font-weight: 500;">${champion_id === 0 ? t('championAnalyze.all') : championData.champion_name}</div>`}
                >
                    <MatchChampImg champId={champion_id} fullImage={fullImage} imgObj={championData}
                                   emptyHelmet={emptyHelmet}/>
                    {(checkPosition() && positionLeft) && <ChampImageAbsoluteLaneLeft absoluteWidth={absoluteWidth}>
                        {!disableImage && <ChampionImageLane lane={position} color={theme.color.theme}/>}
                    </ChampImageAbsoluteLaneLeft>}

                    {(checkPosition() && !positionLeft) && <ChampImageAbsoluteLane absoluteWidth={absoluteWidth}>
                        {!disableImage && <ChampionImageLane lane={position} color={theme.color.theme}/>}
                    </ChampImageAbsoluteLane>}

                    {level && <ChampImageAbsoluteLevel><LevelText>{level}</LevelText></ChampImageAbsoluteLevel>}
                    {count && <ChampionAbsoluteCount><LevelText>{count}</LevelText></ChampionAbsoluteCount>}

                    {banSign && <BanSign/>}
                </BigTooltip>
            </ChampImageWrapper>
            {enableChampName && <ChampNameWrapper className={'champNameWrapper'}><ChampName className={'champName'}
                                                                                            onClick={navigateToBuild}>{getChampionName(championData)}</ChampName></ChampNameWrapper>}
        </Wrapper>
    );
};

export default ChampionImageWithLane;
