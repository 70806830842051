import React from 'react';
import styled from "styled-components";

const Graph = styled.div`
  display: flex;
  justify-content: ${props => {
    let content = 'flex-end'
    if (props.reverse) content = 'flex-start';
    return content;
  }};
  width: 100%;
  height: ${props => props.height + "px" || "4px"};
  background-color: ${props => props.bgColor || '#ffffff'};
`;

const Filled = styled.div`
  height: ${props => props.height || "4px"};
  width: ${props => props.percent}%;
  background-color: ${props => props.color};
`;

const FilledBar = ({barColor, percent, barHeight, bgColor, reverse}) => {
    return (
        <Graph
            reverse={reverse}
            bgColor={bgColor}
            height={barHeight}>
            <Filled
                height={barHeight}
                percent={percent} color={barColor}/>
        </Graph>
    );
};

export default React.memo(FilledBar);
