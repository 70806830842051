import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import ScoreWrapper from "./DetailTab/GameAnalytics/BestContent/BestSection/ScoreWrapper";
import ColorRankBox from "./ColorRankBox";
import {isAram, isArena, isSwarm, threedigits} from "../../function";
import {ColumnBox, FlexBox} from "../ui/styled";


const AIScoreText = styled.h5`
  z-index: 2;
  color: ${props => props.theme.color.dark_200};
`;

const HardCarryEffect = styled.div`
  @keyframes loading {
    0% {
      transform: skew(25deg) translateX(740px);
      opacity: 1;
    }
    80% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      transform: skew(25deg);
    }
  }

  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 100%;
  background: #fff;
  opacity: 0.8;
  animation: loading 2.3s infinite linear;
  filter: blur(37px);
`;

const RatingBox = styled(FlexBox)`
  z-index: 2;
  color: ${props => props.theme.color.dark_100};

  .MVP {
    color: #F3A634;
    font-weight: 700;
  }

  .ACE {
    font-weight: 700;
    color: #8e9ec4;
  }

`;


const MarginTopDiv = styled.div`
  z-index: 2;
  height: 100%;
  display: flex;
  justify-content: ${props => {
    let content = 'space-between';
    if (props.isUrf) content = 'center';
    return content;
  }};
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-top: ${props => props.paddingTop}px;
  padding-bottom: ${props => props.paddingBottom}px;
  margin-left: ${props => props.marginLeft}px;
`;

const CrownMvp = styled.span`
  display: flex;
  background-image: url('images/ico_best_player01.svg');
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-top: 2px;
  content: '';
`;

// noinspection CssUnknownTarget
const CrownAce = styled.span`
  display: flex;
  background-image: url('images/ico_best_player04.svg');
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-top: 2px;
  content: '';
`;


const SwarmFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 139px;
  height: 100%;
  padding-bottom: 7px;
`;

const SwarmText = styled.span`
  color: ${props => props.theme.color.dark_100};
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
`;

const SwarmTitle = styled.span`
  color: ${props => props.theme.color.font_58_A7};
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 5px;
`;


const MatchScoreWrapper = ({match, myMatch, getMvp, getRank, RatingRender}) => {
    const {t} = useTranslation()


    if (isSwarm(match.match_basic_dict.queue_id)) {
        return (
            <SwarmFlexBox>
                <ColumnBox alignItems={'center'}>
                    <SwarmTitle>{t('swarm.slain')}</SwarmTitle>
                    <SwarmText>{threedigits(myMatch.final_stat_dict.cs)}</SwarmText>
                </ColumnBox>
            </SwarmFlexBox>
        )
    }

    return (
        <ColumnBox height={'auto'} alignItems={'center'} margin={'6px 0 0 '} width={'140px'}>
            <AIScoreText>{isArena(match.match_basic_dict.queue_id) ? t('summoner.arenaRank') : "AI-Score"}</AIScoreText>
            <MarginTopDiv paddingTop={'8'} paddingBottom={'5'}>
                {isArena(match.match_basic_dict.queue_id) ?
                    <ColorRankBox rank={myMatch.arena_team.placement} width={'45px'}
                                  height={'28px'}/> :
                    <ScoreWrapper
                        fontSize={19}
                        noneClickable={isAram(match.match_basic_dict.queue_id)}
                        paddingHorizontal={8}
                        score={myMatch.is_runaway ? 0 : myMatch.ai_score}
                        hoverScale={!isAram(match.match_basic_dict.queue_id)}
                    />}
            </MarginTopDiv>
            {!isArena(match.match_basic_dict.queue_id) &&
                <RatingBox>
                    {myMatch?.is_win ? (getMvp && <CrownMvp/>) : (getMvp && <CrownAce/>)}
                    {match.remake ? (<>
                        <i>-</i>
                    </>) : (<RatingRender
                            rating={getRank}
                            match={myMatch}
                        />

                    )}
                </RatingBox>}
        </ColumnBox>
    );
};

export default MatchScoreWrapper;