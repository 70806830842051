import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AllCsText = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.dark_200};;
`;

const MinText = styled.span`
  margin-top: 8px;
  font-size: 11px;
  color: ${props => props.theme.color.dark_200};
  font-weight: 500;
`;


const CsInfo = ({cs, duration}) => {
    const {t} = useTranslation();
    return (
        <Wrapper>
            <AllCsText>{cs}</AllCsText>
            <MinText> ({duration}/{t('summoner.minute')})</MinText>
        </Wrapper>
    );
};

export default CsInfo;
