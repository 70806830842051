import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import ChampLane from "../../../../summoner/Champ/ChampLane";
import regionList from "../../../../../store/static_json/regionList";

const Wrapper = styled.div`
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LangAndLaneWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const LaneWrapper = styled.div`
  width: 480px;
  height: 40px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  background-color: ${props => props.theme.color.site_main_color};
  border-radius: 6px;
  margin-right: 18px;
  height: 40px;

  select {
    &:hover {
      background-color: ${props => props.theme.color.white_200};
    }
    cursor: pointer;
    color: ${props => props.theme.color.dark_200};
    border: 1px solid ${props => props.theme.color.default_border_color};
    border-radius: 6px;
    padding: 0 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 80px;
    height: 100%;
    background: ${props => `url("${props.theme.icon.path}/icon_select_arrow.svg")`} no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
  }

  option {
    background: ${props => props.theme.color.site_main_color};
    color: ${props => props.theme.color.dark_200};
  }
`;


const VersionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const VersionSpan = styled.span`
  font-size: 12px;
  transform: scale(.891);
`;


const RankingTableFilter = ({
                                rankingRegion,
                                setCurrentLane,
                                currentLane,
                                onClickRegion,
                                botRate,
                                midRate,
                                jugRate,
                                supRate,
                                topRate,

                            }) => {
    const onChangePosition = useCallback((lane) => {
        if (typeof lane === 'string') {
            setCurrentLane(lane);
        }
    }, [setCurrentLane]);

    const laneRate = useCallback((value) => {
        const currentValue = typeof value === 'number' ? value : 0;
        return (currentValue * 100).toFixed(0)
    }, [])

    const laneValue = useCallback((lane) => {
        switch (lane) {
            case 'Top': {
                return laneRate(topRate);
            }
            case 'Jungle': {
                return laneRate(jugRate);
            }
            case 'Middle': {
                return laneRate(midRate);
            }
            case 'Bot': {
                return laneRate(botRate);
            }
            case 'Supporter': {
                return laneRate(supRate);
            }
        }
    }, [botRate, midRate, jugRate, supRate, topRate]);

    const filteredList = useMemo(() => {
        return regionList.filter((data) => data.rank)
    },[]);

    return (
        <Wrapper>
            <LangAndLaneWrapper>
                <SelectWrapper>
                    <select onChange={onClickRegion}>
                        {filteredList.map((data) => {
                            if (!data.disabled) {
                                return <option value={data.title}
                                               selected={rankingRegion.serverName === data.serverName}
                                               key={data.title}>{data.title}</option>
                            } else {
                                return null;
                            }

                        })}
                    </select>
                </SelectWrapper>
                <LaneWrapper>
                    <ChampLane
                        allText={true}
                        mapValue={laneValue}
                        onChangePosition={onChangePosition}
                        selectedLane={currentLane}
                    />
                </LaneWrapper>
            </LangAndLaneWrapper>
        </Wrapper>
    );
};

export default RankingTableFilter;
