import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

const UseSummonerTitleData = () => {
    const summoner = useSelector((state) => state.summoner.summoner);
    const {userName,region} = useParams();
    const currentUserName = useMemo(() => {
        if (userName && summoner) {
            return summoner?.summoner_basic_info_dict?.riot_id_name;
        } else {
            return userName;
        }
    }, [summoner, userName]);

    const currentTagName = useMemo(() => {
        if (userName && summoner) {
            return summoner?.summoner_basic_info_dict?.riot_id_tag_line
        } else {
            return '#';
        }
    }, [summoner, userName])

    const currentProName = useMemo(() => {
        if (userName && summoner) {
            return summoner?.summoner_basic_info_dict?.pro_streamer_info_dict?.name
        } else {
            return '';
        }
    }, [summoner, userName])

    return {
        location: region,
        currentProName,
        currentTagName,
        currentUserName: decodeURI(currentUserName)
    }
};

export default UseSummonerTitleData;