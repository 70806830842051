import React, {useLayoutEffect} from 'react';
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

const UseSetCanonical = () => {

    const location = useLocation();
    const {i18n} = useTranslation();

    useLayoutEffect(() => {
        const canonical = document.querySelector('link[rel=canonical]');
        const langAlter = document.querySelectorAll('link[rel=alternate]');

        langAlter.forEach((data) => {

            const lang = data.getAttribute('hreflang')
            if(lang === 'x-default') {
                data.setAttribute('href',`https://www.deeplol.gg${location.pathname}`)
            } else {
                data.setAttribute('href',`https://www.deeplol.gg${location.pathname}?hl=${lang}`)
            }
            // data.setAttribute('href',)
        })

        canonical.setAttribute('href',`https://www.deeplol.gg${location.pathname}?hl=${i18n.language}`)
    },[location.pathname]);

    return null
};

export default UseSetCanonical;