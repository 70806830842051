import React from 'react';
import ChampionsInput from "../../../../Champions/ChampionsContent/ChampionsInput";
import ChampionsHeader from "../../../../Champions/ChampionsContent/ChampionsHeader";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {FlexBox} from "../../../../ui/styled";
import AramBalanceCname from "../AramBalanceCname";
import AramBalanceFilter from "../AramBalanceFilter";

const ExtraText = styled.span`
  font-size: 12px;
  opacity: 0.8;
  color: ${props => props.theme.color.dark_200};
  margin-left: 8px;
`;
const TitleText = styled.span`
  color: ${props => props.theme.color.dark_100};
  font-size: 16px;
  font-weight: bold;
  margin-right: 12px;
`;

const AramBalanceHeader = ({inputValue, setInputValue, version, setChoName,position}) => {
    const {t} = useTranslation();
    return (
        <ChampionsHeader flexD={'column'} alignItems={'stretch'}>
            <FlexBox alignItems={'center'} justifyContent={'flex-start'}>
                <TitleText>{t('championDetailBuild.balance.balanceAramHeader')}<ExtraText>Patch {version}</ExtraText></TitleText>
                <ChampionsInput
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                />
            </FlexBox>
            <AramBalanceCname setChoName={setChoName}/>
            <AramBalanceFilter
                position={position}
            />
        </ChampionsHeader>
    );
};

export default AramBalanceHeader;
