import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  width: 1080px;
  height: fit-content;
  border: 1px solid ${props => props.theme.color.default_border_color};
  padding: 48px 0 32px;
  background-color: ${props => props.theme.color.site_main_color};
`;

const BoxImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 24px;
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 18px;
`;

const WaringText = styled.span`
  color: ${props => props.theme.color.font_3C};
  font-size: 14px;
  opacity: ${props => props.opacity};
`;

const MultiEmpty = () => {
    return (
        <Wrapper>
            <BoxImageWrapper>
                <img
                    src={'images/ico_multi_empty01.svg'}
                    width={87.7}
                />
            </BoxImageWrapper>
            <TextBox>
                <WaringText opacity={1}>입력하신 소환사명과 일치하는 검색 결과가 존재하지 않습니다. 오타를 확인해주세요.</WaringText>
                <WaringText opacity={"0.7"}>There are no search results matching the name of the summoner you entered. Please check for typos.</WaringText>
            </TextBox>
        </Wrapper>
    );
};

export default MultiEmpty;