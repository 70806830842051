import styled from "styled-components";

const FlexBox = styled.div`
  width: ${props => props.width};
  clear: both;
  display: flex;
  flex-direction: row;
  align-items: ${props => {
    let content = 'center';
    if (props.alignItems) content = props.alignItems;
    return content;
  }};
  justify-content: ${props => {
    let content = 'flex-start';
    if (props.justifyContent) content = props.justifyContent;
    return content;
  }};
  height: ${props => {
    let height = '100%';
    if (props.height) height = props.height;
    return height;
  }};
  padding: ${props => {
    let padding = 0;
    if (props.padding) padding = props.padding;
    return padding;
  }};
  background-color: ${props => {
    let color = 'transparent';
    if(props.background) color = props.background;
    if(props.test) color = 'red';
    return color;
  }};
  margin: ${props => {
    let margin = 0;
    if (props.margin) margin = props.margin;
    return margin;
  }};
  border: ${props => {
    let border = 0;
    if (props.border) border = props.border;
    return border;
  }};
  border-radius: ${props => {
    let border = 0;
    if (props.borderRadius) border = props.borderRadius;
    return border;
  }};
  overflow: ${props => {
    let overflow = 'visible';
    if(props.overflow) overflow = props.overflow
    return overflow;
  }};
`;

export const FlexOneBox = styled(FlexBox)`
  width: auto;
  flex: 1;
`;

const ColumnBox = styled.div`
  width: ${props => props.width || 'auto'};
  display: flex;
  flex-direction: column;
  justify-content: ${props => {
    let content = 'center';
    if (props.justifyContent) content = props.justifyContent;
    return content;
  }};
  align-items: ${props => {
    let content = 'flex-start';
    if (props.alignItems) content = props.alignItems;
    return content;
  }};
  height: ${props => {
    let height = '100%';
    if (props.height) height = props.height;
    return height;
  }};
  margin: ${props => {
    let margin = 0;
    if (props.margin) margin = props.margin;
    return margin;
  }};
  padding: ${props => {
    let padding = 0;
    if (props.padding) padding = props.padding;
    return padding;
  }};
  background-color: ${props => {
    let color = 'transparent';
    if(props.background) color = props.background;
    if(props.test) color = 'red';
    return color;
  }};
  border: ${props => {
    let border = 0;
    if (props.border) border = props.border;
    return border;
  }};
  border-radius: ${props => {
    let border = 0;
    if (props.borderRadius) border = props.borderRadius;
    return border;
  }};;
`;


export {FlexBox, ColumnBox}
