import React from 'react';
import styled from "styled-components";
import SpectateLiveTitle from "./title";
import SpectateLiveSummonerList from "./summonerList";
import SpectateLiveSummary from "./summary";
import useSpectateLive from "../../../../common/hooks/useSpectateLive";

const Wrapper = styled.div`
    width: 100%;
`;
const SpectateLiveCompound = ({children}) => {
    const {getSpectateLiveError} = useSpectateLive();


    if(getSpectateLiveError) {
        return <div>Something went wrong...</div>
    }
    return (
        <Wrapper>
            {children}
        </Wrapper>
    );
};

SpectateLiveCompound.summary = SpectateLiveSummary;
SpectateLiveCompound.title = SpectateLiveTitle;
SpectateLiveCompound.summoner = SpectateLiveSummonerList;

export default SpectateLiveCompound;