import React from 'react';
import styled from "styled-components";
import ChampionsAramHeader from "../../common/ChampionsAndAramHeader";
import CheckDataLayer from "../../DataLayer/CheckDataLayer";
import UseRailScrollEvent from "../../../common/hooks/useScrollEvent";


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;


const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: ${props => props.theme.color.site_background_color};
  border-radius: 6px;
  z-index: 1;
`;

const ContentDiv = styled.div`
  display: flex;
  width: 1080px;
  flex-direction: ${props => {
    let flexD = 'row';
    if (props.flexD) flexD = props.flexD;
    return flexD;
  }};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 10vh;
  z-index: 1;
`;

const HeaderAdBox = styled.div`
  width: 970px;
  height: 250px;
  margin-top: 15px;
`;

const AdBox = styled.div`
  flex: 1;
`;


const SmallRail = styled.div`
  width: 300px;
  margin-bottom: 10px;
`;


const LeftSideWrapper = styled.div`
  width: 300px;
`;



const StickyBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
`;



const RightSIdeStickyBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
`;

const StickyBoxWrapper = styled.div`
  width: 340px;
  padding: 20px;
  position: relative;
`

const CenterBox = ({children, flexD, disableExtraHeader = false, disableBottomAd}) => {

    return (
        <CheckDataLayer>

            <Wrapper className={'center_content'}>
                <LeftRailAd/>
                <ContentWrapper>
                    {!disableBottomAd &&
                        <HeaderAdBox id={'center_content_top_ad_box'} data-ad={'billboard'}>

                        </HeaderAdBox>}
                    <CenterDiv className={'center_content_wrapper'}>
                        {!disableExtraHeader && <ChampionsAramHeader/>}
                        <ContentDiv flexD={flexD}>
                            {children}
                        </ContentDiv>
                    </CenterDiv>
                    {!disableBottomAd && <HeaderAdBox id={'center_content_bottom_ad_box'}>
                    </HeaderAdBox>}
                </ContentWrapper>
                <RightRailAd/>
            </Wrapper>
        </CheckDataLayer>
    );
};



const LeftRailAd = () => {
    const ref = UseRailScrollEvent();
    return (
        <StickyBoxWrapper>
            <StickyBox ref={ref}>
                <SmallRail data-ad={'left-rail-1'}/>
                <LeftSideWrapper data-ad={"left-rail-2"}/>
            </StickyBox>
        </StickyBoxWrapper>
    )
}


const RightRailAd = () => {
    const ref = UseRailScrollEvent();
    return (
        <StickyBoxWrapper>
            <RightSIdeStickyBox ref={ref}>
                <SmallRail data-ad={'right-rail-1'}/>
                <LeftSideWrapper  data-ad={'right-rail-2'}/>
            </RightSIdeStickyBox>
        </StickyBoxWrapper>
    )
}


export default CenterBox;
